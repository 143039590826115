import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HelpIcon from '@material-ui/icons/Help';
import { LogsTable } from '../components';
import { __, formatAmount, request } from '../functions';
import '../assets/styles/product-lightbox.css';

/**
 * Product lightbox.
 */
class ProductLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen,
     *     id: number,
     * }}
     */
    static defaultProps = {
        screen: null,
        id: 0,
    };

    /**
     * Default state.
     *
     * @type {{
     *     data: Object,
     *     panel: number,
     * }}
     */
    state = {
        data: {},
        panel: 0,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { id, screen } = this.props;

        // Nacitame data produktu
        request(`/products/view/${id}`, false, 'GET', {}, false).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Produkt neexistuje
                screen.showSnackbar('error', __('Produkt neexistuje'));
                screen.closeLightbox('view');
                return;
            }

            // Nasetujeme data
            this.setState({ data });
        }).catch(error => {
            screen.showSnackbar('error', __('Produkt neexistuje'));
            screen.closeLightbox('view');
        });
    }

    /**
     * Zmena panelu.
     *
     * @param {number} panel
     */
    changePanel(panel) {
        this.setState({ panel });
    }

    /**
     * Rendrujeme hodnotu.
     *
     * @param {string} value
     *
     * @return {string}
     */
    renderValue(value) {
        return !_.isEmpty(value) ? value : '-';
    }

    /**
     * Rendrovanie obsahu.
     *
     * @param {number} panel
     *
     * @return {JSX.Element|null}
     */
    renderContent(panel) {
        const { user } = this.props;
        const { data } = this.state;
        const { name, ean, number, manufacturer, total_price, tax, variant, img_url, stock } = data.data;

        switch (panel) {
            case 0:
                // Produkt
                return (
                    <Paper className="product-lightbox__content__product">
                        <div className="product-lightbox__content__product__panel">
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('Názov')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    {this.renderValue(`${name}${!_.isEmpty(variant) ? ` - ${variant}` : ''}`)}
                                </div>
                            </div>
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('Cena')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    {this.renderValue(formatAmount(total_price, user.settings.currency))}
                                </div>
                            </div>
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('DPH')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    {this.renderValue(formatAmount(tax, '%', 0))}
                                </div>
                            </div>
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('Obrázok')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    <div className="product-lightbox__content__product__panel__item__value__img">
                                        {!_.isEmpty(img_url) ? <img
                                            src={img_url}
                                            alt={name}
                                        /> : <HelpIcon />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-lightbox__content__product__panel">
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('Stav na sklade')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    {formatAmount(stock, '', 0)}
                                </div>
                            </div>
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('Číslo')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    {this.renderValue(number)}
                                </div>
                            </div>
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('EAN')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    {this.renderValue(ean)}
                                </div>
                            </div>
                            <div className="product-lightbox__content__product__panel__item">
                                <div className="product-lightbox__content__product__panel__item__label">{__('Výrobca')}</div>
                                <div className="product-lightbox__content__product__panel__item__value">
                                    {this.renderValue(manufacturer)}
                                </div>
                            </div>
                        </div>
                    </Paper>
                );

            case 1:
                // Pohyby
                const { id, screen } = this.props;

                return (
                    <Paper className="product-lightbox__content__logs">
                        <LogsTable itemType="product" itemId={id} screen={screen} />
                    </Paper>
                );

            default:
                return null;
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { data, panel } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su vyplnene
            return <div className="product-lightbox"><CircularProgress
                className="order-lightbox__loading"
                color="primary"
                size={40}
            /></div>;
        }

        return (
            <div className="product-lightbox">
                <Paper>
                    <Tabs
                        value={panel}
                        indicatorColor="primary"
                        onChange={(event, value) => this.changePanel(value)}
                        className="product-lightbox__tabs"
                    >
                        <Tab label={__('Produkt')} />
                        <Tab label={__('Pohyby')} />
                    </Tabs>
                </Paper>
                <div className="product-lightbox__content">{this.renderContent(panel)}</div>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(ProductLightbox)));
