import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import SyncIcon from '@material-ui/icons/Sync';
import DeleteIcon from '@material-ui/icons/Delete';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import AddonsIcon from '@material-ui/icons/Extension';
import OrderLightbox from '../lightboxes/OrderLightbox';
import { Checkbox, Screen, Select, Table, TableButton } from '../components';
import { __, formatAmount, formatDate, plural, request } from '../functions';
import {
    fetchInvoicePayments,
    cleanInvoicePayments,
    deleteInvoicePayment,
    deleteInvoicePayments,
    addonInvoicePayments,
    setUser,
} from '../actions';
import { BANKS, GATEWAYS } from '../config';
import '../assets/styles/invoice-payments.css';

/**
 * Zoznam platieb.
 */
class InvoicePaymentsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Platby');

    /**
     * Default state.
     *
     * @type {{
     *     loading_sync: boolean,
     *     loading_table_button: ?string,
     *     settings: {},
     *     lightbox: {},
     * }}
     */
    state = {
        loading_sync: false,
        loading_table_button: null,
        settings: {},
        lightbox: {
            order_view: false,
            delete: false,
            multidelete: false,
            multiaddon: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Komponenta dostane nove props.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.isEmpty(prevState.settings) && !_.isEmpty(this.props.user)) {
            // Nemame nasetovanie settingy
            this.setState({ settings: this.props.user.settings });
        }

        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { invoice_payments } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, invoice_payments.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { invoice_payments } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, invoice_payments.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    fetch(page = 1, per_page = 0, filtered = {}, callback = null) {
        const { fetchInvoicePayments } = this.props;

        // Nacitame zoznam
        fetchInvoicePayments(this, { page, per_page, filtered, callback });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanInvoicePayments } = this.props;

        // Vycistime zoznam
        cleanInvoicePayments();
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Zobrazime view objednavky.
     *
     * @param {number} id
     */
    orderView(id) {
        this.showLightbox('order_view', { id });
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {string} button_id
     */
    pdf(id, button_id) {
        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        request(`/invoices/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({ loading_table_button: null });

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Zmazanie platby.
     */
    async delete() {
        const { deleteInvoicePayment, invoice_payments } = this.props;
        const { settings, lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme platbu
        await deleteInvoicePayment(this, lightbox.delete.id, settings);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, invoice_payments.filtered);
    }

    /**
     * Zmazanie platieb cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteInvoicePayments, invoice_payments } = this.props;
        const { settings } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme platby
        await deleteInvoicePayments(this, ids, settings, invoice_payments.filtered);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, invoice_payments.filtered);
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Synchronizujeme.
     */
    sync() {
        this.setState({ loading_sync: true });

        request('/user-eshops/syncPayments').then(response => {
            const { status, data } = response.data;

            this.setState({ loading_sync: false });

            // Vycistime zoznam
            this.clean();

            const { invoice_payments } = this.props;
            const count = status === 'success' ? data.items.length : 0;

            // Nacitame prvu stranku
            this.fetch(1, 0, invoice_payments.filtered, () => this.showSnackbar('success', `${count} ${plural(count, [
                __('platba bola načítaná'),
                __('platby boli načítané'),
                __('platieb bolo načítaných'),
            ])}`));
        });
    }

    /**
     * Zavolanie addonu cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     * @param {string} addon_id
     */
    async multiAddon(ids, clearCallback, loadingCallback, addon_id) {
        const { addonInvoicePayments, invoice_payments } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiaddon');

        // Zobrazime loading
        loadingCallback();

        // Zavolame addon
        await addonInvoicePayments(this, ids, addon_id, invoice_payments.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Event po zmene addonu v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiAddon(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multiaddon: { ...lightbox.multiaddon, addon_id: value } } });
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { loading_table_button } = this.state;
        const { invoice_payments } = this.props;

        const banks = { ...BANKS, ...GATEWAYS };

        return _.map(items, ({ id, data, _matchingData }) => {
            const pdf_text = __('Stiahnuť PDF');
            let by_text = __('Manuálne');

            if (!_.isEmpty(data.by) && data.by !== 'manual' && _.has(banks, data.by)) {
                by_text = banks[data.by];
            }

            return [
                <Link to="#" onClick={() => this.pdf(
                    _matchingData.Invoices.id,
                    `${_matchingData.Invoices.id}${pdf_text}`
                )}>
                    {_matchingData.Invoices.data.name}
                </Link>,
                <Link to="#" onClick={() => this.orderView(_matchingData.Invoices.order_id)}>
                    {_matchingData.Orders.data.number}
                </Link>,
                formatDate(data.date),
                formatAmount(data.amount, data.currency),
                _.has(invoice_payments.accounting_data.payment_types, data.type)
                    ? invoice_payments.accounting_data.payment_types[data.type]
                    : '',
                by_text,
                [
                    <TableButton
                        id={id}
                        key="pdf"
                        text={pdf_text}
                        icon={<PdfIcon />}
                        onClick={button_id => this.pdf(_matchingData.Invoices.id, button_id)}
                        loading_button={loading_table_button}
                    />,
                    <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => this.confirmDelete(id, button_id)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        const { invoice_payments } = this.props;

        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                case 'type':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ'),
                        value: '',
                        options: invoice_payments.accounting_data.payment_types,
                    }}};

                case 'date-from':
                    return { ...result, ...{ [key]: {
                        type: 'date',
                        name: __('Dátum od'),
                        value: null,
                    }}};

                case 'date-to':
                    return { ...result, ...{ [key]: {
                        type: 'date',
                        name: __('Dátum do'),
                        value: null,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { loading_sync, lightbox, settings } = this.state;
        const { user, invoice_payments } = this.props;

        if (_.isEmpty(invoice_payments) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const active = this.hasAccountingAction('payments_sync');
        const synchronized = invoice_payments.synchronized === '1970-01-01 00:00:00'
            ? __('Úvodná synchronizácia prebehne v nasledujúcich minútach')
            : formatDate(invoice_payments.synchronized, __('Synchronizované s ekonomickým systémom o hh:ii'));
        const title = (
            <div className="invoice-payments__title">
                <Typography variant="h5">{__('Platby')}</Typography>
                {active ? <Chip label={synchronized} /> : null}
                {active ? <Chip
                    icon={<div className={`loading-icon ${loading_sync ? 'loading' : ''}`}>
                        <SyncIcon />
                        <CircularProgress color="inherit" size={14} />
                    </div>}
                    label={__('Synchronizovať')}
                    onClick={this.sync.bind(this)}
                    color="secondary"
                    className="invoice-payments__title__sync"
                /> : null}
            </div>
        );
        const has_delete = this.hasAccountingAction('delete');

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            invoice_payments.user_addons,
            (result, addon_id) => (_.has(invoice_payments.addons, addon_id) ? { ...result, [addon_id]: invoice_payments.addons[addon_id] } : result),
            {}
        );

        return super.render(
            <div className="invoice-payments">
                <Table
                    title={title}
                    multiselect={[
                        !_.isEmpty(addons) ? {
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multiaddon', {
                                ids,
                                clearCallback,
                                loadingCallback,
                                addon_id: _.keys(addons)[0],
                            }),
                            icon: <AddonsIcon />,
                            text: __('Doplnok'),
                        } : null,
                        {
                            icon: <DeleteIcon />,
                            text: __('Zmazať'),
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                                ids,
                                clearCallback,
                                loadingCallback,
                            }),
                        }
                    ]}
                    filter={this.formatFilter(invoice_payments.filter)}
                    filtered={invoice_payments.filtered}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Faktúra'),
                        __('Objednávka'),
                        __('Dátum'),
                        __('Suma'),
                        __('Typ'),
                        __('Vytvorené'),
                        '',
                    ]}
                    data={this.formatData(invoice_payments.items)}
                    raw_data={invoice_payments.items}
                    count={invoice_payments.total}
                    page={invoice_payments.page}
                    per_pages={user.settings.per_pages}
                    per_page={invoice_payments.per_page}
                    empty_text={__('Zatiaľ nemáte žiadne platby')}
                />
                {!_.isEmpty(lightbox.order_view) ? this.renderLightbox(
                    'order_view',
                    __('Objednávka'),
                    <OrderLightbox screen={this} id={lightbox.order_view.id} />,
                ) : null}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto platbu?'),
                    <div>
                        {has_delete ? <Checkbox
                            label={__('Zmazať platbu aj z ekonomického systému')}
                            value={settings.invoice_payment_delete_in_acccounting}
                            onChange={checked => this.onChangeSetting('invoice_payment_delete_in_acccounting', checked)}
                        /> : null}
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multiaddon',
                    __('Doplnok'),
                    !_.isEmpty(lightbox.multiaddon) ? <div className="invoice-payments__addons">
                        <Select
                            options={addons}
                            value={lightbox.multiaddon.addon_id}
                            onChange={value => this.onChangeMultiAddon(value)}
                            allow_empty={false}
                        />
                    </div> : null,
                    __('Odoslať'),
                    __('Zrušiť'),
                    !_.isEmpty(lightbox.multiaddon)
                        ? () => this.multiAddon(
                        lightbox.multiaddon.ids,
                        lightbox.multiaddon.clearCallback,
                        lightbox.multiaddon.loadingCallback,
                        lightbox.multiaddon.addon_id
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené platby?'),
                    <div>
                        {has_delete ? <Checkbox
                            label={__('Zmazať platby aj z ekonomického systému')}
                            value={settings.invoice_payment_delete_in_acccounting}
                            onChange={checked => this.onChangeSetting('invoice_payment_delete_in_acccounting', checked)}
                        /> : null}
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                            lightbox.multidelete.ids,
                            lightbox.multidelete.clearCallback,
                            lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, invoice_payments }) => ({ user, invoice_payments });

export default withCookies(withRouter(connect(stateToProps, {
    fetchInvoicePayments,
    cleanInvoicePayments,
    deleteInvoicePayment,
    deleteInvoicePayments,
    addonInvoicePayments,
    setUser,
})(InvoicePaymentsScreen)));
