import { FETCH_ESHOP, FETCH_ESHOP_DATA, CHANGE_ESHOP_AUTO_FIELD, CHANGE_ESHOP_SETTINGS } from '../actions/types';

const ESHOP_DEFAULT_STATE = [];

/**
 * Reducer pre eshop.
 */
const EshopReducer = (state = ESHOP_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ESHOP:
            // Nacitanie eshopu
            return payload;

        case FETCH_ESHOP_DATA:
            // Nacitanie dat eshopu
            return { ...state, data: payload.data, synchronized: payload.synchronized };

        case CHANGE_ESHOP_AUTO_FIELD:
            // Zmena auto fieldu
            return { ...state, [payload.field]: payload.value ? 'on' : 'off' };

        case CHANGE_ESHOP_SETTINGS:
            // Zmena nastaveni eshopu
            return { ...state, settings: payload };

        default:
            return state;
    }
};

export default EshopReducer;