import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import OrderLightbox from '../lightboxes/OrderLightbox';
import { Screen, Table, Status, TableButton, Checkbox } from '../components';
import { __, formatAmount, request, toNumber } from '../functions';
import { fetchInvoices, cleanInvoices, deleteInvoice, deleteInvoices, setUser } from '../actions';
import { INVOICE_TYPES } from '../config';
import '../assets/styles/invoices.css';

/**
 * Zoznam faktur.
 */
class InvoicesScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Faktúry');

    /**
     * Zoznam statusov.
     *
     * @type {Object}
     */
    statuses = {
        unpaid: { color: 'red', name: __('Neuhradená') },
        partially: { color: 'orange', name: __('Čiastočne uhradená') },
        paid: { color: 'green', name: __('Uhradená') },
    };

    /**
     * Default state.
     *
     * @type {{
     *     downloaded_pdf: Array,
     *     loading_table_button: ?string,
     *     settings: {},
     *     lightbox: {},
     * }}
     */
    state = {
        downloaded_pdf: [],
        loading_table_button: null,
        settings: {},
        lightbox: {
            order_view: false,
            delete: false,
            multidelete: false,
            multipdf: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Komponenta dostane nove props.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.isEmpty(prevState.settings) && !_.isEmpty(this.props.user)) {
            // Nemame nasetovanie settingy
            this.setState({ settings: this.props.user.settings });
        }

        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { invoices } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, invoices.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { invoices } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, invoices.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     */
    fetch(page = 1, per_page = 0, filtered = {}) {
        const { fetchInvoices } = this.props;

        // Nacitame zoznam
        fetchInvoices(this, { page, per_page, filtered });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanInvoices } = this.props;

        // Vycistime zoznam
        cleanInvoices();
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Zobrazime view objednavky.
     *
     * @param {number} id
     */
    orderView(id) {
        this.showLightbox('order_view', { id });
    }

    /**
     * Pridame stiahnute pdf.
     *
     * @param {Array} ids
     *
     * @return {Array}
     */
    getDownloadedPdf(ids) {
        let { downloaded_pdf } = this.state;

        _.each(ids, id => {
            downloaded_pdf = [ ...downloaded_pdf, toNumber(id) ];
        });

        return downloaded_pdf;
    }

    /**
     * Zmazanie faktury.
     */
    async delete() {
        const { deleteInvoice, invoices } = this.props;
        const { settings, lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme fakturu
        await deleteInvoice(this, lightbox.delete.id, settings);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, invoices.filtered);
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {string} button_id
     */
    pdf(id, button_id) {
        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        request(`/invoices/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({
                loading_table_button: null,
                downloaded_pdf: this.getDownloadedPdf([id]),
            });

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Zmazanie faktur cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteInvoices, invoices } = this.props;
        const { settings } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme faktury
        await deleteInvoices(this, ids, settings, invoices.filtered);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, invoices.filtered);
    }

    /**
     * Stiahnutie pdf faktur cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    multiPdf(ids, clearCallback, loadingCallback) {
        const { invoices } = this.props;
        const { settings } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multipdf');

        // Zobrazime loading
        loadingCallback();

        let params = { merge_multi_pdf: settings.merge_multi_pdf };

        // Nasetujeme filter
        _.forEach(invoices.filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        request(`/invoices/multiPdf/${ids}`, params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf'));
                return;
            }

            // Nastavime pdfka ako precitane
            this.setState({ downloaded_pdf: this.getDownloadedPdf(ids.split(',')) });

            // Zavolame clear callback
            clearCallback();

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Vratenie status.
     *
     * @param {string} status
     *
     * @return {JSX.Element|null}
     */
    getStatus(status) {
        const { color, name } = this.statuses[status];

        return <Status color={color}>{name}</Status>;
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { user } = this.props;
        const { loading_table_button, downloaded_pdf } = this.state;

        const has_pdf = this.hasAccountingAction('pdf');

        return _.map(items, ({ id, order_id, status, status_pdf, type, data, parent_data, _matchingData }) => {
            const pdf_text = __('Stiahnuť PDF');

            return [
                <div className="invoices__links">
                    <Link
                        to="#"
                        onClick={has_pdf ? () => this.pdf(id, `${id}${pdf_text}`) : () => {}}
                    >{data.name}</Link>
                    {!_.isEmpty(parent_data)
                        ? <span>{parent_data.name}</span>
                        : null}
                </div>,
                <Link to="#" onClick={() => this.orderView(order_id)}>
                    {_matchingData.Orders.data.number}
                </Link>,
                this.getStatus(status),
                INVOICE_TYPES[type],
                data.number,
                formatAmount(data.amount, user.settings.currency),
                formatAmount(data.paid, user.settings.currency),
                [
                    has_pdf ? <TableButton
                        id={id}
                        key="pdf"
                        text={pdf_text}
                        icon={<PdfIcon color={status_pdf === 'newly' && !_.includes(downloaded_pdf, id)
                            ? 'secondary'
                            : 'inherit'} />}
                        onClick={button_id => this.pdf(id, button_id)}
                        loading_button={loading_table_button}
                    /> : null,
                    <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => this.confirmDelete(id, button_id)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                case 'status':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Stav'),
                        value: '',
                        options: _.reduce(
                            this.statuses,
                            (result, { name }, index) => ({ ...result, [index]: name }),
                            {}
                        ),
                    }}};

                case 'type':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ'),
                        value: '',
                        options: INVOICE_TYPES,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, invoices } = this.props;
        const { settings, lightbox } = this.state;

        if (_.isEmpty(invoices) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const has_delete = this.hasAccountingAction('delete');

        return super.render(
            <div className="invoices">
                <Table
                    title={<Typography variant="h5">{__('Faktúry')}</Typography>}
                    multiselect={[
                        this.hasAccountingAction('pdf') ? {
                            icon: <PdfIcon />,
                            text: __('Stiahnuť PDF'),
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multipdf', {
                                ids,
                                clearCallback,
                                loadingCallback,
                            }),
                            limit: 50,
                        } : null,
                        this.hasAccountingAction('delete') ? {
                            icon: <DeleteIcon />,
                            text: __('Zmazať'),
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                                ids,
                                clearCallback,
                                loadingCallback,
                            }),
                        } : null,
                    ]}
                    filter={this.formatFilter(invoices.filter)}
                    filtered={invoices.filtered}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Názov'),
                        __('Objednávka'),
                        __('Stav'),
                        __('Typ'),
                        __('Číslo'),
                        __('Suma'),
                        __('Uhradené'),
                        '',
                    ]}
                    data={this.formatData(invoices.items)}
                    raw_data={invoices.items}
                    count={invoices.total}
                    page={invoices.page}
                    per_pages={user.settings.per_pages}
                    per_page={invoices.per_page}
                    empty_text={__('Zatiaľ nemáte žiadne faktúry')}
                />
                {!_.isEmpty(lightbox.order_view) ? this.renderLightbox(
                    'order_view',
                    __('Objednávka'),
                    <OrderLightbox screen={this} id={lightbox.order_view.id} />,
                ) : null}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto faktúru?'),
                    <div>
                        {has_delete ? <Checkbox
                            label={__('Zmazať faktúru aj z ekonomického systému')}
                            value={settings.invoice_delete_in_acccounting}
                            onChange={checked => this.onChangeSetting('invoice_delete_in_acccounting', checked)}
                        /> : null}
                        <Checkbox
                            label={__('Nastaviť objednávku ako nespracovanú')}
                            value={settings.invoice_delete_reactivate_order}
                            onChange={checked => this.onChangeSetting('invoice_delete_reactivate_order', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené faktúry?'),
                    <div>
                        {has_delete ? <Checkbox
                            label={__('Zmazať faktúry aj z ekonomického systému')}
                            value={settings.invoice_delete_in_acccounting}
                            onChange={checked => this.onChangeSetting('invoice_delete_in_acccounting', checked)}
                        /> : null}
                        <Checkbox
                            label={__('Nastaviť objednávky ako nespracované')}
                            value={settings.invoice_delete_reactivate_order}
                            onChange={checked => this.onChangeSetting('invoice_delete_reactivate_order', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                            lightbox.multidelete.ids,
                            lightbox.multidelete.clearCallback,
                            lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'multipdf',
                    __('Stiahnutie faktúr do pdf'),
                    <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={settings.merge_multi_pdf}
                            onChange={checked => this.onChangeSetting('merge_multi_pdf', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multipdf)
                        ? () => this.multiPdf(
                        lightbox.multipdf.ids,
                        lightbox.multipdf.clearCallback,
                        lightbox.multipdf.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, invoices }) => ({ user, invoices });

export default withCookies(withRouter(connect(stateToProps, {
    fetchInvoices,
    cleanInvoices,
    deleteInvoice,
    deleteInvoices,
    setUser,
})(InvoicesScreen)));