import React, { Component } from 'react';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MainCheckbox from '@material-ui/core/Checkbox';
import { toNumber } from '../functions';

/**
 * Checkbox komponenta.
 */
class Checkbox extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     label: string,
     *     value: string,
     *     disabled: boolean,
     *     onChange: function,
     * }}
     */
    static defaultProps = {
        label: '',
        value: '',
        disabled: false,
        onChange: () => {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     value: string,
     * }}
     */
    state = {
        value: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { value } = this.props;

        // Nasetujeme value do state z props
        this.setState({ value });
    }

    /**
     * Komponenta dostane nove props.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value !== this.props.value) {
            // Nesedi hodnota v state a props, aktualizujeme
            this.setState({ value: this.props.value });
        }
    }

    /**
     * Event po zmene.
     *
     * @param {string} value
     */
    onChange(value) {
        this.setState({ value });

        this.props.onChange(value);
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { label, value, disabled } = this.props;

        return <FormControlLabel
            control={
                <MainCheckbox
                    checked={_.isBoolean(value) ? value : toNumber(value) > 0}
                    onChange={event => this.onChange(event.target.checked)}
                    disabled={disabled}
                    color="primary"
                />
            }
            label={label}
            className="checkbox"
        />;
    }
}

export { Checkbox };