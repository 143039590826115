import { __, request } from '../functions';
import { FETCH_FEEDS, CREATE_FEED } from './types';

/**
 * Nacitanie feedov.
 *
 * @param {Screen} screen
 */
export const fetchFeeds = screen => {
    return dispatch => request('/user-feeds/index').then(response => {
        const { data, sync } = response.data;

        const { user } = sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_FEEDS, payload: data });
    });
};

/**
 * Pridanie feedu.
 *
 * @param {Screen} screen
 * @param {number} feed_id
 */
export const createFeed = (screen, feed_id) => {
    return dispatch => request('/user-feeds/create', { feed_id }, 'POST').then(response => {
        const { status, data } = response.data;

        screen.setState({ loading_feed: 0 });

        if (status === 'error') {
            // Nepodarilo sa pridat feed
            screen.showSnackbar('error', __('Nepodarilo sa vytvoriť feed'));
            return;
        }

        screen.showSnackbar('success', __('Feed bol vytvorený'));

        dispatch({ type: CREATE_FEED, payload: { name: data.name, feed_id } });
    });
};