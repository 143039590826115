import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import EshopLightbox from '../lightboxes/EshopLightbox';
import { Screen, Switch, Button, Select, Input } from '../components';
import { __, formatDate, request, isEmptyString, isValidZip, isValidEmail, isValidPhone } from '../functions';
import { fetchEshop, fetchEshopData, changeEshopAutoField, changeEshopSettings, setUser } from '../actions';
import { ESHOPS, COUNTRIES } from '../config';
import '../assets/styles/eshop.css';

/**
 * Eshop.
 */
class EshopScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nastavenia eshopu');

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     loading_data: boolean,
     *     loading_credentials: boolean,
     *     settings: {},
     *     lightbox: Object,
     * }}
     */
    state = {
        loading: false,
        loading_data: false,
        loading_credentials: false,
        settings: {},
        lightbox: {
            credentials: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchEshop } = this.props;

            await fetchEshop(this);

            const { eshop } = this.props;

            this.setState({ settings: eshop.settings });
        }

        return true;
    }

    /**
     * Event po zmene auto fieldu.
     *
     * @param {string} field
     * @param {boolean} value
     */
    onChangeAutoField(field, value) {
        const { changeEshopAutoField } = this.props;

        changeEshopAutoField(this, field, value);
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Nacitame data eshopu.
     */
    refreshData() {
        const { fetchEshopData } = this.props;

        this.setState({ loading_data: true });

        fetchEshopData(this);
    }

    /**
     * Zmena credentials.
     *
     * @param {Object} credentials
     */
    changeCredentials(credentials) {
        // Zatvorime lightbox
        this.closeLightbox('credentials');

        this.setState({ loading_credentials: true });

        request('/user-eshops/changeEshopCredentials', { credentials }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loading_credentials: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));

            // Refreshneme data
            this.refreshData();
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeEshopSettings } = this.props;
        const { settings } = this.state;

        if (isEmptyString(settings.name)) {
            // Nie je vyplneny nazov eshopu
            this.showSnackbar('error', __('Nie je vyplnený názov eshopu'));
            return;
        } else if (isEmptyString(settings.address)) {
            // Nie je vyplnena adresa eshopu
            this.showSnackbar('error', __('Nie je vyplnená adresa eshopu'));
            return;
        } else if (isEmptyString(settings.city)) {
            // Nie je vyplnene mesto psc
            this.showSnackbar('error', __('Nie je vyplnené mesto eshopu'));
            return;
        } else if (!isValidZip(settings.zip)) {
            // Neplatne psc eshopu
            this.showSnackbar('error', __('Neplatné PSČ eshopu'));
            return;
        } else if (settings.email !== '' && !isValidEmail(settings.email)) {
            // Neplatny email
            this.showSnackbar('error', __('Neplatný email eshopu'));
            return;
        } else if (settings.phone !== '' && !isValidPhone(settings.phone)) {
            // Neplatne cislo
            this.showSnackbar('error', __('Neplatné tel. číslo eshopu'));
            return;
        }

        this.setState({ loading: true });

        changeEshopSettings(this, settings);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { eshop } = this.props;
        const { loading, loading_data, loading_credentials, settings } = this.state;

        if (_.isEmpty(eshop) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Vytiahneme zoznam stavov
        const states = _.reduce(eshop.data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return super.render(
            <Paper className="eshop" elevation={0}>
                <Toolbar className="eshop__header">
                    <div className="eshop__header__left">
                        <Typography className="eshop__header__title" variant="h5">{ESHOPS[eshop.name]}</Typography>
                        <Chip label={formatDate(eshop.synchronized, __('Synchronizované s eshopom o hh:ii'))} />
                    </div>
                    <div className="eshop__header__right">
                        <Tooltip title={__('Načíta aktuálne údaje z eshopu (typy platieb, dopravy...)')}>
                            <span><Button
                                onClick={this.refreshData.bind(this)}
                                loading={loading_data}
                            >{__('Načítať údaje')}</Button></span>
                        </Tooltip>
                        <Button
                            onClick={() => this.showLightbox('credentials')}
                            loading={loading_credentials}
                            color="red"
                        >{__('Zmeniť prihlasovacie údaje')}</Button>
                    </div>
                </Toolbar>
                <div className="eshop__content">
                    <div className="eshop__content__events">
                        <Switch
                            label={__('Automatická synchronizácia objednávok')}
                            checked={eshop.auto === 'on'}
                            onChange={value => this.onChangeAutoField('auto', value)}
                        />
                    </div>
                    <div className="eshop__content__settings">
                        <Typography className="eshop__content__settings__title" variant="h6">{__('Nastavenie')}</Typography>
                        <div className="eshop__content__settings__selects">
                            <Select
                                label={__('Stav objednávky po úhrade')}
                                options={states}
                                value={settings.state_paid}
                                onChange={value => this.onChangeSetting('state_paid', value)}
                            />
                        </div>
                        <Typography className="eshop__content__settings__title" variant="h6">{__('Adresa')}</Typography>
                        <div className="eshop__content__settings__inputs">
                            <Input
                                label={__('Názov')}
                                value={settings.name}
                                onChange={value => this.onChangeSetting('name', value)}
                            />
                            <Input
                                label={__('Adresa')}
                                value={settings.address}
                                onChange={value => this.onChangeSetting('address', value)}
                            />
                            <Input
                                label={__('Mesto')}
                                value={settings.city}
                                onChange={value => this.onChangeSetting('city', value)}
                            />
                            <Input
                                label={__('PSČ')}
                                value={settings.zip}
                                onChange={value => this.onChangeSetting('zip', value)}
                            />
                            <Select
                                label={__('Lokalizácia')}
                                options={COUNTRIES}
                                value={settings.country}
                                onChange={value => this.onChangeSetting('country', value)}
                                allow_empty={false}
                            />
                        </div>
                        <Typography className="eshop__content__settings__title" variant="h6">{__('Kontaktné údaje')}</Typography>
                        <div className="eshop__content__settings__inputs">
                            <Input
                                label={__('Názov')}
                                value={settings.person}
                                onChange={value => this.onChangeSetting('person', value)}
                            />
                            <Input
                                type="email"
                                label={__('Email')}
                                value={settings.email}
                                onChange={value => this.onChangeSetting('email', value)}
                            />
                            <Input
                                label={__('Tel. číslo')}
                                value={settings.phone}
                                onChange={value => this.onChangeSetting('phone', value)}
                            />
                            <Input
                                label={__('Web')}
                                value={settings.web}
                                onChange={value => this.onChangeSetting('web', value)}
                            />
                        </div>
                        {_.includes(['WooCommerce', 'Ecwid'], eshop.name) ? <Typography className="eshop__content__settings__title" variant="h6">{__('Technické údaje')}</Typography> : null}
                        {_.includes(['WooCommerce', 'Ecwid'], eshop.name) ? <div className="eshop__content__settings__inputs">
                            <Input
                                label={__('Vlastné pole pre názov firmy')}
                                value={settings.meta_data_name}
                                onChange={value => this.onChangeSetting('meta_data_name', value)}
                            />
                            <Input
                                label={__('Vlastné pole pre IČO')}
                                value={settings.meta_data_ico}
                                onChange={value => this.onChangeSetting('meta_data_ico', value)}
                            />
                            <Input
                                label={__('Vlastné pole pre DIČ')}
                                value={settings.meta_data_dic}
                                onChange={value => this.onChangeSetting('meta_data_dic', value)}
                            />
                            <Input
                                label={__('Vlastné pole pre IČ DPH')}
                                value={settings.meta_data_ic_dph}
                                onChange={value => this.onChangeSetting('meta_data_ic_dph', value)}
                            />
                            <Input
                                label={__('Vlastné pole pre odberné miesto')}
                                value={settings.meta_data_pickup_point}
                                onChange={value => this.onChangeSetting('meta_data_pickup_point', value)}
                            />
                            <Input
                                label={__('Vlastné pole pre PDF faktúry')}
                                value={settings.meta_data_pdf_link}
                                onChange={value => this.onChangeSetting('meta_data_pdf_link', value)}
                            />
                        </div> : null}
                        <Button
                            onClick={this.changeSettings.bind(this)}
                            loading={loading}
                            className="eshop__content__button"
                        >{__('Uložiť')}</Button>
                    </div>
                </div>
                {this.renderLightbox(
                    'credentials',
                    `${ESHOPS[eshop.name]} - ${__('zmena prihlasovacích údajov')}`,
                    <EshopLightbox
                        screen={this}
                        name={eshop.setting_name}
                        eshops={eshop.eshops}
                        eshop_id={eshop.id}
                        step={0}
                        show_steps={false}
                        change_eshop={false}
                        afterSetEshopCredentials={credentials => this.changeCredentials(credentials)}
                    />
                )}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ eshop, user }) => ({ eshop, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchEshop,
    fetchEshopData,
    changeEshopAutoField,
    changeEshopSettings,
    setUser,
})(EshopScreen)));
