import { FETCH_ADMIN, FETCH_ADMIN_PROCESS, FETCH_ADMIN_ERROR } from '../actions/types';

const ADMIN_DEFAULT_STATE = [];

/**
 * Reducer pre admin.
 */
const AdminReducer = (state = ADMIN_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ADMIN:
        case FETCH_ADMIN_PROCESS:
        case FETCH_ADMIN_ERROR:
            // Nacitanie admina
            // Nacitanie admin procesu
            // Nacitanie admin erroru
            return payload;

        default:
            return state;
    }
};

export default AdminReducer;