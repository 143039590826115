import _ from 'lodash';
import { CUSTOMIZATION } from '../config';

/**
 * Vratime customizaciu.
 *
 * @return {Object|null}
 */
export const getCustomization = () => {
    if (_.has(CUSTOMIZATION, window.location.host)) {
        // Mame customizaciu
        return CUSTOMIZATION[window.location.host];
    }

    return null;
};