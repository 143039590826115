import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import AccountingLightbox from '../lightboxes/AccountingLightbox';
import { Screen, Switch, Button, Select, Input, Message } from '../components';
import { __, formatDate, request, toNumber } from '../functions';
import {
    fetchAccounting,
    fetchAccountingData,
    changeAccountingAutoField,
    changeAccountingSettings,
    setUser,
} from '../actions';
import { INVOICE_TYPES, ACCOUNTINGS, PLACEHOLDERS } from '../config';
import '../assets/styles/accounting.css';

/**
 * Fakturacny system.
 */
class AccountingScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nastavenie ekonomického systému');

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        type: __('Typ'),
        sequence_id: __('Číselník'),
        logo_id: __('Logo'),
        bank_account: __('Bankový účet'),
        centre: __('Stredisko'),
        language: __('Jazyk'),
        name: __('Názov'),
        description: __('Popis'),
        created: __('Dátum vystavenia'),
        delivery: __('Dátum dodania'),
        due: __('Dátum splatnosti'),
        order_no: __('Číslo objednávky'),
        number: __('Číslo faktúry'),
        variable: __('Variabilný symbol'),
        currency: __('Mena'),
        discount: __('Zľava'),
        header_comment: __('Poznámka nad položkami'),
        comment: __('Poznámka'),
        tags: __('Tagy (oddeľte číarkou)'),
        client_name: __('Názov'),
        client_ico: __('IČO'),
        client_dic: __('DIČ'),
        client_ic_dph: __('IČ DPH'),
        client_email: __('Email'),
        client_phone: __('Tel. číslo'),
        client_address: __('Adresa (fakturácia)'),
        client_city: __('Mesto (fakturácia)'),
        client_zip: __('PSČ (fakturácia)'),
        client_country: __('Krajina (fakturácia)'),
        client_delivery_address: __('Adresa (dodanie)'),
        client_delivery_city: __('Mesto (dodanie)'),
        client_delivery_zip: __('PSČ (dodanie)'),
        client_delivery_country: __('Krajina (dodanie)'),
        client_delivery_phone: __('Tel. číslo (dodanie)'),
        product_name: __('Názov'),
        product_description: __('Popis'),
        product_quantity: __('Počet'),
        product_unit: __('Jednotka'),
        product_unit_price: __('Jednotková cena'),
        product_tax: __('DPH'),
        product_ean: __('Unikátne označenie (sku,ean)'),
        product_discount: __('Zľava'),
        product_discount_description: __('Popis zľavy'),
    };

    /**
     * Default state.
     *
     * @type {{
     *     accounting_id: boolean,
     *     loading: boolean,
     *     loading_data: boolean,
     *     loading_add: boolean,
     *     loading_credentials: boolean,
     *     settings: Object,
     *     lightbox: Object,
     * }}
     */
    state = {
        accounting_id: 0,
        loading: false,
        loading_data: false,
        loading_add: false,
        loading_credentials: false,
        settings: {},
        lightbox: {
            add: false,
            credentials: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchAccounting } = this.props;

            await fetchAccounting(this);

            const { accounting } = this.props;

            this.setState({ settings: accounting.settings });
        }

        return true;
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        let additional = {};

        if (type === 'type') {
            // Menime typ faktury, musime vyresetovat ciselnik
            additional = { sequence_id: 0 };
        }

        this.setState({ settings: { ...settings, [type]: value, ...additional } });
    }

    /**
     * Event po kliku na placeholder.
     *
     * @param {string} placeholder
     */
    onClickPlaceholder(placeholder) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(placeholder);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Event po zmene auto fieldu.
     *
     * @param {string} field
     * @param {boolean} value
     */
    onChangeAutoField(field, value) {
        const { changeAccountingAutoField } = this.props;

        changeAccountingAutoField(this, field, value);
    }

    /**
     * Event po zmene create settingu.
     *
     * @param {number} key
     * @param {string} type
     * @param {string} value
     */
    onChangeCreateSetting(key, type, value) {
        const { settings } = this.state;

        const create_settings = _.map(settings.create_settings, (setting, k) => {
            if (k === key) {
                // Editujeme nastavenie
                setting = { ...setting, [type]: value };
            }

            return setting;
        });

        this.setState({ settings: { ...settings, create_settings } });
    }

    /**
     * Pridame novy create setting.
     */
    addCreateSetting() {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, create_settings: [ ...settings.create_settings, {
            type: _.keys(INVOICE_TYPES)[0],
            state: '',
            payment: '',
            delivery: '',
        } ] } });
    }

    /**
     * Zmazeme create setting.
     *
     * @param {int} key
     */
    deleteCreateSetting(key) {
        const { settings } = this.state;

        const create_settings = _.remove(settings.create_settings, (item, k) => k !== key);

        this.setState({ settings: { ...settings, create_settings } });
    }

    /**
     * Nacitame data fakturacneho systemu.
     */
    refreshData() {
        const { fetchAccountingData } = this.props;

        this.setState({ loading_data: true });

        fetchAccountingData(this);
    }

    /**
     * Zmena credentials.
     *
     * @param {string} type
     * @param {number} accounting_id
     * @param {Object} credentials
     */
    changeCredentials(type, accounting_id, credentials) {
        // Zatvorime lightbox
        this.closeLightbox(type);

        this.setState({ [`loading_${type}`]: true, accounting_id });

        request('/user-eshops/changeAccountingCredentials', { accounting_id, credentials }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ [`loading_${type}`]: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.showSnackbar('error', type === 'add'
                    ? __('Nepodarilo sa aktivovať ekonomický systém')
                    : __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.showSnackbar('success', type === 'add'
                ? __('Ekonomický systém bol aktivovaný')
                : __('Prihlasovacie údaje boli zmenené')
            );

            // Refreshneme data
            this.refreshData();
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeAccountingSettings } = this.props;
        const { settings } = this.state;

        this.setState({ loading: true });

        changeAccountingSettings(this, settings);
    }

    /**
     * Vratime zgrupnute settingy.
     *
     * @param {Object} settings
     *
     * @return {Object}
     */
    getSettingsGroups(settings) {
        return _.reduce(settings, (result, value, name) => {
            if (
                name === 'state_create'
                || name === 'state_proforma_create'
                || name === 'create_settings'
            ) {
                return result;
            }

            let group = 'invoice';

            if (name.indexOf('client_') !== -1) {
                // Skupina klient
                group = 'client';
            } else if (name.indexOf('product_') !== -1) {
                // Skupina produkt
                group = 'product';
            }

            return { ...result, [group]: { ...result[group], fields: [ ...result[group].fields, { key: name, value } ] } };
        }, {
            invoice: { name: __('Faktúra'), fields: [] },
            client: { name: __('Klient'), fields: [] },
            product: { name: __('Položka'), fields: [] },
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { accounting } = this.props;
        const { accounting_id, loading, settings, loading_data, loading_add, loading_credentials } = this.state;

        if (_.isEmpty(accounting) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Vytiahneme zoznam stavov
        const states = _.reduce(accounting.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme typy uhrad
        const payment_types = _.reduce(accounting.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam dodani
        const delivery_types = _.reduce(accounting.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        const names = _.reduce(accounting.accountings, (result, { id, name }) => ({ ...result, [id]: name }), {});
        const id = toNumber(accounting.id) > 0 ? toNumber(accounting.id) : toNumber(accounting_id);
        const active = id > 0;
        const accounting_name = toNumber(accounting_id) > 0 ? names[toNumber(accounting_id)] : accounting.name;
        const invoicing = this.hasAccountingAction('invoicing', active ? id : null);
        const has_create_settings = !_.isEmpty(settings.create_settings);

        return super.render(
            <Paper className="accounting" elevation={0}>
                <Toolbar className="accounting__header">
                    <div className="accounting__header__left">
                        <Typography className="accounting__header__title" variant="h5">
                            {active ? ACCOUNTINGS[accounting_name] : __('Ekonomický systém')}
                        </Typography>
                        {active && invoicing ? <Chip
                            label={formatDate(accounting.synchronized, __('Synchronizované s ekonomickým systémom o hh:ii'))}
                        /> : null}
                    </div>
                    {active ? <div className="accounting__header__right">
                        {invoicing ? <Tooltip title={__('Načíta aktuálne údaje z ekonomického systému (číselníky, logá...)')}>
                            <span><Button
                                onClick={this.refreshData.bind(this)}
                                loading={loading_data}
                            >{__('Načítať údaje')}</Button></span>
                        </Tooltip> : null}
                        <Button
                            onClick={() => this.showLightbox('credentials')}
                            loading={loading_credentials}
                            color="red"
                        >{__('Zmeniť prihlasovacie údaje')}</Button>
                    </div> : <div className="accounting__header__right">
                        <Button
                            onClick={() => this.showLightbox('add')}
                            color="green"
                            loading={loading_add}
                        >{__('Aktivovať')}</Button>
                    </div>}
                </Toolbar>
                <div className="accounting__content">
                    {!active ? <Message
                        className="accounting__content__warning"
                        type="warning"
                    >{__('Najprv musíte aktivovať ekonomický systém.')}</Message> : null}
                    <div className="accounting__content__events">
                        {active ? <Switch
                            label={invoicing
                                ? __('Automatické vystavenie faktúry')
                                : __('Automatické odoslanie objednávky')}
                            checked={accounting.auto === 'on'}
                            onChange={value => this.onChangeAutoField('auto', value)}
                        /> : null}
                        {this.hasAccountingAction('regular_from_proforma', active ? id : null) ? <Switch
                            label={__('Automatické vystavenie ostrej faktúry po úhrade zálohovej')}
                            checked={accounting.proforma_auto === 'on'}
                            onChange={value => this.onChangeAutoField('proforma_auto', value)}
                        /> : null}
                        {this.hasAccountingAction('email', active ? id : null) ? <Switch
                            label={__('Automatické odoslanie emailu po vystavený')}
                            checked={accounting.email_auto === 'on'}
                            onChange={value => this.onChangeAutoField('email_auto', value)}
                        /> : null}
                        {active && accounting.auto === 'on' && !has_create_settings ? <Select
                            label={invoicing
                                ? __('Stav objednávky pri vytvorený faktúry')
                                : __('Stav objednávky pri odoslaní objednávky')}
                            options={states}
                            value={settings.state_create}
                            onChange={value => this.onChangeSetting('state_create', value)}
                            placeholder={__('Všetky stavy')}
                        /> : null}
                        {active && accounting.auto === 'on' && invoicing && !has_create_settings ? <Select
                            label={__('Stav objednávky pri vytvorený zálohovej faktúry')}
                            options={states}
                            value={settings.state_proforma_create}
                            onChange={value => this.onChangeSetting('state_proforma_create', value)}
                        /> : null}
                        {active && accounting.auto === 'on' && invoicing && has_create_settings ? <div className="accounting__content__events__settings">
                            {_.map(settings.create_settings, (setting, key) => (
                                <div className="accounting__content__events__settings__setting" key={key}>
                                    <Select
                                        label={__('Typ')}
                                        options={INVOICE_TYPES}
                                        value={setting.type}
                                        onChange={value => this.onChangeCreateSetting(key, 'type', value)}
                                        allow_empty={false}
                                    />
                                    <Select
                                        label={invoicing
                                            ? __('Stav objednávky pri vytvorený faktúry')
                                            : __('Stav objednávky pri odoslaní objednávky')}
                                        options={states}
                                        value={setting.state}
                                        onChange={value => this.onChangeCreateSetting(key, 'state', value)}
                                        placeholder={__('Všetky stavy')}
                                    />
                                    <Select
                                        label={invoicing
                                            ? __('Typ dodania pri vytvorený faktúry')
                                            : __('Typ dodania pri odoslaní objednávky')}
                                        options={delivery_types}
                                        value={setting.delivery}
                                        onChange={value => this.onChangeCreateSetting(key, 'delivery', value)}
                                        placeholder={__('Všetky typy')}
                                    />
                                    <Select
                                        label={invoicing
                                            ? __('Typ platby pri vytvorený faktúry')
                                            : __('Typ platby pri odoslaní objednávky')}
                                        options={payment_types}
                                        value={setting.payment}
                                        onChange={value => this.onChangeCreateSetting(key, 'payment', value)}
                                        placeholder={__('Všetky typy')}
                                    />
                                    <Tooltip title={__('Zmazať')}>
                                        <IconButton
                                            onClick={() => this.deleteCreateSetting(key)}
                                            className="accounting__content__events__settings__setting__button"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ))}
                            <Button
                                onClick={() => this.addCreateSetting()}
                            >{__('Pridať nastavenie')}</Button>
                        </div> : (active && accounting.auto === 'on' && invoicing ? <Button
                            onClick={() => this.addCreateSetting()}
                        >{__('Podrobnejšie nastavenia')}</Button> : null)}
                    </div>
                    {active && !_.isEmpty(accounting.ftp) ? <div className="accounting__content__ftp">
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Návod')}:</div>
                            <div className="accounting__content__ftp__item__value">
                                <a href={accounting.ftp.video} target="_blank" rel="noopener noreferrer">{__('Video')}</a>
                            </div>
                        </div>
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('FTP server')}:</div>
                            <div className="accounting__content__ftp__item__value">{accounting.ftp.host}</div>
                        </div>
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Prihlasovacie meno')}:</div>
                            <div className="accounting__content__ftp__item__value">{accounting.ftp.user}</div>
                        </div>
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Prihlasovacie heslo')}:</div>
                            <div className="accounting__content__ftp__item__value">{accounting.ftp.password}</div>
                        </div>
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Názov súboru')}:</div>
                            <div className="accounting__content__ftp__item__value">{accounting.ftp.file}</div>
                        </div>
                    </div> : null}
                    {active && !_.isEmpty(accounting.web) ? <div className="accounting__content__ftp">
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Návod')}:</div>
                            <div className="accounting__content__ftp__item__value">
                                <a href={accounting.web.video} target="_blank" rel="noopener noreferrer">{__('Video')}</a>
                            </div>
                        </div>
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Url adresa')}:</div>
                            <div className="accounting__content__ftp__item__value">{accounting.web.url}</div>
                        </div>
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Prihlasovacie meno')}:</div>
                            <div className="accounting__content__ftp__item__value">{accounting.web.user}</div>
                        </div>
                        <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Prihlasovacie heslo')}:</div>
                            <div className="accounting__content__ftp__item__value">{accounting.web.password}</div>
                        </div>
                        {!_.isEmpty(accounting.web.download) ? <div className="accounting__content__ftp__item">
                            <div className="accounting__content__ftp__item__label">{__('Súbor')}:</div>
                            <div className="accounting__content__ftp__item__value">
                                <a href={accounting.web.download} target="_blank" rel="noopener noreferrer">{__('Stiahnuť')}</a>
                            </div>
                        </div> : null}
                    </div> : null}
                    <div className="accounting__content__invoice">
                        <Typography className="accounting__content__invoice__title" variant="h6">{invoicing
                            ? __('Nastavenie faktúry')
                            : __('Nastavenie objednávky')}</Typography>
                        <Message type="info">{__('Do nastavení môžete vkladať špecialné slova ktoré sa pri vystavení faktúry automaticky doplnia podľa objednávky. Prázdne položky budú doplnené automaticky podľa ekonomického systému.')}</Message>
                        <div className="accounting__content__invoice__panels">
                            <div className="accounting__content__invoice__panels__panel">
                                <div className="accounting__content__invoice__panels__panel__title">{__('Špeciálne slová')}</div>
                                <div className="accounting__content__invoice__panels__panel__text">{__('Kliknutím skopírujete do schránky.')}</div>
                                <div className="accounting__content__invoice__panels__panel__placeholders">
                                    {_.map(PLACEHOLDERS, (text, placeholder) => (
                                        <div key={placeholder}>
                                            <Tooltip title={text}>
                                                <Chip
                                                    onClick={() => this.onClickPlaceholder(placeholder)}
                                                    label={placeholder}
                                                    clickable
                                                    color="primary"
                                                    deleteIcon={<HelpIcon />}
                                                />
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {_.map(this.getSettingsGroups(accounting.settings), ({ name, fields }, key) => {
                                return (
                                    <div className="accounting__content__invoice__panels__panel" key={key}>
                                        <div className="accounting__content__invoice__panels__panel__title">{name}</div>
                                        {_.map(fields, ({ key }) => {
                                            if (_.includes(['type', 'proforma_payment_id', 'sequence_id', 'logo_id', 'bank_account', 'centre', 'language'], key)) {
                                                let options = {};

                                                if (!invoicing) {
                                                    return null;
                                                }

                                                switch (key) {
                                                    case 'type':
                                                        // Typ faktury
                                                        options = INVOICE_TYPES;
                                                        break;

                                                    case 'sequence_id':
                                                        // Ciselnik
                                                        options = settings.type === 'proforma'
                                                            ? accounting.data.sequences_proforma
                                                            : accounting.data.sequences_regular;
                                                        break;

                                                    case 'logo_id':
                                                        // Loga
                                                        options = accounting.data.logos;
                                                        break;

                                                    case 'bank_account':
                                                        // Bankove ucty
                                                        options = accounting.data.bank_accounts;
                                                        break;

                                                    case 'centre':
                                                        // Stredisko
                                                        options = accounting.data.centres;
                                                        break;

                                                    case 'language':
                                                        // Jazyk
                                                        options = accounting.data.languages;
                                                        break;

                                                    default:
                                                        break;
                                                }

                                                if (_.isEmpty(options)) {
                                                    // Ak su prazdne options, nezobrazime
                                                    return null;
                                                }

                                                return <Select
                                                    key={key}
                                                    label={this.labels[key]}
                                                    options={options}
                                                    value={settings[key]}
                                                    onChange={value => this.onChangeSetting(key, value)}
                                                    placeholder={__('Doplnené ekonomickým systémom')}
                                                />;
                                            }

                                            return <Input
                                                key={key}
                                                label={this.labels[key]}
                                                value={settings[key]}
                                                onChange={value => this.onChangeSetting(key, value)}
                                                placeholder={__('Doplnené ekonomickým systémom')}
                                                multiline={key === 'comment' || key === 'header_comment'}
                                            />;
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <Button
                        onClick={this.changeSettings.bind(this)}
                        loading={loading}
                        className="accounting__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderLightbox(
                    'add',
                    __('Aktivácia ekonomického systému'),
                    <AccountingLightbox
                        screen={this}
                        accountings={accounting.accountings}
                        afterSetAccountingCredentials={
                            (accounting_id, credentials) => this.changeCredentials('add', accounting_id, credentials)
                        }
                    />
                )}
                {this.renderLightbox(
                    'credentials',
                    `${ACCOUNTINGS[accounting_name]} - ${__('zmena prihlasovacích údajov')}`,
                    <AccountingLightbox
                        screen={this}
                        accountings={accounting.accountings}
                        accounting_id={toNumber(accounting_id) > 0 ? accounting_id : accounting.id}
                        change_accounting={false}
                        afterSetAccountingCredentials={
                            (accounting_id, credentials) => this.changeCredentials('credentials', accounting_id, credentials)
                        }
                    />
                )}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ accounting, user }) => ({ accounting, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchAccounting,
    fetchAccountingData,
    changeAccountingAutoField,
    changeAccountingSettings,
    setUser,
})(AccountingScreen)));