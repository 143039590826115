import { FETCH_HEUREKA, CHANGE_HEUREKA_SETTINGS, FETCH_HEUREKA_DATA } from '../actions/types';

const HEUREKA_DEFAULT_STATE = [];

/**
 * Reducer pre heureku.
 */
const HeurekaReducer = (state = HEUREKA_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_HEUREKA:
            // Nacitanie heureky
            return payload;

        case FETCH_HEUREKA_DATA:
            // Nacitanie dat heureky
            return { ...state, data: payload };

        case CHANGE_HEUREKA_SETTINGS:
            // Zmena nastaveni heureky
            return { ...state, settings: payload };

        default:
            return state;
    }
};

export default HeurekaReducer;