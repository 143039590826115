import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/Print';
import PaidIcon from '@material-ui/icons/MonetizationOn';
import { Screen, Table, TableButton } from '../components';
import { __, formatDate } from '../functions';
import { fetchLabels, cleanLabels, deleteLabel, downloadLabel, deleteLabels, setUser } from '../actions';
import '../assets/styles/labels.css';

/**
 * Zoznam stitkov.
 */
class LabelsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Štítky');

    /**
     * Default state.
     *
     * @type {{
     *     loading_table_button: ?string,
     *     lightbox: {},
     * }}
     */
    state = {
        loading_table_button: null,
        lightbox: {
            delete: false,
            multidelete: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        // Nacitame pozadovanu stranku
        this.fetch(page, 0);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     */
    fetch(page = 1, per_page = 0) {
        const { fetchLabels } = this.props;

        // Nacitame zoznam
        fetchLabels(this, { page, per_page });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanLabels } = this.props;

        // Vycistime zoznam
        cleanLabels();
    }

    /**
     * Stiahneme stitky.
     *
     * @param {int} id
     */
    download(id) {
        const { labels, downloadLabel } = this.props;

        window.location = `${labels.host}/user-labels/download/${id}?token=${global.token}`;

        downloadLabel(id);
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Zmazanie stitku.
     */
    async delete() {
        const { deleteLabel } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme stitok
        await deleteLabel(this, lightbox.delete.id);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0);
    }

    /**
     * Zmazanie stitkov cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteLabels } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme stitkov
        await deleteLabels(this, ids);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0);
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { loading_table_button } = this.state;

        return _.map(items, ({ id, status, orders, url, created }) => {
            let counter = 0;

            return [
                _.map(orders, (number, id) => {
                    counter++;

                    return (
                        <span>
                            <Link to={`/orders/view/${id}`}>{number}</Link>
                            {counter !== _.keys(orders).length ? <span>, </span> : null}
                        </span>
                    );
                }),
                formatDate(created, 'dd.mm.yyyy hh:ii'),
                [
                    status === 'unpaid' ? <TableButton
                        id={id}
                        key="paid"
                        text={__('Uhradiť')}
                        icon={<PaidIcon color="secondary" />}
                        onClick={() => window.open(url, '_blank')}
                    /> : <TableButton
                        id={id}
                        key="download"
                        text={__('Stiahnuť')}
                        icon={<DownloadIcon color={status === 'newly' ? 'secondary' : 'inherit'} />}
                        onClick={() => this.download(id)}
                        disabled={status === 'waiting'}
                    />,
                    <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => this.confirmDelete(id, button_id)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, labels } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(labels)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <div className="labels">
                <Table
                    title={<Typography variant="h5">{__('Štítky')}</Typography>}
                    multiselect={[{
                        icon: <DeleteIcon />,
                        text: __('Zmazať'),
                        confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                            ids,
                            clearCallback,
                            loadingCallback,
                        }),
                    }]}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Objednávky'),
                        __('Vytvorené'),
                        '',
                    ]}
                    data={this.formatData(labels.items)}
                    raw_data={labels.items}
                    count={labels.total}
                    page={labels.page}
                    per_pages={user.settings.per_pages}
                    per_page={labels.per_page}
                    empty_text={__('Zatiaľ nemáte žiadne štítky')}
                    message={{
                        type: 'info',
                        text: __('Štítky budú automatické zmazané po 7 dňoch od vytvorenia.'),
                    }}
                />
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento štítok?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené štítky?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                        lightbox.multidelete.ids,
                        lightbox.multidelete.clearCallback,
                        lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, labels }) => ({ user, labels });

export default withCookies(withRouter(connect(stateToProps, {
    fetchLabels,
    cleanLabels,
    deleteLabel,
    downloadLabel,
    deleteLabels,
    setUser,
})(LabelsScreen)));