import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import ArticleIcon from '@material-ui/icons/Forward';
import { Screen, Button, Logo } from '../components';
import { __ } from '../functions';
import { IMAGES, COOKIE_SETTINGS } from '../config';
import '../assets/styles/help.css';

/**
 * Pomoc.
 */
class HelpScreen extends Screen {
    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Redirect po auth required.
     *
     * @type {boolean}
     */
    authRequiredRedirect = false;

    /**
     * Panely.
     *
     * @type {Array}
     */
    panels = [
        {
            name: __('Začíname'),
            text: __('Základné postupy, predovšetkým pre nových a neskúsených užívateľov'),
            articles: [
                __('Založenie eshopu'),
                __('WooCommerce'),
            ],
        },
        {
            name: __('Eshop'),
            text: __('Nastavenia eshopu, Synchronizácia objednávok, Stavy objednávok'),
            articles: [
                __('Synchronizácia objednávok'),
                __('Zmena stavu objednávky'),
                __('Nastavenia eshopu'),
                __('Načítanie dát eshopu (zmena typu dodania, platby)'),
                __('Notifikácie - kontrola akcií na pozadí'),
            ],
        },
        {
            name: __('Fakturácia'),
            text: __('Aktivácia, Nastavenie, Faktúry, Platby'),
            articles: [
                __('Aktivácia ekonomického systému'),
                __('Automatická fakturácia'),
                __('Načítanie dát fakturácie (číselníky, logá, bankové účty)'),
                __('Nastavenia fakturácie'),
                __('Automatické vystavenie ostrých faktúr po úhrade zálohových'),
                __('Úhrada objednávky a faktúry'),
                __('Zoznam platieb'),
                __('Aktivácia OMEGA'),
            ],
        },
        {
            name: __('Prepravcovia'),
            text: __('Odoslanie objednávok, Rozpis zásielok, Nastavenia'),
            articles: [
                __('Odoslanie objednávky'),
                __('Rozpis zásielok'),
                __('Aktivácia prepravcu'),
                __('Nastavenia prepravcu'),
                __('Automatické odosielanie'),
                __('Čísla zásielok'),
                __('Ako odoslať číslo zásielky zákaznikovi'),
                __('Automatické rozpoznanie dobierky'),
                __('Zmena stavu objednávky pred a po odoslaní'),
                __('Tlač štítkov'),
            ],
        },
    ];

    /**
     * Default state.
     *
     * @type {{
     *    scroll: boolean,
     * }}
     */
    state = {
        scroll: false,
        lightbox: {
            article: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return {boolean}
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));

        const { match } = this.props;

        if (
            !_.isEmpty(match.params.section)
            && !_.isEmpty(match.params.article)
        ) {
            // Zobrazime lightbox
            this.showLightbox('article', `${match.params.section}${match.params.article}`);
        }

        return super.componentDidMount();
    };

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    };

    /**
     * Handlujeme scrolovanie.
     *
     * @param {Object} event
     */
    handleScroll(event) {
        const top = event.target.scrollingElement.scrollTop;
        const { scroll } = this.state;

        if (!scroll && top > 0) {
            this.setState({ scroll: true });
        } else if (scroll && top === 0) {
            this.setState({ scroll: false });
        }
    }

    /**
     * Scrolujeme navrch.
     */
    scrollToTop() {
        this.scrollTo(0);
    }

    /**
     * Scrolujeme na poziciu.
     *
     * @param {number} top
     */
    scrollTo(top) {
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
    }

    /**
     * Vratime lightbox data pre clanok.
     *
     * @return {Object}
     */
    getLightboxData() {
        const { lightbox } = this.state;
        const title = this.panels[lightbox.article.substr(0,1)].articles[lightbox.article.substr(1,1)];

        switch (lightbox.article) {
            case '00':
                // Zalozenie eshopu
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Pre vytvorenie eshopu stačí vyplniť 3 údaje. Prvý údaj je webstránka vášho eshopu (vyplňte domovskú stránku). Ďalšie 2 údaje sú prihlasovacie meno a heslo do administrácie eshopu.')}</p>
                        <img src={IMAGES['help00.jpg']} alt="zalozenie eshopu" />
                    </div>,
                };

            case '01':
                // Woocommerce
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Pri vytváraní eshopu sa snažíme prihlásiť do vašej administrácie a vygenerovať potrebné API údaje. Niekedy sa to nemusí podariť a budete musieť tieto údaje nastaviť manuálne.')}</p>
                        <p>{__('Pre API pripojenie bude náš systém potrebovať Kľúč zákazníka (Consumer key) a Tajomstvo zákazníka (Consumer secret). Postupujte podľa obrázka dole.')}</p>
                        <img src={IMAGES['help01.jpg']} alt="woocommerce" />
                        <p>{__('Vyplňte názov, zvoľte používateľa a nastavte práva na "Čítať / Zapísať".')}</p>
                        <img src={IMAGES['help011.jpg']} alt="woocommerce api kluc" />
                        <p>{__('Po vygenerovaní sa vám zobrazí Kľúč zákazníka (Consumer key) a Tajomstvo zákazníka (Consumer secret).')}</p>
                        <img src={IMAGES['help012.jpg']} alt="woocommerce api kluc gener" />
                    </div>,
                };

            case '10':
                // Synchronizacia objednavok
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Náš systém sa automaticky pripája na váš eshop a načítava nové objednávky, produkty, typy úhrad, typy dodaní.')}</p>
                        <p>{__('Každé 2 hodiny aktualizujeme už pridané objednávky v našom systéme. Ak upravíte objednávku vo svojom eshope, my si údaje objednávky načítame znovu.')}</p>
                        <p>{__('Ak ale potrebujete aby sa nové dáta objednávky prejavili ihneď, tak môžete konkrétnu objednávku manuálne synchronizovať s vašim eshopom, tak ako je popísané dole v obrázku.')}</p>
                        <img src={IMAGES['help10.jpg']} alt="synchronizacia objednavok" />
                    </div>,
                };

            case '11':
                // Zmena stavu objednavky
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('V zozname objednávok viete jednoducho zmeniť stav objednávky. Táto zmena sa prejaví aj vo vašom eshope.')}</p>
                        <img src={IMAGES['help11.jpg']} alt="zmena stavu objednavky" />
                    </div>,
                };

            case '12':
                // Nastavenia eshopu
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Nastavenia eshopu je doležité aby ste mali vyplnené. Po registrácii nového eshopu sa pokusíme tieto údaje automaticky doplniť z vášho eshopu.')}</p>
                        <p>{__('Adresa a kontaktné údaje sa používajú napríklad pre fakturačný systém alebo prepravcov.')}</p>
                        <img src={IMAGES['help12.jpg']} alt="nastavenia eshopu" />
                    </div>,
                };

            case '13':
                // Nastavenia data eshopu
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Ak zmeníte v eshope nastavenia dodania, platieb alebo iné dôležité údaje tak ich jedným klikom prenesiete k nám.')}</p>
                        <p>{__('Ak by ste náhodou na to zabudli, tak sa nič nestane. My si tieto údaje pravidelne pár krát do dňa automaticky sťahujeme z vášho eshopu.')}</p>
                        <img src={IMAGES['help13.jpg']} alt="nacitanie dat eshopu" />
                    </div>,
                };

            case '14':
                // Notifikacie
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Notifikácie poskytujú prehľadnú správu automatický akcií na pozadí v systéme.')}</p>
                        <p>{__('Dozviete sa kedy bola objednávka načítaná z eshopu, kedy bola vystavená faktúra, kedy bola objednávka odoslaná a podobne.')}</p>
                        <p>{__('Ak by vznikla chyba napríklad sa nepodarí objednávku odoslať, tak sa to ihneď dozviete v tomto notifikačnom systéme.')}</p>
                        <img src={IMAGES['help14.jpg']} alt="notifikacie" />
                    </div>,
                };

            case '20':
                // Aktivacia fakturacneho systemu
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Aktivácia fakturačného systému je jednoduchá. Stačí ak si vyberiete konkrétny fakturačný systém z ponuky a zadáte prihlasovacie údaje do fakturačného systému.')}</p>
                        <p>{__('Niektoré fakturačné systémy ako SuperFaktúra alebo iDoklad vyžadujú mať zakúpené prémiové predplatné kvôli prepojeniu cez API.')}</p>
                        <p>{__('Ak máte prihlasovacie údaje do fakturačného systému cez sociálne siete (Facebook, Google), tak sa budete musieť prihlásiť do fakturačného systému a v nastaveniach si nastaviť heslo aby sme sa mohli pripojiť.')}</p>
                        <img src={IMAGES['help20.jpg']} alt="aktivacia fakturacneho systemu" />
                    </div>,
                };

            case '21':
                // Automaticka fakturacia
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Automatická fakturácia je predvolene vypnutá. Ak ju chcete zapnúť postupujte podľa obrázku.')}</p>
                        <img src={IMAGES['help21.jpg']} alt="automaticka fakturacia" />
                        <p>{__('Vystavenie faktúry systémom sa dá nastaviť buď ihneď po prijatý objednávky alebo po zmene stavu objednávky na zvolený stav. Toto nastavenie nájdete podľa obrázku.')}</p>
                        <img src={IMAGES['help211.jpg']} alt="automaticka fakturacia na stav" />
                    </div>,
                };

            case '22':
                // Nacitanie dat fakturacie
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Ak zmeníte vo fakturačnom systéme číselník, logo, bankový účet alebo iné dôležité údaje tak ich jedným klikom prenesiete k nám.')}</p>
                        <p>{__('Ak by ste náhodou na to zabudli, tak sa nič nestane. My si tieto údaje pravidelne pár krát do dňa automaticky sťahujeme z vášho fakturačného systému.')}</p>
                        <img src={IMAGES['help22.jpg']} alt="nacitanie dat fakturacie" />
                    </div>,
                };

            case '23':
                // Nastavenia fakturacie
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Nastavenia fakturácie poskytujú variabilnosť vo vytváraní faktúr. Môžete si nastaviť typ faktúry (ostrá, zálohová), zvoliť konkrétne logo alebo bankový účet.')}</p>
                        <p>{__('Takisto si viete modifikovať nastavenia klienta, produktov, názov faktúry. Do dát faktúry viete vkladať špeciálne slová ktoré sa doplnia na základe konkrétnej objednávky.')}</p>
                        <p>{__('Príklad do názvu faktúry si nastavíte "Môj eshop {order.number}" - vystavíte faktúru objednávke s číslom 1234 a výsledny názov faktúry bude "Môj eshop 1234".')}</p>
                        <img src={IMAGES['help23.jpg']} alt="nastavenia fakturacie" />
                    </div>,
                };

            case '24':
                // Automaticke vystavenie ostrej po zalohovej
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Ak máte nastavené vystavovanie zálohových faktúr, tak budete potrebovať vystaviť ostrú faktúru po úhrade zálohovej.')}</p>
                        <p>{__('Presne kvôli tomuto prípadu máme nastavenie na vystavenie ostrej faktúry po úhrade zálohovej. Akonáhle vám zákaznik uhradí zálohovú faktúru tak sa automaticky vystaví ostrá faktúra.')}</p>
                        <img src={IMAGES['help24.jpg']} alt="automaticke vystavenie ostrej po zalohovej" />
                    </div>,
                };

            case '25':
                // Uhrada faktury
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Prišla vám platba od zákaznika a potrebujete uhradiť faktúru a objednávku? Kliknete na tlačítko uhradiť v zozname objednávok.')}</p>
                        <p>{__('Automaticky uhradíme faktúru vo fakturačnom systéme, zmeníme stav objednávky alebo rovno vystavíme ostrú faktúru po úhrade zálohovej.')}</p>
                        <img src={IMAGES['help25.jpg']} alt="uhrada faktury" />
                    </div>,
                };

            case '26':
                // Zoznam platieb
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Zoznam vašich platieb od zákaznikov na jednom mieste. Platbu viete zmazať v našom systéme aj vo fakturačnom.')}</p>
                        <img src={IMAGES['help26.jpg']} alt="zoznam platieb" />
                    </div>,
                };

            case '27':
                // Aktivacia omega
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p><span>{__('Pre aktiváciu OMEGY potrebujeme váš API klúč. Postup ako získať svoj API klúč')}</span><a href="/omega.pdf" target="_blank">{__(' nájdete tu')}</a>.</p>
                        <img src={IMAGES['help27.jpg']} alt="aktivacia omega" />
                    </div>,
                };

            case '30':
                // Odoslanie objednavky
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Odoslanie objednávky prebieha kliknutím na ikonu prepravcu v zozname objednávok. Pred odoslaním sa vás spýta na dátum zvozu a jedným kliknutím odošlete svoju objednávku prepravcovi.')}</p>
                        <p>{__('Objednávky viete odosielať aj hromadne.')}</p>
                        <img src={IMAGES['help30.jpg']} alt="odoslanie objednavky" />
                    </div>,
                };

            case '31':
                // Rozpis zasielok
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Rozpisaním zásielok viete upraviť objednávky ešte pred odoslaním prepravcovi. Viete si nastaviť počet balíkov, váhu, rozmery a dobierku.')}</p>
                        <img src={IMAGES['help31.jpg']} alt="rozpis zasielok postup" />
                        <img src={IMAGES['help311.jpg']} alt="rozpis zasielok" />
                    </div>,
                };

            case '32':
                // Aktivacia prepravcu
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Aktivácia prepravcu je jednoduchá. Stačí ak si vyberiete konkrétneho prepravcu z ponuky a zadáte svoje prihlasovacie údaje do administrácie prepravcu.')}</p>
                        <img src={IMAGES['help32.jpg']} alt="aktivacia prepravcu" />
                    </div>,
                };

            case '33':
                // Nastavenia prepravcu
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('U prepravcu si musíte nastaviť typ dodania z vášho eshopu ktorý patrí danému prepravcovi. Podľa tohto nastavenia systém rozlíši ktorému prepravcovi objednávku pošle.')}</p>
                        <p>{__('Ak potrebujete špecifikovať typ platby kedy má systém nastaviť dobierku môžete tak spraviť v nastavení "Dobierka". Predvolene je nastavené rozpoznávanie dobierky automaticky systémom.')}</p>
                        <p>{__('Pri automatickom odoslaní určite uvítate nastavenie stavu objednávky kedy sa má objednavka odoslať prepravcovi a aký stav sa má nastavit objednávke po odoslaní.')}</p>
                        <img src={IMAGES['help33.jpg']} alt="nastavenia prepravcu" />
                    </div>,
                };

            case '34':
                // Automaticke odoslanie
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Automatické odoslanie objednávok prepravcov môže mať 2 nastavenia. Prvé je odoslať objednávku po každej objednávke. Toto nastavenie má nevýhodu že štítky na tlač budete mať v súboroch zvlášť.')}</p>
                        <p>{__('Druhé nastavenie je nastavenie konkrétnej hodiny v daný deň kedy sa zoberú všetky objednávky čo sa majú odoslať a odošlú sa naraz.')}</p>
                        <img src={IMAGES['help34.jpg']} alt="automaticke odoslanie" />
                    </div>,
                };

            case '35':
                // Cisla zasielok
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Po odoslaní objednávok prepravcov dostaneme podacie čísla zásielok. Tieto čísla zásielok vám priradíme v zozname objednávok ku konkrétnym objednávkam.')}</p>
                        <p>{__('Ak potrebujete odoslať zákaznikovi číslo zásielky, tak sa to dá cez nastavenie na obrázku. Priradíte údaj objednávky kam máme odoslať číslo zásielky. Následne tento údaj viete využiť v šablóne emailu v eshope.')}</p>
                        <img src={IMAGES['help35.jpg']} alt="cisla zasielok" />
                    </div>,
                };

            case '36':
                // Cisla zasielok
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Ak potrebujete odoslať zákaznikovi číslo zásielky, tak sa to dá cez nastavenie na obrázku. Priradíte údaj objednávky kam máme odoslať číslo zásielky. Následne tento údaj viete využiť v šablóne emailu v eshope.')}</p>
                        <img src={IMAGES['help35.jpg']} alt="ako odoslat cislo zasielky" />
                    </div>,
                };

            case '37':
                // Nastavenie dobierky
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Automatické rozpoznávanie dobierky je predvolené nastavenie u prepravcu. Ak máte aktívne toto nastavenie, tak náš systém automaticky rozpoznáva podľa názvu platby v objednávke či ide o dobierku alebo nie.')}</p>
                        <p>{__('Toto nastavenie viete vypnúť a špecifikovať svoj vlastný typ úhrady z eshopu.')}</p>
                        <img src={IMAGES['help37.jpg']} alt="nastavenie dobierky" />
                    </div>,
                };

            case '38':
                // Nastavenie stavov
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('U prepravcu si viete nastaviť stav objednávky kedy sa má objednávka automaticky odoslať prepravcovi aj stav ktorý sa nastaví objednávke po odoslaní.')}</p>
                        <img src={IMAGES['help38.jpg']} alt="nastavenie stavov" />
                    </div>,
                };

            case '39':
                // Tlac stitkov
                return {
                    title,
                    content: <div className="lightbox-article">
                        <p>{__('Štítky z odoslaných objednávok archivujeme 7 dní. Ak máte nastavené automatické odoslanie objednávok, tak štítky nájdete na jednom mieste.')}</p>
                        <img src={IMAGES['help39.jpg']} alt="tlac stitkov" />
                    </div>,
                };

            default:
                return {};
        }
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { cookies } = this.props;
        const { scroll, lightbox } = this.state;
        const article = !_.isEmpty(lightbox.article) ? this.getLightboxData() : {};

        return (
            <div className="help">
                <div className={`help__menu ${scroll ? 'mini' : ''}`}>
                    <Logo
                        onClick={() => this.scrollToTop()}
                        color="white"
                        className="help__menu__logo"
                    />
                    <Typography className="help__menu__title" variant="h4">
                        {__('Centrum podpory')}
                    </Typography>
                    {!_.isEmpty(cookies.get('token', COOKIE_SETTINGS))
                        ? <Button onClick={() => this.redirect('/')}>{__('Naspäť do aplikácie')}</Button>
                        : <Button onClick={() => this.redirect('/')}>{__('Naspäť na hlavnú stránku')}</Button>}
                </div>
                <div className="help__content">
                    <div className="help__content__panels">
                        {_.map(this.panels, (panel, parent_key) => (
                            <div className="help__content__panels__panel" key={parent_key}>
                                <Typography className="help__content__panels__panel__title" variant="h5">
                                    {panel.name}
                                </Typography>
                                <div className="help__content__panels__panel__text">{panel.text}</div>
                                <div className="help__content__panels__panel__articles">
                                    {_.map(panel.articles, (article, key) => (
                                        <div
                                            onClick={() => this.showLightbox('article', `${parent_key}${key}`)}
                                            className="help__content__panels__panel__articles__article"
                                            key={key}
                                        >
                                            <ArticleIcon />
                                            <span>{article}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="help__content__contact">
                        <div className="help__content__contact__title">{__('Spýtajte sa nás, radi poradíme')}</div>
                        <div className="help__content__contact__hours">Po - Pi (09 - 16)</div>
                        <div className="help__content__contact__email">info@goodeshop.sk</div>
                    </div>
                </div>
                {!_.isEmpty(article) ? this.renderLightbox(
                    'article',
                    article.title,
                    article.content,
                ) : null}
            </div>
        );
    }
}

const stateToProps = ({ dashboard, user }) => ({ dashboard, user });

export default withCookies(withRouter(connect(stateToProps)(HelpScreen)));