import { FETCH_EMPLOYEES, CLEAN_EMPLOYEES, DELETE_EMPLOYEE, DELETE_EMPLOYEES, CREATE_EMPLOYEE } from '../actions/types';

const EMPLOYEES_DEFAULT_STATE = {};

/**
 * Reducer pre zamestnancov.
 */
const EmployeesReducer = (state = EMPLOYEES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_EMPLOYEES:
            // Nacitanie zamestnancov
            return payload;

        case CREATE_EMPLOYEE:
        case CLEAN_EMPLOYEES:
        case DELETE_EMPLOYEE:
        case DELETE_EMPLOYEES:
            // Vytvorenie zamestnanca
            // Vycistenie zamestnancov
            // Zmazanie zamestnanca
            // Zmazanie zamestnancov cez multiselect
            return EMPLOYEES_DEFAULT_STATE;

        default:
            return state;
    }
};

export default EmployeesReducer;