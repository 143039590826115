import _ from 'lodash';
import {
    FETCH_ORDERS,
    CLEAN_ORDERS,
    DELETE_ORDER,
    DELETE_ORDERS,
    CREATE_INVOICE,
    CREATE_INVOICES,
    CHANGE_ORDER_STATE,
    CHANGE_ORDERS_STATE,
    SYNC_ORDER,
    SYNC_ORDERS,
    PAID_ORDER,
    PAID_ORDERS,
    SEND_ORDER,
    SEND_ORDERS,
    ADDON_ORDERS,
    CHANGE_ORDER_INTERNAL_COMMENT,
    EXPORT_ORDER,
    EXPORT_ORDERS,
} from './types';
import { request, __, toNumber } from '../functions';

/**
 * Nacitanie objednavok.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchOrders = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/orders/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_ORDERS, payload: data });
    });
};

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {Object} settings
 */
export const deleteOrder = (screen, id, { order_delete_in_eshop }) => {
    return dispatch => request(`/orders/delete/${id}`, { order_delete_in_eshop }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo zmazať pretože má evidovanú faktúru'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zmazaná'));

        dispatch({ type: DELETE_ORDER });
    });
};

/**
 * Vytvorenie faktury.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const createInvoice = (screen, id) => {
    id = toNumber(id);

    return dispatch => request(`/orders/createInvoice/${id}`).then(response => {
        const { status, data } = response.data;
        let has_error = false;

        if (status === 'success') {
            has_error = _.has(data, id) && _.isString(data[id]);
        }

        if (status === 'error' || has_error) {
            // Fakturu sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Faktúru sa nepodarilo vytvoriť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!has_error) {
            screen.showSnackbar('success', __('Faktúra bola vytvorená'));
        }

        dispatch({ type: CREATE_INVOICE, payload: data });
    });
};

/**
 * Zmena stavu objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} state_id
 */
export const changeOrderState = (screen, id, state_id) => {
    return dispatch => request(`/orders/changeState/${id}/${state_id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávky sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávky bol zmenený'));

        dispatch({ type: CHANGE_ORDER_STATE, payload: data });
    });
};

/**
 * Synchronizacia objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const syncOrder = (screen, id) => {
    return dispatch => request(`/orders/sync/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo synchronizovat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo synchronizovať'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zosynchronizovaná s eshopom'));

        dispatch({ type: SYNC_ORDER, payload: data });
    });
};

/**
 * Uhradenie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const paidOrder = (screen, id) => {
    return dispatch => request(`/orders/paid/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo uhradit
            screen.showSnackbar('error', __('Objednávku sa nepodarilo uhradiť'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola uhradená'));

        dispatch({ type: PAID_ORDER, payload: data });
    });
};

/**
 * Odoslanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {string} date
 * @param {string} position
 */
export const sendOrder = (screen, id, date, position) => {
    return dispatch => request(`/orders/send/${id}/${date}/${position}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error' || _.isEmpty(data.packages)) {
            // Objednavku sa nepodarilo odoslat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo odoslať'));

            if (status === 'error') {
                return;
            }
        }

        if (status === 'success' && !_.isEmpty(data.packages)) {
            screen.showSnackbar('success', __('Objednávka bola odoslaná'));

            if (!_.isEmpty(data.label_url)) {
                // Stiahneme subor
                window.location = data.label_url;
            }
        }

        dispatch({ type: SEND_ORDER, payload: data });
    });
};

/**
 * Zmazanie objednavok cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} settings
 * @param {Object} filtered
 */
export const deleteOrders = (screen, ids, { order_delete_in_eshop }, filtered = {}) => {
    let params = { order_delete_in_eshop };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavky sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávky sa nepodarilo zmazať pretože majú evidované faktúry'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli zmazané'));

        dispatch({ type: DELETE_ORDERS });
    });
};

/**
 * Vytvorenie faktur k objednavkam cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const createInvoices = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiCreateInvoice/${ids}`, params).then(response => {
        const { status, data } = response.data;
        let has_error = false;

        if (status === 'success') {
            // Pozrieme ci nejaka faktura skoncila chybou
            _.each(data, item => {
                if (_.isString(item)) {
                    has_error = true;
                }
            });
        }

        if (status === 'error' || has_error) {
            // Objednavky sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Niektoré faktúry sa nepodarilo vystaviť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!has_error) {
            screen.showSnackbar('success', __('Faktúry boli vytvorené'));
        }

        dispatch({ type: CREATE_INVOICES, payload: data });
    });
};

/**
 * Odoslanie objednavok.
 *
 * @param {Screen} screen
 * @param {number} ids
 * @param {string} date
 * @param {string} position
 * @param {Object} settings
 * @param {Object} filtered
 */
export const sendOrders = (screen, ids, date, position, settings = {}, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiSend/${ids}/${date}/${position}`, { settings }, 'POST', params).then(response => {
        const { status, data } = response.data;

        if (!_.isEmpty(settings) && _.has(data, 'packages')) {
            // Na rozpise zasielok nasetujeme vysledky
            screen.setState({
                settings: _.reduce(_.keys(screen.state.settings), (result, id) => ({ ...result, [id]: { ...screen.state.settings[id], resend: 0 } }), {}),
                responses: { ...screen.state.responses, ...data.packages },
                loading: false,
            });
        }

        if (status === 'error' || _.isEmpty(data.packages)) {
            // Objednavky sa nepodarilo odoslat
            screen.showSnackbar('error', __('Objednávky sa nepodarilo odoslať'));

            if (status === 'error') {
                return;
            }
        }

        if (status === 'success' && !_.isEmpty(data.packages)) {
            let errors = 0;

            _.each(data.packages, item => {
                if (_.has(item, 'error') && item.error !== '') {
                    // Error
                    errors++;
                }
            });

            if (errors > 0) {
                // Mame nejake errory
                let error_message = __('{COUNT} objednávok nebolo odoslaných, prosím skontrolujte zoznam. Ostatné boli odoslané.');

                if (errors === 1) {
                    error_message = __('{COUNT} objednávka nebola odoslaná, prosím skontrolujte zoznam. Ostatné boli odoslané.');
                } else if (errors < 5) {
                    error_message = __('{COUNT} objednávky neboli odoslané, prosím skontrolujte zoznam. Ostatné boli odoslané.');
                }

                screen.showSnackbar('error', error_message.replace('{COUNT}', errors.toString()));
            } else {
                screen.showSnackbar('success', __('Objednávky boli odoslané'));
            }

            if (!_.isEmpty(data.label_url)) {
                // Stiahneme subor
                window.location = data.label_url;
            }
        }

        dispatch({ type: SEND_ORDERS, payload: data });
    });
};

/**
 * Zmena stavu objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} state_id
 * @param {Object} filtered
 */
export const changeOrdersState = (screen, ids, state_id, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiChangeState/${ids}/${state_id}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávok sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávok bol zmenený'));

        dispatch({ type: CHANGE_ORDERS_STATE, payload: data });
    });
};

/**
 * Sync objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const syncOrders = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiSync/${ids}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Nepodarilo sa synchronizovat
            screen.showSnackbar('error', __('Objednávky sa nepodarilo synchronizovať s eshopom'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli synchronizované s eshopom'));

        dispatch({ type: SYNC_ORDERS, payload: data });
    });
};

/**
 * Uhradenie objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const paidOrders = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiPaid/${ids}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavky sa nepodarilo uhradit
            screen.showSnackbar('error', __('Objednávky sa nepodarilo uhradiť'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli uhradené'));

        dispatch({ type: PAID_ORDERS, payload: data });
    });
};

/**
 * Addon objednavok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} addon_id
 * @param {Object} filtered
 */
export const addonOrders = (screen, ids, addon_id, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiAddon/${ids}/${addon_id}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Nepodarilo sa nacitat doplnok
            screen.showSnackbar('error', __('Nepodarilo sa načítať doplnok'));
            return;
        }

        screen.showSnackbar('success', __('Doplnok bol načítaný'));

        if (_.has(data, 'link')) {
            // Addon ma subor na stiahnutie
            window.location = data.link;
        }

        dispatch({ type: ADDON_ORDERS });
    });
};

/**
 * Stiahnutie order pdf.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} pdfExportId
 */
export const pdfOrder = (screen, id, pdfExportId) => {
    return dispatch => request(`/orders/export/${id}/${pdfExportId}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf objednávky'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola exportovaná'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_ORDER, payload: { [id]: id } });
    });
};

/**
 * Stiahnutie order pdf.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {number} pdfExportId
 * @param {Object} filtered
 */
export const pdfOrders = (screen, ids, pdfExportId, filtered = {}) => {
    let params = { merge_multi_order_pdf: screen.state.settings.merge_multi_order_pdf };

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/multiExport/${ids}/${pdfExportId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf objednávok'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli exportované'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_ORDERS, payload: data.ids });
    });
};

/**
 * Zmenime internu poznamku objednavke.
 *
 * @param {number} id
 * @param {string} comment
 */
export const changeOrderInternalComment = (id, comment) => ({ type: CHANGE_ORDER_INTERNAL_COMMENT, payload: { id, comment } });

/**
 * Vycistenie objednavok.
 */
export const cleanOrders = () => ({ type: CLEAN_ORDERS });
