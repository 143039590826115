import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import SubscriptionIcon from '@material-ui/icons/Payment';
import { ResponsiveContainer, AreaChart, Area, XAxis, Tooltip } from 'recharts';
import { Screen, Select, NotificationsTable, Button } from '../components';
import { __, formatAmount, formatDate, toNumber, getGrowth } from '../functions';
import { fetchDashboard, setUser, readNotification, fetchUserNotifications } from '../actions';
import EshopLightbox from '../lightboxes/EshopLightbox';
import { ACCOUNTINGS, ESHOPS, IMAGES, ESHOP_COLORS, ACCOUNTING_COLORS } from '../config';
import '../assets/styles/dashboard.css';

/**
 * Dashboard.
 */
class DashboardScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Dashboard');

    /**
     * Defaut state.
     *
     * @type {{
     *     period: string,
     *     notifications_read: Array,
     *     lightbox: Object,
     * }}
     */
    state = {
        period: '',
        notifications_read: [],
        lightbox: {
            eshop: false,
        },
    };

    /**
     * Eshop required.
     *
     * @type {boolean}
     */
    eshopRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchDashboard } = this.props;

            await fetchDashboard(this);

            const { dashboard } = this.props;

            this.setState({ period: formatDate(dashboard.stats.date, 'yyyy-mm') });
        }

        if (!toNumber(global.user_eshop_id)) {
            // User nema ziadny eshop, zobrazime lightbox
            this.showLightbox('eshop');
        }

        return true;
    }

    /**
     * Event po zmene obdobia.
     *
     * @param {string} period
     */
    onChangePeriod(period) {
        const { fetchDashboard } = this.props;

        this.setState({ period });

        // Nacitame dashboard
        fetchDashboard(this, period);
    }

    /**
     * Oznacenie notifikacie ako precitane.
     *
     * @param {number} id
     * @param {number} button_id
     */
    read(id, button_id) {
        const { readNotification, fetchUserNotifications } = this.props;
        const { notifications_read } = this.state;

        this.setState({ notifications_read: [ ...notifications_read, id ] });

        // Precitame
        readNotification(this, id);

        // Nacitame novy pocet notifikacii
        fetchUserNotifications();
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { dashboard, user } = this.props;
        const { period, notifications_read } = this.state;

        if (_.isEmpty(dashboard) || _.isEmpty(period)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const dates = _.reduce(
            dashboard.periods.reverse(),
            (result, date) => ({ ...result, [formatDate(date, 'yyyy-mm')]: formatDate(date, 'mm/yyyy') }),
            {}
        );
        const date = formatDate(dashboard.stats.date, 'mm/yyyy');
        const graph_data = _.reduce(dashboard.stats.days, (result, stats, date) => ([ ...result, { name: formatDate(date, 'dd.mm'), ...stats } ]), []);
        const total_sum_growth = getGrowth(dashboard.stats.total_sum, dashboard.stats.previous.total_sum);
        const invoices_growth = getGrowth(dashboard.stats.total_invoices, dashboard.stats.previous.total_invoices);
        const packages_growth = getGrowth(dashboard.stats.total_packages, dashboard.stats.previous.total_packages);

        const eshop = toNumber(dashboard.eshop_id) ? dashboard.eshops[dashboard.eshop_id].name : '';
        const accounting = toNumber(dashboard.accounting_id) ? dashboard.accountings[dashboard.accounting_id].name : '';

        return super.render(
            <div className="dashboard">
                <div className="dashboard__content">
                    <div className="dashboard__content__left">
                        <div className="dashboard__content__left__header">
                            <Typography
                                className="dashboard__content__left__header__title"
                                variant="h5"
                            >{__('Dashboard')}</Typography>
                            <Select
                                options={dates}
                                value={period}
                                onChange={value => this.onChangePeriod(value)}
                                allow_empty={false}
                            />
                        </div>
                        <div className="dashboard__content__left__stats">
                            <Paper className="dashboard__content__left__stats__stat" elevation={0}>
                                <div className="dashboard__content__left__stats__stat__name">{__('Objednávky')}</div>
                                <div className="dashboard__content__left__stats__stat__value">
                                    <span>{formatAmount(dashboard.stats.total_sum, user.settings.currency)}</span>
                                    <span
                                        className={total_sum_growth >= 0 ? 'green' : 'red'}
                                    >{total_sum_growth >= 0 ? '+' : ''}{formatAmount(total_sum_growth, '%')}</span>
                                </div>
                            </Paper>
                            <Paper className="dashboard__content__left__stats__stat" elevation={0}>
                                <div className="dashboard__content__left__stats__stat__name">{__('Faktúry')}</div>
                                <div className="dashboard__content__left__stats__stat__value">
                                    <span>{dashboard.stats.total_invoices}</span>
                                    <span
                                        className={invoices_growth >= 0 ? 'green' : 'red'}
                                    >{invoices_growth >= 0 ? '+' : ''}{formatAmount(invoices_growth, '%')}</span>
                                </div>
                            </Paper>
                            <Paper className="dashboard__content__left__stats__stat" elevation={0}>
                                <div className="dashboard__content__left__stats__stat__name">{__('Zásielky')}</div>
                                <div className="dashboard__content__left__stats__stat__value">
                                    <span>{dashboard.stats.total_packages}</span>
                                    <span
                                        className={packages_growth >= 0 ? 'green' : 'red'}
                                    >{packages_growth >= 0 ? '+' : ''}{formatAmount(packages_growth, '%')}</span>
                                </div>
                            </Paper>
                        </div>
                        <Paper className="dashboard__content__left__graph" elevation={0}>
                            <Typography className="dashboard__content__left__graph__title" variant="h6">{date}</Typography>
                            <ResponsiveContainer>
                                <AreaChart data={graph_data}>
                                    <XAxis dataKey="name" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="orders_sum" stroke="#5c70ff" fill="#EFF1FF" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </Paper>
                    </div>
                    <div className="dashboard__content__right">
                        <Paper
                            className={`dashboard__content__right__subscription ${user.subscription ? 'active' : ''}`}
                            elevation={0}
                        >
                            <SubscriptionIcon
                                className="dashboard__content__right__subscription__icon"
                                color="inherit"
                            />
                            <div
                                className="dashboard__content__right__subscription__text"
                            >{user.subscription
                                ? __('Vaše členstvo je aktívne')
                                : __('Vaše členstvo je neaktívne')}</div>
                        </Paper>
                        <div className="dashboard__content__right__services">
                            {eshop !== '' ? <Paper
                                className="dashboard__content__right__services__service"
                                elevation={0}
                                style={{ backgroundColor: ESHOP_COLORS[eshop].background }}
                            >
                                <Link to="/eshop" style={{ color: ESHOP_COLORS[eshop].text }}>
                                    <ButtonBase focusRipple>
                                        <img src={IMAGES[`${eshop.toLowerCase()}.png`]} alt={eshop} />
                                        <div className="dashboard__content__right__services__service__data">
                                            <div
                                                className="dashboard__content__right__services__service__data__title"
                                            >{ESHOPS[eshop]}</div>
                                            <div
                                                className="dashboard__content__right__services__service__data__text"
                                            >{__('Eshopový systém')}</div>
                                        </div>
                                    </ButtonBase>
                                </Link>
                            </Paper> : null}
                            {accounting !== '' ? <Paper
                                className="dashboard__content__right__services__service"
                                elevation={0}
                                style={{ backgroundColor: ACCOUNTING_COLORS[accounting].background }}
                            >
                                <Link to="/accounting" style={{ color: ACCOUNTING_COLORS[accounting].text }}>
                                    <ButtonBase focusRipple>
                                        <img src={IMAGES[`${accounting.toLowerCase()}.png`]} alt={accounting} />
                                        <div className="dashboard__content__right__services__service__data">
                                            <div
                                                className="dashboard__content__right__services__service__data__title"
                                            >{ACCOUNTINGS[accounting]}</div>
                                            <div
                                                className="dashboard__content__right__services__service__data__text"
                                            >{__('Ekonomický systém')}</div>
                                        </div>
                                    </ButtonBase>
                                </Link>
                            </Paper> : null}
                        </div>
                        <div className="dashboard__content__right__notifications">
                            <NotificationsTable
                                title={<div className="dashboard__content__right__notifications__title">
                                    <div
                                        className="dashboard__content__right__notifications__title__text"
                                    >{__('Najnovšie upozornenia')}</div>
                                    <Button
                                        onClick={() => window.location = '/notifications'}
                                        color="primary"
                                    >{__('Zobraziť všetky')}</Button>
                                </div>}
                                user={user}
                                notifications={{ items: _.map(dashboard.notifications, notification => {
                                    if (_.includes(notifications_read, notification.id)) {
                                        // Je uz precitana
                                        return { ...notification, status: 'read' };
                                    }

                                    return notification;
                                }) }}
                                onRead={(id, button_id) => this.read(id, button_id)}
                                paginate={false}
                                show_category={false}
                                show_priority={false}
                                show_columns={false}
                                show_delete={false}
                                errorChars={24}
                            />
                        </div>
                    </div>
                </div>
                {this.renderLightbox(
                    'eshop',
                    __('Vytvorenie nového eshopu'),
                    <EshopLightbox screen={this} eshops={dashboard.eshops} />,
                    '',
                    '',
                    () => {},
                    true
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ dashboard, user }) => ({ dashboard, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchDashboard,
    setUser,
    readNotification,
    fetchUserNotifications,
})(DashboardScreen)));