import React, { Component } from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

/**
 * Message komponenta.
 */
class Message extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     type: string,
     *     icon: string,
     *     className: string,
     * }}
     */
    static defaultProps = {
        type: 'success',
        icon: '',
        className: '',
    };

    /**
     * Zoznam ikon.
     *
     * @type {object}
     */
    icons = {
        success: <CheckCircleIcon />,
        error: <ErrorIcon />,
        info: <InfoIcon />,
        warning: <InfoIcon />,
    };

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { type, icon, children, className } = this.props;

        const icon_name = icon !== '' ? icon : type;

        return (
            <div className={`snackbar ${type} ${className}`}>
                <SnackbarContent
                    className="snackbar-content"
                    message={<span className="snackbar-message">{this.icons[icon_name]}{children}</span>}
                />
            </div>
        );
    }
}

export { Message };