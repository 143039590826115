import { request } from '../functions';
import { FETCH_DASHBOARD } from './types';

/**
 * Nacitanie dashboardu.
 *
 * @param {Screen} screen
 * @param {string} date
 */
export const fetchDashboard = (screen, date = '') => {
    return dispatch => request(`/user-eshops/dashboard/${date}`).then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_DASHBOARD, payload: data.data });
    });
};