import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import NoteIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import { Screen, Table, TableButton } from '../components';
import { __, formatDate, toNumber } from '../functions';
import { fetchExports, cleanExports, deleteExport, deleteExports, setUser } from '../actions';
import { EXPORT_TYPES } from '../config';
import '../assets/styles/exports.css';

/**
 * Zoznam exportov.
 */
class ExportsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Exporty');

    /**
     * Default state.
     *
     * @type {{
     *     loading_table_button: ?string,
     *     lightbox: {},
     * }}
     */
    state = {
        loading_table_button: null,
        lightbox: {
            delete: false,
            multidelete: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { exports } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, exports.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { exports } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, exports.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     */
    fetch(page = 1, per_page = 0, filtered = {}) {
        const { fetchExports } = this.props;

        // Nacitame zoznam
        fetchExports(this, { page, per_page, filtered });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanExports } = this.props;

        // Vycistime zoznam
        cleanExports();
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Zmazanie exportu.
     */
    async delete() {
        const { deleteExport, exports } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme exportu
        await deleteExport(this, lightbox.delete.id);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, exports.filtered);
    }

    /**
     * Zmazanie exportov cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteExports, exports } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme exportov
        await deleteExports(this, ids, exports.filtered);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, exports.filtered);
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { loading_table_button } = this.state;

        return _.map(items, (item) => {
            const { id, type, name, created } = item;

            return [
                name,
                EXPORT_TYPES[type],
                formatDate(created, 'dd.mm.yyyy'),
                [
                    <TableButton
                        id={id}
                        key="note"
                        text={__('Upraviť')}
                        icon={<NoteIcon />}
                        onClick={() => this.redirect(`/export/${id}`)}
                        loading_button={loading_table_button}
                    />,
                    <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => this.confirmDelete(id, button_id)}
                        loading_button={loading_table_button}
                        disabled={toNumber(item.def) === 1}
                    />,
                ],
            ];
        });
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                case 'type':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ'),
                        value: '',
                        options: EXPORT_TYPES,
                   }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, exports } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(exports)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <div className="exports">
                <Table
                    title={<div className="exports__title">
                        <Typography variant="h5">{__('Exporty')}</Typography>
                        <Chip
                            onClick={() => this.redirect('/export')}
                            label={__('Nový export')}
                            icon={<AddIcon />}
                            color="secondary"
                        />
                    </div>}
                    multiselect={[{
                        icon: <DeleteIcon />,
                        text: __('Zmazať'),
                        confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                            ids,
                            clearCallback,
                            loadingCallback,
                        }),
                    }]}
                    filter={this.formatFilter(exports.filter)}
                    filtered={exports.filtered}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Názov'),
                        __('Typ'),
                        __('Vytvorené'),
                        '',
                    ]}
                    data={this.formatData(exports.items)}
                    raw_data={exports.items}
                    count={exports.total}
                    page={exports.page}
                    per_pages={user.settings.per_pages}
                    per_page={exports.per_page}
                    empty_text={__('Zatiaľ nemáte žiadne exporty')}
                />
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento export?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené exporty?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                        lightbox.multidelete.ids,
                        lightbox.multidelete.clearCallback,
                        lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, exports }) => ({ user, exports });

export default withCookies(withRouter(connect(stateToProps, {
    fetchExports,
    cleanExports,
    deleteExport,
    deleteExports,
    setUser,
})(ExportsScreen)));