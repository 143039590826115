import React, { Component } from 'react';
import _ from 'lodash';

/**
 * Status komponenta.
 */
class Status extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     color: string,
     *     show_dot: boolean,
     *     show_name: boolean,
     * }}
     */
    static defaultProps = {
        color: '',
        show_dot: true,
        show_name: true,
    };

    /**
     * Zoznam farieb.
     *
     * @type {object}
     */
    colors = {
        green: '#459350',
        red: '#c24646',
        orange: '#ff9931',
    };

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { show_dot, show_name, children } = this.props;
        let { color } = this.props;

        if (_.has(this.colors, color)) {
            // Pouzivame preddefinovanu farbu
            color = this.colors[color];
        }

        return (
            <div className="status" title={!show_name ? children : null}>
                {show_dot ? <div className="status__dot" style={{ backgroundColor: color }} /> : null}
                {show_name ? <div className="status__text" style={{ color }}>{children}</div> : null}
            </div>
        );
    }
}

export { Status };