import { request } from '../functions';
import { FETCH_GATEWAYS } from './types';

/**
 * Nacitanie bran.
 *
 * @param {Screen} screen
 */
export const fetchGateways = screen => {
    return dispatch => request('/user-gateways/dashboard').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_GATEWAYS, payload: data.data });
    });
};