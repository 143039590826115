import _ from 'lodash';

/**
 * Je validny email?
 *
 * @param {string} email
 *
 * @returns {boolean}
 */
export const isValidEmail = email => {
    const regex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(_.toString(email));
};