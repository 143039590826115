import _ from 'lodash';
import { FETCH_PRODUCTS, CLEAN_PRODUCTS, CHANGE_PRODUCT_STOCK, CHANGE_PRODUCT_MALL } from './types';
import { __, request } from '../functions';

/**
 * Nacitanie produktov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchProducts = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/products/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_PRODUCTS, payload: data });
    });
};

/**
 * Nastavime produktu stav na sklade.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {string} stock
 */
export const changeProductStock = (screen, id, stock) => {
    return dispatch => request(`/products/stock/${id}`, { stock }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkt sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť stav na sklade'));
            return;
        }

        screen.showSnackbar('success', __('Stav na sklade bol zmenený'));

        dispatch({ type: CHANGE_PRODUCT_STOCK, payload: { id, stock } });
    });
};

/**
 * Zobrazime/zrusime produkt na mall.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {boolean} show
 */
export const changeProductMall = (screen, id, show) => {
    return dispatch => request(`/products/mall/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Produkt sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa pridať alebo zmeniť produkt na mall'));
            return;
        }

        screen.showSnackbar('success', show ? __('Produkt bol pridaný na mall') : __('Produkt bol zrušený na mall'));

        dispatch({ type: CHANGE_PRODUCT_MALL, payload: { id } });
    });
};

/**
 * Vycistenie produktov.
 */
export const cleanProducts = () => ({ type: CLEAN_PRODUCTS });
