import _ from 'lodash';
import { FETCH_ADDONS, CREATE_ADDON, DELETE_ADDON } from '../actions/types';
import { toNumber } from '../functions';

const ADDONS_DEFAULT_STATE = [];

/**
 * Reducer pre addony.
 */
const AddonsReducer = (state = ADDONS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ADDONS:
            // Nacitanie addonov
            return payload;

        case CREATE_ADDON:
            // Vytvorenie addonu
            return { ...state, user_addons: { ...state.user_addons, [payload.id]: payload.addon_id } };

        case DELETE_ADDON:
            // Zmazanie addonu
            return { ...state, user_addons: _.remove(
                state.user_addons,
                (addon_id, id) => toNumber(id) !== toNumber(payload.id)
            ) };

        default:
            return state;
    }
};

export default AddonsReducer;