import { CLEAN_TEMPLATE, FETCH_TEMPLATE } from './types';
import { __, request, toNumber } from '../functions';

/**
 * Vycistenie sablony.
 */
export const cleanTemplate = () => ({ type: CLEAN_TEMPLATE });

/**
 * Nacitanie sablony.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const fetchTemplate = (screen, id) => {
    return dispatch => request(`/templates/view/${id}`).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_TEMPLATE, payload: data });
    });
};

/**
 * Vytvorime sablonu.
 *
 * @param {Screen} screen
 * @param {Object} data
 */
export const createTemplate = (screen, data) => {
    const edit = toNumber(data.id) > 0;

    return dispatch => request(`/templates/${edit ? 'edit' : 'create'}`, data, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa ulozit sablony
            screen.showSnackbar('error', __('Nepodarilo sa uložiť email'));
            return;
        }

        window.location = '/templates';
    });
};