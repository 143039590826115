import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Table button.
 */
class TableButton extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     id: number,
     *     link: ?string,
     *     target: string,
     *     text: string,
     *     icon: null,
     *     loading_button: ?string,
     *     onClick: function(),
     *     disabled: boolean,
     * }}
     */
    static defaultProps = {
        id: 0,
        link: null,
        target: '_self',
        text: '',
        icon: null,
        loading_button: null,
        onClick: () => {},
        disabled: false,
    };

    /**
     * Event po kliknuti.
     *
     * @param {string} button_id
     */
    onClick(button_id) {
        const { onClick, loading_button } = this.props;

        if (loading_button === null) {
            // Zavolame callback
            onClick(button_id);
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { id, link, target, text, loading_button, disabled } = this.props;
        let { icon } = this.props;

        // Unikatne button id
        const button_id = `${id}${text}`;

        if (loading_button === button_id) {
            // Tento button ma loading
            icon = <CircularProgress color="primary" size={20} />;
        }

        let button = <IconButton
            onClick={this.onClick.bind(this, button_id)}
            disabled={disabled}
        >{icon}</IconButton>;

        if (link !== null) {
            // Je zadany link
            button = <Link to={link} target={target}>{button}</Link>
        }

        return <Tooltip title={loading_button === null && !disabled ? text : ''}>{button}</Tooltip>;
    }
}

export { TableButton };