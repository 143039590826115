import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Button, Input, Message, Select } from '../components';
import { __, request, toNumber } from '../functions';
import '../assets/styles/service-lightbox.css';

/**
 * Service lightbox.
 */
class ServiceLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen,
     *     services: Object,
     *     service_id: number,
     *     item_label: string,
     *     item_text: string,
     *     item_list_key: string,
     *     item_id_key: string,
     *     callback: function,
     *     create: boolean,
     * }}
     */
    static defaultProps = {
        screen: null,
        services: {},
        service_id: 0,
        item_label: '',
        item_text: '',
        item_list_key: '',
        item_id_key: '',
        callback: () => {},
        create: true,
    };

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     data: Object,
     *     credentials: Object,
     *     items: Object,
     * }}
     */
    state = {
        loading: false,
        data: {},
        credentials: {},
        items: {},
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { services, service_id } = this.props;

        this.setState({ data: this.getDefaultFields(services[service_id].fields) });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [field]: value } });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Skontrolujeme credentials.
     */
    checkCredentials() {
        const { screen, services, service_id, item_id_key } = this.props;
        const { data, credentials, items } = this.state;

        if (!_.isEmpty(items)) {
            // Mame zadane polozky, pokracujeme
            this.save({ ...credentials, [item_id_key]: data.item_id });
            return;
        }

        this.setState({ loading: true });

        const service_data = data;

        request('/user-services/credentials', service_data, 'POST', { service: services[service_id].name }).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });
                screen.showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                return;
            }

            const { item_list_key, item_id_key } = this.props;
            const items = data.credentials[item_list_key];
            const item_id = data.credentials[item_id_key];

            if (toNumber(item_id)) {
                // Mame zadanu polozku, ideme dalej
                this.setState({ loading: false, credentials: data.credentials });
                this.save(data.credentials);
                return;
            }

            this.setState({
                loading: false,
                credentials: data.credentials,
                items,
                // Ako aktivnu nastavime prvu polozku
                data: { ...service_data, item_id: _.keys(items)[0] },
            });
        });
    }

    /**
     * Ulozenie.
     *
     * @param {Object} credentials
     */
    save(credentials) {
        const { service_id, screen, callback, services, create } = this.props;

        if (!create) {
            // Nejde o vytvorenie, rovno volame callback
            callback(credentials);
            return;
        }

        this.setState({ loading: true });

        request(
            '/user-services/create',
            { service_id, credentials },
            'POST',
            { service: services[service_id].name }
        ).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa vytvorit
                screen.showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                return;
            }

            // Zavolame callback
            callback();
        });
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} fields
     *
     * @return {JSX.Element[]}
     */
    renderFields(fields) {
        const { data } = this.state;
        const { item_label } = this.props;

        return _.map(fields, (field, name) => {
            switch (name) {
                case 'item_id':
                    // Polozka
                    return <Select
                        key={name}
                        label={item_label}
                        options={field}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={false}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={__('Prihlasovací email')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={__('Prihlasovacie heslo')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { loading, data, items } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su vyplnene
            return null;
        }

        const { services, service_id, item_text } = this.props;
        let fields = services[service_id].fields;

        if (!_.isEmpty(items)) {
            // Mame zadany zoznam poloziek zobrazime len ten
            fields = { item_id: items };
        }

        return <div className="service-lightbox">
            {!_.isEmpty(items) ? <Message type="warning">{item_text}</Message> : null}
            {this.renderFields(fields)}
            <Button
                onClick={this.checkCredentials.bind(this)}
                className="service-lightbox__button"
                loading={loading}
            >{__('Uložiť')}</Button>
        </div>;
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(ServiceLightbox)));