import { FETCH_FEEDS, CREATE_FEED } from '../actions/types';

const FEEDS_DEFAULT_STATE = [];

/**
 * Reducer pre feedy.
 */
const FeedsReducer = (state = FEEDS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_FEEDS:
            // Nacitanie feedov
            return payload;

        case CREATE_FEED:
            // Vytvorenie feedu
            return { ...state, user_feeds: { ...state.user_feeds, [payload.feed_id]: payload.name } };

        default:
            return state;
    }
};

export default FeedsReducer;