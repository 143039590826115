import { FETCH_STATES, SAVE_STATES } from '../actions/types';

const STATES_DEFAULT_STATE = [];

/**
 * Reducer pre stavy.
 */
const StatesReducer = (state = STATES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_STATES:
            // Nacitanie stavov
            return payload;

        case SAVE_STATES:
            // Ulozenie stavov
            const { items, eshop_settings } = payload;

            return { ...state, items, eshop_settings };

        default:
            return state;
    }
};

export default StatesReducer;