import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import HelpIcon from '@material-ui/icons/Help';
import { Button, Input, Message, Screen, Select } from '../components';
import { __, isEmptyString, toNumber } from '../functions';
import { fetchExport, cleanExport, createExport, setUser } from '../actions';
import { EXPORT_TYPES, PLACEHOLDERS, ADDITIONAL_PLACEHOLDERS } from '../config';
import '../assets/styles/export.css';

/**
 * Vytvorenie/edit exportu.
 */
class ExportScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nový export');

    /**
     * Default state.
     *
     * @type {{
     *     data: Object,
     *     loading: boolean,
     * }}
     */
    state = {
        data: {},
        loading: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchExport, match } = this.props;

            // Ide o edit?
            const is_edit = _.has(match.params, 'id');

            // Nasetujeme title
            this.setTitle(is_edit ? __('Úprava exportu') : __('Nový export'));

            await fetchExport(this, is_edit ? match.params.id : 0);

            const { export_data } = this.props;

            let data = {
                type: _.keys(EXPORT_TYPES)[0],
                name: '',
                data: '',
            };

            if (!_.isEmpty(export_data)) {
                // Mame data z editu
                data = {
                    type: export_data.type,
                    name: export_data.name,
                    data: export_data.data,
                };
            }

            this.setState({ data });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanExport } = this.props;

        cleanExport();
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        if (isEmptyString(data.name)) {
            // Nie je vyplneny nazov
            error = __('Nie je vyplnený názov');
        } else if (isEmptyString(data.data)) {
            // Nie je vyplnena sablona
            error = __('Nie je vyplnená šablóna');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createExport, match } = this.props;

        // Ulozime export
        createExport(this, { ...data, id: _.has(match.params, 'id') ? match.params.id : 0 });
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [type]: value } });
    }

    /**
     * Event po kliku na placeholder.
     *
     * @param {string} placeholder
     */
    onClickPlaceholder(placeholder) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(placeholder);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { export_data, match } = this.props;
        const { data, loading } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Je to edit?
        const is_edit = _.has(match.params, 'id');
        // Je to default?
        const is_default = _.has(export_data, 'def') && toNumber(export_data.def) === 1;

        return super.render(
            <Paper className="export" elevation={0}>
                <Toolbar className="export__header">
                    <div className="export__header__left">
                        <Typography className="export__header__title" variant="h5">
                            {is_edit ? __('Úprava exportu') : __('Nový export')}
                        </Typography>
                    </div>
                </Toolbar>
                <div className="export__content">
                    <Message type="info">{__('Do exportov môžete vkladať špecialné slova ktoré sa pri exportovaní automaticky doplnia podľa objednávky.')}</Message>
                    <div className="export__content__panels">
                        <div className="export__content__panels__panel">
                            <div className="export__content__panels__panel__title">{__('Špeciálne slová')}</div>
                            <div className="export__content__panels__panel__text">{__('Kliknutím skopírujete do schránky.')}</div>
                            <div className="export__content__panels__panel__placeholders">
                                {_.map({ ...PLACEHOLDERS, ...ADDITIONAL_PLACEHOLDERS }, (text, placeholder) => (
                                    <div key={placeholder}>
                                        <Tooltip title={text}>
                                            <Chip
                                                onClick={() => this.onClickPlaceholder(placeholder)}
                                                label={placeholder}
                                                clickable
                                                color="primary"
                                                deleteIcon={<HelpIcon />}
                                            />
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="export__content__panels__panel">
                            <Select
                                label={__('Typ')}
                                value={data.type}
                                options={EXPORT_TYPES}
                                onChange={value => this.onChange('type', value)}
                                allow_empty={false}
                                disabled={is_default}
                            />
                            <Input
                                label={__('Názov')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                                disabled={is_default}
                            />
                            <Message type="info">{data.type === 'excel'
                                ? __('V šablóne si môžete zvoliť ľubovoľný počet riadkov. Stĺpce oddeľte medzi sebou bodkočiarkou ";".')
                                : __('V šablóne môžete použiť HTML kód.')}</Message>
                            <Input
                                label={__('Šablóna')}
                                value={data.data}
                                onChange={value => this.onChange('data', value)}
                                placeholder={data.type === 'excel'
                                    ? `${__('Objednávka č.')} {order.number};{order.client.name};{order.amount};{order.delivery_name};{order.payment_name}`
                                    : `<h3>${__('Objednávka č.')} {order.number}</h3>`}
                                multiline
                            />
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="export__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ export_data, user }) => ({ export_data, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchExport,
    cleanExport,
    createExport,
    setUser,
})(ExportScreen)));