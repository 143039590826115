import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Button, Select, Input, Message } from '../components';
import { __, request, toNumber } from '../functions';
import '../assets/styles/storage-lightbox.css';

/**
 * Storage lightbox.
 */
class StorageLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen
     *     storages: Object,
     *     storage_id: number|null,
     *     name: string,
     *     change_storage: boolean,
     *     afterSetStorageCredentials: function|null,
     * }}
     */
    static defaultProps = {
        screen: null,
        storages: {},
        storage_id: null,
        name: '',
        change_storage: true,
        afterSetStorageCredentials: null,
    };

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     name: string,
     *     storage_id: number|null,
     *     storage_data: Object,
     *     storage_credentials: Object,
     *     storage_companies: Object,
     * }}
     */
    state = {
        loading: false,
        name: '',
        storage_id: null,
        storage_data: {},
        storage_credentials: {},
        storage_companies: {},
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { storages, name } = this.props;

        const storage_id = this.props.storage_id !== null ? this.props.storage_id : _.keys(storages)[0];

        this.setState({
            name,
            storage_id,
            storage_data: !_.isEmpty(storages) ? this.getDefaultFields(storages[storage_id].fields) : {},
        });
    }

    /**
     * Event po zmene nazvu.
     *
     * @param {string} name
     */
    onChangeName(name) {
        this.setState({ name });
    }

    /**
     * Event po zmene skladu.
     *
     * @param {number} value
     */
    onChangeStorage(value) {
        const { storages } = this.props;

        this.setState({ storage_id: value, storage_data: this.getDefaultFields(storages[value].fields) });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const fields = this.state.storage_data;

        this.setState({ storage_data: { ...fields, [field]: value } });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Ulozenie.
     */
    save() {
        const { storage_id, storage_data, storage_credentials, storage_companies } = this.state;
        const { screen } = this.props;

        this.setState({ loading: true });

        if (!_.isEmpty(storage_companies)) {
            // Mame zadany zoznam firiem, pokracujeme dalej
            this.afterSetStorageCredentials(storage_id, {
                ...storage_credentials,
                company_id: storage_data.company_id,
            });
            return;
        }

        request(`/user-eshops/storageCredentials/${storage_id}`, storage_data, 'POST').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });
                screen.showSnackbar('error', __('Nepodarilo sa prihlásiť do skladu'));
                return;
            }

            const { companies, company_id } = data.credentials;

            if (toNumber(company_id) || !_.has(data.credentials, 'company_id')) {
                // Je zadane company id alebo ho nerozlisujeme, mame len jednu firmu, ideme dalej
                this.afterSetStorageCredentials(storage_id, data.credentials);
            } else {
                // Mame viac firiem musime zobrazit select
                this.setState({
                    loading: false,
                    storage_credentials: data.credentials,
                    storage_companies: companies,
                    // Ako aktivnu nastavime prvu firmu
                    storage_data: { ...storage_data, company_id: _.keys(companies)[0] },
                });
            }
        });
    }

    /**
     * Event po obdrzani storage credentials.
     *
     * @param {number} storage_id
     * @param {object} credentials
     */
    afterSetStorageCredentials(storage_id, credentials) {
        const { afterSetStorageCredentials } = this.props;

        if (afterSetStorageCredentials !== null) {
            // Mame zadany custom callback
            afterSetStorageCredentials(storage_id, credentials);
            return;
        }

        this.setState({ loading: false, storage_credentials: credentials });
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} fields
     *
     * @return {JSX.Element[]}
     */
    renderFields(fields) {
        const values = this.state.storage_data;

        return _.map(fields, (field, name) => {
            switch (name) {
                case 'market':
                    // Lokalizacia
                    return <Select
                        key={name}
                        label={__('Lokalizácia')}
                        options={field}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={false}
                    />;

                case 'company_id':
                    // Firma
                    return <Select
                        key={name}
                        label={__('Firma')}
                        options={field}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={false}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={__('Prihlasovací email')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={__('Prihlasovacie heslo')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'url':
                    // Url adresa
                    return <Input
                        key={name}
                        label={__('Url adresa')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="http://195.146.148.111"
                    />;

                case 'api_key':
                    // API kluc
                    return <Input
                        key={name}
                        label={__('API klúč')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'db_path':
                    // DB path
                    return <Input
                        key={name}
                        label={__('Cesta ku zdrojovej ONIX databáze')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="C:\ProgramData\ONIX\Data\Demo\Demo.ndb"
                    />;

                case 'order_id':
                    // Order id
                    return <Input
                        key={name}
                        label={__('ID typu Objednávka')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="1000013"
                    />;

                case 'storage_code':
                    // Kod skladu
                    return <Input
                        key={name}
                        label={__('Kód skladu')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="HS"
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { storage_id, storage_companies, loading } = this.state;
        const { storages, change_storage } = this.props;

        if (storage_id === null) {
            return null;
        }

        let fields = storages[storage_id].fields;

        if (!_.isEmpty(storage_companies)) {
            // Mame zadany zoznam firiem zobrazime len ten
            fields = { company_id: storage_companies };
        }

        return <div className="storage-lightbox">
            <div className="storage-lightbox__content">
                {_.isEmpty(storage_companies) && change_storage ? <Select
                    label={__('Sklad')}
                    options={_.reduce(storages, (result, { id, name }) => ({ ...result, [id]: name }), {})}
                    value={storage_id}
                    onChange={value => this.onChangeStorage(value)}
                    allow_empty={false}
                /> : null}
                {!_.isEmpty(storage_companies) ? <Message type="warning">{__('V sklade máte zaevidovaných viacero firiem, prosím vyberte konkrétnu firmu')}</Message> : null}
                {this.renderFields(fields)}
            </div>
            <Button
                onClick={this.save.bind(this)}
                className="storage-lightbox__button"
                loading={loading}
            >{__('Uložiť')}</Button>
        </div>;
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(StorageLightbox)));
