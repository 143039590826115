import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import EshopIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import SubscriptionIcon from '@material-ui/icons/Payment';
import HelpIcon from '@material-ui/icons/Help';
import EmployeesIcon from '@material-ui/icons/People';
import EshopLightbox from '../lightboxes/EshopLightbox';
import { Screen } from './Screen';
import { Select } from './Select';
import { Message } from './Message';
import { __, getCustomization, request, toNumber } from '../functions';
import { cleanUser } from '../actions';
import { COOKIE_SETTINGS } from '../config';
import '../assets/styles/header.css';

/**
 * Header komponenta.
 */
class Header extends Screen {
    /**
     * Povolujeme eventy?
     *
     * @type {boolean}
     */
    allowEvents = false;

    /**
     * Defaut state.
     *
     * @type {{
     *     lightbox: Object,
     * }}
     */
    state = {
        lightbox: {
            eshop: false,
        },
    };

    /**
     * Centrum podpory.
     */
    help() {
        const { cleanUser } = this.props;

        // Vycistime usera
        cleanUser();

        this.redirect('/help');
    }

    /**
     * Odhlasenie.
     */
    logout() {
        const { cookies } = this.props;

        // Zmazeme token
        cookies.remove('token', COOKIE_SETTINGS);
        cookies.remove('user_eshop_id', COOKIE_SETTINGS);

        window.location = '/login';
    }

    /**
     * Event po zmene eshop id.
     *
     * @param {int} id
     */
    onChangeEshop(id) {
        if (toNumber(id) === 0) {
            return;
        }

        request(`/users/changeEshop/${id}`).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Do tohto eshopu nemáte prístup'));
                return;
            }

            // Nastavime do cookie
            this.props.cookies.set('user_eshop_id', toNumber(id), COOKIE_SETTINGS);

            // Refreshneme
            window.location = this.getActualUrl();
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user } = this.props;

        if (_.isEmpty(user)) {
            // Nie sme prihlaseny
            return null;
        }

        const has_customization = getCustomization() !== null;
        const opened_menu = user.settings.menu !== 'mini';
        let show_subscription = true;

        if (user.subscription && _.has(user, 'subscription_end') && user.subscription_end.substring(0, 4) === '2050') {
            // Nezobrazujeme clenstvo
            show_subscription = false;
        }

        return (
            <AppBar
                position="fixed"
                className={`header ${!opened_menu ? 'closed' : ''}`}
            >
                <div className="header__left">
                    {user.subscription && _.has(user, 'subscription_days') && user.subscription_days !== '' && toNumber(user.subscription_days) <= 5
                        ? <Message type="warning">{__('Vaše členstvo skončí o x y')
                            .replace('x', user.subscription_days)
                            .replace('y', toNumber(user.subscription_days) === 1 ? __('deň') : __('dní'))
                        }</Message>
                        : null}
                    {!user.subscription ? <Message type="error">{__('Vaše členstvo skončilo')}</Message> : null}
                </div>
                <div className="header__buttons">
                    {!_.isEmpty(user.user_eshops) ? <div className="header__buttons__eshop">
                        <EshopIcon color="primary" />
                        <Select
                            options={user.user_eshops}
                            value={user.user_eshop_id}
                            variant="standard"
                            onChange={value => this.onChangeEshop(value)}
                            allow_empty={false}
                        />
                        {user.type !== 'employee' && !has_customization ? <Tooltip title={__('Nový eshop')}>
                            <IconButton
                                onClick={() => this.showLightbox('eshop')}
                                className="header__buttons__button"
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip> : null}
                    </div> : null}
                    <Tooltip title={__('Upozornenia')}>
                        <Link to="/notifications">
                            <IconButton className="header__buttons__button">
                                <Badge badgeContent={user.notifications} color="primary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Link>
                    </Tooltip>
                    {show_subscription && !has_customization ? <Tooltip title={__('Členstvo')}>
                        <Link to="/subscription">
                            <IconButton className="header__buttons__button">
                                <SubscriptionIcon color={user.subscription ? 'inherit' : 'error'} />
                            </IconButton>
                        </Link>
                    </Tooltip> : null}
                    {!has_customization ? <Tooltip title={__('Centrum podpory')}>
                        <IconButton
                            onClick={this.help.bind(this)}
                            className="header__buttons__button"
                        >
                            <HelpIcon />
                        </IconButton>
                    </Tooltip> : null}
                    {user.type !== 'employee' ? <Tooltip title={__('Zamestnanci')}>
                        <Link to="/employees">
                            <IconButton className="header__buttons__button">
                                <EmployeesIcon />
                            </IconButton>
                        </Link>
                    </Tooltip> : null}
                    <Tooltip title={__('Nastavenia')}>
                        <Link to="/settings">
                            <IconButton className="header__buttons__button">
                                <SettingsIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title={__('Odhlásenie')}>
                        <IconButton
                            onClick={this.logout.bind(this)}
                            className="header__buttons__button"
                        >
                            <LogoutIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                {this.renderLightbox(
                    'eshop',
                    __('Vytvorenie nového eshopu'),
                    <EshopLightbox screen={this} eshops={user.eshops} />
                )}
                {this.renderSnackbar()}
            </AppBar>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps, { cleanUser })(Header)));
