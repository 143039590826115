import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CourierLightbox from '../lightboxes/CourierLightbox';
import { Screen, Button, Message, Select, Checkbox, Input } from '../components';
import { __, request, toNumber, resetObject } from '../functions';
import { fetchCourier, changeCourierSettings, setUser } from '../actions';
import { COURIERS } from '../config';
import '../assets/styles/courier.css';

/**
 * Kurier.
 */
class CourierScreen extends Screen {
    /**
     * Default state.
     *
     * @type {{
     *     name: string,
     *     loading_credentials: boolean,
     *     loading_delete: boolean,
     *     settings: Object|null,
     *     lightbox: Object,
     * }}
     */
    state = {
        name: '',
        loading_credentials: false,
        loading_delete: false,
        settings: null,
        lightbox: {
            credentials: false,
        },
    };

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        UloznaLehota: __('Úložná lehota pri dobierke (počet dní)'),
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            this.fetch().then();
        }

        return true;
    }

    /**
     * Nacitame data.
     */
    async fetch() {
        const { fetchCourier, match } = this.props;

        // Vytiahneme nazov kuriera
        const name = _.has(COURIERS, match.params.type) ? COURIERS[match.params.type] : '';

        // Nastavime title
        this.setTitle(name);

        await fetchCourier(this);

        const { courier } = this.props;
        const specific_fields = this.getSpecific();

        const settings = _.reduce(courier.settings, (result, settings, id) => {
            let services = settings.services;

            _.each(_.keys(courier.services), service => {
                if (!_.has(services, service)) {
                    services = { ...services, [service]: '' };
                }
            });

            let specific = {};

            _.each(specific_fields, specific_field => {
                let value = specific_field.value;

                if (_.has(settings.specific, specific_field.name)) {
                    // Uz mame nastavene
                    value = settings.specific[specific_field.name];
                }

                specific = { ...specific, [specific_field.name]: value };
            });

            return [ ...result,  { ...settings, id, services, specific } ];
        }, []);

        this.setState({ loading: false, name, settings });
    }

    /**
     * Event po zmene settingu.
     *
     * @param {int} key
     * @param {string} type
     * @param {string|Object} value
     */
    onChangeSetting(key, type, value) {
        let { settings } = this.state;

        settings = _.map(settings, (data, k) => {
            if (toNumber(k) === toNumber(key)) {
                // Editujeme
                return { ...data, [type]: value };
            }

            return data;
        });

        this.setState({ settings });
    }

    /**
     * Event po zmene sluzby.
     *
     * @param {int} key
     * @param {string} type
     * @param {string} value
     */
    onChangeService(key, type, value) {
        const { settings } = this.state;

        this.onChangeSetting(key, 'services', { ...settings[key].services, [type]: value });
    }

    /**
     * Event po zmene specifickeho nastavenia.
     *
     * @param {int} key
     * @param {string} type
     * @param {string} value
     */
    onChangeSpecific(key, type, value) {
        const { settings } = this.state;

        this.onChangeSetting(key, 'specific', { ...settings[key].specific, [type]: value });
    }

    /**
     * Zmena credentials.
     *
     * @param {Object} credentials
     */
    changeCredentials(credentials) {
        const { lightbox } = this.state;

        // Vytiahneme id nastavenia ktoremu menime udaje
        const credentials_id = !_.isEmpty(lightbox.credentials.id) ? lightbox.credentials.id : 'all';

        // Zatvorime lightbox
        this.closeLightbox('credentials');

        this.setState({ loading_credentials: true });

        const { match } = this.props;

        request(
            '/user-couriers/changeCredentials',
            { credentials, credentials_id },
            'POST',
            { courier: match.params.type }
        ).then(response => {
            const { status } = response.data;

            this.setState({ loading_credentials: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));
        });
    }

    /**
     * Zmazanie kuriera.
     */
    delete() {
        this.setState({ loading_delete: true });

        const { match } = this.props;

        request('/user-couriers/delete', { courier: match.params.type }).then(response => {
            const { status } = response.data;

            this.setState({ loading_delete: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit
                this.showSnackbar('error', __('Nepodarilo sa zmazať prepravcu'));
                return;
            }

            // Zmazane, presmerujeme
            this.redirect('/couriers');
        });
    }

    /**
     * Pridame dalsie nastavenie.
     */
    addSetting() {
        const { settings } = this.state;

        this.setState({ settings: [ ...settings, resetObject(settings[0]) ] });
    }

    /**
     * Zmazeme nastavenie.
     *
     * @param {int} key
     */
    deleteSetting(key) {
        let { settings } = this.state;

        settings = _.remove(settings, (item, k) => k !== key);

        this.setState({ settings });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeCourierSettings } = this.props;
        const { settings } = this.state;

        this.setState({ loading: true });

        changeCourierSettings(this, settings);
    }

    /**
     * Event po aktivacii.
     */
    afterActivate() {
        // Zavrieme lightbox
        this.closeLightbox('credentials');

        this.setState({ loading: true });

        // Znova nacitame data
        this.componentDidMount();
    }

    /**
     * Vratime item lightbox data podla kuriera.
     *
     * @return {Object}
     */
    getItemLightboxData() {
        const { match } = this.props;

        switch (match.params.type) {
            case 'Zasielkovna':
                return {
                    label: __('Odosielatelia'),
                    text: __('V Zásielkovni máte zaevidovaných viacero odosielateľov, prosím vyberte konkrétneho odosielateľa.'),
                    list_key: 'senders',
                    id_key: 'sender_id',
                    allow_empty: false,
                };

            case 'Kurier123':
                return {
                    label: __('Odosielatelia'),
                    text: __('V 123Kuriér máte zaevidovaných viacero odosielateľov, prosím vyberte konkrétneho odosielateľa.'),
                    list_key: 'senders',
                    id_key: 'sender_id',
                    allow_empty: false,
                };

            case 'SlovenskaPosta':
                return {
                    label: __('Odosielatelia'),
                    text: __('V Slovenskej pošte máte zaevidovaných odosielateľov, ak chcete používať konkrétneho odosielateľa prosím vyberte.'),
                    list_key: 'senders',
                    id_key: 'sender_id',
                    allow_empty: true,
                };

            case 'Dpd':
                return {
                    label: __('Odosielatelia'),
                    text: __('V DPD máte zaevidovaných viacero odosielateľov, prosím vyberte konkrétneho odosielateľa.'),
                    list_key: 'senders',
                    id_key: 'sender_id',
                    allow_empty: false,
                };

            case 'Geis':
                return {
                    label: __('Odosielatelia'),
                    text: __('V GEIS máte zaevidovaných viacero odosielateľov, prosím vyberte konkrétneho odosielateľa.'),
                    list_key: 'senders',
                    id_key: 'sender_id',
                    allow_empty: false,
                };

            case 'Dhl':
                return {
                    label: __('Odosielatelia'),
                    text: __('V DHL máte zaevidovaných viacero odosielateľov, prosím vyberte konkrétneho odosielateľa.'),
                    list_key: 'senders',
                    id_key: 'sender_id',
                    allow_empty: false,
                };

            default:
                return {
                    label: '',
                    text: '',
                    list_key: '',
                    id_key: '',
                    allow_empty: false,
                };
        }
    }

    /**
     * Vratime specifikacie nastavenia.
     *
     * @return {Array}
     */
    getSpecific() {
        const { match } = this.props;

        switch (match.params.type) {
            case 'Remax':
                return [
                    {
                        name: 'send',
                        label: __('Stav po importe u prepravcu'),
                        options: {
                            yes: __('Odoslané'),
                            no: __('Neodoslané'),
                        },
                        value: 'yes',
                   },
                ];

            case 'Kurier123':
            case 'Sps':
                return [
                    {
                        name: 'send',
                        label: __('Tlač štítkov'),
                        options: {
                            yes: __('Áno'),
                            no: __('Nie'),
                        },
                        value: 'yes',
                    },
                ];

            default:
                return [];
        }
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { courier } = this.props;
        const { name, loading, loading_credentials, loading_delete, settings } = this.state;

        if (_.isEmpty(courier) || settings === null) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const empty = _.isEmpty(courier.credentials);
        const delivers = _.reduce(courier.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});
        const invalid_setting = _.isEmpty(courier.settings) || _.isEmpty(courier.settings[_.keys(courier.settings)[0]].delivery_id);
        const item_lightbox_data = this.getItemLightboxData();
        const specific = this.getSpecific();
        const multiple = settings.length > 1;

        // Vytiahneme zoznam stavov
        const states = _.reduce(courier.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});
        // Vytiahneme typy uhrad
        const payment_types = _.reduce(courier.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return super.render(
            <Paper className="courier" elevation={0}>
                <Toolbar className="courier__header">
                    <div className="courier__header__left">
                        <Typography className="courier__header__title" variant="h5">{name}</Typography>
                    </div>
                    {!empty ? <div className="courier__header__right">
                        <Button
                            onClick={() => this.showLightbox('credentials', { id: 'all' })}
                            loading={loading_credentials}
                            color="primary"
                        >{__('Zmeniť prihlasovacie údaje')}</Button>
                        <Button
                            onClick={() => this.delete()}
                            loading={loading_delete}
                            color="red"
                        >{__('Zmazať')}</Button>
                    </div> : null}
                    {empty ? <Button
                        onClick={() => this.showLightbox('credentials')}
                        loading={loading_credentials}
                        color="green"
                    >{__('Aktivovať')}</Button> : null}
                </Toolbar>
                <div className="courier__content">
                    {empty
                        ? <Message type="info">{__('Najprv musíte zadať prihlasovacie údaje')}</Message>
                        : null
                    }
                    {!empty && invalid_setting
                        ? <Message type="warning">{__('Pozor! Nemáte nastavený typ dodania ktorý patrí danému prepravcovi.')}</Message>
                        : null}
                    {!empty && !invalid_setting
                        ? <Message type="success">{__('Prepravca je aktívny.')}</Message>
                        : null}
                    {!_.isEmpty(settings) ? <div className="courier__content__title">
                        <Typography
                            className="courier__content__title__text"
                            variant="h6"
                        >{__('Nastavenie')}</Typography>
                        <Chip
                            onClick={() => this.addSetting()}
                            label={__('Pridať nastavenie')}
                            color={empty ? 'inherit' : 'primary'}
                            icon={<AddIcon />}
                            clickable
                            disabled={empty}
                        />
                    </div> : null}
                    {_.map(settings, (settings, key) => {
                        const disabled_credentials = toNumber(settings.id) === 0;

                        return (
                            <div className="courier__content__settings" key={key}>
                                {multiple ? <div className="courier__content__settings__name">
                                    <Input
                                        label={__('Názov nastavenia')}
                                        value={settings.name}
                                        onChange={value => this.onChangeSetting(key, 'name', value)}
                                        placeholder={`${name} ${key + 1}`}
                                    />
                                    <Tooltip title={!disabled_credentials
                                        ? __('Zmeniť prihlasovacie údaje konkrétnemu nastaveniu')
                                        : __('Najprv musíte uložiť nastavenie')}>
                                        <span><Button
                                            onClick={() => this.showLightbox('credentials', { id: settings.id })}
                                            loading={loading_credentials}
                                            color="primary"
                                            disabled={disabled_credentials}
                                        >{__('Zmeniť prihlasovacie údaje')}</Button></span>
                                    </Tooltip>
                                </div> : null}
                                <div className="courier__content__settings__selects">
                                    {!_.isEmpty(courier.types) ? <Select
                                        label={__('Typ zásielky')}
                                        options={courier.types}
                                        value={settings.type}
                                        onChange={value => this.onChangeSetting(key, 'type', value)}
                                    /> : null}
                                    <Select
                                        label={__('Typ dodania objednávky')}
                                        options={delivers}
                                        value={settings.delivery_id}
                                        onChange={value => this.onChangeSetting(key, 'delivery_id', value)}
                                    />
                                    <Select
                                        label={__('Dobierka')}
                                        options={payment_types}
                                        value={settings.cod_payment}
                                        onChange={value => this.onChangeSetting(key, 'cod_payment', value)}
                                        placeholder={__('Automatické rozpoznanie systémom')}
                                    />
                                    <Select
                                        label={__('Stav objednávky pri podaní zásielky')}
                                        options={states}
                                        value={settings.state_send}
                                        onChange={value => this.onChangeSetting(key, 'state_send', value)}
                                    />
                                    <Select
                                        label={__('Stav objednávky po podaní zásielky')}
                                        options={states}
                                        value={settings.state_after_send}
                                        onChange={value => this.onChangeSetting(key, 'state_after_send', value)}
                                    />
                                    {multiple ? <Tooltip title={__('Zmazať')}>
                                        <IconButton
                                            onClick={() => this.deleteSetting(key)}
                                            className="courier__content__settings__selects__button"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip> : null}
                                </div>
                                {!_.isEmpty(specific) ? <div
                                    className="courier__content__settings__title"
                                >{__('Doplnkové nastavenia')}</div> : null}
                                <div className="courier__content__settings__selects">
                                    {_.map(specific, (item) => <Select
                                        key={item.name}
                                        label={item.label}
                                        options={item.options}
                                        value={settings.specific[item.name]}
                                        onChange={value => this.onChangeSpecific(key, item.name, value)}
                                        allow_empty={false}
                                    />)}
                                </div>
                                {!_.isEmpty(courier.services) ? <div
                                    className="courier__content__settings__title"
                                >{__('Doplnkové služby')}</div> : null}
                                <div className="courier__content__settings__checkboxes">
                                    {_.map(courier.services, ({ type, name }, subkey) => {
                                        switch (type) {
                                            case 'text':
                                                return <Input
                                                    key={subkey}
                                                    label={_.has(this.labels, subkey) ? this.labels[subkey] : name}
                                                    value={settings.services[subkey]}
                                                    onChange={value => this.onChangeService(key, subkey, value)}
                                                />;

                                            case 'checkbox':
                                                return <Checkbox
                                                    key={subkey}
                                                    label={name}
                                                    value={settings.services[subkey]}
                                                    onChange={checked => this.onChangeService(key, subkey, checked)}
                                                />;

                                            default:
                                                return null;
                                        }
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    {!_.isEmpty(settings) ? <Button
                        onClick={this.changeSettings.bind(this)}
                        loading={loading}
                        className="courier__content__button"
                        disabled={empty}
                    >{__('Uložiť')}</Button> : null}
                </div>
                {this.renderLightbox(
                    'credentials',
                    `${name} - ${empty ? __('aktivácia') : __('zmena prihlasovacích údajov')}`,
                    <CourierLightbox
                        screen={this}
                        couriers={courier.couriers}
                        courier_id={courier.id}
                        settings={courier.eshop_courier_settings}
                        callback={empty ? this.afterActivate.bind(this) : this.changeCredentials.bind(this)}
                        create={empty}
                        item_label={item_lightbox_data.label}
                        item_text={item_lightbox_data.text}
                        item_list_key={item_lightbox_data.list_key}
                        item_id_key={item_lightbox_data.id_key}
                        item_allow_empty={item_lightbox_data.allow_empty}
                    />
                )}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ courier, user }) => ({ courier, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchCourier,
    changeCourierSettings,
    setUser,
})(CourierScreen)));
