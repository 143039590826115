import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Button, Select, Input, Message } from '../components';
import { __, request, toNumber } from '../functions';
import { ACCOUNTINGS } from '../config';
import '../assets/styles/accounting-lightbox.css';

/**
 * Accounting lightbox.
 */
class AccountingLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen
     *     accountings: Object,
     *     accounting_id: number|null,
     *     name: string,
     *     change_accounting: boolean,
     *     afterSetAccountingCredentials: function|null,
     * }}
     */
    static defaultProps = {
        screen: null,
        accountings: {},
        accounting_id: null,
        name: '',
        change_accounting: true,
        afterSetAccountingCredentials: null,
    };

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     name: string,
     *     accounting_id: number|null,
     *     accounting_data: Object,
     *     accounting_credentials: Object,
     *     accounting_companies: Object,
     * }}
     */
    state = {
        loading: false,
        name: '',
        accounting_id: null,
        accounting_data: {},
        accounting_credentials: {},
        accounting_companies: {},
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { accountings, name } = this.props;

        const accounting_id = this.props.accounting_id !== null ? this.props.accounting_id : _.keys(accountings)[0];

        this.setState({
            name,
            accounting_id,
            accounting_data: !_.isEmpty(accountings) ? this.getDefaultFields(accountings[accounting_id].fields) : {},
        });
    }

    /**
     * Event po zmene nazvu.
     *
     * @param {string} name
     */
    onChangeName(name) {
        this.setState({ name });
    }

    /**
     * Event po zmene fakturacie.
     *
     * @param {number} value
     */
    onChangeAccounting(value) {
        const { accountings } = this.props;

        this.setState({ accounting_id: value, accounting_data: this.getDefaultFields(accountings[value].fields) });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const fields = this.state.accounting_data;

        this.setState({ accounting_data: { ...fields, [field]: value } });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Ulozenie.
     */
    save() {
        const { accounting_id, accounting_data, accounting_credentials, accounting_companies } = this.state;
        const { screen } = this.props;

        this.setState({ loading: true });

        if (!_.isEmpty(accounting_companies)) {
            // Mame zadany zoznam firiem, pokracujeme dalej
            this.afterSetAccountingCredentials(accounting_id, {
                ...accounting_credentials,
                company_id: accounting_data.company_id,
            });
            return;
        }

        request(`/user-eshops/accountingCredentials/${accounting_id}`, accounting_data, 'POST').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });
                screen.showSnackbar('error', __('Nepodarilo sa prihlásiť do ekonomického systému'));
                return;
            }

            const { companies, company_id } = data.credentials;

            if (toNumber(company_id) || !_.has(data.credentials, 'company_id')) {
                // Je zadane company id alebo ho nerozlisujeme, mame len jednu firmu, ideme dalej
                this.afterSetAccountingCredentials(accounting_id, data.credentials);
            } else {
                // Mame viac firiem musime zobrazit select
                this.setState({
                    loading: false,
                    accounting_credentials: data.credentials,
                    accounting_companies: companies,
                    // Ako aktivnu nastavime prvu firmu
                    accounting_data: { ...accounting_data, company_id: _.keys(companies)[0] },
                });
            }
        });
    }

    /**
     * Event po obdrzani accounting credentials.
     *
     * @param {number} accounting_id
     * @param {object} credentials
     */
    afterSetAccountingCredentials(accounting_id, credentials) {
        const { afterSetAccountingCredentials } = this.props;

        if (afterSetAccountingCredentials !== null) {
            // Mame zadany custom callback
            afterSetAccountingCredentials(accounting_id, credentials);
            return;
        }

        this.setState({ loading: false, accounting_credentials: credentials });
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} fields
     *
     * @return {JSX.Element[]}
     */
    renderFields(fields) {
        const values = this.state.accounting_data;

        return _.map(fields, (field, name) => {
            switch (name) {
                case 'market':
                    // Lokalizacia
                    return <Select
                        key={name}
                        label={__('Lokalizácia')}
                        options={field}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={false}
                    />;

                case 'company_id':
                    // Firma
                    return <Select
                        key={name}
                        label={__('Firma')}
                        options={field}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={false}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={__('Prihlasovací email')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={__('Prihlasovacie heslo')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'url':
                    // Url adresa
                    return <Input
                        key={name}
                        label={__('Url adresa')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="http://195.146.148.111"
                    />;

                case 'api_key':
                    // API kluc
                    return <Input
                        key={name}
                        label={__('API klúč')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'db_path':
                    // DB path
                    return <Input
                        key={name}
                        label={__('Cesta ku zdrojovej ONIX databáze')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="C:\ProgramData\ONIX\Data\Demo\Demo.ndb"
                    />;

                case 'apisecret':
                    // Api secret
                    return <Input
                        key={name}
                        label={__('API secret')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'ico':
                    // ICO
                    return <Input
                        key={name}
                        label={__('IČO účtovnej jednotky')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'regular_id':
                    // Regular id
                    return <Input
                        key={name}
                        label={__('ID typu Faktúra')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="1000016"
                    />;

                case 'proforma_id':
                    // Proforma id
                    return <Input
                        key={name}
                        label={__('ID typu Zálohová faktúra')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        placeholder="1000014"
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { accounting_id, accounting_companies, loading } = this.state;
        const { accountings, change_accounting, screen } = this.props;

        if (accounting_id === null) {
            return null;
        }

        let fields = accountings[accounting_id].fields;

        if (!_.isEmpty(accounting_companies)) {
            // Mame zadany zoznam firiem zobrazime len ten
            fields = { company_id: accounting_companies };
        }

        // Vytiahneme lightbox message
        const lightbox_message = screen.hasAccountingAction('lightbox_message', accounting_id, null);

        return <div className="accounting-lightbox">
            <div className="accounting-lightbox__content">
                {_.isEmpty(accounting_companies) && change_accounting ? <Select
                    label={__('Ekonomický systém')}
                    options={_.reduce(accountings, (result, { id, name }) => ({ ...result, [id]: ACCOUNTINGS[name] }), {})}
                    value={accounting_id}
                    onChange={value => this.onChangeAccounting(value)}
                    allow_empty={false}
                /> : null}
                {_.isEmpty(accounting_companies) ? (<div>
                    {lightbox_message !== null ? <Message type="info">{lightbox_message}</Message> : null}
                    {screen.hasAccountingAction('show_premium_message', accounting_id)
                        ? <Message type="warning">{__('Vo ekonomickom systéme musíte mať aktívne prémiové predplatné.')}</Message>
                        : null}
                    {screen.hasAccountingAction('show_social_login_message', accounting_id)
                        ? <Message type="info">{__('Ak máte vo ekonomickom systéme nastavené prihlasovanie cez sociálne siete (facebook, google) je potrebné si nastaviť prihlasovacie heslo vo ekonomickom systéme')}</Message>
                        : null}
                </div>) : null}
                {!_.isEmpty(accounting_companies) ? <Message type="warning">{__('Vo ekonomickom systéme máte zaevidovaných viacero firiem, prosím vyberte konkrétnu firmu')}</Message> : null}
                {this.renderFields(fields)}
            </div>
            <Button
                onClick={this.save.bind(this)}
                className="accounting-lightbox__button"
                loading={loading}
            >{__('Uložiť')}</Button>
        </div>;
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(AccountingLightbox)));
