import _ from 'lodash';
import { __, request } from '../functions';
import { FETCH_SETTINGS, SAVE_SETTINGS } from './types';

/**
 * Nacitanie nastaveni.
 *
 * @param {Screen} screen
 */
export const fetchSettings = screen => {
    return dispatch => request('/users/settings').then(response => {
        const { data, sync } = response.data;

        const { user } = sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_SETTINGS, payload: data });
    });
};

/**
 * Ulozenie nastaveni.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const saveSettings = (screen, settings) => {
    return dispatch => request('/users/saveSettings', { settings }, 'POST').then(response => {
        const { status, data } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            let error = __('Nepodarilo sa zmeniť nastavenia');

            if (_.has(data, 'email_old')) {
                error = __('Aktuálny email je neplatný');
            } else if (_.has(data, 'email_password')) {
                error = __('Heslo nie je správne');
            } else if (_.has(data, 'password_old')) {
                error = __('Aktuálne heslo nie je správne');
            }

            screen.showSnackbar('error', error);
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        // Nasetujeme nastavenia
        screen.setSettings(data);

        dispatch({ type: SAVE_SETTINGS, payload: data });
    });
};