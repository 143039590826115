import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Slider from '@material-ui/core/Slider';
import { Button, Message, Switch } from '../components';
import { __, formatAmount, formatDate, request } from '../functions';
import { setUser } from '../actions';
import { IMAGES } from '../config';
import '../assets/styles/payment-lightbox.css';

/**
 * Payment lightbox.
 */
class PaymentLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen,
     *     prices: Object,
     *     pay_date: string,
     *     onClick: function,
     * }}
     */
    static defaultProps = {
        screen: null,
        prices: {},
        pay_date: '',
        onClick: () => {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     loadingTransfer: boolean,
     *     pricing: number,
     *     type: string,
     *     transfer: boolean,
     * }}
     */
    state = {
        loading: false,
        loadingTransfer: false,
        pricing: 0,
        type: 'year',
        transfer: false,
    };

    /**
     * Event po zmene cennika.
     *
     * @param {number} pricing
     */
    onChangePricing(pricing) {
        this.setState({ pricing });
    }

    /**
     * Event po zmene typu.
     *
     * @param {string} type
     */
    onChangeType(type) {
        this.setState({ type });
    }

    /**
     * Uhrada.
     *
     * @param {number} id
     */
    pay(id) {
        this.setState({ loading: true });

        const { onClick } = this.props;

        // Zavolame callback
        onClick(id);
    }

    /**
     * Prevod.
     *
     * @param {number} id
     */
    transfer(id) {
        const { screen, setUser } = this.props;

        this.setState({ loadingTransfer: true });

        request(`/subscriptions/createProforma/${id}`).then(response => {
            const { status, data, sync } = response.data;

            if (status === 'error') {
                // Nepodarilo sa generovat
                screen.showSnackbar('error', __('Nepodarilo sa vygenerovať faktúru'));
                this.setState({ loadingTransfer: false });
                return;
            }

            // Nasetujeme user data
            setUser(sync.user);

            // Stiahneme fakturu
            request(`/subscriptions/pdf/${data.id}`).then(response => {
                const { status, data } = response.data;

                if (status === 'error') {
                    // Nepodarilo sa stiahnut pdf
                    screen.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                    this.setState({ loadingTransfer: false });
                    return;
                }

                // Stiahneme subor
                window.location = data.link;

                this.setState({ transfer: true });
            });
        });
    }

    /**
     * Zatvorime lightbox.
     */
    close() {
        const { screen } = this.props;

        screen.closeLightbox('payment');
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { loading, loadingTransfer, pricing, type, transfer } = this.state;
        const { prices, pay_date } = this.props;

        const items = _.reduce(prices, (result, price) => {
            return { ...result, [price.type]: { ...result[price.type], [price.orders]: price.price } };
        }, {});
        const ids = _.reduce(prices, (result, price) => {
            return { ...result, [price.type]: { ...result[price.type], [price.orders]: price.id } };
        }, {});

        if (transfer) {
            // Platba prevodom
            return (
                <div className="payment-lightbox">
                    <Message type="info">{__('V najbližšom čase sa vám stiahne zálohová faktúra ktorú je potrebné uhradiť do 3 pracovných dní. Ak vám v tomto čase vyprší členstvo tak vám ho automaticky predĺžíme.')}</Message>
                    <div className="payment-lightbox__buttons">
                        <Button
                            onClick={() => this.close()}
                            className="payment-lightbox__buttons__button cancel"
                        >{__('Zatvoriť')}</Button>
                    </div>
                </div>
            );
        }

        return <div className="payment-lightbox">
            <Message type="warning">{__('Pri ročnej platbe ušetríte 2 mesiace zdarma.')}</Message>
            <Message type="info">{__('Koľko objednávok máte mesačne?')}</Message>
            <div className="payment-lightbox__slider">
                <Slider
                    defaultValue={pricing}
                    step={1}
                    marks={_.reduce(_.keys(items.month), (result, value, key) => [ ...result, {
                        value: key,
                        label: formatAmount(value, '', 0),
                    } ], [])}
                    min={0}
                    max={_.keys(items.month).length - 1}
                    onChange={(event, value) => this.onChangePricing(value)}
                />
            </div>
            <div className="payment-lightbox__values">
                <div className="payment-lightbox__values__value">
                    <div className="payment-lightbox__values__value__label">{__('Ročná platba')}</div>
                    <div className="payment-lightbox__values__value__text">
                        <Switch
                            label={null}
                            checked={type === 'year'}
                            onChange={value => this.onChangeType(value ? 'year' : 'month')}
                        />
                    </div>
                </div>
                <div className="payment-lightbox__values__value">
                    <div className="payment-lightbox__values__value__label">{__('Dátum platby')}</div>
                    <div className="payment-lightbox__values__value__text">
                        {formatDate(pay_date, 'dd.mm.yyyy')}
                    </div>
                </div>
                <div className="payment-lightbox__values__value">
                    <div className="payment-lightbox__values__value__label">{__('Suma platby')}</div>
                    <div className="payment-lightbox__values__value__text">
                        {formatAmount(items[type][_.keys(items[type])[pricing]], 'EUR')}
                    </div>
                </div>
            </div>
            <div className="payment-lightbox__buttons">
                <Button
                    onClick={() => this.pay(ids[type][_.keys(items[type])[pricing]])}
                    className="payment-lightbox__buttons__button paypal"
                    loading={loading}
                >
                    <img src={IMAGES['paypal.png']} alt="paypal" />
                </Button>
                <Button
                    onClick={() => this.transfer(ids[type][_.keys(items[type])[pricing]])}
                    className="payment-lightbox__buttons__button transfer"
                    loading={loadingTransfer}
                    color="green"
                >{__('Uhradiť prevodom')}</Button>
            </div>
        </div>;
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps, { setUser })(PaymentLightbox)));
