import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Screen, Message, Select, Button, Input } from '../components';
import { __ } from '../functions';
import { fetchStates, saveStates, setUser } from '../actions';
import '../assets/styles/states.css';

/**
 * Stav objednavky.
 */
class StatesScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Stav objednávky');

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     items: [],
     *     settings: {},
     * }}
     */
    state = {
        loading: false,
        items: [],
        settings: {},
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchStates } = this.props;

            await fetchStates(this);

            const { states } = this.props;

            this.setState({
                items: states.items,
                settings: {
                    state_url_field: states.eshop_settings.state_url_field,
                    state_url_cancel: states.eshop_settings.state_url_cancel,
                },
            });
        }

        return true;
    }

    /**
     * Event po kliku na link.
     *
     * @param {string} link
     */
    onClickLink(link) {
        // Nastavime link do clipboardu
        navigator.clipboard.writeText(link);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Pridame stav.
     */
    addState() {
        const { items } = this.state;

        this.setState({ items: [ ...items, { id: 0, state_id: 0, name: '', text: '' } ] });
    }

    /**
     * Zmazeme stav.
     *
     * @param {int} key
     */
    deleteState(key) {
        let { items } = this.state;

        items = _.remove(items, (item, k) => k !== key);

        this.setState({ items });
    }

    /**
     * Event po zmene stavu.
     *
     * @param {int} key
     * @param {string} type
     * @param {string} value
     */
    onChangeState(key, type, value) {
        let { items } = this.state;

        items = _.map(items, (item, k) => {
            if (key === k) {
                // Editujeme polozku
                return { ...item, [type]: value };
            }

            return item;
        });

        this.setState({ items });
    }

    /**
     * Ulozime nastavenia.
     */
    save() {
        const { items, settings } = this.state;
        const { saveStates } = this.props;

        this.setState({ loading: true });

        saveStates(this, items, settings);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { states } = this.props;
        const { items, settings, loading } = this.state;

        if (_.isEmpty(states) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const eshop_states = _.reduce(states.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return super.render(
            <Paper className="states" elevation={0}>
                <Toolbar className="states__header">
                    <div className="states__header__left">
                        <Typography className="states__header__title" variant="h5">{__('Stav objednávky')}</Typography>
                        <Tooltip title={__('Kliknutím skopírujete do schránky.')}>
                            <Chip
                                onClick={() => this.onClickLink(states.url)}
                                label={states.url}
                                color="primary"
                                icon={<LinkIcon />}
                            />
                        </Tooltip>
                    </div>
                </Toolbar>
                <div className="states__content">
                    <Message type="info">{__('Po návšteve vyššie uvedeného linku sa zobrazí externe vašemu zákazníkovi stav jeho objednávky. Nižšie si viete nastaviť konkrétne stavy.')}</Message>
                    <div className="states__content__selects">
                        <Select
                            label={__('Údaj objednávky kde sa má uložit url adresa stavu')}
                            options={states.eshop_data.placeholder_fields}
                            value={settings.state_url_field}
                            onChange={value => this.onChangeSetting('state_url_field', value)}
                            allow_empty={true}
                        />
                        <Select
                            label={__('Stav objednávky po storne od zákazníka')}
                            options={eshop_states}
                            value={settings.state_url_cancel}
                            onChange={value => this.onChangeSetting('state_url_cancel', value)}
                            allow_empty={true}
                        />
                    </div>
                    <div className="states__content__title">
                        <Typography
                            className="states__content__title__text"
                            variant="h6"
                        >{__('Zoznam stavov')}</Typography>
                        <Chip
                            onClick={() => this.addState()}
                            label={__('Pridať stav')}
                            color="primary"
                            icon={<AddIcon />}
                            clickable
                        />
                    </div>
                    <div className="states__content__items">
                        {_.map(items, (item, key) => (
                            <div className="states__content__items__item" key={key}>
                                <Select
                                    label={__('Stav objednávky')}
                                    options={eshop_states}
                                    value={item.state_id}
                                    onChange={value => this.onChangeState(key, 'state_id', value)}
                                    allow_empty={true}
                                />
                                <Input
                                    label={__('Názov')}
                                    value={item.name}
                                    onChange={value => this.onChangeState(key, 'name', value)}
                                />
                                <Input
                                    label={__('Text')}
                                    value={item.text}
                                    onChange={value => this.onChangeState(key, 'text', value)}
                                />
                                <Tooltip title={__('Zmazať')}>
                                    <IconButton
                                        onClick={() => this.deleteState(key)}
                                        className="states__content__items__item__button"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                    <Button
                        onClick={this.save.bind(this)}
                        loading={loading}
                        className="states__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ states, user }) => ({ states, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchStates,
    saveStates,
    setUser,
})(StatesScreen)));