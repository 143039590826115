import _ from 'lodash';
import {
    FETCH_TEMPLATES,
    CLEAN_TEMPLATES,
    DELETE_TEMPLATE,
    DELETE_TEMPLATES,
} from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie sablon.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchTemplates = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/templates/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_TEMPLATES, payload: data });
    });
};

/**
 * Zmazanie sablony.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteTemplate = (screen, id) => {
    return dispatch => request(`/templates/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Sablonu sa nepodarilo zmazat
            screen.showSnackbar('error', __('Nepodarilo sa zmazať email'));
            return;
        }

        screen.showSnackbar('success', __('Email bol zmazaný'));

        dispatch({ type: DELETE_TEMPLATE });
    });
};

/**
 * Zmazanie sablon cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteTemplates = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/templates/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Sablony sa nepodarilo zmazat
            screen.showSnackbar('error', __('Emaily sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Emaily boli zmazané'));

        dispatch({ type: DELETE_TEMPLATES });
    });
};

/**
 * Vycistenie sablon.
 */
export const cleanTemplates = () => ({ type: CLEAN_TEMPLATES });