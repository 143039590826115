import { FETCH_BANKS, CREATE_BANK, SAVE_BANK_SETTINGS } from '../actions/types';

const BANKS_DEFAULT_STATE = [];

/**
 * Reducer pre banky.
 */
const BanksReducer = (state = BANKS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_BANKS:
            // Nacitanie bank
            return payload;

        case CREATE_BANK:
            // Vytvorenie banky
            return { ...state, user_banks: { ...state.user_banks, [payload.bank_id]: payload.name } };

        case SAVE_BANK_SETTINGS:
        default:
            return state;
    }
};

export default BanksReducer;