import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import NewlyIcon from '@material-ui/icons/Alarm';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Error';
import ReadIcon from '@material-ui/icons/AlarmOn';
import { Table } from './Table';
import { TableButton } from './TableButton';
import { __, formatAmount, formatDate } from '../functions';
import { COURIERS, NOTIFICATIONS_CATEGORIES } from '../config';

/**
 * Zoznam notifikacii.
 */
class NotificationsTable extends Component
{
    /**
     * Default props.
     * @type {{
     *     title: JSX.Element|null,
     *     notifications: Object,
     *     multiselect: Array,
     *     filter: Object,
     *     filtered: Object,
     *     paginate: boolean,
     *     show_columns: boolean,
     *     show_category: boolean,
     *     show_delete: boolean,
     *     show_priority: boolean,
     *     onChangeFilter: function,
     *     onChangePage: function,
     *     onChangePerPage: function,
     *     onRead: function,
     *     onDelete: function,
     *     errorChars: number,
     * }}
     */
    static defaultProps = {
        title: null,
        notifications: {},
        multiselect: [],
        filter: {},
        filtered: {},
        paginate: true,
        show_columns: true,
        show_category: true,
        show_delete: true,
        show_priority: true,
        onChangeFilter: () => {},
        onChangePage: () => {},
        onChangePerPage: () => {},
        onRead: () => {},
        onDelete: () => {},
        errorChars: 64,
    };

    /**
     * Default state.
     *
     * @type {{
     *     loading_table_button: ?string,
     * }}
     */
    state = {
        loading_table_button: null,
    };

    /**
     * Zoznam notifikacii.
     *
     * @type {Object}
     */
    texts = {
        'order.create': __('Objednávka {NUMBER} bola vytvorená'),
        'order.send': __('Objednávka {NUMBER} bola odoslaná do {COURIER} s číslom zásielky {PACKAGE_NUMBER}'),
        'order.not_send': __('Objednávku {NUMBER} sa nepodarilo odoslať do {COURIER} - {ERROR}'),
        'order.send_waiting_payment': __('Objednávka {NUMBER} bola odoslaná do {COURIER} ale čaká na úhradu u prepravcu'),
        'invoice.create': __('Faktúra {NUMBER} bola vystavená na základe objednávky {ORDER_NUMBER}'),
        'invoice.regular_create': __('Faktúra {NUMBER} bola vystavená na základe úhrady zálohovej faktúry {PROFORMA_NUMBER}'),
        'invoice.paid': __('Faktúre {NUMBER} bola pridaná úhrada vo výške {AMOUNT}'),
        'heureka.create': __('Objednávka {NUMBER} bola vytvorená na heuréke'),
        'heureka.cancel': __('Objednávka {NUMBER} bola stornovaná na heuréke'),
        'heureka.paid': __('Objednávka {NUMBER} bola uhradená na heuréke'),
        'user.no_subscription': __('Objednávka nebola importovaná z dôvodu neaktívneho členstva'),
        'user.info_days_subscription': __('Vaše členstvo končí o {COUNT} dní'),
        'user.info_tomorrow_subscription': __('Vaše členstvo končí zajtra'),
        'bmail.invalid_amount': __('Objednávka číslo {NUMBER} nebola spárovaná z dôvodu prijatia rozdielnej sumy {AMOUNT}'),
    };

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        const { onChangeFilter } = this.props;

        onChangeFilter(filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        const { onChangePage } = this.props;

        onChangePage(page);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        const { onChangePerPage } = this.props;

        onChangePerPage(per_page);
    }

    /**
     * Naformatujeme notifikaciu.
     *
     * @param {string} text
     * @param {Object} data
     *
     * @return {JSX.Element}
     */
    formatText(text, data) {
        // Vytiahneme zoznam placeholderov
        const placeholders = text.match(/({.*?})/g);

        // Rozdelime text na slova
        const words = text.split(' ');
        let response = [];

        _.each(words, word => {
            response = [ ...response, ...[word, ' '] ];
        });

        if (_.toArray(placeholders).length === 0) {
            // Nemame ziadne placeholdre
            return response;
        }

        const { errorChars } = this.props;

        _.each(placeholders, placeholder => {
            // Vytiahneme nazov fieldu
            const field = placeholder.replace(/[{}]/g, '').toLowerCase();

            if (_.has(data, field)) {
                // Data maju placeholder field
                let value = data[field];

                switch (field) {
                    case 'number':
                    case 'order_number':
                    case 'proforma_number':
                        // Number field ma svoj number_type a number_id field
                        let prefix_number = field.match(/(.*?)_/);
                        prefix_number = prefix_number !== null ? prefix_number[0] : '';

                        const number_type_field = `${prefix_number}number_type`;
                        const number_id_field = `${prefix_number}number_id`;

                        if (_.has(data, number_type_field) && _.has(data, number_id_field)) {
                            let url = '';

                            switch (data[number_type_field]) {
                                case 'order':
                                default:
                                    // Objednavka
                                    url = `/orders/view/${data[number_id_field]}`;
                                    break;

                                case 'invoice':
                                    // Faktura
                                    url = `/invoices/view/${data[number_id_field]}`;
                                    break;
                            }

                            value = <Link key={url} to={url}>{value}</Link>;
                        }
                        break;

                    case 'amount':
                        // Amount field ma currency field
                        if (_.has(data, 'currency')) {
                            value = formatAmount(value, data.currency);
                        }
                        break;

                    case 'courier':
                        if (_.has(COURIERS, value)) {
                            value = <Link key={value} to={`/courier/${value}`}>{COURIERS[value]}</Link>;
                        }
                        break;

                    case 'error':
                        value = value.substring(0, errorChars);
                        break;

                    default:
                        break;
                }

                // Nahradime placeholder v response
                response = _.map(response, word => {
                    if (word === placeholder) {
                        return value;
                    }

                    return word;
                });
            }
        });

        return response;
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { onRead, onDelete, show_category, show_delete, show_priority } = this.props;
        const { loading_table_button } = this.state;

        return _.map(items, ({ id, status, priority, category, type, data, created }) => {
            let item = [
                show_priority
                    ? (priority === 'high' && status === 'newly' ? <WarningIcon color="action" /> : null)
                    : null,
            ];

            if (show_category) {
                // Zobrazujeme kategoriu
                item = [ ...item, _.has(NOTIFICATIONS_CATEGORIES, category) ? NOTIFICATIONS_CATEGORIES[category] : '' ];
            }

            return [ ...item, ...[
                _.has(this.texts, type) ? this.formatText(this.texts[type], data) : '',
                formatDate(created, 'dd.mm.yyyy hh:ii'),
                [
                    <TableButton
                        id={id}
                        key="read"
                        text={status === 'newly' ? __('Označiť ako prečítané') : __('Prečítané')}
                        icon={status === 'newly' ? <NewlyIcon color="action" /> : <ReadIcon />}
                        onClick={button_id => onRead(id, button_id)}
                        loading_button={loading_table_button}
                        disabled={status === 'read'}
                    />,
                    show_delete ? <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => onDelete(id, button_id)}
                        loading_button={loading_table_button}
                    /> : null,
                ],
            ] ];
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const {
            title,
            user,
            notifications,
            multiselect,
            filter,
            filtered,
            paginate,
            show_category,
            show_columns,
            show_priority,
        } = this.props;

        const columns = _.remove([
            show_priority ? '' : null,
            show_category ? __('Kategória') : null,
            __('Text'),
            __('Vytvorené'),
            '',
        ], column => column !== null);

        return (
            <Table
                title={title}
                multiselect={multiselect}
                filter={filter}
                filtered={filtered}
                onChangeFilter={filtered => this.onChangeFilter(filtered)}
                onChangePage={page => this.onChangePage(page)}
                onChangePerPage={per_page => this.onChangePerPage(per_page)}
                columns={show_columns ? columns : []}
                data={this.formatData(notifications.items)}
                raw_data={notifications.items}
                count={paginate ? notifications.total : notifications.items.length}
                page={paginate ? notifications.page : 1}
                per_pages={user.settings.per_pages}
                per_page={paginate ? notifications.per_page : notifications.items.length}
                empty_text={__('Zatiaľ nemáte žiadne upozornenia')}
                paginate={paginate}
            />
        );
    }
}

export { NotificationsTable };