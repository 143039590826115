import { FETCH_PACKAGES } from '../actions/types';

const PACKAGES_DEFAULT_STATE = [];

/**
 * Reducer pre zasielky.
 */
const PackagesReducer = (state = PACKAGES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_PACKAGES:
            // Nacitanie zasielok
            return payload;

        default:
            return state;
    }
};

export default PackagesReducer;