import { FETCH_LABELS, CLEAN_LABELS, DELETE_LABEL, DOWNLOAD_LABEL, DELETE_LABELS } from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';

const LABELS_DEFAULT_STATE = {};

/**
 * Reducer pre stitky.
 */
const LabelsReducer = (state = LABELS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_LABELS:
            // Nacitanie stitkov
            return payload;

        case DOWNLOAD_LABEL:
            // Stiahnutie stitku
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload)) {
                    // Nasli sme stitok, zmenime status
                    item = { ...item, status: 'download' };
                }

                return item;
            }) };

        case CLEAN_LABELS:
        case DELETE_LABEL:
        case DELETE_LABELS:
            // Vycistenie stitkov
            // Zmazanie stitku
            // Zmazanie stitkov
            return LABELS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default LabelsReducer;