import React, { Component } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { __ } from '../functions';

/**
 * Input komponenta.
 */
class Input extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     type: string,
     *     label: string,
     *     value: string,
     *     maxLength: number,
     *     required: boolean,
     *     multiline: boolean,
     *     focus: boolean,
     *     disabled: boolean,
     *     error: boolean,
     *     inputRef: ref,
     *     content: null,
     *     rows: number,
     *     placeholder: string,
     *     onChange: function,
     * }}
     */
    static defaultProps = {
        type: 'text',
        label: '',
        value: '',
        maxLength: null,
        required: false,
        multiline: false,
        focus: false,
        disabled: false,
        error: false,
        inputRef: null,
        content: null,
        rows: 0,
        placeholder: '',
        onChange: () => {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     value: string
     * }}
     */
    state = {
        value: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { value } = this.props;

        // Nasetujeme value do state z props
        this.setState({ value });
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value !== this.props.value) {
            // Nesedi hodnota v state a props, aktualizujeme
            this.setState({ value: this.props.value });
        }
    }

    /**
     * Event po zmene.
     *
     * @param {string} value
     */
    onChange(value) {
        this.setState({ value });

        this.props.onChange(value);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { type, label, maxLength, required, multiline, rows, focus, disabled, inputRef, placeholder, error } = this.props;
        const { value } = this.state;

        let inputProps = {};

        if (maxLength !== null) {
            inputProps = { ...inputProps, maxLength };
        }

        let content = this.props.content !== null ? this.props.content : <TextField
            type={type}
            className="input__container"
            onChange={event => this.onChange(event.target.value)}
            value={value}
            variant="outlined"
            inputProps={inputProps}
            required={required}
            multiline={multiline}
            rows={rows}
            autoFocus={focus}
            disabled={disabled}
            inputRef={inputRef}
            placeholder={placeholder}
            fullWidth
            error={error}
        />;

        if (type === 'date') {
            content = <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className="input__container"
                    disableToolbar
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    value={value}
                    onChange={date => this.onChange(date)}
                    cancelLabel={__('Zrušiť')}
                    invalidDateMessage={null}
                />
            </MuiPickersUtilsProvider>;
        } else if (type === 'time') {
            content = <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                    className="input__container"
                    disableToolbar
                    inputVariant="outlined"
                    openTo="hours"
                    views={['hours']}
                    ampm={false}
                    value={value}
                    onChange={date => this.onChange(date)}
                    cancelLabel={__('Zrušiť')}
                />
            </MuiPickersUtilsProvider>;
        }

        return (
            <div className={`input ${disabled ? 'disabled' : ''}`}>
                {label !== '' ? <Typography variant="body2" className="input__label">{`${label}${required ? ' *' : ''}`}</Typography> : null}
                {content}
            </div>
        );
    }
}

export { Input };
