import { __, request } from '../functions';
import { FETCH_GATEWAY, CHANGE_GATEWAY_SETTINGS } from './types';

/**
 * Nacitanie brany.
 *
 * @param {Screen} screen
 */
export const fetchGateway = screen => {
    const { match } = screen.props;

    return dispatch => request('/user-gateways/get', { gateway: match.params.type }).then(response => {
        const { status, data, sync } = response.data;

        if (status === 'error') {
            // Neplatna brana
            window.location = '/dashboard';
            return;
        }

        const { user } = sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_GATEWAY, payload: data });
    });
};

/**
 * Zmenime nastavenia brany.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeGatewaySettings = (screen, settings) => {
    const { match } = screen.props;

    return dispatch => request(
        '/user-gateways/changeSettings',
        { settings },
        'POST',
        { gateway: match.params.type }
    ).then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        // Vyresetujeme nastavenia
        screen.setState({ settings: null });

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        // Nacitame data nanovo
        screen.fetch();

        dispatch({ type: CHANGE_GATEWAY_SETTINGS, payload: settings });
    });
};