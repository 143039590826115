import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Button, Input, Message, Select } from '../components';
import { __, isValidAccount, isValidIban, request, toNumber } from '../functions';
import '../assets/styles/courier-lightbox.css';

/**
 * Courier lightbox.
 */
class CourierLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen,
     *     couriers: Object,
     *     courier_id: number,
     *     item_label: string,
     *     item_text: string,
     *     item_list_key: string,
     *     item_id_key: string,
     *     item_allow_empty: boolean,
     *     callback: function,
     *     create: boolean,
     *     settings: Object,
     * }}
     */
    static defaultProps = {
        screen: null,
        couriers: {},
        courier_id: 0,
        item_label: '',
        item_text: '',
        item_list_key: '',
        item_id_key: '',
        item_allow_empty: false,
        callback: () => {},
        create: true,
        settings: {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     data: Object,
     *     credentials: Object,
     *     items: Object,
     * }}
     */
    state = {
        loading: false,
        data: {},
        credentials: {},
        items: {},
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { couriers, courier_id, settings } = this.props;

        let data = this.getDefaultFields(couriers[courier_id].fields);

        if (_.has(data, 'iban') && _.isEmpty(data.iban)) {
            // Nastavujeme iban
            data = { ...data, iban: settings.iban };
        }

        this.setState({ data });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [field]: value } });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
        );
    }

    /**
     * Skontrolujeme credentials.
     */
    checkCredentials() {
        const { screen, couriers, courier_id, item_id_key, item_allow_empty } = this.props;
        const { data, credentials, items } = this.state;

        if (!_.isEmpty(items)) {
            // Mame zadane polozky, pokracujeme
            this.save({ ...credentials, [item_id_key]: data.item_id });
            return;
        }

        if (_.has(data, 'iban') && !isValidIban(data.iban) && !isValidAccount(data.iban)) {
            // Neplatny iban
            screen.showSnackbar('error', __('Neplatný IBAN alebo číslo účtu'));
            return;
        }

        this.setState({ loading: true });

        const courier_data = data;

        request(
            '/user-couriers/credentials',
            courier_data,
            'POST',
            { courier: couriers[courier_id].name }
        ).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });

                if (_.has(data, 'type')) {
                    switch (data.type) {
                        case 'invalid senders':
                            // Dopravca bez odosielatelov
                            screen.showSnackbar('error', __('U dopravcu nemáte zaevidovaného odosielateľa'));
                            return;

                        default:
                            break;
                    }
                }

                screen.showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                return;
            }

            const { item_list_key, item_id_key } = this.props;

            if (_.isEmpty(item_list_key)) {
                // Nerozlisujeme item id, zavolame rovno save
                this.save(data.credentials);
                return;
            }

            const items = data.credentials[item_list_key];
            const item_id = data.credentials[item_id_key];

            if (toNumber(item_id)) {
                // Mame zadanu polozku, ideme dalej
                this.setState({ loading: false, credentials: data.credentials });
                this.save(data.credentials);
                return;
            }

            this.setState({
                loading: false,
                credentials: data.credentials,
                items,
                // Ako aktivnu nastavime prvu polozku
                data: { ...courier_data, item_id: !item_allow_empty ? _.keys(items)[0] : 0 },
            });
        });
    }

    /**
     * Ulozenie.
     *
     * @param {Object} credentials
     */
    save(credentials) {
        const { courier_id, screen, callback, couriers, create } = this.props;

        if (!create) {
            // Nejde o vytvorenie, rovno volame callback
            callback(credentials);
            return;
        }

        this.setState({ loading: true });

        request(
            '/user-couriers/create',
            { courier_id, credentials },
            'POST',
            { courier: couriers[courier_id].name }
        ).then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa vytvorit
                screen.showSnackbar('error', __('Nepodarilo sa prihlásiť'));
                return;
            }

            // Zavolame callback
            callback();
        });
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} fields
     *
     * @return {JSX.Element[]}
     */
    renderFields(fields) {
        const { data } = this.state;
        const { item_label, item_allow_empty } = this.props;

        return _.map(fields, (field, name) => {
            switch (name) {
                case 'item_id':
                    // Polozka
                    return <Select
                        key={name}
                        label={item_label}
                        options={field}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={item_allow_empty}
                    />;

                case 'dpd_portal':
                    // DPD portal
                    return <Select
                        key={name}
                        label={__('Portál pre prihlásenie')}
                        options={{ 'old': __('dpdportal.sk'), 'new': __('sphn.dpd.sk') }}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={false}
                    />;

                case 'company_id':
                    // Company id
                    return <Input
                        key={name}
                        label={__('Company id')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'id':
                    // ID
                    return <Input
                        key={name}
                        label={__('ID klienta')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'email':
                    // Email
                    return <Input
                        type="email"
                        key={name}
                        label={__('Prihlasovací email')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'username':
                    // Prihlasovacie meno
                    return <Input
                        key={name}
                        label={__('Prihlasovacie meno')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={__('Prihlasovacie heslo')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'iban':
                    // Iban
                    return <Input
                        key={name}
                        label={__('IBAN / Číslo účtu pre úhrady dobierok')}
                        value={data[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { loading, data, items } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su vyplnene
            return null;
        }

        const { couriers, courier_id, item_text } = this.props;
        let fields = couriers[courier_id].fields;

        if (!_.isEmpty(items)) {
            // Mame zadany zoznam poloziek zobrazime len ten
            fields = { item_id: items };
        }

        return <div className="courier-lightbox">
            {!_.isEmpty(items) ? <Message type="warning">{item_text}</Message> : null}
            {this.renderFields(fields)}
            <Button
                onClick={this.checkCredentials.bind(this)}
                className="courier-lightbox__button"
                loading={loading}
            >{__('Uložiť')}</Button>
        </div>;
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(CourierLightbox)));
