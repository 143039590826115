import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import { Screen, Table, TableButton, Button, Input, Message } from '../components';
import { __, isEmptyString, isValidEmail, request, toNumber } from '../functions';
import { fetchEmployees, cleanEmployees, deleteEmployee, deleteEmployees, createEmployee, setUser } from '../actions';
import '../assets/styles/employees.css';

/**
 * Zoznam zamestnancov.
 */
class EmployeesScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Zamestnanci');

    /**
     * Default state.
     *
     * @type {{
     *     loading_create: boolean,
     *     loading_table_button: ?string,
     *     lightbox: {},
     * }}
     */
    state = {
        loading_create: false,
        loading_table_button: null,
        lightbox: {
            create: false,
            delete: false,
            multidelete: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { employees } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, employees.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { employees } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, employees.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     */
    fetch(page = 1, per_page = 0, filtered = {}) {
        const { fetchEmployees } = this.props;

        // Nacitame zoznam
        fetchEmployees(this, { page, per_page, filtered });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanEmployees } = this.props;

        // Vycistime zoznam
        cleanEmployees();
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Zmazanie zamestnanca.
     */
    async delete() {
        const { deleteEmployee, employees } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteEmployee(this, lightbox.delete.id);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, employees.filtered);
    }

    /**
     * Zmazanie zamestnancov cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteEmployees, employees } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme klientov
        await deleteEmployees(this, ids, employees.filtered);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, employees.filtered);
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { loading_table_button } = this.state;

        return _.map(items, ({ id, name, _matchingData }) => {
            return [
                name,
                _matchingData.Users.email,
                [
                    <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => this.confirmDelete(id, button_id)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                default:
                    return result;
            }
        }, {});
    }

    /**
     * Event po zmene zamestnanca.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeCreateEmployee(type, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, [type]: value } } });
    }

    /**
     * Skontrolujeme email zamestnanca.
     */
    checkEmployeeEmail() {
        const { lightbox } = this.state;

        if (!isValidEmail(lightbox.create.email)) {
            // Email nie je validny
            this.showSnackbar('error', __('Neplatný email'));
            return;
        }

        this.setState({ loading_create: true });

        // Vygenerujeme heslo
        const password = Math.random().toString(36).substr(2, 8);

        // Zavolame registraciu
        request('/users/register?employee=1', {
            email: lightbox.create.email,
            password, password_repeat: password,
        }, 'POST').then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Registracia sa nepodarila, nasetujeme user id
                if (data.type !== 'employee') {
                    // Ucet nie je zamestnanec
                    this.setState({ loading_create: false });
                    this.showSnackbar('error', __('Tento účet nemôže byť použitý ako účet zamestnanca'));
                    return;
                }

                this.setState({ loading_create: false, lightbox: { ...lightbox, create: {
                    ...lightbox.create,
                    user_id: data.id,
                } } });
                return;
            }

            // Email neexistoval, vytvorili sme usera, zobrazime heslo
            this.setState({ loading_create: false, lightbox: { ...lightbox, create: {
                ...lightbox.create,
                user_id: data.id,
                password,
            } } });
        });
    }

    /**
     * Event po kliku na heslo.
     *
     * @param {string} password
     */
    onClickPassword(password) {
        // Nastavime heslo do clipboardu
        navigator.clipboard.writeText(password);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Ulozenie zamestnanca.
     */
    async save() {
        const { lightbox } = this.state;

        if (isEmptyString(lightbox.create.name)) {
            // Meno nie je vyplnene
            this.showSnackbar('error', __('Musíte vyplniť meno'));
            return;
        }

        this.setState({ loading_create: true });

        const { employees, createEmployee } = this.props;

        // Vytvorime zamestnanca
        await createEmployee(this, { user_id: lightbox.create.user_id, name: lightbox.create.name });

        // Zatvorime lightbox
        this.closeLightbox('create');

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, employees.filtered);
    }

    /**
     * Rendrujeme vytvorenie zamestnanca.
     *
     * @return {JSX.Element}
     */
    renderCreate() {
        const { loading_create, lightbox } = this.state;

        if (_.isEmpty(lightbox.create)) {
            // Nezobrazujeme lightbox
            return null;
        }

        // Mame usera?
        const has_user = toNumber(lightbox.create.user_id) > 0;
        const has_password = !_.isEmpty(lightbox.create.password);

        return (
            <div className="lightbox-create">
                {has_password ? <Message type="warning">{__('Bol vytvorený nový účet. Prosím uložte si jeho heslo, po prihlásení ho bude možné zmeniť.')}</Message> : null}
                {has_user && !has_password ? <Message type="success">{__('Účet už existuje.')}</Message> : null}
                <Input
                    label={__('Email')}
                    value={lightbox.create.email}
                    onChange={value => this.onChangeCreateEmployee('email', value)}
                    disabled={has_user}
                    type="email"
                />
                {has_password ? <div className="lightbox-create__password">
                    <Input
                        label={__('Heslo')}
                        value={lightbox.create.password}
                        disabled
                    />
                    <Tooltip title={__('Skopírovať do schránky')}>
                        <IconButton
                            onClick={() => this.onClickPassword(lightbox.create.password)}
                            className="lightbox-create__password__button"
                        >
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                </div> : null}
                {has_user ? <Input
                    label={__('Meno')}
                    value={lightbox.create.name}
                    onChange={value => this.onChangeCreateEmployee('name', value)}
                /> : null}
                {!has_user ? <Button
                    onClick={() => this.checkEmployeeEmail()}
                    loading={loading_create}
                    color="green"
                >{__('Overiť')}</Button> : <Button
                    loading={loading_create}
                    onClick={() => this.save()}
                >{__('Uložiť')}</Button>}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, employees } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(employees)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <div className="employees">
                <Table
                    title={<div className="employees__title">
                        <Typography variant="h5">{__('Zamestnanci')}</Typography>
                        <Button
                            onClick={() => this.showLightbox('create', { user_id: 0, email: '', password: '', name: '' })}
                            color="green"
                        >{__('Nový zamestnanec')}</Button>
                    </div>}
                    multiselect={[{
                        icon: <DeleteIcon />,
                        text: __('Zmazať'),
                        confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                            ids,
                            clearCallback,
                            loadingCallback,
                        }),
                    }]}
                    filter={this.formatFilter(employees.filter)}
                    filtered={employees.filtered}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Meno'),
                        __('Email'),
                        '',
                    ]}
                    data={this.formatData(employees.items)}
                    raw_data={employees.items}
                    count={employees.total}
                    page={employees.page}
                    per_pages={user.settings.per_pages}
                    per_page={employees.per_page}
                    empty_text={__('Zatiaľ nemáte žiadnych zamestnancov')}
                />
                {this.renderLightbox(
                    'create',
                    __('Nový zamestnanec'),
                    this.renderCreate(),
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tohto zamestnanca?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označených zamestnancov?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                        lightbox.multidelete.ids,
                        lightbox.multidelete.clearCallback,
                        lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, employees }) => ({ user, employees });

export default withCookies(withRouter(connect(stateToProps, {
    fetchEmployees,
    cleanEmployees,
    deleteEmployee,
    deleteEmployees,
    createEmployee,
    setUser,
})(EmployeesScreen)));