import { CLEAN_EXPORT, FETCH_EXPORT } from '../actions/types';

const EXPORT_DEFAULT_STATE = {};

/**
 * Reducer pre export.
 */
const ExportReducer = (state = EXPORT_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_EXPORT:
            // Nacitanie exportu
            return payload;

        case CLEAN_EXPORT:
            // Vycistenie exportu
            return EXPORT_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ExportReducer;