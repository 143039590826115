import { FETCH_COURIERS, CHANGE_COURIERS_AUTO_FIELD, CHANGE_COURIERS_SETTINGS } from '../actions/types';

const COURIERS_DEFAULT_STATE = [];

/**
 * Reducer pre kurierov.
 */
const CouriersReducer = (state = COURIERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_COURIERS:
            // Nacitanie kurierov
            return payload;

        case CHANGE_COURIERS_AUTO_FIELD:
            // Zmena auto fieldu
            return { ...state, [payload.field]: payload.value ? 'on' : 'off' };

        case CHANGE_COURIERS_SETTINGS:
            // Zmena nastaveni kuriera
            return { ...state, settings: payload };

        default:
            return state;
    }
};

export default CouriersReducer;