import React, { Component } from 'react';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MainSelect from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { __ } from '../functions';

/**
 * Select komponenta.
 */
class Select extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     onChange: function(),
     *     options: {},
     *     label: string,
     *     value: string,
     *     allow_empty: boolean,
     *     placeholder: null|string,
     *     variant: string,
     *     disabled: boolean,
     *     strict: boolean,
     *     className: string,
     * }}
     */
    static defaultProps = {
        label: '',
        value: '',
        options: {},
        onChange: () => {},
        allow_empty: true,
        placeholder: null,
        variant: 'outlined',
        disabled: false,
        strict: true,
        className: '',
    };

    /**
     * Default state.
     *
     * @type {{
     *     value: string,
     * }}
     */
    state = {
        value: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        this.setState({ value: this.formatValue(this.props.value) });
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const value = this.formatValue(this.props.value);

        if (this.state.value !== value) {
            // Nesedi hodnota v state a props, aktualizujeme
            this.setState({ value });
        }
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} value
     */
    onChange(value) {
        this.setState({ value });

        const { onChange } = this.props;

        // Zavolame callback
        onChange(value);
    }

    /**
     * Naformatujeme value.
     *
     * @param {string|int} value
     *
     * @return {string}
     */
    formatValue(value) {
        const { strict } = this.props;

        if (strict && (value === 0 || value === '0')) {
            // Nula je prazdna hodnota
            return '';
        }

        return value;
    }

    /**
     * Naformatujeme option.
     *
     * @param {string} option
     *
     * @return {JSX.Element|string}
     */
    formatOption(option) {
        if (option.indexOf('https://') !== -1) {
            // Zobrazujeme obrazok
            return <img src={option} height={30} alt={option} />;
        }

        switch (option) {
            case 'sk':
                return __('Slovensko');

            case 'cz':
                return __('Česká republika');

            default:
                return option;
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { label, options, allow_empty, placeholder, variant, disabled, className } = this.props;
        const { value } = this.state;

        return (
            <div className={`select ${disabled ? 'disabled' : ''} ${className}`}>
                {label !== '' ? <Typography variant="body2" className="input__label">{label}</Typography> : null}
                <FormControl variant={variant}>
                    <MainSelect
                        className="select__container"
                        value={value}
                        onChange={event => this.onChange(event.target.value)}
                        displayEmpty={placeholder !== null}
                        disabled={disabled}
                    >
                        {allow_empty ? <MenuItem value="">
                            <em>{placeholder !== null ? placeholder : __('Žiadne')}</em>
                        </MenuItem> : null}
                        {_.map(_.keys(options), key => <MenuItem key={key} value={key}>{this.formatOption(options[key])}</MenuItem>)}
                    </MainSelect>
                </FormControl>
            </div>
        );
    }
}

export { Select };