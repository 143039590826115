import _ from 'lodash';

/**
 * Zformatujeme datum.
 *
 * @param {string} source
 * @param {string} format
 *
 * @return {string}
 */
export const formatDate = (source, format = 'dd.mm.yyyy') => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (_.isString(source) && source.length === 19) {
        // Datum s HIS
        hours = parseInt(source.substring(11, 13));
        minutes = parseInt(source.substring(14, 16));
        seconds = parseInt(source.substring(17, 19));
        source = source.substring(0, 10);
    }

    const date = new Date(source);

    let days = date.getDate();
    let month = date.getMonth() + 1;
    const years = date.getFullYear();

    days = days < 10 ? `0${days}` : days;
    month = month < 10 ? `0${month}` : month;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return format
        .replace('dd', days)
        .replace('mm', month)
        .replace('yyyy', years)
        .replace('hh', hours)
        .replace('ii', minutes)
        .replace('ss', seconds);
};