import _ from 'lodash';
import {
    FETCH_NOTIFICATIONS,
    CLEAN_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATIONS,
    READ_NOTIFICATION,
    READ_NOTIFICATIONS,
} from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie notifikacii.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchNotifications = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/notifications/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_NOTIFICATIONS, payload: data });
    });
};

/**
 * Zmazanie notifikacie.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteNotification = (screen, id) => {
    return dispatch => request(`/notifications/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Notifikaciu sa nepodarilo zmazat
            screen.showSnackbar('error', __('Nepodarilo sa zmazať upozornenie'));
            return;
        }

        screen.showSnackbar('success', __('Upozornenie bolo zmazané'));

        dispatch({ type: DELETE_NOTIFICATION });
    });
};

/**
 * Precitanie notifikacie.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const readNotification = (screen, id) => {
    return dispatch => request(`/notifications/read/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Notifikaciu sa nepodarilo precitat
            screen.showSnackbar('error', __('Nepodarilo sa prečítať upozornenie'));
            return;
        }

        screen.showSnackbar('success', __('Upozornenie bolo prečítané'));

        dispatch({ type: READ_NOTIFICATION, payload: data });
    });
};

/**
 * Zmazanie notifikacii cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteNotifications = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/notifications/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Notifikacie sa nepodarilo zmazat
            screen.showSnackbar('error', __('Upozornenia sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Upozornenia boli zmazané'));

        dispatch({ type: DELETE_NOTIFICATIONS });
    });
};

/**
 * Precitanie notifikacii cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 */
export const readNotifications = (screen, ids) => {
    return dispatch => request(`/notifications/multiRead/${ids}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Notifikacie sa nepodarilo precitat
            screen.showSnackbar('error', __('Upozornenia sa nepodarilo prečítať'));
            return;
        }

        screen.showSnackbar('success', __('Upozornenia boli prečítané'));

        dispatch({ type: READ_NOTIFICATIONS, payload: data });
    });
};

/**
 * Vycistenie notifikacii.
 */
export const cleanNotifications = () => ({ type: CLEAN_NOTIFICATIONS });