export const FETCH_DASHBOARD = 'fetch_dashboard';
export const SET_USER = 'set_user';
export const CLEAN_USER = 'clean_user';
export const FETCH_USER_NOTIFICATIONS = 'fetch_user_notifications';
export const CHANGE_USER_SETTING = 'change_user_setting';
export const FETCH_ORDERS = 'fetch_orders';
export const CLEAN_ORDERS = 'clean_orders';
export const DELETE_ORDER = 'delete_order';
export const DELETE_ORDERS = 'delete_orders';
export const CREATE_INVOICES = 'create_invoices';
export const CHANGE_ORDER_STATE = 'change_order_state';
export const CHANGE_ORDER_INTERNAL_COMMENT = 'change_order_internal_comment';
export const CHANGE_ORDERS_STATE = 'change_orders_state';
export const EXPORT_ORDER = 'export_order';
export const EXPORT_ORDERS = 'export_orders';
export const SYNC_ORDER = 'sync_order';
export const SYNC_ORDERS = 'sync_orders';
export const FETCH_CLIENTS = 'fetch_clients';
export const CLEAN_CLIENTS = 'clean_clients';
export const DELETE_CLIENT = 'delete_client';
export const DELETE_CLIENTS = 'delete_clients';
export const FETCH_INVOICES = 'fetch_invoices';
export const CLEAN_INVOICES = 'clean_invoices';
export const DELETE_INVOICE = 'delete_invoice';
export const DELETE_INVOICES = 'delete_invoices';
export const CREATE_INVOICE = 'create_invoice';
export const FETCH_ACCOUNTING = 'fetch_accounting';
export const FETCH_ACCOUNTING_DATA = 'fetch_accounting_data';
export const CHANGE_ACCOUNTING_AUTO_FIELD = 'change_accounting_auto_field';
export const CHANGE_ACCOUNTING_SETTINGS = 'change_accounting_settings';
export const FETCH_INVOICE_PAYMENTS = 'fetch_invoice_payments';
export const CLEAN_INVOICE_PAYMENTS = 'clean_invoice_payments';
export const DELETE_INVOICE_PAYMENT = 'delete_invoice_payment';
export const DELETE_INVOICE_PAYMENTS = 'delete_invoice_payments';
export const FETCH_ESHOP = 'fetch_eshop';
export const FETCH_ESHOP_DATA = 'fetch_eshop_data';
export const CHANGE_ESHOP_AUTO_FIELD = 'change_eshop_auto_field';
export const FETCH_HEUREKA = 'fetch_heureka';
export const FETCH_HEUREKA_DATA = 'fetch_heureka_data';
export const CHANGE_HEUREKA_SETTINGS = 'change_heureka_settings';
export const CHANGE_ESHOP_SETTINGS = 'change_eshop_settings';
export const PAID_ORDER = 'paid_order';
export const PAID_ORDERS = 'paid_orders';
export const FETCH_COURIERS = 'fetch_couriers';
export const CHANGE_COURIERS_AUTO_FIELD = 'change_couriers_auto_field';
export const CHANGE_COURIERS_SETTINGS = 'change_couriers_settings';
export const FETCH_COURIER = 'fetch_courier';
export const CHANGE_COURIER_SETTINGS = 'change_courier_settings';
export const SEND_ORDER = 'send_order';
export const SEND_ORDERS = 'send_orders';
export const FETCH_LABELS = 'fetch_labels';
export const CLEAN_LABELS = 'clean_labels';
export const DELETE_LABEL = 'delete_label';
export const DELETE_LABELS = 'delete_labels';
export const DOWNLOAD_LABEL = 'download_label';
export const FETCH_NOTIFICATIONS = 'fetch_notifications';
export const CLEAN_NOTIFICATIONS = 'clean_notifications';
export const DELETE_NOTIFICATION = 'delete_notification';
export const DELETE_NOTIFICATIONS = 'delete_notifications';
export const READ_NOTIFICATION = 'read_notification';
export const READ_NOTIFICATIONS = 'read_notifications';
export const FETCH_ADMIN = 'fetch_admin';
export const FETCH_ADMIN_PROCESS = 'fetch_admin_process';
export const FETCH_ADMIN_ERROR = 'fetch_admin_error';
export const CLEAN_ORDER = 'clean_order';
export const FETCH_STATES = 'fetch_states';
export const SAVE_STATES = 'save_states';
export const FETCH_SETTINGS = 'fetch_settings';
export const SAVE_SETTINGS = 'save_settings';
export const FETCH_FEEDS = 'fetch_feeds';
export const CREATE_FEED = 'create_feed';
export const FETCH_SUBSCRIPTION = 'fetch_subscription';
export const CHANGE_SUBSCRIPTION_SETTINGS = 'change_subscription_settings';
export const FETCH_ADDONS = 'fetch_addons';
export const CREATE_ADDON = 'create_addon';
export const DELETE_ADDON = 'delete_addon';
export const ADDON_ORDERS = 'addon_orders';
export const ADDON_INVOICE_PAYMENTS = 'addon_invoice_payments';
export const FETCH_PACKAGES = 'fetch_packages';
export const CHANGE_CLIENT_NOTE = 'change_client_note';
export const FETCH_STORAGE = 'fetch_storage';
export const FETCH_STORAGE_DATA = 'fetch_storage_data';
export const CHANGE_STORAGE_AUTO_FIELD = 'change_storage_auto_field';
export const CHANGE_STORAGE_SETTINGS = 'change_storage_settings';
export const FETCH_BANKS = 'fetch_banks';
export const CREATE_BANK = 'create_bank';
export const SAVE_BANK_SETTINGS = 'save_bank_settings';
export const FETCH_GATEWAYS = 'fetch_gateways';
export const FETCH_GATEWAY = 'fetch_gateway';
export const CHANGE_GATEWAY_SETTINGS = 'change_gateway_settings';
export const FETCH_CREATE_ORDER = 'fetch_create_order';
export const FETCH_EMPLOYEES = 'fetch_employees';
export const CREATE_EMPLOYEE = 'create_employee';
export const DELETE_EMPLOYEE = 'delete_employee';
export const DELETE_EMPLOYEES = 'delete_employees';
export const CLEAN_EMPLOYEES = 'clean_employees';
export const FETCH_EXPORTS = 'fetch_exports';
export const CLEAN_EXPORTS = 'clean_exports';
export const DELETE_EXPORT = 'delete_export';
export const DELETE_EXPORTS = 'delete_exports';
export const FETCH_EXPORT = 'fetch_export';
export const CLEAN_EXPORT = 'clean_export';
export const FETCH_TEMPLATES = 'fetch_templates';
export const CLEAN_TEMPLATES = 'clean_templates';
export const DELETE_TEMPLATE = 'delete_template';
export const DELETE_TEMPLATES = 'delete_templates';
export const FETCH_TEMPLATE = 'fetch_template';
export const CLEAN_TEMPLATE = 'clean_template';
export const FETCH_PRODUCTS = 'fetch_products';
export const CLEAN_PRODUCTS = 'clean_products';
export const CHANGE_PRODUCT_STOCK = 'change_product_stock';
export const CHANGE_PRODUCT_MALL = 'change_product_mall';
export const FETCH_SYNC_PRODUCTS = 'fetch_sync_products';
export const CLEAN_SYNC_PRODUCTS = 'clean_sync_products';
