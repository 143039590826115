import React, { Component } from 'react';
import _ from 'lodash';
import { Select, Button, Input } from '../components';
import { __, formatDate } from '../functions';
import '../assets/styles/filter.css';

/**
 * Filter komponenta.
 */
class Filter extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     data: Object,
     *     filtered: Object,
     *     onChange: function(),
     * }}
     */
    static defaultProps = {
        data: {},
        filtered: {},
        onChange: () => {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     values: {},
     * }}
     */
    state = {
        values: {},
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { data, filtered } = this.props;

        // Naformatujeme data ako key->value
        const values = _.reduce(data, (result, { value }, key) => {
            if (_.has(filtered, key)) {
                // Dany field filtrujeme
                value = filtered[key];
            }

            return { ...result, [key]: value };
        }, {});

        this.setState({ values });
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { values } = this.state;

        this.setState({ values: { ...values, [type]: value } })
    }

    /**
     * Vratime zoznam filtrovanich fieldov.
     *
     * @return {Object}
     */
    getFiltered() {
        const { values } = this.state;
        const { data } = this.props;

        return _.reduce(values, (result, value, key) => {
            if (value !== data[key].value) {
                // Hodnota sa nerovna s default hodnotou
                return { ...result, ...{ [key]: value } };
            }

            return result;
        }, {});
    }

    /**
     * Ulozenie filtra.
     */
    save() {
        const { onChange } = this.props;

        // Zavolame callback
        onChange(this.getFiltered());
    }

    /**
     * Reset filtra na povodne hodnoty.
     */
    reset() {
        const { onChange } = this.props;

        // Zavolame callback
        onChange({});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { data } = this.props;
        const { values } = this.state;

        return (
            <div className="filter">
                <div className="filter__values">
                    {_.map(data, (item, key) => {
                        const { type, name, options } = item;

                        switch (type) {
                            case 'select':
                                let strict = true;

                                if (_.has(item, 'strict') && !item.strict) {
                                    // Vypnuty striktny rezim
                                    strict = false;
                                }

                                return <Select
                                    key={key}
                                    label={name}
                                    options={options}
                                    value={values[key]}
                                    onChange={value => this.onChange(key, value)}
                                    strict={strict}
                                />;

                            case 'input':
                                return <Input
                                    key={key}
                                    label={name}
                                    value={values[key]}
                                    onChange={value => this.onChange(key, value)}
                                />;

                            case 'date':
                                return <Input
                                    type="date"
                                    key={key}
                                    label={name}
                                    value={values[key]}
                                    onChange={value => this.onChange(key, formatDate(value, 'yyyy-mm-dd'))}
                                />;

                            default:
                                return null;
                        }
                    })}
                </div>
                <div className="filter__buttons">
                    <Button onClick={this.save.bind(this)}>{__('Filtrovať')}</Button>
                    {!_.isEmpty(this.getFiltered()) ? <Button
                        onClick={this.reset.bind(this)}
                        color="red"
                    >{__('Resetovať')}</Button> : null}
                </div>
            </div>
        );
    }
}

export { Filter };
