import {
    FETCH_TEMPLATES,
    CLEAN_TEMPLATES,
    DELETE_TEMPLATE,
    DELETE_TEMPLATES,
} from '../actions/types';

const TEMPLATES_DEFAULT_STATE = {};

/**
 * Reducer pre sablony.
 */
const TemplatesReducer = (state = TEMPLATES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_TEMPLATES:
            // Nacitanie sablon
            return payload;

        case CLEAN_TEMPLATES:
        case DELETE_TEMPLATE:
        case DELETE_TEMPLATES:
            // Vycistenie sablon
            // Zmazanie sablony
            // Zmazanie sablon
            return TEMPLATES_DEFAULT_STATE;

        default:
            return state;
    }
};

export default TemplatesReducer;