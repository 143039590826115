import React from 'react';
import { connect } from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import onScan from 'onscan.js';
import base64url from 'base64url';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ValidIcon from '@material-ui/icons/CheckCircle';
import { Screen, Select, Input, Button, Message } from '../components';
import { __, isEmptyString, isValidZip, round, toNumber } from '../functions';
import { fetchPackages, sendOrders, setUser } from '../actions';
import { COURIERS, PACKS } from '../config';
import '../assets/styles/packages.css';

/**
 * Rozpis zasielok.
 */
class PackagesScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Rozpis zásielok');

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     settings: Object,
     *     responses: Object,
     *     products: Object,
     *     controlled: Array,
     *     show_clients: Array,
     *     show_products: Array,
     * }}
     */
    state = {
        loading: false,
        settings: {},
        responses: {},
        products: {},
        controlled: [],
        show_clients: [],
        show_products: [],
    };

    /**
     * Id objednavky ktora sa scanuje.
     *
     * @type {number}
     */
    scan_order_id = 0;

    /**
     * Audio elem.
     *
     * @type {Document|null}
     */
    audio = null;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchPackages, match } = this.props;

            let filtered = {};

            if (_.has(match.params, 'filtered') && match.params.filtered !== '-') {
                // Je zadany filter
                filtered = JSON.parse(base64url.decode(match.params.filtered));
            }

            await fetchPackages(this, match.params.ids, filtered);

            const { packages } = this.props;

            // Vytiahneme nastavenia
            const settings = _.reduce(
                packages.items,
                (result, order, id) => ({ ...result, [id]: {
                    ...order.settings,
                    delivery_pickup_point_id: order.settings.client_delivery_pickup_point_id,
                    cod: round(order.settings.cod).toString().replace('.', ','),
                    boxes: _.map(order.settings.boxes, box => ({
                        ...box,
                        weight: round(box.weight).toString().replace('.', ','),
                    })),
                } }),
                {}
            );

            this.setState({ settings });

            onScan.attachTo(document, {
                minLength: 5,
                onScan: (ean, q) => this.onScan(ean),
            });

            window.onScan = onScan;

            // Nasetujeme audio
            this.audio = document.getElementById('audio');
        }

        return true;
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {number} id
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(id, type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [id]: { ...settings[id], [type]: value } } });
    }

    /**
     * Event po zmene baliku v nastaveniach.
     *
     * @param {number} key
     * @param {number} id
     * @param {string} type
     * @param {string} value
     */
    onChangeSettingBox(key, id, type, value) {
        const { settings } = this.state;

        const boxes = _.map(settings[id].boxes, (box, k) => {
            if (k === key) {
                // Editujeme
                box = { ...box, [type]: value };
            }

            return box;
        });

        this.onChangeSetting(id, 'boxes', boxes);
    }

    /**
     * Event po oskenovani EAN kodu.
     *
     * @param {string} ean
     */
    onScan(ean) {
        let { products, controlled } = this.state;

        if (this.audio !== null) {
            this.audio.currentTime = 0;
            this.audio.play();
        }

        if (_.has(products, ean)) {
            // Nasli sme produkt
            products = { ...products, [ean]: { ...products[ean], count: products[ean].count - 1 } };

            // Vytiahneme celkovy pocet neoskenovanych produktov
            const total = _.reduce(products, (result, product) => result + product.count, 0);

            if (total === 0) {
                // Nie su ziadne produkty na oskenovanie, objednavka je skontrolovana
                controlled = [ ...controlled, ...[this.scan_order_id] ];
            }

            this.setState({ products, controlled });
        } else {
            this.showSnackbar('error', __('Neplatný EAN kód'));
        }
    }

    /**
     * Pridame novy balik.
     *
     * @param {number} id
     */
    addSettingBox(id) {
        const { settings } = this.state;

        const boxes = [ ...settings[id].boxes, { pack: _.keys(PACKS)[0], weight: 0, reference: '' } ];

        this.onChangeSetting(id, 'boxes', boxes);
    }

    /**
     * Zmazeme balik.
     *
     * @param {number} key
     * @param {number} id
     */
    deleteSettingBox(key, id) {
        const { settings } = this.state;

        const boxes = _.remove(settings[id].boxes, (item, k) => k !== key);

        this.onChangeSetting(id, 'boxes', boxes);
    }

    /**
     * Zobrazime client data.
     *
     * @param {number} id
     */
    showClient(id) {
        let { show_clients, show_products } = this.state;

        // Zmazeme pripadne zobrazenie produktov
        show_products = _.remove(show_products, item_id => item_id !== id);

        this.setState({ show_products, show_clients: [ ...show_clients, id ] });
    }

    /**
     * Schovame client data.
     *
     * @param {number} id
     */
    hideClient(id) {
        let { show_clients } = this.state;

        show_clients = _.remove(show_clients, item_id => item_id !== id);

        this.setState({ show_clients });
    }

    /**
     * Zobrazime produkty.
     *
     * @param {number} id
     * @param {Array} products
     */
    showProducts(id, products) {
        let { show_clients } = this.state;

        // Nasetujeme id aktivnej objednavky
        this.scan_order_id = id;

        // Zmazeme pripadne zobrazenie klienta
        show_clients = _.remove(show_clients, item_id => item_id !== id);

        // Nasetujeme zoznam produktov
        const products_list = _.reduce(products, (result, product) => {
            if (!_.isEmpty(product.ean)) {
                // Je zadany ean
                result = { ...result, [product.ean]: { name: product.name, count: toNumber(product.quantity) } };
            }

            return result;
        }, {});

        this.setState({ show_clients, show_products: [ id ], products: products_list });
    }

    /**
     * Odoslanie.
     */
    async send() {
        const { sendOrders, match } = this.props;
        const { settings } = this.state;

        this.setState({ loading: true });

        let errors = [];

        _.each(settings, ({ client_name, client_address, client_city, client_zip, client_country, delivery_pickup_point_id, client_delivery_pickup_point_id }, order_id) => {
            let order_errors = [];

            if (isEmptyString(client_name)) {
                // Nie je vyplneny nazov prijemcu
                order_errors = [ ...order_errors, ...['name'] ];
            }

            if (isEmptyString(client_address)) {
                // Nie je vyplnena adresa prijemcu
                order_errors = [ ...order_errors, ...['address'] ];
            }

            if (isEmptyString(client_city)) {
                // Nie je vyplnene mesto prijemcu
                order_errors = [ ...order_errors, ...['city'] ];
            }

            if (isEmptyString(client_country)) {
                // Nie je vyplnena krajina prijemcu
                order_errors = [ ...order_errors, ...['country'] ];
            }

            if (!isValidZip(client_zip)) {
                // Nie je vyplnene PSC prijemcu
                order_errors = [ ...order_errors, ...['zip'] ];
            }

            if (delivery_pickup_point_id !== 0 && client_delivery_pickup_point_id === 0) {
                // Nie je vyplnene odberne miesto
                order_errors = [ ...order_errors, ...['pickup_point'] ];
            }

            if (!_.isEmpty(order_errors)) {
                // Objednavka ma errory
                errors = { ...errors, [order_id]: order_errors };
            }
        });

        if (!_.isEmpty(errors)) {
            // Mame errory
            this.showSnackbar('error', __('Niektoré zásielky obsahujú neplatné údaje.'));
            this.setState({
                loading: false,
                errors,
                show_products: [],
                show_clients: _.keys(errors),
            });
            return;
        }

        await sendOrders(
            this,
            match.params.ids !== 'all' ? match.params.ids : _.keys(settings),
            match.params.date,
            match.params.position,
            settings
        );

        this.setState({ loading: false });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { packages, match } = this.props;
        const { loading, settings, responses, show_clients, show_products, products, controlled, errors } = this.state;

        if (_.isEmpty(packages) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Vytiahneme zoznam kurierov
        const couriers = _.reduce(
            packages.user_couriers,
            (result, { id, courier_name, settings }) => ({ ...result, [toNumber(id)]: !_.isEmpty(settings.name)
                    ? settings.name
                    : COURIERS[courier_name] }),
            {}
        );

        return super.render(
            <Paper className="packages" elevation={0}>
                <Toolbar className="packages__header">
                    <div className="packages__header__left">
                        <Typography className="packages__header__title" variant="h5">{__('Rozpis zásielok')}</Typography>
                    </div>
                    <div className="packages__header__right">
                        <Button
                            onClick={() => this.redirect(`/orders/${_.has(match.params, 'keys') && match.params.keys !== '-' ? match.params.keys : ''}`)}
                            color=""
                        >{__('Naspäť na zoznam objednávok')}</Button>
                    </div>
                </Toolbar>
                <div className="packages__content">
                    {_.map(packages.items, (item, id) => {
                        const response = _.has(responses, id) ? responses[id] : null;
                        const send = (item.package.number !== '' || (response !== null && !_.isEmpty(response.number) && response.number !== 'waiting_import'))
                            && toNumber(settings[id].resend) === 0;
                        const has_errors = _.has(errors, id);
                        const show_client = _.includes(show_clients, id);
                        const order_controlled = _.includes(controlled, id);
                        const show_scan = _.includes(show_products, id) && !order_controlled;
                        const courier_id = toNumber(settings[id].courier_id);
                        const courier_name = _.has(couriers, courier_id) ? couriers[courier_id] : '';
                        const show_pickup_point = show_client
                            && settings[id].delivery_pickup_point_id !== 0
                            && (
                                (
                                    courier_name === 'Zásielkovňa'
                                    && item.data.client.delivery_pickup_point_type === 'zasielkovna'
                                )
                                || (
                                    courier_name === 'GLS'
                                    && item.data.client.delivery_pickup_point_type === 'gls'
                                )
                            );

                        let pickup_point = null;
                        let pickup_points = packages.pickup_points;

                        if (show_pickup_point) {
                            // Najdeme nazov odberneho miesta
                            if (
                                item.data.client.delivery_pickup_point_type !== 'zasielkovna'
                                && _.has(packages, `pickup_points_${item.data.client.delivery_pickup_point_type}`)
                            ) {
                                pickup_points = packages[`pickup_points_${item.data.client.delivery_pickup_point_type}`];
                            }

                            _.each(pickup_points, point => {
                                if (point.id === settings[id].client_delivery_pickup_point_id) {
                                    pickup_point = point;
                                }
                            });
                        }

                        return (
                            <div className="packages__content__item" key={id}>
                                <div className="packages__content__item__main">
                                    <div className="packages__content__item__main__info">
                                        <Link to={`/orders/view/${id}`} className="number">
                                            {`${__('Objednávka č.')} ${item.data.number}`}
                                        </Link>
                                        <div className="packages__content__item__main__info__client">
                                            {item.data.client.name}
                                        </div>
                                    </div>
                                    {send ? <Button
                                        onClick={() => this.onChangeSetting(id, 'resend', '1')}
                                        color="green"
                                    >{__('Odoslať znovu')}</Button> : null}
                                    {!send ? <Select
                                        label={__('Prepravca')}
                                        options={couriers}
                                        value={courier_id}
                                        onChange={value => this.onChangeSetting(id, 'courier_id', value)}
                                        placeholder={__('Bez dopravcu')}
                                    /> : null}
                                    {!send ? <Input
                                        label={__('Dobierka')}
                                        value={settings[id].cod}
                                        onChange={value => this.onChangeSetting(id, 'cod', value)}
                                    /> : null}
                                    {!send ? <Input
                                        type="number"
                                        label={__('Počet')}
                                        value={settings[id].boxes.length > 1 ? settings[id].boxes.length : settings[id].count}
                                        onChange={value => this.onChangeSetting(id, 'count', value)}
                                        disabled={settings[id].boxes.length > 1}
                                    /> : null}
                                </div>
                                {!send ? <div className="packages__content__item__settings">
                                    <div className="packages__content__item__settings__status">
                                        {response !== null && !_.isEmpty(response.error)
                                            ? <span className="error">{__('Error:')} {_.truncate(response.error, { length: 64 })}</span>
                                            : null}
                                        {response !== null && !_.isEmpty(response.number)
                                            ? <span className="success">{response.number === 'waiting_import' ? __('Odoslané, ale čaká u prepravcu na spracovanie. Štítky nájdete v zozname štítkov po spracovaní zásielky prepravcom.') : (
                                                response.number === 'waiting_payment'
                                                    ? __('Čaká na úhradu')
                                                    : __('Odoslané')
                                            )}</span>
                                            : null}
                                    </div>
                                    <div className="packages__content__item__settings__items">
                                        {_.map(settings[id].boxes, (box, key) => (
                                            <div className="packages__content__item__settings__items__setting" key={key}>
                                                <div className="packages__content__item__settings__items__setting__lines">
                                                    <div className="packages__content__item__settings__items__setting__lines__line">
                                                        <Select
                                                            label={__('Typ')}
                                                            options={PACKS}
                                                            value={box.pack}
                                                            onChange={value => this.onChangeSettingBox(key, id, 'pack', value)}
                                                            allow_empty={false}
                                                        />
                                                        <Input
                                                            label={__('Hmotnosť')}
                                                            value={box.weight}
                                                            onChange={value => this.onChangeSettingBox(key, id, 'weight', value)}
                                                        />
                                                    </div>
                                                    {courier_name === 'SPS' ? <div className="packages__content__item__settings__items__setting__lines__line">
                                                        <Input
                                                            label={__('Referencia')}
                                                            value={box.reference}
                                                            onChange={value => this.onChangeSettingBox(key, id, 'reference', value)}
                                                        />
                                                    </div> : null}
                                                </div>
                                                {key === 0 ? <Button
                                                    onClick={() => this.addSettingBox(id)}
                                                >{__('Pridať')}</Button> : <Tooltip title={__('Zmazať')}>
                                                    <IconButton
                                                        onClick={() => this.deleteSettingBox(key, id)}
                                                        className="packages__content__item__settings__items__setting__delete"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                            </div>
                                        ))}
                                        {show_client ? <div className="packages__content__item__settings__items__client">
                                            <div className="packages__content__item__settings__items__client__item">
                                                <Input
                                                    label={__('Názov príjemcu')}
                                                    value={settings[id].client_name}
                                                    onChange={value => this.onChangeSetting(id, 'client_name', value)}
                                                    error={has_errors && _.includes(errors[id], 'name')}
                                                />
                                                <Input
                                                    label={__('Email príjemcu')}
                                                    value={settings[id].client_email}
                                                    onChange={value => this.onChangeSetting(id, 'client_email', value)}
                                                    error={has_errors && _.includes(errors[id], 'email')}
                                                />
                                            </div>
                                            {!show_pickup_point ? <div className="packages__content__item__settings__items__client__item">
                                                <Input
                                                    label={__('Adresa príjemcu')}
                                                    value={settings[id].client_address}
                                                    onChange={value => this.onChangeSetting(id, 'client_address', value)}
                                                    error={has_errors && _.includes(errors[id], 'address')}
                                                />
                                                <Input
                                                    label={__('Mesto príjemcu')}
                                                    value={settings[id].client_city}
                                                    onChange={value => this.onChangeSetting(id, 'client_city', value)}
                                                    error={has_errors && _.includes(errors[id], 'city')}
                                                />
                                            </div> : null}
                                            {!show_pickup_point ? <div className="packages__content__item__settings__items__client__item">
                                                <Input
                                                    label={__('PSČ príjemcu')}
                                                    value={settings[id].client_zip}
                                                    onChange={value => this.onChangeSetting(id, 'client_zip', value)}
                                                    error={has_errors && _.includes(errors[id], 'zip')}
                                                />
                                                <Select
                                                    label={__('Krajina príjemcu')}
                                                    value={settings[id].client_country.toString().toLowerCase()}
                                                    options={packages.countries}
                                                    onChange={value => this.onChangeSetting(id, 'client_country', value)}
                                                    error={has_errors && _.includes(errors[id], 'country')}
                                                    allow_empty={false}
                                                />
                                            </div> : null}
                                            {show_pickup_point ? <div className="packages__content__item__settings__items__client__item">
                                                <Input
                                                    label={__('Odberné miesto')}
                                                    content={<Autocomplete
                                                        options={pickup_points}
                                                        getOptionLabel={option => option.name}
                                                        onChange={(event, value) => this.onChangeSetting(id, 'client_delivery_pickup_point_id', value !== null ? value.id : 0)}
                                                        renderInput={(params) => <TextField
                                                            { ...params }
                                                            placeholder={__('Začnite písať názov...')}
                                                            variant="outlined"
                                                            error={has_errors && _.includes(errors[id], 'pickup_point')}
                                                        />}
                                                        noOptionsText={__('Nenašlo sa žiadne odberné miesto')}
                                                        clearText={__('Zrušiť')}
                                                        className="pickup-point"
                                                        defaultValue={pickup_point}
                                                    />}
                                                />
                                            </div> : null}
                                            <div className="packages__content__item__settings__items__client__item">
                                                <Input
                                                    label={__('Tel. číslo príjemcu')}
                                                    value={settings[id].client_phone}
                                                    onChange={value => this.onChangeSetting(id, 'client_phone', value)}
                                                    error={has_errors && _.includes(errors[id], 'phone')}
                                                />
                                                <Input
                                                    label={__('Poznámka príjemcu')}
                                                    value={settings[id].client_note}
                                                    onChange={value => this.onChangeSetting(id, 'client_note', value)}
                                                />
                                            </div>
                                            <div className="packages__content__item__settings__items__client__item">
                                                <Input
                                                    label={__('Kontaktná osoba príjemcu')}
                                                    value={settings[id].client_contact_name}
                                                    onChange={value => this.onChangeSetting(id, 'client_contact_name', value)}
                                                />
                                            </div>
                                        </div> : null}
                                        {show_scan ? <Message
                                            className="packages__content__item__settings__items__message"
                                            type="info"
                                        >{__('Môžete skenovať EAN kódy produktov.')}</Message> : null}
                                        {show_scan ? <Paper className="packages__content__item__settings__items__products" elevation={0}>
                                            <div className="packages__content__item__settings__items__products__item">
                                                <div>{__('Názov / EAN')}</div>
                                                <div>{__('Počet')}</div>
                                            </div>
                                            {_.map(products, (product, ean) => {
                                                return (
                                                    <div
                                                        className="packages__content__item__settings__items__products__item"
                                                        key={ean}
                                                    >
                                                        <div>{product.name}<br/><span>{ean}</span></div>
                                                        <div>{product.count === 0
                                                            ? <ValidIcon color="secondary" />
                                                            : product.count}</div>
                                                    </div>
                                                );
                                            })}
                                        </Paper> : null}
                                    </div>
                                    <div className="packages__content__item__settings__buttons">
                                        <Button
                                            onClick={!show_client ? () => this.showClient(id) : () => this.hideClient(id)}
                                            color=""
                                        >{!show_client ? __('Upraviť príjemcu') : __('Schovať príjemcu')}</Button>
                                        <Tooltip title={__('Kontrola produktov cez skener.')}>
                                            <span>
                                                <Button
                                                    onClick={!order_controlled
                                                        ? () => this.showProducts(id, item.data.products)
                                                        : () => {}}
                                                    color={order_controlled ? 'green' : ''}
                                                >{!order_controlled ? __('Skontrolovať') : __('Skontrolované')}</Button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div> : null}
                            </div>
                        );
                    })}
                    <Button
                        onClick={this.send.bind(this)}
                        loading={loading}
                        className="packages__content__button"
                    >{__('Odoslať')}</Button>
                </div>
                <audio id="audio">
                    <source src="/scan.mp3" />
                </audio>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ packages, user }) => ({ packages, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchPackages,
    sendOrders,
    setUser,
})(PackagesScreen)));
