import {
    FETCH_EXPORTS,
    CLEAN_EXPORTS,
    DELETE_EXPORT,
    DELETE_EXPORTS,
} from '../actions/types';

const EXPORTS_DEFAULT_STATE = {};

/**
 * Reducer pre exporty.
 */
const ExportsReducer = (state = EXPORTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_EXPORTS:
            // Nacitanie exportov
            return payload;

        case CLEAN_EXPORTS:
        case DELETE_EXPORT:
        case DELETE_EXPORTS:
            // Vycistenie exportov
            // Zmazanie exportu
            // Zmazanie exportov
            return EXPORTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ExportsReducer;