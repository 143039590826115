import React from 'react';
import { connect } from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button, Checkbox, Screen, Select, Table } from '../components';
import { __, formatAmount, isEmptyString, request, toNumber } from '../functions';
import { fetchSyncProducts, cleanSyncProducts, setUser } from '../actions';
import '../assets/styles/sync-products.css';

/**
 * Sklad.
 */
class SyncProductsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Synchronizácia produktov');

    /**
     * Default state.
     *
     * @type {{
     *     loading: {boolean},
     *     showSettings: {boolean}
     *     settings: {Object},
     *     selected: {Array},
     *     paired: {Object},
     * }}
     */
    state = {
        loading: false,
        loadingPage: false,
        showSettings: false,
        settings: {},
        selected: [],
        paired: {},
    };

    /**
     * Pocet zaznamov na stranku.
     *
     * @type {number}
     */
    perPage = 200;

    /**
     * Zoznam labelov.
     *
     * @type {Object}
     */
    labels = {
        name: __('Názov'),
        price: __('Cena'),
        stock: __('Stav na sklade'),
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            this.fetch();
        }

        return true;
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     */
    async fetch(page = 1) {
        const { fetchSyncProducts } = this.props;

        // Nacitame zoznam
        await fetchSyncProducts(this, { page, per_page: this.perPage });

        const { sync_products, user } = this.props;

        // Vytiahneme produkty ktore sa synchronizuju
        const selected = _.reduce(sync_products.items, (result, { id }, index) => {
            if (_.has(sync_products.sync_products, id)) {
                // Synchronizujeme
                return [ ...result, index ];
            }

            return result;
        }, []);

        // Vytiahneme naparovane produkty
        const paired = _.reduce(sync_products.items, (result, { id, data }) => {
            if (_.has(sync_products.sync_products, id) && toNumber(sync_products.sync_products[id]) > 0) {
                // Uz synchronizujeme a je zadany produkt ktory je naparovany
                return { ...result, [id]: toNumber(sync_products.sync_products[id]) };
            }

            // Pokusime sa naparovat produkt
            if (!isEmptyString(data[user.settings.sync_products.check_field])) {
                const checkField = data[user.settings.sync_products.check_field];

                let foundId = 0;

                // Prejdeme produkty a skusime
                _.each(sync_products.paired_products, (data, pairedId) => {
                    let pairedCheckField = data[user.settings.sync_products.check_field];

                    if (!isEmptyString(pairedCheckField) && pairedCheckField === checkField) {
                        // Nasli sme zhodu
                        foundId = toNumber(pairedId);
                    }
                });

                if (foundId > 0) {
                    return { ...result, [id]: foundId };
                }
            }

            return result;
        }, {});

        this.setState({
            loadingPage: false,
            settings: user.settings.sync_products,
            selected,
            paired,
        });
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        this.setState({ loadingPage: true });

        // Vycistime zoznam
        this.clean();

        // Nacitame pozadovanu stranku
        this.fetch(page);
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanSyncProducts } = this.props;

        // Vycistime zoznam
        cleanSyncProducts();
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string|number} value
     */
    onChangeData(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} name
     * @param {boolean} value
     */
    onChangeDataField(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, fields: { ...settings.fields, [name]: value } } });
    }

    /**
     * Zobrazime/schovame nastavenia.
     */
    showSettings() {
        const { showSettings } = this.state;

        this.setState({ showSettings: !showSettings });
    }

    /**
     * Event po zaskrnuty produktu.
     *
     * @param {number} index
     * @param {boolean} checked
     * @param {boolean} result
     *
     * @return {Array}
     */
    onSelectProduct(index, checked, result = false) {
        let { selected } = this.state;
        let s = [index];

        if (checked) {
            // Zaskrtnute, pridame
            selected = [ ...selected, ...s ];
        } else {
            // Odskrtnute, odstranime
            selected = _.remove(selected, i => !_.includes(s, i));
        }

        if (result) {
            return selected;
        }

        this.setState({ selected });
    }

    /**
     * Event po zmene naparovania produktu.
     *
     * @param {number} index
     * @param {number} id
     * @param {number} paired_id
     */
    onChangePairedProduct(index, id, paired_id) {
        const { selected, paired } = this.state;

        let state = { paired: { ...paired, [id]: paired_id } };

        if (!_.includes(selected, index)) {
            // Nie je oznaceny
            state = { ...state, selected: this.onSelectProduct(index, true, true) };
        }

        this.setState(state);
    }

    /**
     * Ulozime.
     */
    save() {
        const { sync_products } = this.props;
        const { settings, selected, paired } = this.state;

        this.setState({ loading: true });

        // Vytiahneme zoznam vsetkych idciek na danej strane
        const all = _.reduce(sync_products.items, (result, product) => ([ ...result, product.id ]), []);

        // Vytiahneme zoznam idciek produktov
        const ids = _.map(selected, index => sync_products.items[index].id);

        request('/user-eshops/save-sync-products', { settings, selected: ids, paired, all }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Nepodarilo sa ulozit nastavenia
                this.showSnackbar('error', __('Nepodarilo sa uložiť nastavenia'));
                return;
            }

            window.location = '/sync-products';
        });
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { user, sync_products } = this.props;
        const { paired } = this.state;

        // Naformatujeme
        const paired_products = _.reduce(sync_products.paired_products, (result, { name }, id) => {
            return { ...result, [id]: name };
        }, {});

        return _.map(items, ({ id, name, data }, index) => {
            return [
                <Link to="#" onClick={() => {}}>{name}</Link>,
                formatAmount(data.total_price, user.settings.currency),
                `${data.number !== '' ? data.number : '-'} / ${data.ean !== '' ? data.ean : '-'}`,
                formatAmount(data.stock, '', 0),
                <Select
                    options={paired_products}
                    value={_.has(paired, id) ? paired[id] : 0}
                    onChange={value => this.onChangePairedProduct(index, id, value)}
                />
            ];
        });
    }

    /**
     * Rendrujeme nastavenia.
     *
     * @return {JSX.Element}
     */
    renderSettings() {
        const { settings } = this.state;

        return (
            <div className="sync-products__settings">
                <div className="sync-products__settings__values">
                    {_.map(settings.fields, (checked, field) => {
                        return <Checkbox
                            label={this.labels[field]}
                            value={checked}
                            onChange={checked => this.onChangeDataField(field, checked)}
                            key={field}
                        />
                    })}
                </div>
                <div className="sync-products__settings__right">
                    <Select
                        label={__('Párovať produkt podla')}
                        options={{ ean: __('EAN'), number: __('Číslo') }}
                        value={settings.check_field}
                        onChange={value => this.onChangeData('check_field', value)}
                        allow_empty={false}
                    />
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, sync_products } = this.props;
        const { settings, showSettings, loading, loadingPage, selected } = this.state;

        if (_.isEmpty(settings) || loadingPage) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Naformatujeme zoznam eshopov
        const user_eshops = _.reduce(user.user_eshops, (result, name, id) => {
            if (id === user.user_eshop_id) {
                // Aktualny eshop nechceme
                return result;
            }

            return { ...result, [id]: name };
        }, {});

        return super.render(
            <Paper className="sync-products" elevation={0}>
                <Toolbar className="sync-products__header">
                    <div className="sync-products__header__left">
                        <Typography className="sync-products__header__title" variant="h5">
                            {__('Synchronizácia produktov')}
                        </Typography>
                    </div>
                    <div className="sync-products__header__right">
                        <Select
                            options={user_eshops}
                            value={settings.user_eshop_id}
                            onChange={value => this.onChangeData('user_eshop_id', value)}
                            placeholder={__('Zvoľte eshop')}
                        />
                        <Tooltip title={__('Nastavenia')}>
                            <IconButton onClick={() => this.showSettings()}>
                                <SettingsIcon color={showSettings ? 'primary' : 'inherit'} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
                {showSettings ? this.renderSettings() : null}
                <div className="sync-products__content">
                    {toNumber(user.settings.sync_products.user_eshop_id) === toNumber(settings.user_eshop_id) ? <Table
                        columns={[
                            __('Názov'),
                            __('Cena'),
                            __('Číslo produktu / EAN'),
                            __('Stav na sklade'),
                            __('Párovať s'),
                        ]}
                        data={this.formatData(sync_products.items)}
                        raw_data={sync_products.items}
                        count={sync_products.total}
                        page={sync_products.page}
                        per_page={this.perPage}
                        allowPerPage={false}
                        onChangePage={page => this.onChangePage(page)}
                        multiselect_selected={selected}
                        multiselect_selected_force={selected}
                        onCheckbox={(index, checked) => this.onSelectProduct(index, checked)}
                        empty_text={__('Zatiaľ nemáte žiadne produkty')}
                    /> : null}
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="sync-products__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ sync_products, user }) => ({ sync_products, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchSyncProducts,
    cleanSyncProducts,
    setUser,
})(SyncProductsScreen)));
