import _ from 'lodash';

/**
 * Vyresetujeme data objektu.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const resetObject = data => _.reduce(data, (result, value, key) => {
    if (_.isObject(value)) {
        // Prejdeme rekurzivne
        value = resetObject(value);
    } else {
        value = '';
    }

    return { ...result, [key]: value };
}, {});