import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import ActivatedIcon from '@material-ui/icons/CheckCircle';
import { Screen, Switch, Button, Select, Message, Input, Checkbox } from '../components';
import { __, isEmptyString, isValidIban, isValidAccount, toNumber } from '../functions';
import { fetchCouriers, changeCouriersAutoField, changeCouriersSettings, setUser } from '../actions';
import { IMAGES, COURIERS, COURIER_COLORS } from '../config';
import '../assets/styles/couriers.css';

/**
 * Kurieri.
 */
class CouriersScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Prepravcovia');

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     settings: {},
     * }}
     */
    state = {
        loading: false,
        settings: {},
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchCouriers } = this.props;

            await fetchCouriers(this);

            const { couriers } = this.props;

            this.setState({ settings: couriers.settings });
        }

        return true;
    }

    /**
     * Event po zmene auto fieldu.
     *
     * @param {string} field
     * @param {boolean} value
     */
    onChangeAutoField(field, value) {
        const { changeCouriersAutoField } = this.props;

        changeCouriersAutoField(this, field, value);
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeCouriersSettings } = this.props;
        const { settings } = this.state;

        if (settings.iban !== '' && !isValidIban(settings.iban) && !isValidAccount(settings.iban)) {
            // Neplatny iban alebo cislo uctu
            this.showSnackbar('error', __('Neplatný IBAN alebo číslo účtu'));
            return;
        }

        this.setState({ loading: true });

        changeCouriersSettings(this, settings);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { couriers } = this.props;
        const { loading, settings } = this.state;

        if (_.isEmpty(couriers) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <Paper className="couriers" elevation={0}>
                <Toolbar className="couriers__header">
                    <div className="couriers__header__left">
                        <Typography className="couriers__header__title" variant="h5">{__('Prepravcovia')}</Typography>
                    </div>
                </Toolbar>
                <div className="couriers__content">
                    <div className="couriers__content__events">
                        <div className="couriers__content__events__auto">
                            <Switch
                                label={__('Automatické odoslanie zásielok')}
                                checked={couriers.auto === 'on'}
                                onChange={value => this.onChangeAutoField('auto', value)}
                            />
                            {couriers.auto === 'on' ? <div className="couriers__content__events__auto__setting">
                                <Checkbox
                                    label={__('Odosielať prepravcovi po každej objednávke')}
                                    value={settings.send_every_order}
                                    onChange={checked => this.onChangeSetting('send_every_order', checked)}
                                />
                                {!settings.send_every_order ? <Input
                                    label={__('Čas hromadného odoslania prepravcom')}
                                    type="time"
                                    value={new Date().setHours(settings.send_hour, 0)}
                                    onChange={date => this.onChangeSetting('send_hour', date.getHours())}
                                /> : null}
                            </div> : null}
                        </div>
                    </div>
                    {isEmptyString(couriers.eshop_settings.name) ? <Message
                        type="warning"
                        className="couriers__content__warning"
                    >{__('Pozor! Nemáte nastavenú adresu v nastaveniach eshopu.')}</Message> : null}
                    <div className="couriers__content__items">
                        {_.map(couriers.couriers, (name, id) => {
                            id = toNumber(id);

                            const activated = _.includes(_.values(couriers.user_couriers), id);

                            return (
                                <Paper className="couriers__content__items__item" elevation={1} key={id}>
                                    <Tooltip title={COURIERS[name]}>
                                        <Link to={`/courier/${name}`}>
                                            <ButtonBase
                                                focusRipple
                                                className="couriers__content__items__item__button"
                                                style={{ backgroundColor: COURIER_COLORS[name].background }}
                                            >
                                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                                {activated
                                                    ? <ActivatedIcon
                                                        className="couriers__content__items__item__button__icon"
                                                        color="secondary"
                                                    />
                                                    : null
                                                }
                                            </ButtonBase>
                                        </Link>
                                    </Tooltip>
                                </Paper>
                            );
                        })}
                        <Paper className="couriers__content__items__item" elevation={1}>
                            <Tooltip title={__('Nevidíte tu svojho prepravcu? Kontaktujte nás a zvážime jeho zapracovanie do systému.')}>
                                <Link to="#">
                                    <ButtonBase
                                        focusRipple
                                        className="couriers__content__items__item__button"
                                    >
                                        <HelpIcon
                                            className="couriers__content__items__item__button__help"
                                            color="primary"
                                        />
                                    </ButtonBase>
                                </Link>
                            </Tooltip>
                        </Paper>
                    </div>
                    <div className="couriers__content__settings">
                        <Typography className="couriers__content__settings__title" variant="h6">{__('Nastavenie')}</Typography>
                        <div className="couriers__content__settings__selects">
                            <Select
                                label={__('Údaj objednávky kde sa má uložit číslo zásielky')}
                                options={couriers.eshop_data.placeholder_fields}
                                value={settings.placeholder_field}
                                onChange={value => this.onChangeSetting('placeholder_field', value)}
                            />
                            <Input
                                label={__('IBAN / Číslo účtu pre úhrady dobierok')}
                                value={settings.iban}
                                onChange={value => this.onChangeSetting('iban', value)}
                            />
                            <Select
                                label={__('Predvolený dopravca v rozpise zásielok')}
                                options={couriers.user_couriers_list}
                                value={settings.default_courier}
                                onChange={value => this.onChangeSetting('default_courier', value)}
                            />
                        </div>
                        <Button
                            onClick={this.changeSettings.bind(this)}
                            loading={loading}
                            className="couriers__content__button"
                        >{__('Uložiť')}</Button>
                    </div>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ couriers, user }) => ({ couriers, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchCouriers,
    changeCouriersAutoField,
    changeCouriersSettings,
    setUser,
})(CouriersScreen)));