import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import HelpIcon from '@material-ui/icons/LiveHelp';
import { Button, Screen, Input } from '../components';
import { __, formatAmount, request } from '../functions';
import '../assets/styles/order-state.css';

/**
 * Verejny stav objednavky.
 */
class OrderStateScreen extends Screen {
    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     loading_unlock: boolean,
     *     loading_download: boolean,
     *     loading_cancel: boolean,
     *     data: Object,
     *     email: string,
     * }}
     */
    state = {
        loading: true,
        loading_unlock: false,
        loading_download: false,
        loading_cancel: false,
        data: {},
        email: '',
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            const { match } = this.props;

            // Nastavime title
            this.setTitle(`${__('Objednávka číslo')} ${match.params.number}`);

            // Nacitame
            this.load();
        }

        return true;
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} email
     */
    onChangeEmail(email) {
        this.setState({ email });
    }

    /**
     * Nacitanie dat.
     *
     * @param {string} email
     */
    load(email = '') {
        const { match } = this.props;

        request(`/orders/state/${match.params.user_eshop_id}/${match.params.number}/${email}`).then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false, loading_unlock: false });

            if (status === 'error') {
                // Objednavka neexistuje
                this.showSnackbar('error', __('Objednávka neexistuje'));
                setTimeout(() => window.location = '/dashboard', 1000);
                return;
            } else if (email !== '' && _.isEmpty(data.data)) {
                // Email nesedi
                this.showSnackbar('error', __('Neplatný email'));
            }

            this.setState({ data });
        });
    }

    /**
     * Odomknutie.
     */
    unlock() {
        const { email } = this.state;

        this.setState({ loading_unlock: true });

        // Nacitame
        this.load(email);
    }

    /**
     * Stiahneme pdf.
     */
    download() {
        const { email } = this.state;

        this.setState({ loading_download: true });

        const { match } = this.props;

        request(`/orders/statePdf/${match.params.user_eshop_id}/${match.params.number}/${email}`).then(response => {
            const { status, data } = response.data;

            this.setState({ loading_download: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť faktúru'));
                return;
            }

            window.location = data.link;
        });
    }

    /**
     * Storno.
     */
    cancel() {
        const { email, data } = this.state;

        this.setState({ loading_cancel: true });

        const { match } = this.props;

        request(`/orders/stateCancel/${match.params.user_eshop_id}/${match.params.number}/${email}`).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.setState({ loading_cancel: false });
                this.showSnackbar('error', __('Nepodarilo sa stornovať objednávku'));
                return;
            }

            // Nastavime do aktualneho stav storno stav
            this.setState({ loading_cancel: false, data: { ...data, state: { id: data.state_cancel } } });

            this.showSnackbar('success', __('Objednávka bola stornovaná'));
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { loading, loading_unlock, loading_download, loading_cancel, data, email } = this.state;

        if (loading) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        } else if (_.isEmpty(data)) {
            // Objednavka neexistuje
            return this.renderSnackbar();
        }

        const { match } = this.props;
        const locked = _.isEmpty(data.data);
        const canceled = !_.isEmpty(data.state_cancel) && data.state.id === data.state_cancel;
        const state = _.find(data.states, state => state.state_id.toString() === data.state.id.toString());

        return (
            <div className="order-state">
                <Paper className="order-state__window" elevation={0}>
                    <div className="order-state__header">
                        <Typography className="order-state__header__title" variant="h5">
                            {__('Objednávka číslo')} {match.params.number}
                        </Typography>
                    </div>
                    <div className="order-state__content">
                        <div className="order-state__content__state">
                            <div className="order-state__content__state__icon">
                                <HelpIcon />
                            </div>
                            <div className="order-state__content__state__data">
                                <div className="order-state__content__state__data__name">
                                    {typeof state !== 'undefined' ? state.name : '-'}
                                </div>
                                <div className="order-state__content__state__data__text">
                                    {typeof state !== 'undefined' ? state.text : '-'}
                                </div>
                            </div>
                        </div>
                        {locked ? <div className="order-state__content__client center"><LockIcon /></div> : null}
                        {!locked ? <div className="order-state__content__client">
                            <div className="order-state__content__client__panel">
                                <div className="order-state__content__client__panel__name">{__('Meno')}</div>
                                <div className="order-state__content__client__panel__text">
                                    {data.data.client.name}
                                </div>
                            </div>
                            <div className="order-state__content__client__panel">
                                <div className="order-state__content__client__panel__name">{__('Dodacia adresa')}</div>
                                <div className="order-state__content__client__panel__text">
                                    {`${data.data.client.delivery_address} ${data.data.client.delivery_city} ${data.data.client.delivery_zip}`}
                                </div>
                            </div>
                            <div className="order-state__content__client__panel">
                                <div className="order-state__content__client__panel__name">{__('Suma')}</div>
                                <div className="order-state__content__client__panel__text">
                                    {formatAmount(data.data.amount, data.data.currency)}
                                </div>
                            </div>
                        </div> : null}
                    </div>
                    <div className="order-state__buttons">
                        {locked ? <div className="order-state__buttons__unlock">
                            <Input
                                placeholder={__('Zadajte email')}
                                value={email}
                                onChange={value => this.onChangeEmail(value)}
                            />
                            <Button
                                onClick={() => this.unlock()}
                                color="primary"
                                loading={loading_unlock}
                                disabled={email === ''}
                            >{__('Odomknúť')}</Button>
                        </div> : null}
                        {data.invoice ? <Button
                            onClick={!canceled ? () => this.download() : () => {}}
                            color="primary"
                            disabled={locked && !canceled}
                            loading={loading_download}
                        >{__('Stiahnuť faktúru')}</Button> : null}
                        {!_.isEmpty(data.state_cancel) ? <Button
                            onClick={!canceled ? () => this.cancel() : () => {}}
                            color={canceled ? 'green' : 'red'}
                            disabled={locked && !canceled}
                            loading={loading_cancel}
                        >{!canceled ? __('Stornovať') : __('Stornované')}</Button> : null}
                    </div>
                </Paper>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(OrderStateScreen)));