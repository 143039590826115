import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonCore from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Screen, Input, Button, Logo, Checkbox } from '../components';
import { __, isValidEmail,  request } from '../functions';
import { IMAGES, PASSWORD_MIN_LENGTH, COOKIE_SETTINGS } from '../config';
import '../assets/styles/register.css';

/**
 * Registracia.
 */
class RegisterScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Registrácia');

    /**
     * Default state.
     *
     * @type {{
     *     email: string,
     *     password: string,
     *     password_repeat: string,
     *     vop: boolean,
     *     loading: boolean,
     *     social_links: Object|null,
     * }}
     */
    state = {
        email: '',
        password: '',
        password_repeat: '',
        vop: false,
        loading: false,
        social_links: null,
    };

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame linky na soc siete
            request('/users/social-links').then(response => {
                const { data } = response.data;

                this.setState({ social_links: data });
            });
        }

        return true;
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    /**
     * Registracia.
     */
    register() {
        const { email, password, password_repeat, vop } = this.state;

        if (!isValidEmail(email)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Neplatný email'));
            return;
        } else if (password.length < PASSWORD_MIN_LENGTH) {
            // Nie je vyplnene heslo
            this.showSnackbar(
                'error',
                __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
            );
            return;
        } else if (password !== password_repeat) {
            // Heslo a heslo znovu sa nerovnaju
            this.showSnackbar('error', __('Heslá nie sú rovnaké'));
            return;
        } else if (!vop) {
            // Nesuhlas s VOP
            this.showSnackbar('error', __('Musíte súhlasiť s Všeobecnými obchodnými podmienkami'));
            return;
        }

        this.setState({ loading: true });

        request('/users/register', { email, password, password_repeat }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Registracia sa nepodarila
                this.showSnackbar('error', __('Email sa už používa'));
                return;
            }

            // Zavolame callback
            this.afterRegister(data.token);
        });
    }

    /**
     * Callback po registracii.
     *
     * @param {string} token
     */
    afterRegister(token) {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set('token', token, COOKIE_SETTINGS);
        cookies.remove('user_eshop_id', COOKIE_SETTINGS);

        window.location = '/dashboard';
    }

    /**
     * Registracia cez soc. siete.
     *
     * @param {string} link
     */
    socialRegister(link) {
        const { vop } = this.state;

        if (!vop) {
            // Nesuhlas s VOP
            this.showSnackbar('error', __('Musíte súhlasiť s Všeobecnými obchodnými podmienkami'));
            return;
        }

        window.location = link;
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { email, password, password_repeat, vop, loading, social_links } = this.state;

        if (social_links === null) {
            // Nie su nacitane linky na soc siete
            return (
                <Paper className="register" elevation={0}>
                    {this.renderLoading()}
                </Paper>
            );
        }

        return (
            <Paper className="register" elevation={0}>
                <Logo onClick={() => { window.location = '/'; }} />
                <Typography className="register__title" variant="h4">{__('Registrácia')}</Typography>
                <div className="register__social">
                    <ButtonCore
                        onClick={!_.isEmpty(social_links)
                            ? () => this.socialRegister(social_links.facebook_register)
                            : () => {}}
                        className="register__social__button facebook"
                        variant="outlined"
                        color="inherit"
                    >
                        <FacebookIcon />
                        <Typography variant="body2">{__('Facebook')}</Typography>
                    </ButtonCore>
                    <ButtonCore
                        onClick={!_.isEmpty(social_links)
                            ? () => this.socialRegister(social_links.google_register)
                            : () => {}}
                        className="register__social__button google"
                        variant="outlined"
                        color="inherit"
                    >
                        <img src={IMAGES['google.svg']} alt="google logo" />
                        <Typography variant="body2">{__('Google')}</Typography>
                    </ButtonCore>
                </div>
                <Typography className="register__or">{__('Alebo sa registrujte cez email a heslo')}</Typography>
                <Input
                    type="email"
                    label={__('Email')}
                    value={email}
                    onChange={value => this.onChangeValue('email', value)}
                />
                <Input
                    type="password"
                    label={__('Heslo')}
                    value={password}
                    onChange={value => this.onChangeValue('password', value)}
                />
                <Input
                    type="password"
                    label={__('Heslo znovu')}
                    value={password_repeat}
                    onChange={value => this.onChangeValue('password_repeat', value)}
                />
                <Checkbox
                    label={<span>
                        <span>{__('Súhlasím so')} </span>
                        <a href="/vop.pdf?v=1" target="_blank">{__('Všeobecnými obchodnými podmienkami')}</a>
                    </span>}
                    value={vop}
                    onChange={checked => this.onChangeValue('vop', checked)}
                />
                <Button
                    onClick={this.register.bind(this)}
                    className="register__button"
                    loading={loading}
                >{__('Registrovať')}</Button>
                <Link to="/login">
                    <Button
                        color="transparent"
                        className="register__button"
                    >{__('Už máte účet? Prihláste sa.')}</Button>
                </Link>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(RegisterScreen)));
