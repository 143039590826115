import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import ActivatedIcon from '@material-ui/icons/CheckCircle';
import NumberIcon from '@material-ui/icons/Filter2';
import { Screen, Input, Select, Checkbox, Button, Message } from '../components';
import { __, formatAmount, isEmptyString, isValidZip, request, toNumber } from '../functions';
import { fetchCreateOrder, setUser, cleanOrder, addOrder } from '../actions';
import { CURRENCIES } from '../config';
import '../assets/styles/order-create.css';

/**
 * Vytvorenie objednavky.
 */
class OrderCreateScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nová objednávka');

    /**
     * Default state.
     *
     * @type {{
     *     loading_ares: boolean,
     *     loading_vies: boolean,
     *     loading_sequences: boolean,
     *     valid_vies: boolean,
     *     loading: boolean,
     *     same_delivery: boolean,
     *     data: Object,
     *     lightbox: Object,
     * }}
     */
    state = {
        loading_ares: false,
        loading_vies: false,
        loading_sequences: false,
        valid_vies: false,
        loading: false,
        same_delivery: true,
        data: {
            order: {
                number: '',
                state: '',
                payment_type: '',
                delivery_type: '',
                currency: '',
            },
            client: {
                name: '',
                ico: '',
                dic: '',
                ic_dph: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                zip: '',
                country: '',
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_country: '',
                delivery_phone: '',
                delivery_note: '',
            },
            products: [],
        },
        sequences: {
            manual: {
                active: false,
                mask: '',
                value: 1,
            },
            auto: {
                active: false,
                mask: '',
                value: 1,
            },
        },
        lightbox: {
            sequences: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchCreateOrder } = this.props;

            // Nacitame data
            await fetchCreateOrder(this);

            const { order, user } = this.props;
            let { data, sequences } = this.state;

            let state_id = null;

            _.each(order.eshop_data.states, state => {
                if (state_id === null) {
                    state_id = state.id;
                }
            });

            _.each(_.keys(order.sequences), (type) => {
                const { mask, value } = order.sequences[type];

                sequences = { ...sequences, [type]: { ...sequences[type], active: true, mask, value } };
            });

            this.setState({ sequences, data: {
                ...data,
                order: {
                    ...data.order,
                    number: order.number,
                    currency: user.settings.currency,
                    state: state_id,
                },
                products: [ this.getProduct() ],
                client: {
                    ...data.client,
                    country: user.settings.market,
                    delivery_country: user.settings.market,
                },
            } });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanOrder } = this.props;

        cleanOrder();
    }

    /**
     * Event po zmene rovnakej fakturacnej a dodacej adresy.
     *
     * @param {boolean} value
     */
    onChangeSameDelivery(value) {
        const { data } = this.state;
        let { client } = data;

        if (value) {
            // Chceme rovnaku, nasetujeme
            client = {
                ...client,
                delivery_address: client.address,
                delivery_city: client.city,
                delivery_zip: client.zip,
                delivery_country: client.country,
                delivery_phone: client.phone,
            };
        } else {
            // Nechceme rovnaku, vycistime
            client = {
                ...client,
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_phone: '',
            };
        }

        this.setState({ data: { ...data, client }, same_delivery: value });
    }

    /**
     * Event po zmene objednavky.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeOrder(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, order: { ...data.order, [type]: value } } });
    }

    /**
     * Event po zmene klienta.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeClient(type, value) {
        const { data, same_delivery } = this.state;

        let client = { ...data.client, [type]: value };

        if (same_delivery) {
            // Dodacia je rovnaka
            switch (type) {
                case 'address':
                    // Menime adresu
                    client = { ...client, delivery_address: value };
                    break;

                case 'city':
                    // Menime mesto
                    client = { ...client, delivery_city: value };
                    break;

                case 'zip':
                    // Menime PSC
                    client = { ...client, delivery_zip: value };
                    break;

                case 'country':
                    // Menime krajinu
                    client = { ...client, delivery_country: value };
                    break;

                case 'phone':
                    // Menime telefon
                    client = { ...client, delivery_phone: value };
                    break;

                default:
                    break;
            }
        }

        this.setState({ data: { ...data, client } });
    }

    /**
     * Event po zmene klienta.
     *
     * @param {number} key
     * @param {string} type
     * @param {string} value
     */
    onChangeProduct(key, type, value) {
        const { data } = this.state;

        const products = _.map(data.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                if (type === 'variant') {
                    return { ...product, variant: value };
                } else if (type === 'name') {
                    return { ...product, name: value };
                }

                let quantity = product.quantity;
                let unit_price = product.unit_price;
                let tax = product.tax;
                let total_price = product.total_price;

                value = value.replace(/[^0-9.,]/g, '');

                switch (type) {
                    default:
                    case 'quantity':
                        // Menime pocet
                        quantity = value;
                        break;

                    case 'unit_price':
                        // Menime jednotkovu cenu
                        unit_price = value;
                        total_price = formatAmount(toNumber(unit_price) * (tax / 100 + 1), '').replace(' ', '');
                        break;

                    case 'tax':
                        // Menime DPH
                        tax = value;
                        total_price = formatAmount(toNumber(unit_price) * (tax / 100 + 1), '').replace(' ', '');
                        break;

                    case 'total_price':
                        // Menime celkovu cenu
                        total_price = value;
                        unit_price = formatAmount(toNumber(total_price) * (100 / (100 + tax)), '').replace(' ', '');
                        break;
                }

                product = { ...product, quantity, unit_price, tax, total_price };
            }

            return product;
        });

        this.setState({ data: { ...data, products }});
    }

    /**
     * Pridanie produktu.
     */
    addProduct() {
        const { data } = this.state;

        this.setState({ data: { ...data, products: [ ...data.products, this.getProduct() ] } });
    }

    /**
     * Zmazanie produktu.
     *
     * @param {number} key
     */
    deleteProduct(key) {
        const { data } = this.state;
        let { products } = data;

        products = _.remove(products, (item, k) => k !== key);

        this.setState({ data: { ...data, products }});
    }

    /**
     * Vratime data noveho produktu.
     *
     * @return {Object}
     */
    getProduct() {
        const { user } = this.props;

        return {
            id: '',
            name: '',
            variant: '',
            variants: {},
            quantity: 1,
            unit_price: '',
            tax: user.settings.market === 'cz' ? 21 : 20,
            total_price: '',
        };
    }

    /**
     * Event po vybrani produktu.
     *
     * @param {number} key
     * @param {Object} product_data
     */
    onSelectProduct(key, product_data) {
        const { user } = this.props;
        const { data } = this.state;

        const products = _.map(data.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                const variants = product_data !== null
                    ? _.reduce(product_data.data.variants, (result, variant) => {
                        let variant_name = variant;
                        
                        if (
                            _.has(product_data.data.options, 'variants_stock')
                            && _.has(product_data.data.options.variants_stock, variant)
                        ) {
                            // Mame skladove zasoby k variantam
                            variant_name = `${variant_name} (${__('skladom')}: ${toNumber(product_data.data.options.variants_stock[variant])})`;
                        }

                        return { ...result, [variant]: variant_name };
                    }, {})
                    : {};

                product = {
                    ...product,
                    id: product_data !== null ? product_data.id : '',
                    variants,
                    variant: !_.isEmpty(variants) ? _.keys(variants)[0] : '',
                    unit_price: product_data !== null ? formatAmount(toNumber(product_data.data.unit_price), '', 2).replace(' ', '') : '',
                    tax: product_data !== null ? product_data.data.tax : (user.settings.market === 'cz' ? 21 : 20),
                    total_price: product_data !== null ? formatAmount(toNumber(product_data.data.total_price), '', 2).replace(' ', '') : '',
                };
            }

            return product;
        });

        this.setState({ data: { ...data, products }});
    }

    /**
     * Nacitanie udajov z ARES.
     */
    checkAres() {
        const { data, same_delivery } = this.state;

        this.setState({ loading_ares: true });

        request(`/orders/ares/${data.client.ico}/${data.client.country}`).then(response => {
            const { status, data } = response.data;

            this.setState({ loading_ares: false });

            if (status === 'error') {
                // Nepodarilo sa nacitat udaje
                this.showSnackbar('error', __('Nepodarilo sa načítať údaje'));
                return;
            }

            let { client } = this.state.data;

            client = {
                ...client,
                name: data.name,
                address: data.address,
                city: data.city,
                zip: data.zip,
                dic: data.dic,
            };

            if (same_delivery) {
                // Fakturacna adresa je rovnaka ako dodacia
                client = {
                    ...client,
                    delivery_address: data.address,
                    delivery_city: data.city,
                    delivery_zip: data.zip,
                };
            }

            this.setState({ data: { ...this.state.data, client } });
        });
    }

    /**
     * Overenie VIES.
     */
    checkVies() {
        const { data } = this.state;

        this.setState({ loading_vies: true });

        request(`/orders/vies/${data.client.dic}/${data.client.country}`).then(response => {
            const { status } = response.data;

            this.setState({ loading_vies: false });

            if (status === 'error') {
                // Nepodarilo sa nacitat udaje
                this.showSnackbar('error', __('Nepodarilo sa overiť cez VIES'));
                return;
            }

            this.setState({ valid_vies: true })
            this.showSnackbar('success', __('Overené cez VIES'));
        });
    }

    /**
     * Event po zmene ciselnika.
     *
     * @param {string} type
     * @param {string} key
     * @param {string} value
     */
    onChangeSequence(type, key, value) {
        const { sequences } = this.state;

        this.setState({ sequences: { ...sequences, [type]: { ...sequences[type], [key]: value } } });
    }

    /**
     * Ulozenie ciselnikov.
     */
    saveSequences() {
        const { sequences } = this.state;

        let error = '';

        if (
            (sequences.manual.active && isEmptyString(sequences.manual.mask))
            || (sequences.auto.active && isEmptyString(sequences.auto.mask))
        ) {
            // Nie je vyplnena maska
            error = __('Nie je vyplnená maska číselníka');
        } else if (
            (sequences.manual.active && toNumber(sequences.manual.value) === 0)
            || (sequences.auto.active && toNumber(sequences.auto.value) === 0)
        ) {
            // Nie je vyplnene nasledujuce cislo
            error = __('Nie je vyplnené nasledujúce číslo číselníka');
        }

        if (!_.isEmpty(error)) {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading_sequences: true });

        request('/orders/sequences', sequences, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loading_sequences: false });

            if (status === 'error') {
                // Nepodarilo sa ulozit
                this.showSnackbar('error', __('Nepodarilo sa uložiť číselníky'));
                return;
            }

            this.showSnackbar('success', __('Číselníky boli uložené'));

            // Refresneme
            setTimeout(() => window.location = '/orders/create', 200);
        });
    }

    /**
     * Ulozenie.
     */
    save() {
        const { data } = this.state;

        let error = '';

        if (isEmptyString(data.order.number)) {
            // Nevyplnene cislo objednavky
            error = __('Nie je zadané číslo objednávky');
        } else if (data.order.payment_type === '') {
            // Nevyplneny typ uhrady
            error = __('Nie je zadaný typ úhrady');
        } else if (data.order.delivery_type === '') {
            // Nevyplneny typ dodania
            error = __('Nie je zadaný typ dodania');
        } else if (isEmptyString(data.client.name)) {
            // Nevyplneny nazov klienta
            error = __('Nie je zadaný názov zákaznika');
        } else if (!isEmptyString(data.client.zip) && !isValidZip(data.client.zip)) {
            // Neplatne PSC
            error = __('Neplatné PSČ');
        } else if (_.reduce(data.products, (result, product) => product.id !== '' ? result + 1 : result, 0) === 0) {
            // Nie je zadany produkt
            error = __('Nie je zadaný produkt');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { addOrder } = this.props;

        // Ulozime objednavku
        addOrder(this, data);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { order } = this.props;
        const { lightbox, sequences, data, same_delivery, loading, loading_ares, loading_vies, loading_sequences, valid_vies } = this.state;

        if (_.isEmpty(order)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Vytiahneme zoznam stavov
        const states = _.reduce(order.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam platieb
        const payment_types = _.reduce(order.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam dodani
        const delivery_types = _.reduce(order.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        let unit_price = 0;
        let total_price = 0;

        return super.render(
            <Paper className="order-create" elevation={0}>
                <Toolbar className="order-create__header">
                    <div className="order-create__header__left">
                        <Typography
                            className="order-create__header__title"
                            variant="h5"
                        >{__('Nová objednávka')}</Typography>
                    </div>
                </Toolbar>
                <div className="order-create__content">
                    <div className="order-create__content__sections">
                        <div className="order-create__content__sections__section">
                            <div className="order-create__content__sections__section__title">{__('Objednávka')}</div>
                            <div className="order-create__content__sections__section__number">
                                <Input
                                    label={__('Číslo objednávky')}
                                    value={data.order.number}
                                    onChange={value => this.onChangeOrder('number', value)}
                                />
                                <Tooltip title={__('Nastaviť číslovanie objednávok')}>
                                    <IconButton
                                        onClick={() => this.showLightbox('sequences', { data: [] })}
                                        className="order-create__content__sections__section__number__button"
                                    >
                                        <NumberIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Select
                                label={__('Stav')}
                                value={data.order.state}
                                options={states}
                                onChange={value => this.onChangeOrder('state', value)}
                                allow_empty={false}
                            />
                            <Select
                                label={__('Dodanie')}
                                value={data.order.delivery_type}
                                options={delivery_types}
                                onChange={value => this.onChangeOrder('delivery_type', value)}
                            />
                            <Select
                                label={__('Platba')}
                                value={data.order.payment_type}
                                options={payment_types}
                                onChange={value => this.onChangeOrder('payment_type', value)}
                            />
                            <Select
                                label={__('Mena')}
                                value={data.order.currency}
                                options={CURRENCIES}
                                onChange={value => this.onChangeOrder('currency', value)}
                                allow_empty={false}
                            />
                        </div>
                        <div className="order-create__content__sections__section">
                            <div className="order-create__content__sections__section__title">{__('Zákaznik')}</div>
                            <div className="order-create__content__sections__section__panels">
                                <div className="order-create__content__sections__section__panels__panel">
                                    <Input
                                        label={__('Meno a priezvisko (názov firmy)')}
                                        value={data.client.name}
                                        onChange={value => this.onChangeClient('name', value)}
                                        required
                                    />
                                    <div className="order-create__content__sections__section__panels__panel__sync">
                                        <Input
                                            label={__('IČO')}
                                            value={data.client.ico}
                                            onChange={value => this.onChangeClient('ico', value)}
                                        />
                                        <Tooltip title={__('Načítať údaje z ARES')}>
                                            <span>
                                                <IconButton
                                                    onClick={() => this.checkAres()}
                                                    className="order-create__content__sections__section__panels__panel__sync__button"
                                                    disabled={isEmptyString(data.client.ico)}
                                                >
                                                {loading_ares
                                                    ? <CircularProgress className="loading" color="inherit" size={20} />
                                                    : <SyncIcon />}
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div className="order-create__content__sections__section__panels__panel__sync">
                                        <Input
                                            label={__('DIČ')}
                                            value={data.client.dic}
                                            onChange={value => this.onChangeClient('dic', value)}
                                        />
                                        <Tooltip title={__('Overiť VIES')}>
                                            <span>
                                                <IconButton
                                                    onClick={() => this.checkVies()}
                                                    className="order-create__content__sections__section__panels__panel__sync__button"
                                                    disabled={isEmptyString(data.client.dic) || valid_vies}
                                                >
                                                {loading_vies
                                                    ? <CircularProgress className="loading" color="inherit" size={20} />
                                                    : (valid_vies ? <ActivatedIcon color="secondary" /> : <SyncIcon />)}
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <Input
                                        label={__('IČ DPH')}
                                        value={data.client.ic_dph}
                                        onChange={value => this.onChangeClient('ic_dph', value)}
                                    />
                                    <Input
                                        label={__('Email')}
                                        value={data.client.email}
                                        onChange={value => this.onChangeClient('email', value)}
                                    />
                                </div>
                                <div className="order-create__content__sections__section__panels__panel">
                                    <Input
                                        label={__('Adresa (fakturácia)')}
                                        value={data.client.address}
                                        onChange={value => this.onChangeClient('address', value)}
                                    />
                                    <Input
                                        label={__('Mesto (fakturácia)')}
                                        value={data.client.city}
                                        onChange={value => this.onChangeClient('city', value)}
                                    />
                                    <Input
                                        label={__('PSČ (fakturácia)')}
                                        value={data.client.zip}
                                        onChange={value => this.onChangeClient('zip', value)}
                                    />
                                    <Select
                                        label={__('Krajina (fakturácia)')}
                                        value={data.client.country}
                                        options={order.countries}
                                        onChange={value => this.onChangeClient('country', value)}
                                        allow_empty={false}
                                    />
                                    <Input
                                        label={__('Telefón (fakturácia)')}
                                        value={data.client.phone}
                                        onChange={value => this.onChangeClient('phone', value)}
                                    />
                                    <Checkbox
                                        label={__('Dodacia adresa rovnaká ako fakturačná')}
                                        value={same_delivery}
                                        onChange={checked => this.onChangeSameDelivery(checked)}
                                    />
                                </div>
                                <div className="order-create__content__sections__section__panels__panel">
                                    <Input
                                        label={__('Adresa (dodanie)')}
                                        value={data.client.delivery_address}
                                        onChange={value => this.onChangeClient('delivery_address', value)}
                                        disabled={same_delivery}
                                    />
                                    <Input
                                        label={__('Mesto (dodanie)')}
                                        value={data.client.delivery_city}
                                        onChange={value => this.onChangeClient('delivery_city', value)}
                                        disabled={same_delivery}
                                    />
                                    <Input
                                        label={__('PSČ (dodanie)')}
                                        value={data.client.delivery_zip}
                                        onChange={value => this.onChangeClient('delivery_zip', value)}
                                        disabled={same_delivery}
                                    />
                                    <Select
                                        label={__('Krajina (dodanie)')}
                                        value={data.client.delivery_country}
                                        options={order.countries}
                                        onChange={value => this.onChangeClient('delivery_country', value)}
                                        disabled={same_delivery}
                                        allow_empty={false}
                                    />
                                    <Input
                                        label={__('Telefón (dodanie)')}
                                        value={data.client.delivery_phone}
                                        onChange={value => this.onChangeClient('delivery_phone', value)}
                                        disabled={same_delivery}
                                    />
                                    <Input
                                        label={__('Poznámka (dodanie)')}
                                        value={data.client.delivery_note}
                                        onChange={value => this.onChangeClient('delivery_note', value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="order-create__content__products">
                        <div className="order-create__content__products__title">{__('Produkty')}</div>
                        {_.map(data.products, (product, key) => {
                            const quantity = toNumber(product.quantity);
                            unit_price += toNumber(product.unit_price) * quantity;
                            total_price += toNumber(product.total_price) * quantity;

                            return (
                                <div className="order-create__content__products__product" key={key}>
                                    <Input
                                        label={__('Názov')}
                                        content={<Autocomplete
                                            options={order.products}
                                            getOptionLabel={option => option.data.name}
                                            renderOption={(option, state) => <div>{`${option.data.name} (${__('skladom')}: ${toNumber(option.data.stock)})`}</div>}
                                            onChange={(event, value) => this.onSelectProduct(key, value)}
                                            onInputChange={(event, value) => this.onChangeProduct(key, 'name', value)}
                                            renderInput={(params) => <TextField
                                                { ...params }
                                                placeholder={__('Začnite písať názov...')}
                                                variant="outlined"
                                            />}
                                            noOptionsText={__('Nenašiel sa žiadny produkt')}
                                            clearText={__('Zrušiť')}
                                            freeSolo
                                        />}
                                    />
                                    <Select
                                        label={__('Varianta')}
                                        value={product.variant}
                                        options={product.variants}
                                        onChange={value => this.onChangeProduct(key, 'variant', value)}
                                        allow_empty={false}
                                        disabled={_.isEmpty(product.variants)}
                                    />
                                    <Input
                                        label={__('Počet')}
                                        value={product.quantity}
                                        onChange={value => this.onChangeProduct(key, 'quantity', value)}
                                    />
                                    <Input
                                        label={__('Cena bez DPH')}
                                        value={product.unit_price}
                                        onChange={value => this.onChangeProduct(key, 'unit_price', value)}
                                    />
                                    <Input
                                        label={__('DPH')}
                                        value={product.tax}
                                        onChange={value => this.onChangeProduct(key, 'tax', value)}
                                    />
                                    <Input
                                        label={__('Cena s DPH')}
                                        value={product.total_price}
                                        onChange={value => this.onChangeProduct(key, 'total_price', value)}
                                    />
                                    <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="order-create__content__products__product__delete"
                                            disabled={key === 0}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                </div>
                            );
                        })}
                        <Button
                            onClick={() => this.addProduct()}
                            className="order-create__content__products__button"
                        >{__('Pridať produkt')}</Button>
                    </div>
                    <div className="order-create__content__summary">
                        <div className="order-create__content__summary__line">
                            <div className="order-create__content__summary__line__name">{__('Bez DPH')}</div>
                            <div className="order-create__content__summary__line__value">{formatAmount(unit_price, data.order.currency)}</div>
                        </div>
                        <div className="order-create__content__summary__line">
                            <div className="order-create__content__summary__line__name">{__('Celkom')}</div>
                            <div className="order-create__content__summary__line__value">{formatAmount(total_price, data.order.currency)}</div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="order-create__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
                {this.renderLightbox(
                    'sequences',
                    __('Číslovanie objednávok'),
                    !_.isEmpty(lightbox.sequences) ? <div className="lightbox-sequences">
                        <Message type="info">{__('Špeciálne slová - {YYYY} (aktuálny rok 4 čísla), {YY} (aktuálny rok 2 čísla), {NNN} (ľubovoľný počet čísiel poradia)')}</Message>
                        <div className="lightbox-sequences__panel">
                            <div className="lightbox-sequences__panel__title">{__('Manuálne objednávky')}</div>
                            <div className="lightbox-sequences__panel__inputs">
                                <Checkbox
                                    value={sequences.manual.active}
                                    onChange={checked => this.onChangeSequence('manual', 'active', checked)}
                                />
                                <Input
                                    label={__('Formát')}
                                    value={sequences.manual.mask}
                                    onChange={value => this.onChangeSequence('manual', 'mask', value)}
                                    placeholder="M{YY}-{NNNN}"
                                    disabled={!sequences.manual.active}
                                />
                                <Input
                                    label={__('Nasledujúce číslo')}
                                    value={sequences.manual.value}
                                    onChange={value => this.onChangeSequence('manual', 'value', value)}
                                    disabled={!sequences.manual.active}
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="lightbox-sequences__panel">
                            <div className="lightbox-sequences__panel__title">{__('Objednávky z eshopu')}</div>
                            <div className="lightbox-sequences__panel__inputs">
                                <Checkbox
                                    value={sequences.auto.active}
                                    onChange={checked => this.onChangeSequence('auto', 'active', checked)}
                                />
                                <Input
                                    label={__('Formát')}
                                    value={sequences.auto.mask}
                                    onChange={value => this.onChangeSequence('auto', 'mask', value)}
                                    placeholder="E{YY}-{NNNN}"
                                    disabled={!sequences.auto.active}
                                />
                                <Input
                                    label={__('Nasledujúce číslo')}
                                    value={sequences.auto.value}
                                    onChange={value => this.onChangeSequence('auto', 'value', value)}
                                    disabled={!sequences.auto.active}
                                    type="number"
                                />
                            </div>
                        </div>
                        <Button
                            onClick={() => this.saveSequences()}
                            loading={loading_sequences}
                            className="lightbox-sequences__button"
                        >{__('Uložiť')}</Button>
                    </div> : null,
                )}
            </Paper>
        );
    }
}

const stateToProps = ({ order, user }) => ({ order, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchCreateOrder,
    setUser,
    cleanOrder,
    addOrder,
})(OrderCreateScreen)));