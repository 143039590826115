import _ from 'lodash';
import { __, request } from '../functions';
import { FETCH_EMPLOYEES, CREATE_EMPLOYEE, DELETE_EMPLOYEE, CLEAN_EMPLOYEES, DELETE_EMPLOYEES } from './types';

/**
 * Nacitanie zamestnancov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchEmployees = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/user-employees/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_EMPLOYEES, payload: data });
    });
};

/**
 * Pridanie zamestnanca.
 *
 * @param {Screen} screen
 * @param {Object} data
 */
export const createEmployee = (screen, data) => {
    return dispatch => request('/user-employees/create', data, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading_create: false });

        if (status === 'error') {
            // Nepodarilo sa pridat zamestnanca
            screen.showSnackbar('error', __('Nepodarilo sa pridať zamestnanca'));
            return;
        }

        screen.showSnackbar('success', __('Zamestnanec bol vytvorený'));

        dispatch({ type: CREATE_EMPLOYEE });
    });
};

/**
 * Zmazanie zamestnanca.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteEmployee = (screen, id) => {
    return dispatch => request(`/user-employees/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Nepodarilo sa odstranit zamestnanca
            screen.showSnackbar('error', __('Nepodarilo sa zmazať zamestnanca'));
            return;
        }

        screen.showSnackbar('success', __('Zamestnanec bol zmazaný'));

        dispatch({ type: DELETE_EMPLOYEE, payload: id });
    });
};

/**
 * Zmazanie zamestnancov cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteEmployees = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/user-employees/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Zamestnancov sa nepodarilo zmazat
            screen.showSnackbar('error', __('Zamestnancov sa nepodarilo zmazať'));
            return;
        }

        screen.showSnackbar('success', __('Zamestnanci boli zmazaní'));

        dispatch({ type: DELETE_EMPLOYEES });
    });
};

/**
 * Vycistenie zamestnancov.
 */
export const cleanEmployees = () => ({ type: CLEAN_EMPLOYEES });