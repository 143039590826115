import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Button, Select, Input, Message } from '../components';
import { __, isEmptyString, request, toNumber } from '../functions';
import { COOKIE_SETTINGS, ESHOPS } from '../config';
import '../assets/styles/eshop-lightbox.css';

/**
 * Eshop lightbox.
 */
class EshopLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen
     *     eshops: Object,
     *     name: string,
     *     eshop_id: number|null,
     *     show_steps: boolean,
     *     step: number,
     *     change_eshop: boolean,
     *     afterSetEshopCredentials: function|null,
     * }}
     */
    static defaultProps = {
        screen: null,
        eshops: {},
        name: '',
        eshop_id: null,
        show_steps: true,
        step: 0,
        change_eshop: true,
        afterSetEshopCredentials: null,
    };

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     step: number,
     *     name: string,
     *     eshop_id: number|null,
     *     eshop_data: Object,
     *     eshop_credentials: Object,
     *     manual_fields: Object,
     *     use_create: boolean
     * }}
     */
    state = {
        loading: false,
        step: 0,
        name: '',
        eshop_id: null,
        eshop_data: {},
        eshop_credentials: {},
        manual_fields: {},
        use_create: false,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { eshops, step, name } = this.props;

        const eshop_id = this.props.eshop_id !== null ? this.props.eshop_id : _.keys(eshops)[0];

        this.setState({
            step,
            name,
            eshop_id,
            eshop_data: !_.isEmpty(eshops) ? this.getDefaultFields(eshops[eshop_id].fields) : {},
        });
    }

    /**
     * Event po zmene nazvu.
     *
     * @param {string} name
     */
    onChangeName(name) {
        this.setState({ name });
    }

    /**
     * Event po zmene eshopu.
     *
     * @param {number} value
     */
    onChangeEshop(value) {
        const { eshops } = this.props;

        this.setState({ eshop_id: value, eshop_data: this.getDefaultFields(eshops[value].fields) });
    }

    /**
     * Event po zmene fieldu.
     *
     * @param {string} field
     * @param {string} value
     */
    onChangeField(field, value) {
        const fields = this.state.eshop_data;

        this.setState({ eshop_data: { ...fields, [field]: value } });
    }

    /**
     * Vratime zoznam default fieldov.
     *
     * @param {Object} fields
     *
     * @return {Object}
     */
    getDefaultFields(fields) {
        return _.reduce(
            fields,
            (result, field, name) => ({ ...result, [name]: _.isObject(field) ? _.keys(field)[0] : '' }),
            {}
       );
    }

    /**
     * Dalsi krok.
     */
    nextStep() {
        const { name, eshop_id, eshop_data, manual_fields } = this.state;
        const { screen, afterSetEshopCredentials } = this.props;
        let { step, eshop_credentials } = this.state;

        this.setState({ loading: true });

        if (step === 0 && !_.isEmpty(manual_fields)) {
            // Sme na kroku 0 ale mame manual fields, chceme rovno create
            eshop_credentials = eshop_data;
            step = 1;
        }

        switch (step) {
            case 0:
            default:
                // Eshop
                const { eshop_data } = this.state;

                if (isEmptyString(name)) {
                    // Nazov nie je vyplneny
                    this.setState({ loading: false });
                    screen.showSnackbar('error', __('Názov musí byť vyplnený'));
                    return;
                }

                request(`/user-eshops/eshopCredentials/${eshop_id}`, eshop_data, 'POST').then(response => {
                    const { status, data } = response.data;

                    if (status === 'error') {
                        if (!_.isEmpty(data.manual_fields) && afterSetEshopCredentials === null) {
                            // Mame invalid fields, treba rucne zadat
                            const { eshop_data } = this.state;

                            this.setState({
                                loading: false,
                                manual_fields: data.manual_fields,
                                eshop_data: { ...eshop_data, ...data.manual_fields },
                            });
                            return;
                        }

                        this.setState({ loading: false });
                        screen.showSnackbar('error', data.message === 'invalid'
                            ? __('Nepodarilo sa prihlásiť do eshopu')
                            : __('Tieto prihlasovacie údaje už niekto používa')
                        );
                        return;
                    }

                    this.afterSetEshopCredentials(data.credentials);
                });
                break;

            case 1:
                // Hotovo
                request('/user-eshops/create', { name, eshop_id, eshop_credentials }, 'POST').then(response => {
                    const { status, data } = response.data;

                    this.setState({ loading: false, use_create: true });

                    if (status === 'error') {
                        // Nepodarilo sa vytvorit eshop
                        screen.showSnackbar('error', __('Nepodarilo sa vytvoriť eshop'));
                        return;
                    }

                    // Ulozime user eshop id
                    screen.props.cookies.set('user_eshop_id', toNumber(data.id), COOKIE_SETTINGS);

                    window.location = '/orders';
                });

                break;
        }
    }

    /**
     * Event po obdrzani eshop credentials.
     *
     * @param {object} credentials
     */
    afterSetEshopCredentials(credentials) {
        const { afterSetEshopCredentials } = this.props;

        if (afterSetEshopCredentials !== null) {
            // Mame zadany custom callback
            afterSetEshopCredentials(credentials);
            return;
        }

        this.setState({ loading: false, step: 1, eshop_credentials: credentials });
    }

    /**
     * Vratime text pre warning manual fields.
     *
     * @return {JSX.Element|string}
     */
    getManualFieldText() {
        const { eshop_id } = this.state;
        const { eshops } = this.props;

        const list = _.reduce(eshops, (result, { id, name }) => ({ ...result, [id]: name }), {});

        switch (list[eshop_id]) {
            case 'WooCommerce':
                return <div>
                    <span>{__('Nepodarilo sa predvyplniť API údaje, prosím zadajte ich manuálne')} - </span>
                    <a href="/help/0/1" target="_blank">{__('Návod')}</a>
                </div>;

            case 'BiznisWeb':
                return <div>
                    <span>{__('Nepodarilo sa predvyplniť API údaje, prosím zadajte ich manuálne')} - </span>
                    <a href="https://www.biznisweb.sk/a/1270/token-api" rel="noopener noreferrer" target="_blank">{__('Návod')}</a>
                </div>;

            default:
                return __('Nepodarilo sa predvyplniť údaje eshopu, prosím zadajte ich manuálne');
        }
    }

    /**
     * Rendrovanie fieldov.
     *
     * @param {Object} fields
     *
     * @return {JSX.Element[]}
     */
    renderFields(fields) {
        const { eshop_data, manual_fields } = this.state;

        const values = eshop_data;
        fields = { ...manual_fields, ...fields };

        if (!_.isEmpty(manual_fields)) {
            // Mame manual fields, netreba meno a heslo
            _.unset(fields, 'url');
            _.unset(fields, 'email');
            _.unset(fields, 'password');
        }

        return _.map(fields, (field, name) => {
            switch (name) {
                case 'video':
                    // Video navod
                    return (
                        <Message type="info">
                            <span>{__('Ak si neviete poradiť pri zadávaní parametrov eshopu, kliknite na')}</span>
                            <a
                                href={field}
                                target="_blank"
                                rel="noopener noreferrer"
                            >{__('video')}</a>
                        </Message>
                    );

                case 'url':
                    // Url adresa
                    return <Input
                        key={name}
                        label={__('Url adresa eshopu (https://obchod.sk)')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'version':
                    // Verzia
                    return <Select
                        key={name}
                        label={__('Verzia API')}
                        options={{ '2': __('Verzia 2'), '31': __('Verzia 3 - OAuth 1.0'), '3': __('Verzia 3') }}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                        allow_empty={false}
                    />;

                case 'consumer_key':
                    // Consumer key
                    return <Input
                        key={name}
                        label={__('Kľúč zákazníka (key)')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'consumer_secret':
                    // Consumer secret
                    return <Input
                        key={name}
                        label={__('Tajomstvo zákazníka (secret)')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'shopify_host':
                    // Shopify host
                    return <Input
                        key={name}
                        label={__('Názov eshopu (https://{nazov}.myshopify.com)')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'cscart_company_id':
                    // CS cart company id
                    return <Input
                        key={name}
                        label={__('Company id')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'apikey':
                    // Api kluc
                    return <Input
                        key={name}
                        label={__('API klúč')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'apitoken':
                case 'auth_code':
                    // Api token
                    return <Input
                        key={name}
                        label={__('API token')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'apipassword':
                    // Api password
                    return <Input
                        key={name}
                        label={__('API password')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'email':
                    // Email
                    return <Input
                        key={name}
                        label={__('Email / meno do administrácie eshopu')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                case 'password':
                    // Heslo
                    return <Input
                        type="password"
                        key={name}
                        label={__('Heslo do administrácie eshopu')}
                        value={values[name]}
                        onChange={value => this.onChangeField(name, value)}
                    />;

                default:
                    return null;
            }
        });
    }

    /**
     * Rendrovanie konkretneho kroku.
     *
     * @return {JSX.Element}
     */
    renderStep() {
        const { step, use_create } = this.state;

        switch (step) {
            case 0:
            default:
                // Eshopovy system
                const { eshop_id, name, manual_fields } = this.state;
                const { eshops, change_eshop } = this.props;

                return (
                    <div className="eshop-lightbox__step">
                        <Input
                            label={__('Interný názov')}
                            value={name}
                            onChange={value => this.onChangeName(value)}
                        />
                        {!_.isEmpty(manual_fields) && !use_create
                            ? <Message type="warning">{this.getManualFieldText()}</Message>
                            : null}
                        {!_.isEmpty(manual_fields) && _.has(manual_fields, 'version') && use_create
                            ? <Message type="warning">{__('Skúste zmeniť verziu API')}</Message>
                            : null}
                        {change_eshop && _.isEmpty(manual_fields) ? <Select
                            label={__('Eshopový systém')}
                            options={_.reduce(eshops, (result, { id, name }) => ({ ...result, [id]: ESHOPS[name] }), {})}
                            value={eshop_id}
                            onChange={value => this.onChangeEshop(value)}
                            allow_empty={false}
                        /> : null}
                        {this.renderFields(eshops[eshop_id].fields)}
                    </div>
                );

            case 1:
                // Hotovo
                return (
                    <div className="eshop-lightbox__step">
                        <Message type="success">{__('Prepojenie na váš eshop prebehlo v poriadku')}</Message>
                        <Message type="info">{__('Po uložený budete presmerovaný na zoznam objednávok kde sa vám začnú zobrazovať nové objednávky z eshopu od tohto momentu. Môžete si nastaviť prepojenie na ekonomický systém alebo prepravcov balíkov. Ak budete potrebovať pomôcť s nastavením tak nás určite kontaktujte.')}</Message>
                    </div>
                );
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { step, eshop_id, loading } = this.state;

        if (eshop_id === null) {
            return null;
        }

        const { show_steps } = this.props;

        return <div className="eshop-lightbox">
            {show_steps ? <Stepper activeStep={step} alternativeLabel>
                <Step><StepLabel>{__('Eshop')}</StepLabel></Step>
                <Step><StepLabel>{__('Hotovo!')}</StepLabel></Step>
            </Stepper> : null}
            {this.renderStep()}
            <Button
                onClick={this.nextStep.bind(this)}
                className="eshop-lightbox__button"
                loading={loading}
            >{__('Uložiť')}</Button>
        </div>;
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(EshopLightbox)));
