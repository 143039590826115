import { request, toNumber, __ } from '../functions';
import {
    FETCH_ESHOP,
    FETCH_ESHOP_DATA,
    CHANGE_ESHOP_AUTO_FIELD,
    CHANGE_ESHOP_SETTINGS,
} from './types';

/**
 * Nacitanie eshopu.
 *
 * @param {Screen} screen
 */
export const fetchEshop = screen => {
    return dispatch => request('/user-eshops/eshop').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_ESHOP, payload: data.data });
    });
};

/**
 * Nacitanie dat eshopu.
 *
 * @param {Screen} screen
 */
export const fetchEshopData = screen => {
    return dispatch => request('/user-eshops/eshopData').then(({ data }) => {
        screen.setState({ loading_data: false });
        screen.showSnackbar('success', __('Údaje z eshopu boli načítané'));

        dispatch({ type: FETCH_ESHOP_DATA, payload: data.data });
    });
};

/**
 * Zmenime auto field eshopu.
 *
 * @param {Screen} screen
 * @param {string} field
 * @param {boolean} value
 */
export const changeEshopAutoField = (screen, field, value) => {
    return dispatch => request(`/user-eshops/setEshopAuto/${field}/${toNumber(value)}`).then(({ data }) => {
        screen.showSnackbar('success', value ? __('Zapnuté') : __('Vypnuté'));

        dispatch({ type: CHANGE_ESHOP_AUTO_FIELD, payload: { field, value } });
    });
};

/**
 * Zmenime nastavenia eshopu.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeEshopSettings = (screen, settings) => {
    return dispatch => request('/user-eshops/changeEshopSettings', { settings }, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: CHANGE_ESHOP_SETTINGS, payload: settings });
    });
};