import _ from 'lodash';

/**
 * Je validne tel. cislo?
 *
 * @param {string} phone
 *
 * @returns {boolean}
 */
export const isValidPhone = phone => {
    // Odstranime z cisla vsetko okrem + a cisel
    phone = _.replace(_.toString(phone), /[^\\+\d]/g, '');

    // Spocitame dlzku cisla
    let length = phone.length;

    if (length === 13 || length === 14) {
        // Bud mame zadany format +421 alebo 00421
        const before = length === 13 ? phone.substr(1, 3) : phone.substr(2, 3);

        if (before !== '420' && before !== '421') {
            // Predvolba nie je validna
            return false;
        }

        // Vytiahneme klasicky format cisla za predvolbou
        phone = length === 13 ? phone.substr(4) : phone.substr(5);
        // Ak je to sk predvolba, doplnime nulu na zaciatok
        phone = before === '421' ? `0${phone}` : phone;
    }

    length = phone.length;

    if (length === 9 || length === 10) {
        if (length === 9) {
            // Ak ma cislo 9 znakov ide o cz
            return true;
        }

        // SK cislo je validne ak zacina predvolbou 09
        return true;
    }

    return false;
};