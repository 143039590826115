import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import NoteIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import { Button, Input, Screen, Select, Table, TableButton, Message } from '../components';
import { __, formatDate, isEmptyString, isValidEmail, request } from '../functions';
import { fetchTemplates, cleanTemplates, deleteTemplate, deleteTemplates, setUser } from '../actions';
import { TEMPLATES_EVENTS } from '../config';
import '../assets/styles/templates.css';

/**
 * Zoznam sablon.
 */
class TemplatesScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Emaily');

    /**
     * Default state.
     *
     * @type {{
     *     loading_smtp: boolean,
     *     loading_table_button: ?string,
     *     lightbox: {},
     * }}
     */
    state = {
        loading_smtp: false,
        loading_table_button: null,
        lightbox: {
            delete: false,
            multidelete: false,
            smtp: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { templates } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, templates.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { templates } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, templates.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     */
    fetch(page = 1, per_page = 0, filtered = {}) {
        const { fetchTemplates } = this.props;

        // Nacitame zoznam
        fetchTemplates(this, { page, per_page, filtered });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanTemplates } = this.props;

        // Vycistime zoznam
        cleanTemplates();
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Zmazanie sablony.
     */
    async delete() {
        const { deleteTemplate, templates } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme sablonu
        await deleteTemplate(this, lightbox.delete.id);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, templates.filtered);
    }

    /**
     * Zmazanie sablon cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteTemplates, templates } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme sablon
        await deleteTemplates(this, ids, templates.filtered);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, templates.filtered);
    }

    /**
     * Event po zmene SMTP.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSmtp(type, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, smtp: { ...lightbox.smtp, [type]: value } } });
    }

    /**
     * Nahratie loga.
     *
     * @param {Object} file
     */
    uploadLogo(file) {
        request('/user-eshops/logo', file, 'FILE').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa nahrat logo
                this.showSnackbar('error', __('Nepodarilo sa nahrať logo'));
                return;
            }

            // Refreshneme
            window.location = '/templates';
        });
    }

    /**
     * Ulozenie smtp.
     */
    saveSmtp() {
        const { lightbox } = this.state;

        let error = '';

        if (isEmptyString(lightbox.smtp.host)) {
            // Nie je zadany host
            error = __('Nie je zadaný server host');
        } else if (isEmptyString(lightbox.smtp.email)) {
            // Nie je zadany email
            error = __('Nie je zadaný email');
        } else if (isEmptyString(lightbox.smtp.password)) {
            // Nie je zadane heslo
            error = __('Nie je zadané heslo');
        } else if (isEmptyString(lightbox.smtp.name)) {
            // Nie je zadany odosielatel
            error = __('Nie je zadaný názov odosielateľa');
        } else if (!isEmptyString(lightbox.smtp.reply) && !isValidEmail(lightbox.smtp.reply)) {
            // Neplatne reply to
            error = __('Neplatný email pre odpoveď');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading_smtp: true });

        request('/templates/smtp', lightbox.smtp, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading_smtp: false });

            if (status === 'error') {
                // Neplatne udaje
                this.showSnackbar('error', data.message);
                return;
            }

            this.showSnackbar('success', __('Nastavenia boli uložené'));

            // Zavrieme lightbox
            this.closeLightbox('smtp');

            const { setUser, user } = this.props;

            // Prepiseme nastavenia smtp
            setUser({ ...user, settings: { ...user.settings, smtp: lightbox.smtp } });
        });
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { loading_table_button } = this.state;

        return _.map(items, (item) => {
            const { id, event, name, created } = item;

            return [
                name,
                TEMPLATES_EVENTS[event].name,
                formatDate(created, 'dd.mm.yyyy'),
                [
                    <TableButton
                        id={id}
                        key="note"
                        text={__('Upraviť')}
                        icon={<NoteIcon />}
                        onClick={() => this.redirect(`/template/${id}`)}
                        loading_button={loading_table_button}
                    />,
                    <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => this.confirmDelete(id, button_id)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, templates } = this.props;
        const { lightbox, loading_smtp } = this.state;

        if (_.isEmpty(templates)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Je aktivne smtp?
        const active_smtp = !_.isEmpty(user.settings.smtp);

        return super.render(
            <div className="templates">
                <Table
                    title={<div className="templates__title">
                        <Typography variant="h5">{__('Emaily')}</Typography>
                        <Chip
                            onClick={() => this.redirect('/template')}
                            label={__('Nový email')}
                            icon={<AddIcon />}
                            color="secondary"
                            disabled={!active_smtp}
                        />
                    </div>}
                    right_data={!active_smtp ? <Button
                        onClick={() => this.showLightbox('smtp', {
                            host: '',
                            email: '',
                            password: '',
                            port: '',
                            encryption: '',
                            name: templates.eshop_settings.name,
                            reply: templates.eshop_settings.email,
                        })}
                        color="green"
                    >{__('Aktivovať')}</Button> : (<div>
                        <Button
                            className="templates__upload"
                            color="shadow"
                        >
                            {templates.logo === ''
                                ? <div className="templates__upload__text">{__('Nahrať logo')}</div>
                                : <img src={templates.logo} alt="logo eshop" />}
                            <input type="file" onChange={event => this.uploadLogo(event.target.files[0])} />
                        </Button>
                        <Button
                            onClick={() => this.showLightbox('smtp', user.settings.smtp)}
                            color="primary"
                        >{__('Nastavenia SMTP')}</Button>
                    </div>)}
                    multiselect={[{
                        icon: <DeleteIcon />,
                        text: __('Zmazať'),
                        confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                            ids,
                            clearCallback,
                            loadingCallback,
                        }),
                    }]}
                    filtered={templates.filtered}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Názov'),
                        __('Event'),
                        __('Vytvorené'),
                        '',
                    ]}
                    data={this.formatData(templates.items)}
                    raw_data={templates.items}
                    count={templates.total}
                    page={templates.page}
                    per_pages={user.settings.per_pages}
                    per_page={templates.per_page}
                    empty_text={__('Zatiaľ nemáte žiadne emaily')}
                />
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento email?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené emaily?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                        lightbox.multidelete.ids,
                        lightbox.multidelete.clearCallback,
                        lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'smtp',
                    __('Nastavenia SMTP'),
                    !_.isEmpty(lightbox.smtp) ? <div className="lightbox-smtp">
                        <Message type="warning">{__('Pre správne pripojenie si skontrolujte u poskytovateľa SMTP či máte povolené prihlasovanie zo zahraničia.')}</Message>
                        <div className="lightbox-smtp__panels">
                            <div className="lightbox-smtp__panels__panel">
                                <Input
                                    label={__('Server host')}
                                    value={lightbox.smtp.host}
                                    onChange={value => this.onChangeSmtp('host', value)}
                                    placeholder="smtp.websupport.sk"
                                />
                                <Input
                                    label={__('Email')}
                                    value={lightbox.smtp.email}
                                    onChange={value => this.onChangeSmtp('email', value)}
                                    placeholder="info@eshop.sk"
                                />
                                <Input
                                    label={__('Heslo')}
                                    value={lightbox.smtp.password}
                                    onChange={value => this.onChangeSmtp('password', value)}
                                    type="password"
                                />
                                <Select
                                    label={__('Zabezpečenie pripojenia')}
                                    value={lightbox.smtp.encryption}
                                    options={{ 'tls': 'TLS', 'ssl': 'SSL' }}
                                    onChange={value => this.onChangeSmtp('encryption', value)}
                                />
                                <Input
                                    label={__('Port')}
                                    value={lightbox.smtp.port}
                                    onChange={value => this.onChangeSmtp('port', value)}
                                    placeholder={__('Doplnené podľa zabezpečenia pripojenia')}
                                />
                            </div>
                            <div className="lightbox-smtp__panels__panel">
                                <Input
                                    label={__('Názov odosielateľa')}
                                    value={lightbox.smtp.name}
                                    onChange={value => this.onChangeSmtp('name', value)}
                                    maxLength={20}
                                    placeholder="Eshop.sk"
                                />
                                <Input
                                    label={__('Email na ktorý príde odpoveď (reply to)')}
                                    value={lightbox.smtp.reply}
                                    onChange={value => this.onChangeSmtp('reply', value)}
                                    placeholder="info@eshop.sk"
                                />
                            </div>
                        </div>
                        <Button
                            onClick={() => this.saveSmtp()}
                            className="lightbox-smtp__button"
                            loading={loading_smtp}
                        >{__('Uložiť')}</Button>
                    </div> : null
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, templates }) => ({ user, templates });

export default withCookies(withRouter(connect(stateToProps, {
    fetchTemplates,
    cleanTemplates,
    deleteTemplate,
    deleteTemplates,
    setUser,
})(TemplatesScreen)));