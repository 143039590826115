/*import _ from 'lodash';
import sk from '../translations/sk.json';
import cz from '../translations/cz.json';
import en from '../translations/en.json';

// Zformatujeme preklady na key -> value
const langs = {
    sk: _.reduce(sk, (result, { key, value }) => ({ ...result, [key]: value }), {}),
    cz: _.reduce(cz, (result, { key, value }) => ({ ...result, [key]: value }), {}),
    en: _.reduce(en, (result, { key, value }) => ({ ...result, [key]: value }), {})
};*/

/**
 * Nacitame preklad.
 *
 * @param {string} word
 * @param {string} type
 *
 * @returns {string}
 */
export const __ = (word, type = '') => {
    //word = _.has(langs[lang], word) && !_.isEmpty(langs[lang][word]) ? langs[lang][word] : word;

    switch (type) {
        case 'l':
            return word.toLowerCase();

        case 'u':
            return word.toUpperCase();

        default:
            return word;
    }
};