import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import HelpIcon from '@material-ui/icons/Help';
import ActivatedIcon from '@material-ui/icons/CheckCircle';
import { Screen, Message } from '../components';
import { __, toNumber } from '../functions';
import { fetchFeeds, createFeed, setUser } from '../actions';
import { FEEDS, IMAGES } from '../config';
import '../assets/styles/feeds.css';

/**
 * Feedy.
 */
class FeedsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('XML feedy');

    /**
     * Default state.
     *
     * @type {{
     *     loading_feed: number,
     * }}
     */
    state = {
        loading_feed: 0,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchFeeds } = this.props;

            fetchFeeds(this);
        }

        return true;
    }

    /**
     * Event po kliku na feed.
     *
     * @param {string} link
     */
    onClickFeed(link) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(link);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Pridame novy feed.
     *
     * @param {number} id
     */
    add(id) {
        const { createFeed } = this.props;

        this.setState({ loading_feed: id });

        // Vytvorime feed
        createFeed(this, id);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { feeds } = this.props;
        const { loading_feed } = this.state;

        if (_.isEmpty(feeds)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <Paper className="feeds" elevation={0}>
                <Toolbar className="feeds__header">
                    <div className="feeds__header__left">
                        <Typography className="feeds__header__title" variant="h5">{__('XML feedy')}</Typography>
                    </div>
                </Toolbar>
                <div className="feeds__content">
                    <Message type="info">
                        {__('Po kliku na konkrétny feed ho aktivujete. Po ďalšom kliknutí skopírujete url adresu feedu do schránky')}
                    </Message>
                    <div className="feeds__content__items">
                        {_.map(feeds.feeds, (name, id) => {
                            id = toNumber(id);

                            const activated = _.includes(_.map(_.keys(feeds.user_feeds), id => toNumber(id)), id);
                            const loaded = id === loading_feed;
                            const url = activated ? feeds.url.replace('{name}', feeds.user_feeds[id]) : '';
                            let activated_text = __('kliknutím skopírujete do schránky url adresu feedu');

                            if (name === 'Heureka') {
                                // Pre heureku doplnime info o dostupnostnom feede
                                activated_text = `${activated_text}. ${__('Dostupnostný feed sa nachádza na rovnakej url adrese s prívlastkom "-available" na konci. Prik. {url}-available')}`;
                            }

                            return (
                                <Paper
                                    className="feeds__content__items__item"
                                    elevation={1}
                                    key={id}
                                >
                                    {!loaded ? <Tooltip title={`${FEEDS[name]} - ${activated
                                        ? activated_text
                                        : __('kliknutím aktivujete')
                                    }`}>
                                        <ButtonBase
                                            onClick={activated ? () => this.onClickFeed(url) : () => this.add(id)}
                                            focusRipple
                                            className="feeds__content__items__item__button"
                                        >
                                            <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                            {activated
                                                ? <ActivatedIcon
                                                    className="feeds__content__items__item__button__icon"
                                                    color="secondary"
                                                />
                                                : null
                                            }
                                        </ButtonBase>
                                    </Tooltip> : <CircularProgress color="primary" size={30} />}
                                </Paper>
                            );
                        })}
                        <Paper className="feeds__content__items__item" elevation={1}>
                            <Tooltip title={__('Nevidíte tu svoj feed? Kontaktujte nás a zvážime jeho zapracovanie do systému.')}>
                                <ButtonBase
                                    focusRipple
                                    className="feeds__content__items__item__button"
                                >
                                    <HelpIcon
                                        className="feeds__content__items__item__button__help"
                                        color="primary"
                                    />
                                </ButtonBase>
                            </Tooltip>
                        </Paper>
                    </div>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ feeds, user }) => ({ feeds, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchFeeds,
    createFeed,
    setUser,
})(FeedsScreen)));