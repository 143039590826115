import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import NoteIcon from '@material-ui/icons/Create';
import ViewIcon from '@material-ui/icons/Folder';
import MallIcon from '@material-ui/icons/AddCircle';
import ProductLightbox from '../lightboxes/ProductLightbox';
import { Screen, Table, TableButton, Input } from '../components';
import { __, formatAmount, formatDate } from '../functions';
import { fetchProducts, cleanProducts, changeProductStock, changeProductMall, setUser } from '../actions';
import '../assets/styles/products.css';

/**
 * Zoznam produktov.
 */
class ProductsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Produkty');

    /**
     * Default state.
     *
     * @type {{
     *     loading_table_button: ?string,
     *     lightbox: {},
     * }}
     */
    state = {
        loading_table_button: null,
        lightbox: {
            view: false,
            stock: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();

            if (_.has(this.props.match.params, 'id')) {
                // View
                this.view(this.props.match.params.id);
            }
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { products } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, products.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { products } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, products.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     */
    fetch(page = 1, per_page = 0, filtered = {}) {
        const { fetchProducts } = this.props;

        // Nacitame zoznam
        fetchProducts(this, { page, per_page, filtered });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanProducts } = this.props;

        // Vycistime zoznam
        cleanProducts();
    }

    /**
     * Zobrazime lightbox na sklade.
     *
     * @param {number} id
     * @param {number} button_id
     * @param {number} stock
     */
    showStock(id, button_id, stock) {
        this.showLightbox('stock', { id, button_id, stock });
    }

    /**
     * Event po zmene stavu na sklade.
     *
     * @param {string} stock
     */
    onChangeStock(stock) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, stock: { ...lightbox.stock, stock } } })
    }

    /**
     * Ulozime stav na sklade.
     */
    saveStock() {
        const { changeProductStock } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.stock.button_id });

        // Zmenime stav na sklade
        changeProductStock(this, lightbox.stock.id, lightbox.stock.stock);
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Zobrazime view produktu.
     *
     * @param {number} id
     */
    view(id) {
        this.showLightbox('view', { id });
    }

    /**
     * Zobrazit/zrusit na mall.
     *
     * @param {number} id
     * @param {number} button_id
     * @param {boolean} show
     */
    mall(id, button_id, show) {
        const { changeProductMall } = this.props;

        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        // Zmenime produkt na mall
        changeProductMall(this, id, show);
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { user, products } = this.props;
        const { loading_table_button } = this.state;

        return _.map(items, ({ id, name, data, modified }) => {
            const activeMall = _.has(data.settings, 'mall');

            return [
                <Link to="#" onClick={() => this.view(id)}>{name}</Link>,
                formatAmount(data.total_price, user.settings.currency),
                `${data.number !== '' ? data.number : '-'} / ${data.ean !== '' ? data.ean : '-'}`,
                formatAmount(data.stock, '', 0),
                formatDate(modified, 'dd.mm.yyyy hh:ii'),
                [
                    products.mall_active ? <TableButton
                        id={id}
                        key="mall"
                        text={activeMall ? __('Zrušiť na mall') : __('Pridať na mall')}
                        icon={<MallIcon color={activeMall ? 'secondary' : 'inherit'} />}
                        onClick={button_id => this.mall(id, button_id, !activeMall)}
                        loading_button={loading_table_button}
                    /> : null,
                    <TableButton
                        id={id}
                        key="view"
                        text={__('Zobraziť detail')}
                        icon={<ViewIcon />}
                        onClick={() => this.view(id)}
                        loading_button={loading_table_button}
                    />,
                    <TableButton
                        id={id}
                        key="stock"
                        text={__('Zmeniť stav na sklade')}
                        icon={<NoteIcon />}
                        onClick={button_id => this.showStock(id, button_id, data.stock)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, products } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(products)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const synchronized = products.init_sync
            ? __('Prebieha import produktov z eshopu.')
            : formatDate(products.synchronized, __('Synchronizované s eshopom o dd.mm.yyyy hh:ii'));

        const title = (
            <div className="products__title">
                <Typography variant="h5">{__('Produkty')}</Typography>
                <Chip label={synchronized} className={products.init_sync ? 'waiting' : ''} />
            </div>
        );

        return super.render(
            <div className="products">
                <Table
                    title={title}
                    multiselect={[null]}
                    filter={this.formatFilter(products.filter)}
                    filtered={products.filtered}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Názov'),
                        __('Cena'),
                        __('Číslo produktu / EAN'),
                        __('Stav na sklade'),
                        __('Posledná zmena'),
                        '',
                    ]}
                    data={this.formatData(products.items)}
                    raw_data={products.items}
                    count={products.total}
                    page={products.page}
                    per_pages={user.settings.per_pages}
                    per_page={products.per_page}
                    empty_text={__('Zatiaľ nemáte žiadne produkty')}
                />
                {!_.isEmpty(lightbox.view) ? this.renderLightbox(
                    'view',
                    __('Produkt'),
                    <ProductLightbox
                        screen={this}
                        id={lightbox.view.id}
                    />,
                ) : null}
                {this.renderLightbox(
                    'stock',
                    __('Stav na sklade'),
                    !_.isEmpty(lightbox.stock) ? <div className="lightbox-stock">
                        <Input
                            label={__('Počet')}
                            value={lightbox.stock.stock}
                            onChange={value => this.onChangeStock(value)}
                            type="number"
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    this.saveStock.bind(this)
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, products }) => ({ user, products });

export default withCookies(withRouter(connect(stateToProps, {
    fetchProducts,
    cleanProducts,
    changeProductStock,
    changeProductMall,
    setUser,
})(ProductsScreen)));
