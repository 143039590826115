import { request } from '../functions';
import { FETCH_ADMIN, FETCH_ADMIN_PROCESS, FETCH_ADMIN_ERROR } from './types';

/**
 * Nacitanie adminu.
 *
 * @param {Screen} screen
 */
export const fetchAdmin = screen => {
    return dispatch => request('/users/adminDashboard').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_ADMIN, payload: data.data });
    });
};

/**
 * Nacitanie admin procesu.
 *
 * @param {Screen} screen
 * @param {string} name
 * @param {string} date
 */
export const fetchAdminProcess = (screen, name, date) => {
    return dispatch => request(`/users/adminProcess/${name}/${date}`).then(({ data }) => {
        dispatch({ type: FETCH_ADMIN_PROCESS, payload: data.data });
    });
};

/**
 * Nacitanie admin erroru.
 *
 * @param {Screen} screen
 * @param {string} name
 * @param {string} date
 */
export const fetchAdminError = (screen, name, date) => {
    return dispatch => request(`/users/adminError/${name}/${date}`).then(({ data }) => {
        dispatch({ type: FETCH_ADMIN_ERROR, payload: data.data });
    });
};