import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import OrdersIcon from '@material-ui/icons/ShoppingCart';
import ClientsIcon from '@material-ui/icons/People';
import InvoicesIcon from '@material-ui/icons/Description';
import CouriersIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import PaymentsIcon from '@material-ui/icons/Payment';
import LabelsIcon from '@material-ui/icons/Print';
import GatewaysIcon from '@material-ui/icons/Contactless';
import StateIcon from '@material-ui/icons/Bookmark';
import AddonsIcon from '@material-ui/icons/Extension';
import StorageIcon from '@material-ui/icons/Store';
import BankIcon from '@material-ui/icons/AccountBalanceWallet';
import ExportsIcon from '@material-ui/icons/GetApp';
import TemplatesIcon from '@material-ui/icons/Email';
import ProductsIcon from '@material-ui/icons/Storefront';
import { Logo } from './Logo';
import { __, request, getCustomizationKey } from '../functions';
import { changeUserSetting } from '../actions';
import { IMAGES } from '../config';
import '../assets/styles/menu.css';

/**
 * Menu komponenta.
 */
class Menu extends Component {

    /**
     * Otvorime menu.
     */
    open() {
        this.changeMenuSetting('full');
    }

    /**
     * Zatvorime menu.
     */
    close() {
        this.changeMenuSetting('mini');
    }

    /**
     * Zmenime menu setting.
     *
     * @param {string} value
     */
    changeMenuSetting(value) {
        const { changeUserSetting } = this.props;

        // Zmenime nastavenie
        changeUserSetting('menu', value);

        // Ulozime nastavenie
        request('/user-eshops/changeSetting', { menu: value }).then(response => {});
    }

    /**
     * Rendrujeme button.
     *
     * @param {string} uri
     * @param {JSX.Element} icon
     * @param {string} text
     *
     * @returns {JSX.Element}
     */
    renderButton(uri, icon, text) {
        const { user } = this.props;

        return (
            <Link to={uri}>
                <ListItem className={`menu__button ${uri === this.props.location.pathname ? 'active' : ''}`} button>
                    <Tooltip title={user.settings.menu !== 'full' ? text : ''}>
                        <ListItemIcon>{icon}</ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={text} />
                </ListItem>
            </Link>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user } = this.props;

        if (_.isEmpty(user)) {
            // Nie sme prihlaseny
            return null;
        }

        const opened = user.settings.menu !== 'mini';

        return (
            <Drawer
                variant="permanent"
                className={`menu ${!opened ? 'closed' : ''} ${getCustomizationKey()}`}
            >
                <div className="menu__logo">
                    <Logo color="white" />
                    <Tooltip title={opened ? __('Schovať menu') : __('Zobraziť menu')}>
                        <IconButton
                            className="menu__logo__button"
                            color="inherit"
                            onClick={opened ? this.close.bind(this) : this.open.bind(this)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <List>
                    {this.renderButton('/dashboard', <HomeIcon />, __('Dashboard'))}
                    {opened ? <div className="menu__divider">{__('Eshop', 'u')}</div> : null}
                    {this.renderButton('/orders', <OrdersIcon />, __('Objednávky'))}
                    {this.renderButton('/clients', <ClientsIcon />, __('Zákaznici'))}
                    {this.renderButton('/products', <ProductsIcon />, __('Produkty'))}
                    {this.renderButton('/eshop', <SettingsIcon />, __('Nastavenie'))}
                    {opened ? <div className="menu__divider">{__('Ekonomický systém', 'u')}</div> : null}
                    {this.renderButton('/invoices', <InvoicesIcon />, __('Faktúry'))}
                    {this.renderButton('/payments', <PaymentsIcon />, __('Platby'))}
                    {this.renderButton('/accounting', <SettingsIcon />, __('Nastavenie'))}
                    {opened ? <div className="menu__divider">{__('Služby', 'u')}</div> : null}
                    {this.renderButton('/couriers', <CouriersIcon />, __('Prepravcovia'))}
                    {this.renderButton('/labels', <LabelsIcon />, __('Štítky'))}
                    {this.renderButton('/storage', <StorageIcon />, __('Sklad'))}
                    {this.renderButton('/banks', <BankIcon />, __('Banka'))}
                    {this.renderButton('/gateways', <GatewaysIcon />, __('Platobné brány'))}
                    {this.renderButton('/exports', <ExportsIcon />, __('Exporty'))}
                    {this.renderButton('/templates', <TemplatesIcon />, __('Emaily'))}
                    {this.renderButton('/states', <StateIcon />, __('Stav objednávky'))}
                    {this.renderButton('/heureka', <img src={IMAGES['heureka-icon.png']} alt="heureka" />, __('Heureka košík'))}
                    {this.renderButton('/addons', <AddonsIcon />, __('Doplnky'))}
                </List>
            </Drawer>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps, { changeUserSetting })(Menu)));
