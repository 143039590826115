import { __, request, toNumber } from '../functions';
import { FETCH_COURIERS, CHANGE_COURIERS_AUTO_FIELD, CHANGE_COURIERS_SETTINGS } from './types';

/**
 * Nacitanie kurierov.
 *
 * @param {Screen} screen
 */
export const fetchCouriers = screen => {
    return dispatch => request('/user-couriers/dashboard').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_COURIERS, payload: data.data });
    });
};

/**
 * Zmenime auto field kurierov.
 *
 * @param {Screen} screen
 * @param {string} field
 * @param {boolean} value
 */
export const changeCouriersAutoField = (screen, field, value) => {
    return dispatch => request(`/user-eshops/setCourierAuto/${field}/${toNumber(value)}`).then(({ data }) => {
        screen.showSnackbar('success', value ? __('Zapnuté') : __('Vypnuté'));

        dispatch({ type: CHANGE_COURIERS_AUTO_FIELD, payload: { field, value } });
    });
};

/**
 * Zmenime nastavenia kurierov.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeCouriersSettings = (screen, settings) => {
    return dispatch => request('/user-eshops/changeCourierSettings', { settings }, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: CHANGE_COURIERS_SETTINGS, payload: settings });
    });
};