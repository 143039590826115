import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import HelpIcon from '@material-ui/icons/Help';
import ActivatedIcon from '@material-ui/icons/CheckCircle';
import { Screen, Message } from '../components';
import { __, toNumber } from '../functions';
import { fetchAddons, createAddon, deleteAddon, setUser } from '../actions';
import { ADDONS, IMAGES } from '../config';
import '../assets/styles/addons.css';

/**
 * Doplnky.
 */
class AddonsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Doplnky');

    /**
     * Default state.
     *
     * @type {{
     *     loading_addon: number,
     * }}
     */
    state = {
        loading_addon: 0,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchAddons } = this.props;

            fetchAddons(this);
        }

        return true;
    }

    /**
     * Pridame novy addon.
     *
     * @param {number} id
     */
    add(id) {
        const { createAddon } = this.props;

        this.setState({ loading_addon: id });

        // Vytvorime addon
        createAddon(this, id);
    }

    /**
     * Pridame novy addon.
     *
     * @param {number} id
     * @param {number} addon_id
     */
    delete(id, addon_id) {
        const { deleteAddon } = this.props;

        this.setState({ loading_addon: addon_id });

        // Zmazeme addon
        deleteAddon(this, id);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { addons } = this.props;
        const { loading_addon } = this.state;

        if (_.isEmpty(addons)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <Paper className="addons" elevation={0}>
                <Toolbar className="addons__header">
                    <div className="addons__header__left">
                        <Typography className="addons__header__title" variant="h5">{__('Doplnky')}</Typography>
                    </div>
                </Toolbar>
                <div className="addons__content">
                    <Message type="info">
                        {__('Po kliku na konkrétny doplnok ho aktivujete.')}
                    </Message>
                    <div className="addons__content__items">
                        {_.map(addons.addons, (name, id) => {
                            id = toNumber(id);
                            let item_id = 0;

                            _.each(addons.user_addons, (addon_id, key) => {
                                if (toNumber(addon_id) === id) {
                                    // Nasli sme addon
                                    item_id = key;
                                }
                            });

                            const activated = toNumber(item_id) > 0;

                            const loaded = id === loading_addon;
                            return (
                                <Paper
                                    className="addons__content__items__item"
                                    elevation={1}
                                    key={id}
                                >
                                    {!loaded ? <Tooltip title={`${ADDONS[name]} - ${activated
                                        ? __('kliknutím deaktivujete')
                                        : __('kliknutím aktivujete')
                                    }`}>
                                        <ButtonBase
                                            onClick={activated ? () => this.delete(item_id, id) : () => this.add(id)}
                                            focusRipple
                                            className="addons__content__items__item__button"
                                        >
                                            <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                            {activated
                                                ? <ActivatedIcon
                                                    className="addons__content__items__item__button__icon"
                                                    color="secondary"
                                                />
                                                : null
                                            }
                                        </ButtonBase>
                                    </Tooltip> : <CircularProgress color="primary" size={30} />}
                                </Paper>
                            );
                        })}
                        <Paper className="addons__content__items__item" elevation={1}>
                            <Tooltip title={__('Nevidíte tu službu ktorú používate vo vašom eshope? Kontaktujte nás a zvážime jej zapracovanie do systému.')}>
                                <ButtonBase
                                    focusRipple
                                    className="addons__content__items__item__button"
                                >
                                    <HelpIcon
                                        className="addons__content__items__item__button__help"
                                        color="primary"
                                    />
                                </ButtonBase>
                            </Tooltip>
                        </Paper>
                    </div>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ addons, user }) => ({ addons, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchAddons,
    createAddon,
    deleteAddon,
    setUser,
})(AddonsScreen)));