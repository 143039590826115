import { __, request } from '../functions';
import { FETCH_SUBSCRIPTION, CHANGE_SUBSCRIPTION_SETTINGS } from './types';

/**
 * Nacitanie clenstva.
 *
 * @param {Screen} screen
 */
export const fetchSubscription = screen => {
    return dispatch => request('/subscriptions').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_SUBSCRIPTION, payload: data.data });
    });
};

/**
 * Zmenime fakturacne udaje.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeSubscriptionSettings = (screen, settings) => {
    return dispatch => request('/subscriptions/change-settings', { settings }, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading_settings: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť fakturačné údaje'));
            return;
        }

        screen.showSnackbar('success', __('Fakturačné údaje boli zmenené'));

        dispatch({ type: CHANGE_SUBSCRIPTION_SETTINGS, payload: settings });
    });
};