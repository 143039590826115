import { request, __ } from '../functions';
import { FETCH_HEUREKA, CHANGE_HEUREKA_SETTINGS, FETCH_HEUREKA_DATA } from './types';

/**
 * Nacitanie heureka dat.
 *
 * @param {Screen} screen
 */
export const fetchHeureka = screen => {
    return dispatch => request('/user-services/dashboard', { service: 'Heureka' }).then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_HEUREKA, payload: data.data });
    });
};

/**
 * Nacitanie dat heureky.
 *
 * @param {Screen} screen
 */
export const fetchHeurekaData = screen => {
    return dispatch => request('/user-services/data', { service: 'Heureka' }).then(({ data }) => {
        screen.setState({ loading_data: false });
        screen.showSnackbar('success', __('Údaje z heuréky boli načítané'));

        dispatch({ type: FETCH_HEUREKA_DATA, payload: data.data });
    });
};

/**
 * Zmenime nastavenia heureky.
 *
 * @param {Screen} screen
 * @param {Object} settings
 */
export const changeHeurekaSettings = (screen, settings) => {
    return dispatch => request('/user-services/changeSettings', { settings }, 'POST', { service: 'Heureka' }).then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: CHANGE_HEUREKA_SETTINGS, payload: settings });
    });
};