import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import HelpIcon from '@material-ui/icons/Help';
import UploadIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Input, Message, Screen, Select, Checkbox } from '../components';
import { __, isEmptyString, request } from '../functions';
import { fetchTemplate, cleanTemplate, createTemplate, setUser } from '../actions';
import { PLACEHOLDERS, ADDITIONAL_PLACEHOLDERS, TEMPLATES_EVENTS, EMAIL_PLACEHOLDERS } from '../config';
import '../assets/styles/template.css';

/**
 * Vytvorenie/edit sablony.
 */
class TemplateScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nový email');

    /**
     * Default state.
     *
     * @type {{
     *     data: Object,
     *     loading: boolean,
     *     loadingUpload: boolean,
     * }}
     */
    state = {
        data: {},
        loading: false,
        loadingUpload: false,
    };

    /**
     * Default nastavenia.
     *
     * @type {{
     *     manual: boolean,
     * }}
     */
    defaultSettings = {
        manual: true,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchTemplate, match } = this.props;

            // Ide o edit?
            const is_edit = _.has(match.params, 'id');

            // Nasetujeme title
            this.setTitle(is_edit ? __('Úprava emailu') : __('Nový email'));

            await fetchTemplate(this, is_edit ? match.params.id : 0);

            const { template } = this.props;
            const default_event = _.keys(TEMPLATES_EVENTS)[0];

            let data = {
                name: '',
                event: default_event,
                subject: '',
                text: TEMPLATES_EVENTS[default_event].text,
                settings: TEMPLATES_EVENTS[default_event].settings,
            };

            if (!_.isEmpty(template.template)) {
                // Mame data z editu
                data = {
                    name: template.template.name,
                    event: template.template.event,
                    subject: template.template.subject,
                    text: template.template.text,
                    settings: template.template.settings,
                };
            }

            data = { ...data, text: this.getEditorState(data.text) };

            if (!_.has(data.settings, 'manual')) {
                // Sablona nema default nastavenie
                data = { ...data, settings: { ...data.settings, ...this.defaultSettings } };
            }

            this.setState({ data });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanTemplate } = this.props;

        cleanTemplate();
    }

    /**
     * Vratime editor state na zaklade textu.
     *
     * @param {string} text
     *
     * @return {EditorState}
     */
    getEditorState(text) {
        const blocksFromHtml = htmlToDraft(text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        return EditorState.createWithContent(contentState);
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        // Prekonvertujeme text na html
        const text = draftToHtml(convertToRaw(data.text.getCurrentContent()))

        if (isEmptyString(data.name)) {
            // Nie je vyplneny nazov
            error = __('Nie je vyplnený názov');
        } else if (isEmptyString(data.subject)) {
            // Nie je vyplneny predmet
            error = __('Nie je vyplnený predmet');
        } else if (isEmptyString(text)) {
            // Nie je vyplneny text
            error = __('Nie je vyplnená šablóna');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createTemplate, match } = this.props;

        // Ulozime sablonu
        createTemplate(this, {
            ...data,
            id: _.has(match.params, 'id') ? match.params.id : 0,
            text,
        });
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { data } = this.state;

        let additional = {};

        if (type === 'event') {
            // Menime event, nastavime settings
            additional = {
                text: this.getEditorState(TEMPLATES_EVENTS[value].text),
                settings: { ...TEMPLATES_EVENTS[value].settings, ...this.defaultSettings }
            };

            if (_.has(additional.settings, 'state')) {
                // Mame stav objednavky, nasetujeme default
                const { template } = this.props;

                additional = { ...additional, settings: { ...additional.settings, state: template.eshop_data.states[0].id } };
            }
        }

        this.setState({ data: { ...data, [type]: value, ...additional } });
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {string} type
     * @param {string|boolean} value
     */
    onChangeSetting(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, settings: { ...data.settings, [type]: value } } });
    }

    /**
     * Event po kliku na placeholder.
     *
     * @param {string} placeholder
     */
    onClickPlaceholder(placeholder) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(placeholder);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Event po uploade prilohy.
     *
     * @param {Object} file
     */
    uploadAttachment(file) {
        if (file.type !== 'application/pdf') {
            // Nie je to pdf
            this.showSnackbar('error', __('Neplatný typ súboru'));
            return;
        }

        if (file.size > 5242880) {
            // Velky subor
            this.showSnackbar('error', __('Súbor je väčší ako 5MB'));
            return;
        }

        this.setState({ loadingUpload: true });

        request('/templates/uploadAttachment', file, 'FILE').then(response => {
            const { status } = response.data;

            this.setState({ loadingUpload: false });

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa nahrať prílohu'));
                return;
            }

            // Nastavime prilohu
            this.onChangeSetting('attachment', file.name);
        });
    }

    /**
     * Zmazanie prilohy.
     *
     * @param {string} name
     */
    deleteAttachment(name) {
        this.setState({ loadingUpload: true });

        request('/templates/deleteAttachment', { name }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loadingUpload: false });

            if (status === 'error') {
                // Nepodarilo sa zmazat prilohu
                this.showSnackbar('error', __('Nepodarilo sa zmazať prílohu'));
                return;
            }

            // Zrusime prilohu
            this.onChangeSetting('attachment', '');
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { match, template } = this.props;
        const { data, loading, loadingUpload } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Vytiahneme zoznam stavov
        const states = _.reduce(template.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme typy uhrad
        const payment_types = _.reduce(template.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme typy dodani
        const delivery_types = _.reduce(template.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Je to edit?
        const is_edit = _.has(match.params, 'id');

        // Vytiahneme settingy ktore zobrazujeme
        const show_settings = _.reduce(data.settings, (result, value, key) => {
            if (_.includes(['state', 'payment_type', 'delivery_type', 'attachment'], key)) {
                // Nechceme zobrazovat
                return result;
            }

            return { ...result, [key]: value };
        }, {});

        // Priloha
        const attachment = _.has(data.settings, 'attachment') ? data.settings.attachment : null;

        return super.render(
            <Paper className="template" elevation={0}>
                <Toolbar className="template__header">
                    <div className="template__header__left">
                        <Typography className="template__header__title" variant="h5">
                            {is_edit ? __('Úprava emailu') : __('Nový email')}
                        </Typography>
                    </div>
                </Toolbar>
                <div className="template__content">
                    <Message type="info">{__('Do emailu môžete vkladať špecialné slova ktoré sa pri odoslaní automaticky doplnia podľa objednávky.')}</Message>
                    <div className="template__content__panels">
                        <div className="template__content__panels__panel">
                            <div className="template__content__panels__panel__title">{__('Špeciálne slová')}</div>
                            <div className="template__content__panels__panel__text">{__('Kliknutím skopírujete do schránky.')}</div>
                            <div className="template__content__panels__panel__placeholders">
                                {_.map({ ...PLACEHOLDERS, ...ADDITIONAL_PLACEHOLDERS, ...EMAIL_PLACEHOLDERS }, (text, placeholder) => {
                                    if (_.includes(['{order.payment_type}', '{order.delivery_type}'], placeholder)) {
                                        // Nechceme zobrazovat
                                        return null;
                                    }

                                    return (
                                        <div key={placeholder}>
                                            <Tooltip title={text}>
                                                <Chip
                                                    onClick={() => this.onClickPlaceholder(placeholder)}
                                                    label={placeholder}
                                                    clickable
                                                    color="primary"
                                                    deleteIcon={<HelpIcon />}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="template__content__panels__panel">
                            <Input
                                label={__('Názov')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                            />
                            <Select
                                label={__('Udalosť')}
                                value={data.event}
                                onChange={value => this.onChange('event', value)}
                                options={_.reduce(TEMPLATES_EVENTS, (result, event, key) => ({ ...result, [key]: event.name }), {})}
                                allow_empty={false}
                            />
                            {_.has(data.settings, 'state') ? <Select
                                label={__('Stav objednávky')}
                                options={states}
                                value={data.settings.state}
                                onChange={value => this.onChangeSetting('state', value)}
                                allow_empty={false}
                            /> : null}
                            {_.has(data.settings, 'payment_type') ? <Select
                                label={__('Typ platby objednávky')}
                                options={payment_types}
                                value={data.settings.payment_type}
                                onChange={value => this.onChangeSetting('payment_type', value)}
                            /> : null}
                            {_.has(data.settings, 'delivery_type') ? <Select
                                label={__('Typ dodania objednávky')}
                                options={delivery_types}
                                value={data.settings.delivery_type}
                                onChange={value => this.onChangeSetting('delivery_type', value)}
                            /> : null}
                            <Input
                                label={__('Predmet')}
                                value={data.subject}
                                onChange={value => this.onChange('subject', value)}
                                placeholder={__('Objednávka {order.number}')}
                            />
                            <Editor
                                editorState={data.text}
                                wrapperClassName="editor"
                                editorClassName="editor-text"
                                toolbarClassName="editor-toolbar"
                                onEditorStateChange={state => this.onChange('text', state)}
                                toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'] }}
                            />
                            <div
                                onClick={attachment ? () => this.deleteAttachment(attachment) : null}
                                className="template__content__panels__panel__upload"
                            >
                                {loadingUpload ? this.renderLoading(20) : null}
                                {!loadingUpload && !attachment ? <UploadIcon /> : null}
                                {!loadingUpload && !attachment ? <div className="template__content__panels__panel__upload__text">{__('Nahrajte prílohu vo formáte PDF.')}</div> : null}
                                {!loadingUpload && !attachment ? <input type="file" onChange={event => this.uploadAttachment(event.target.files[0])} /> : null}
                                {!loadingUpload && attachment ? <div className="template__content__panels__panel__upload__text">
                                    {attachment}
                                    <DeleteIcon />
                                </div> : null}
                            </div>
                            <div className="template__content__panels__panel__settings">
                                {!_.isEmpty(show_settings) ? <div className="template__content__panels__panel__settings__title">{__('Nastavenia')}</div> : null}
                                {_.map(show_settings, (setting, key) => {
                                    const label = key === 'manual'
                                        ? __('Odosielať pri manuálnom volaní udalosti')
                                        : TEMPLATES_EVENTS[data.event].labels[key];

                                    return (
                                        <Checkbox
                                            label={label}
                                            value={setting}
                                            onChange={checked => this.onChangeSetting(key, checked)}
                                            key={key}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        disabled={loadingUpload}
                        className="template__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ template, user }) => ({ template, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchTemplate,
    cleanTemplate,
    createTemplate,
    setUser,
})(TemplateScreen)));
