import React, { Component } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getCustomizationKey } from '../functions';

/**
 * Lightbox komponenta.
 */
class Lightbox extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     open: boolean,
     *     title: string,
     *     text: string,
     *     content: ?JSX.Element,
     *     agree_text: string,
     *     close_text: string,
     *     disable_close: boolean,
     *     onAgree: function,
     *     onClose: function,
     *     onExit: function,
     * }}
     */
    static defaultProps = {
        open: false,
        title: '',
        text: '',
        content: null,
        agree_text: '',
        close_text: '',
        disable_close: false,
        onAgree: () => {},
        onClose: () => {},
        onExit: () => {},
    };

    /**
     * Default props.
     *
     * @type {{
     *     open: boolean,
     *     title: string,
     * }}
     */
    state = {
        open: false,
        title: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { open, title } = this.props;

        this.setState({ open, title });
    }

    /**
     * Komponenta bude zmenena.
     *
     * @param {object} prevProps
     * @param {object} prevState
     * @param {object} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open !== this.state.open) {
            // Zmenil sa open v props chceme ho zmenit aj v state
            this.setState({ open: this.props.open });
        } else if (this.props.title !== this.state.title) {
            // Zmenila sa title v props chceme ju zmenit aj v state
            this.setState({ title: this.props.title });
        }
    }

    /**
     * Prijatie lightboxu.
     */
    onAgree() {
        this.setState({ open: false });

        // Zavolame callback
        this.props.onAgree();
    }

    /**
     * Zatvorenie lightboxu.
     */
    onClose() {
        this.setState({ open: false });

        // Zavolame callback
        this.props.onClose();
    }

    /**
     * Exit lightboxu.
     */
    onExit() {
        this.props.onExit();
    }

    /**
     * Rendrovanie textu.
     *
     * @param {string} text
     *
     * @returns {array}
     */
    renderText(text) {
        return _.map(_.isArray(text) ? text : [text], value => <DialogContentText key={value}>{value}</DialogContentText>);
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { open, title } = this.state;
        const { content, text, agree_text, close_text, disable_close } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.onClose.bind(this)}
                onBackdropClick={!disable_close ? this.onClose.bind(this) : () => {}}
                onExit={!disable_close ? this.onExit.bind(this) : () => {}}
                className={getCustomizationKey()}
            >
                {!_.isEmpty(title) ? <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle> : null}
                <DialogContent>{content !== null ? content : this.renderText(text)}</DialogContent>
                <DialogActions>
                    {agree_text ? <Button onClick={this.onAgree.bind(this)} color="primary">{agree_text}</Button> : null}
                    {close_text ? <Button onClick={this.onClose.bind(this)} color="primary">{close_text}</Button> : null}
                </DialogActions>
            </Dialog>
        );
    }
}

export { Lightbox };