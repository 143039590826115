import _ from 'lodash';
import { FETCH_SYNC_PRODUCTS, CLEAN_SYNC_PRODUCTS } from './types';
import { request } from '../functions';

/**
 * Nacitanie sync produktov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchSyncProducts = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/user-eshops/sync-products', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_SYNC_PRODUCTS, payload: data });
    });
};

/**
 * Vycistenie sync produktov.
 */
export const cleanSyncProducts = () => ({ type: CLEAN_SYNC_PRODUCTS });
