import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import HelpIcon from '@material-ui/icons/Help';
import ActivatedIcon from '@material-ui/icons/CheckCircle';
import { Screen, Message, Select, Button, Checkbox } from '../components';
import { __, toNumber } from '../functions';
import { fetchBanks, createBank, saveBankSettings, setUser } from '../actions';
import { BANKS, BANK_COLORS, IMAGES } from '../config';
import '../assets/styles/banks.css';

/**
 * Banka.
 */
class BanksScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Banka');

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     loading_bank: number,
     *     settings: {},
     * }}
     */
    state = {
        loading: false,
        loading_bank: 0,
        settings: {},
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchBanks } = this.props;

            await fetchBanks(this);

            const { banks } = this.props;

            this.setState({
                settings: {
                    state_paid: banks.eshop_settings.state_paid,
                    pair_by_variable: banks.eshop_settings.pair_by_variable,
                    pair_by_invoice_number: banks.eshop_settings.pair_by_invoice_number,
                    pair_by_order_number: banks.eshop_settings.pair_by_order_number,
                },
            });
        }

        return true;
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Event po kliku na banku.
     *
     * @param {string} email
     */
    onClickBank(email) {
        // Nastavime email do clipboardu
        navigator.clipboard.writeText(email);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Pridame novu banku.
     *
     * @param {number} id
     */
    add(id) {
        const { createBank } = this.props;

        this.setState({ loading_bank: id });

        // Vytvorime banku
        createBank(this, id);
    }

    /**
     * Ulozenie nastaveni.
     */
    save() {
        const { settings } = this.state;
        const { saveBankSettings } = this.props;

        this.setState({ loading: true });

        saveBankSettings(this, settings);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { banks } = this.props;
        const { settings, loading, loading_bank } = this.state;

        if (_.isEmpty(banks) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const states = _.reduce(banks.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return super.render(
            <Paper className="banks" elevation={0}>
                <Toolbar className="banks__header">
                    <div className="banks__header__left">
                        <Typography className="banks__header__title" variant="h5">{__('Banka')}</Typography>
                    </div>
                </Toolbar>
                <div className="banks__content">
                    <Message type="info">
                        {__('Po kliku na konkrétnu banku ju aktivujete. Po ďalšom kliknutí skopírujete bmailovú adresu banky do schránky')}
                    </Message>
                    <div className="banks__content__items">
                        {_.map(banks.banks, (name, id) => {
                            id = toNumber(id);

                            const activated = _.includes(_.map(_.keys(banks.user_banks), id => toNumber(id)), id);
                            const loaded = id === loading_bank;
                            const email = activated ? banks.email.replace('{name}', banks.user_banks[id]) : '';
                            const activated_text = __('kliknutím skopírujete do schránky bmailovú adresu banky');

                            return (
                                <Paper
                                    className="banks__content__items__item"
                                    elevation={1}
                                    style={{ backgroundColor: BANK_COLORS[name].background }}
                                    key={id}
                                >
                                    {!loaded ? <Tooltip title={`${BANKS[name]} - ${activated
                                        ? activated_text
                                        : __('kliknutím aktivujete')
                                    }`}>
                                        <ButtonBase
                                            onClick={activated ? () => this.onClickBank(email) : () => this.add(id)}
                                            focusRipple
                                            className="banks__content__items__item__button"
                                        >
                                            <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                            {activated
                                                ? <ActivatedIcon
                                                    className="banks__content__items__item__button__icon"
                                                    color="secondary"
                                                />
                                                : null
                                            }
                                        </ButtonBase>
                                    </Tooltip> : <CircularProgress color="primary" size={30} />}
                                </Paper>
                            );
                        })}
                        <Paper className="banks__content__items__item" elevation={1}>
                            <Tooltip title={__('Nevidíte tu svoj banku? Kontaktujte nás a zvážime jej zapracovanie do systému.')}>
                                <ButtonBase
                                    focusRipple
                                    className="banks__content__items__item__button"
                                >
                                    <HelpIcon
                                        className="banks__content__items__item__button__help"
                                        color="primary"
                                    />
                                </ButtonBase>
                            </Tooltip>
                        </Paper>
                    </div>
                    <div className="banks__content__settings">
                        <Typography className="banks__content__settings__title" variant="h6">{__('Nastavenie')}</Typography>
                        <div className="banks__content__settings__items">
                            <Select
                                label={__('Stav objednávky po úhrade')}
                                options={states}
                                value={settings.state_paid}
                                onChange={value => this.onChangeSetting('state_paid', value)}
                            />
                            <Checkbox
                                label={__('Párovať podla VS faktúry')}
                                value={settings.pair_by_variable}
                                onChange={checked => this.onChangeSetting('pair_by_variable', checked)}
                            />
                            <Checkbox
                                label={__('Párovať podla čísla faktúry')}
                                value={settings.pair_by_invoice_number}
                                onChange={checked => this.onChangeSetting('pair_by_invoice_number', checked)}
                            />
                            <Checkbox
                                label={__('Párovať podla čísla objednávky')}
                                value={settings.pair_by_order_number}
                                onChange={checked => this.onChangeSetting('pair_by_order_number', checked)}
                            />
                        </div>
                    </div>
                    <Button
                        onClick={this.save.bind(this)}
                        loading={loading}
                        className="banks__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ banks, user }) => ({ banks, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchBanks,
    createBank,
    saveBankSettings,
    setUser,
})(BanksScreen)));