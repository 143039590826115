import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import StorageLightbox from '../lightboxes/StorageLightbox';
import { Screen, Switch, Button, Message, Select } from '../components';
import { __, formatDate, request, toNumber } from '../functions';
import {
    fetchStorage,
    fetchStorageData,
    changeStorageAutoField,
    changeStorageSettings,
    setUser,
} from '../actions';
import { STORAGES } from '../config';
import '../assets/styles/storage.css';

/**
 * Sklad.
 */
class StorageScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Sklad');

    /**
     * Default state.
     *
     * @type {{
     *     storage_id: boolean,
     *     loading: boolean,
     *     loading_data: boolean,
     *     loading_add: boolean,
     *     loading_credentials: boolean,
     *     settings: Object,
     *     lightbox: Object,
     * }}
     */
    state = {
        storage_id: 0,
        loading: false,
        loading_data: false,
        loading_add: false,
        loading_credentials: false,
        settings: {},
        lightbox: {
            add: false,
            credentials: false,
        },
    };

    /**
     * Zoznam skladov ktore nepodporuju import objednavok.
     *
     * @type {[string]}
     */
    disallow_import_orders = [
        'Ikelp',
        'Mrp',
        'Idoklad',
    ];

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchStorage } = this.props;

            await fetchStorage(this);

            const { storage } = this.props;

            this.setState({ settings: storage.settings });
        }

        return true;
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        let additional = {};

        this.setState({ settings: { ...settings, [type]: value, ...additional } });
    }

    /**
     * Event po zmene auto fieldu.
     *
     * @param {string} field
     * @param {boolean} value
     */
    onChangeAutoField(field, value) {
        const { changeStorageAutoField } = this.props;

        changeStorageAutoField(this, field, value);
    }

    /**
     * Nacitame data skladu.
     */
    refreshData() {
        const { fetchStorageData } = this.props;

        this.setState({ loading_data: true });

        fetchStorageData(this);
    }

    /**
     * Zmena credentials.
     *
     * @param {string} type
     * @param {number} storage_id
     * @param {Object} credentials
     */
    changeCredentials(type, storage_id, credentials) {
        // Zatvorime lightbox
        this.closeLightbox(type);

        this.setState({ [`loading_${type}`]: true, storage_id });

        request('/user-eshops/changeStorageCredentials', { storage_id, credentials }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ [`loading_${type}`]: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.showSnackbar('error', type === 'add'
                    ? __('Nepodarilo sa aktivovať sklad')
                    : __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.showSnackbar('success', type === 'add'
                ? __('Sklad bol aktivovaný')
                : __('Prihlasovacie údaje boli zmenené')
            );

            // Refreshneme data
            this.refreshData();
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeStorageSettings } = this.props;
        const { settings } = this.state;

        this.setState({ loading: true });

        changeStorageSettings(this, settings);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { storage } = this.props;
        const { storage_id, loading, settings, loading_data, loading_add, loading_credentials } = this.state;

        if (_.isEmpty(storage) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const names = _.reduce(storage.storages, (result, { id, name }) => ({ ...result, [id]: name }), {});
        const id = toNumber(storage.id) > 0 ? toNumber(storage.id) : toNumber(storage_id);
        const active = id > 0;
        const storage_name = toNumber(storage_id) > 0 ? names[toNumber(storage_id)] : storage.name;
        const auto_orders = storage.auto_orders === 'on';

        return super.render(
            <Paper className="storage" elevation={0}>
                <Toolbar className="storage__header">
                    <div className="storage__header__left">
                        <Typography className="storage__header__title" variant="h5">
                            {active ? STORAGES[storage_name] : __('Sklad')}
                        </Typography>
                        {active ? <Chip
                            label={formatDate(storage.synchronized, __('Synchronizované so skladom o hh:ii'))}
                        /> : null}
                    </div>
                    {active ? <div className="storage__header__right">
                        <Tooltip title={__('Načíta aktuálne údaje zo skladu')}>
                            <span><Button
                                onClick={this.refreshData.bind(this)}
                                loading={loading_data}
                            >{__('Načítať údaje')}</Button></span>
                        </Tooltip>
                        <Button
                            onClick={() => this.showLightbox('credentials')}
                            loading={loading_credentials}
                            color="red"
                        >{__('Zmeniť prihlasovacie údaje')}</Button>
                    </div> : <div className="storage__header__right">
                        <Button
                            onClick={() => this.showLightbox('add')}
                            color="green"
                            loading={loading_add}
                        >{__('Aktivovať')}</Button>
                    </div>}
                </Toolbar>
                <div className="storage__content">
                    {!active ? <Message
                        className="storage__content__warning"
                        type="warning"
                    >{__('Najprv musíte aktivovať sklad.')}</Message> : <Message
                        className="storage__content__warning"
                        type="info"
                    >{__('Skladové zásoby sa synchronizujú každú hodinu.')}</Message>}
                    <div className="storage__content__events">
                        {active && !storage.accounting_active && !_.includes(this.disallow_import_orders, storage_name) ? <Switch
                            label={__('Automatický import objednávok')}
                            checked={auto_orders}
                            onChange={value => this.onChangeAutoField('auto_orders', value)}
                        /> : null}
                    </div>
                    <div className="storage__content__ftps">
                        {active && !_.isEmpty(storage.web) ? <div className="storage__content__ftps__ftp">
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Návod')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">
                                    <a href={storage.web.video} target="_blank" rel="noopener noreferrer">{__('Video')}</a>
                                </div>
                            </div>
                            {!_.isEmpty(storage.web.download) ? <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Súbor')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">
                                    <a href={storage.web.download} target="_blank" rel="noopener noreferrer">{__('Stiahnuť')}</a>
                                </div>
                            </div> : null}
                        </div> : null}
                        {active && !_.isEmpty(storage.ftp) ? <div className="storage__content__ftps__ftp">
                            {!_.isEmpty(storage.accounting_ftp) && auto_orders ? <div className="storage__content__ftps__ftp__name">{__('Sklad')}</div> : null}
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Návod')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">
                                    <a href={storage.ftp.video} target="_blank" rel="noopener noreferrer">{__('Video')}</a>
                                </div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('FTP server')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.ftp.host}</div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Prihlasovacie meno')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.ftp.user}</div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Prihlasovacie heslo')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.ftp.password}</div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Názov súboru')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.ftp.file}</div>
                            </div>
                            {!_.isEmpty(storage.ftp.download) ? <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Súbor')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">
                                    <a href={storage.ftp.download} target="_blank" rel="noopener noreferrer">{__('Stiahnuť')}</a>
                                </div>
                            </div> : null}
                        </div> : null}
                        {active && !_.isEmpty(storage.accounting_ftp) && auto_orders ? <div className="storage__content__ftps__ftp">
                            <div className="storage__content__ftps__ftp__name">{__('Objednávky')}</div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Návod')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">
                                    <a href={storage.accounting_ftp.video} target="_blank" rel="noopener noreferrer">{__('Video')}</a>
                                </div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('FTP server')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.accounting_ftp.host}</div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Prihlasovacie meno')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.accounting_ftp.user}</div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Prihlasovacie heslo')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.accounting_ftp.password}</div>
                            </div>
                            <div className="storage__content__ftps__ftp__item">
                                <div className="storage__content__ftps__ftp__item__label">{__('Názov súboru')}:</div>
                                <div className="storage__content__ftps__ftp__item__value">{storage.accounting_ftp.file}</div>
                            </div>
                        </div> : null}
                    </div>
                    <div className="storage__content__settings">
                        <Typography className="storage__content__settings__title" variant="h6">{__('Nastavenie')}</Typography>
                        <div className="storage__content__settings__selects">
                            <Select
                                label={__('Párovať produkt podla')}
                                options={{ ean: __('EAN'), number: __('Číslo') }}
                                value={settings.check_field}
                                onChange={value => this.onChangeSetting('check_field', value)}
                                allow_empty={false}
                            />
                        </div>
                    </div>
                    <Button
                        onClick={this.changeSettings.bind(this)}
                        loading={loading}
                        className="storage__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderLightbox(
                    'add',
                    __('Aktivácia skladu'),
                    <StorageLightbox
                        screen={this}
                        storages={storage.storages}
                        afterSetStorageCredentials={
                            (storage_id, credentials) => this.changeCredentials('add', storage_id, credentials)
                        }
                    />
                )}
                {this.renderLightbox(
                    'credentials',
                    `${STORAGES[storage_name]} - ${__('zmena prihlasovacích údajov')}`,
                    <StorageLightbox
                        screen={this}
                        storages={storage.storages}
                        storage_id={toNumber(storage_id) > 0 ? storage_id : storage.id}
                        change_storage={false}
                        afterSetStorageCredentials={
                            (storage_id, credentials) => this.changeCredentials('credentials', storage_id, credentials)
                        }
                    />
                )}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ storage, user }) => ({ storage, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchStorage,
    fetchStorageData,
    changeStorageAutoField,
    changeStorageSettings,
    setUser,
})(StorageScreen)));
