import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Screen, Button, Input, Select, Checkbox } from '../components';
import { __, isEmptyString, isValidEmail } from '../functions';
import { fetchSettings, saveSettings, setUser } from '../actions';
import { PASSWORD_MIN_LENGTH, COUNTRIES, CURRENCIES } from '../config';
import '../assets/styles/settings.css';

/**
 * Nastavenia.
 */
class SettingsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nastavenia');

    /**
     * Default state.
     *
     * @type {{
     *     loading: boolean,
     *     data: Object
     * }}
     */
    state = {
        loading: false,
        data: {},
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchSettings } = this.props;

            await fetchSettings(this);

            const { settings } = this.props;

            // Nasetujeme nastavenia
            this.setSettings(settings);
        }

        return true;
    }

    /**
     * Nasetujeme nastavenia.
     *
     * @param {Object} settings
     */
    setSettings(settings) {
        this.setState({ data: {
            market: settings.market,
            currency: settings.currency,
            inline_pdf: settings.inline_pdf,
            email_old: '',
            email_new: '',
            email_password: '',
            password_old: '',
            password_new: '',
            password_new_repeat: '',
       } });
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { data } = this.state;

        this.setState({ data: { ...data, [type]: value } });
    }

    /**
     * Ulozenie nastaveni.
     */
    save() {
        const { data } = this.state;

        if (!isEmptyString(data.email_old)) {
            // Je vyplneny stary email
            if (!isValidEmail(data.email_old)) {
                // Stary email nie je validny
                this.showSnackbar('error', __('Aktuálny email je neplatný'));
                return;
            }

            if (!isValidEmail(data.email_new)) {
                // Novy email nie je validny
                this.showSnackbar('error', __('Nový email je neplatný'));
                return;
            }

            if (isEmptyString(data.email_password)) {
                // Nie je zadane heslo
                this.showSnackbar('error', __('Heslo nie je zadané'));
                return;
            }
        }

        if (!isEmptyString(data.password_old)) {
            // Je vyplnene stare heslo
            if (data.password_new.length < PASSWORD_MIN_LENGTH) {
                // Dlzka hesla je zla
                this.showSnackbar(
                    'error',
                    __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
                );
                return;
            } else if (data.password_new !== data.password_new_repeat) {
                // Hesla sa nezhoduju
                this.showSnackbar('error', __('Heslá nie sú rovnaké'));
                return;
            }
        }

        this.setState({ loading: true });

        const { saveSettings } = this.props;

        saveSettings(this, data);
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { settings } = this.props;
        const { loading, data } = this.state;

        if (_.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <Paper className="settings" elevation={0}>
                <Toolbar className="settings__header">
                    <div className="settings__header__left">
                        <Typography className="settings__header__title" variant="h5">{__('Nastavenia')}</Typography>
                    </div>
                </Toolbar>
                <div className="settings__content">
                    <div className="settings__content__panel">
                        <div className="settings__content__panel__inputs">
                            <Select
                                label={__('Lokalizácia')}
                                options={COUNTRIES}
                                value={data.market}
                                onChange={value => this.onChangeSetting('market', value)}
                                allow_empty={false}
                            />
                            <Select
                                label={__('Mena')}
                                options={CURRENCIES}
                                value={data.currency}
                                onChange={value => this.onChangeSetting('currency', value)}
                                allow_empty={false}
                            />
                        </div>
                        <div className="settings__content__panel__checkboxes">
                            <Checkbox
                                label={__('Zobrazovať PDF v prehliadači')}
                                value={data.inline_pdf}
                                onChange={checked => this.onChangeSetting('inline_pdf', checked)}
                            />
                        </div>
                    </div>
                    <div className="settings__content__panel">
                        <Typography
                            className="settings__content__panel__title"
                            variant="h6"
                        >{__('Zmena emailu')}</Typography>
                        <div className="settings__content__panel__inputs">
                            <Input
                                label={__('Aktuálny email')}
                                value={data.email_old}
                                onChange={value => this.onChangeSetting('email_old', value)}
                                type="email"
                            />
                            <Input
                                label={__('Nový email')}
                                value={data.email_new}
                                onChange={value => this.onChangeSetting('email_new', value)}
                                type="email"
                            />
                            <Input
                                label={__('Heslo')}
                                value={data.email_password}
                                onChange={value => this.onChangeSetting('email_password', value)}
                                type="password"
                            />
                        </div>
                    </div>
                    <div className="settings__content__panel">
                        <Typography
                            className="settings__content__panel__title"
                            variant="h6"
                        >{__('Zmena hesla')}</Typography>
                        <div className="settings__content__panel__inputs">
                            <Input
                                label={__('Aktuálne heslo')}
                                value={data.password_old}
                                onChange={value => this.onChangeSetting('password_old', value)}
                                type="password"
                            />
                            <Input
                                label={__('Nové heslo')}
                                value={data.password_new}
                                onChange={value => this.onChangeSetting('password_new', value)}
                                type="password"
                            />
                            <Input
                                label={__('Nové heslo znovu')}
                                value={data.password_new_repeat}
                                onChange={value => this.onChangeSetting('password_new_repeat', value)}
                                type="password"
                            />
                        </div>
                    </div>
                    <Button
                        onClick={this.save.bind(this)}
                        loading={loading}
                        className="settings__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ settings, user }) => ({ settings, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchSettings,
    saveSettings,
    setUser,
})(SettingsScreen)));