import React, { Component } from 'react';
import ButtonCore from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Button komponenta.
 */
class Button extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     color: string,
     *     size: string,
     *     variant: string,
     *     className: string,
     *     onClick: function,
     *     disabled: boolean,
     *     loading: boolean,
     * }}
     */
    static defaultProps = {
        color: 'primary',
        size: 'large',
        variant: 'contained',
        className: '',
        onClick: () => {},
        disabled: false,
        loading: false,
    };

    /**
     * Event po kliknuty.
     */
    onClick() {
        this.props.onClick();
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { color, size, variant, className, disabled, children, loading } = this.props;

        const content = loading
            ? (
                <div>
                    <CircularProgress className="loading" color="inherit" size={20} />
                    <div style={{opacity:0}}>{children}</div>
                </div>
            )
            : children;

        return <ButtonCore
            onClick={this.onClick.bind(this)}
            className={`button button-${!disabled ? color : 'disabled'} ${className}`}
            variant={variant}
            color="inherit"
            size={size}
            disabled={disabled || loading}
        >{content}</ButtonCore>;
    }
}

export { Button };