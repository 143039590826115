import { __, request } from '../functions';
import { FETCH_BANKS, CREATE_BANK, SAVE_BANK_SETTINGS } from './types';

/**
 * Nacitanie bank.
 *
 * @param {Screen} screen
 */
export const fetchBanks = screen => {
    return dispatch => request('/user-banks/index').then(response => {
        const { data, sync } = response.data;

        const { user } = sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_BANKS, payload: data });
    });
};

/**
 * Pridanie banky.
 *
 * @param {Screen} screen
 * @param {number} bank_id
 */
export const createBank = (screen, bank_id) => {
    return dispatch => request('/user-banks/create', { bank_id }, 'POST').then(response => {
        const { status, data } = response.data;

        screen.setState({ loading_bank: 0 });

        if (status === 'error') {
            // Nepodarilo sa pridat bank
            screen.showSnackbar('error', __('Nepodarilo sa aktivovať banku'));
            return;
        }

        screen.showSnackbar('success', __('Banka bol aktivovaná'));

        dispatch({ type: CREATE_BANK, payload: { name: data.name, bank_id } });
    });
};

/**
 * Ulozenie nastaveni banky.
 *
 * @param {Screen} screen
 * @param {Object} settings
 * @param {Object} settings
 */
export const saveBankSettings = (screen, settings) => {
    return dispatch => request('/user-banks/save', { settings }, 'POST').then(response => {
        const { status } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit nastavenia
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: SAVE_BANK_SETTINGS });
    });
};