import _ from 'lodash';
import { FETCH_PACKAGES } from './types';
import { request } from '../functions';

/**
 * Nacitanie zasielok.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const fetchPackages = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/orders/packages/${ids}`, params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_PACKAGES, payload: data });
    });
};