import { FETCH_SETTINGS, SAVE_SETTINGS } from '../actions/types';

const SETTINGS_DEFAULT_STATE = [];

/**
 * Reducer pre nastavenia.
 */
const SettingsReducer = (state = SETTINGS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_SETTINGS:
        case SAVE_SETTINGS:
            // Nacitanie nastaveni
            // Ulozenie nastaveni
            return payload;

        default:
            return state;
    }
};

export default SettingsReducer;