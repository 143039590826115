import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { Screen } from '../components';
import { __ } from '../functions';
import onScan from 'onscan.js';

/**
 * Scan.
 */
class ScanScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Scan');

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    state = {
        scanned: '',
    };

    componentDidMount() {
        super.componentDidMount();

        onScan.attachTo(document, {
            onScan: (sCode, iQty) => this.onScan(sCode),
        });

        setTimeout(() => onScan.simulate(document, '1234567890123'), 2000);

        return true;
    }

    onScan(code) {
        this.setState({ scanned: code });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { scanned } = this.state;

        return (
            <div className="scan">EAN: {scanned}</div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(ScanScreen)));