import { getAssets } from './functions/getAssets';
import { __ } from './functions/__';

/**
 * Zoznam obrazkov.
 *
 * @var object
 */
export const IMAGES = getAssets(require.context('./assets/img/', false, /\.(png|jpe?g|svg|gif)$/));

/**
 * Zoznam mien.
 *
 * @type {object}
 */
export const CURRENCIES = {
    EUR: '€',
    CZK: 'Kč',
    HUF: 'Ft',
    USD: '$',
    GBP: '£',
    PLN: 'PLN',
};

/**
 * Cennik.
 *
 * @type {Object}
 */
export const PRICING = {
    5000: 19.99,
    10000: 39.99,
    20000: 79.99,
    50000: 199.99,
    100000: 399.99,
};

/**
 * Cennik.
 *
 * @type {Object}
 */
export const PUBLIC_PRICING = {
    month: {
        5000: 19.99,
        10000: 39.99,
        20000: 79.99,
        50000: 199.99,
        100000: 399.99,
    },
    year: {
        5000: 16.99,
        10000: 33.39,
        20000: 66.69,
        50000: 166.69,
        100000: 333.39,
    },
};

/**
 * Typy faktury.
 *
 * @type {{
 *     proforma: string,
 *     regular: string,
 * }}
 */
export const INVOICE_TYPES = {
    regular: __('Ostrá faktúra'),
    proforma: __('Zálohová faktúra'),
};

/**
 * Zoznam placeholdrov.
 *
 * @type {Object}
 */
export const PLACEHOLDERS = {
    '{order.id}': __('Id objednávky'),
    '{order.number}': __('Číslo objednávky'),
    '{order.currency}': __('Mena objednávky'),
    '{order.date}': __('Dátum vytvorenia objednávky'),
    '{order.amount}': __('Celková suma objednávky'),
    '{order.discount}': __('Zľava objednávky'),
    '{order.payment_type}': __('Typ úhrady objednávky'),
    '{order.delivery_type}': __('Typ dodania objednávky'),
    '{order.client.id}': __('Id zákazníka'),
    '{order.client.name}': __('Meno zákazníka'),
    '{order.client.ico}': __('IČO zákazníka'),
    '{order.client.dic}': __('DIČ zákazníka'),
    '{order.client.ic_dph}': __('IČ DPH zákazníka'),
    '{order.client.email}': __('Email zákazníka'),
    '{order.client.phone}': __('Tel. číslo zákazníka'),
    '{order.client.address}': __('Adresa zákazníka (fakturácia)'),
    '{order.client.city}': __('Mesto zákazníka (fakturácia)'),
    '{order.client.zip}': __('PSČ zákazníka (fakturácia)'),
    '{order.client.country}': __('Krajina zákazníka (fakturácia)'),
    '{order.client.delivery_address}': __('Adresa zákazníka (dodanie)'),
    '{order.client.delivery_city}': __('Mesto zákazníka (dodanie)'),
    '{order.client.delivery_zip}': __('PSČ zákazníka (dodanie)'),
    '{order.client.delivery_country}': __('Krajina zákazníka (dodanie)'),
    '{order.client.delivery_phone}': __('Tel. číslo zákazníka (dodanie)'),
    '{order.client.delivery_note}': __('Poznámka (dodanie)'),
    '{order.product.name}': __('Názov produktu'),
    '{order.product.quantity}': __('Počet produktu'),
    '{order.product.unit}': __('Jednotka produktu'),
    '{order.product.unit_price}': __('Jednotková cena produktu'),
    '{order.product.total_price}': __('Jednotková cena produktu s DPH'),
    '{order.product.tax}': __('DPH produktu'),
    '{order.product.ean}': __('Unikátne označenie produktu'),
    '{order.product.variant}': __('Varianta produktu'),
};

/**
 * Doplnujuce placeholdre.
 *
 * @type {Object}
 */
export const ADDITIONAL_PLACEHOLDERS = {
    '{order.delivery_name}': __('Názov dodania'),
    '{order.payment_name}': __('Názov platby'),
    '{order.products_total}': __('Celková suma produktov'),
    '{order.delivery_type_amount}': __('Celková suma za dopravu'),
    '{order.payment_type_amount}': __('Celková suma za platbu'),
    '{invoice.number}': __('Číslo faktúry'),
    '{package.number}': __('Číslo zásielky'),
};

/**
 * Email placeholdre.
 *
 * @type {Object}
 */
export const EMAIL_PLACEHOLDERS = {
    '{invoice.pdf_link}': __('Verejný odkaz na stiahnutie faktúry'),
};

/**
 * Typy exportov.
 *
 * @type {{
 *     excel: string,
 *     pdf: string,
 * }}
 */
export const EXPORT_TYPES = {
    excel: __('Excel'),
    pdf: __('Pdf'),
};

/**
 * Nazvy eshopov.
 *
 * @type {Object}
 */
export const ESHOPS = {
    Webareal: __('Webareal'),
    WooCommerce: __('WooCommerce'),
    Shopify: __('Shopify'),
    CsCart: __('CS-Cart'),
    EshopRychlo: __('Eshop rychlo'),
    BiznisWeb: __('BiznisWeb'),
    Wix: __('Wix'),
    Api: __('API'),
    Ecwid: __('Ecwid'),
};

/**
 * Farby fakturacnych systemov.
 *
 * @type {Object}
 */
export const ESHOP_COLORS = {
    Webareal: { background: '#55bcfb', text: 'white' },
    WooCommerce: { background: '#995c8d', text: 'white' },
    Shopify: { background: 'white', text: 'black' },
    CsCart: { background: 'white', text: 'black' },
    EshopRychlo: { background: 'white', text: 'black' },
    BiznisWeb: { background: 'white', text: 'black' },
    Wix: { background: '#1677ff', text: 'white' },
    Api: { background: '#cfa14c', text: 'white' },
    Ecwid: { background: '#0086cc', text: 'white' },
};

/**
 * Nazvy skladov.
 *
 * @type {Object}
 */
export const STORAGES = {
    Oberon: __('Oberon'),
    Ikelp: __('iKelp'),
    Mrp: __('MRP'),
    Idoklad: __('iDoklad'),
    MrpKs: __('MRP'),
    Pohoda: __('Pohoda'),
    Onix: __('ONIX'),
};

/**
 * Farby skladov.
 *
 * @type {Object}
 */
export const STORAGE_COLORS = {
    Oberon: { background: '#552b83', text: 'white' },
    Ikelp: { background: 'white', text: 'black' },
    Mrp: { background: 'white', text: 'black' },
    Idoklad: { background: 'white', text: 'black' },
    Pohoda: { background: 'white', text: 'black' },
    Onix: { background: 'white', text: 'black' },
};

/**
 * Nazvy fakturacnych systemov.
 *
 * @type {Object}
 */
export const ACCOUNTINGS = {
    SuperFaktura: __('SuperFaktúra'),
    Idoklad: __('iDoklad'),
    Omega: __('Omega'),
    Oberon: __('Oberon'),
    Pohoda: __('Pohoda'),
    MkSoft: __('MKsoft'),
    Ikros: __('iKROS'),
    Mrp: __('MRP'),
    FakturyOnline: __('Faktúry online'),
    Fakturoid: __('Fakturoid'),
    Money: __('Money S3'),
    Profit365: __('Profit365'),
    MoneyS4: __('Money S4'),
    Billdu: __('Billdu'),
    Vyfakturuj: __('Vyfakturuj'),
    Onix: __('ONIX'),
};

/**
 * Farby fakturacnych systemov.
 *
 * @type {Object}
 */
export const ACCOUNTING_COLORS = {
    SuperFaktura: { background: '#28577c', text: 'white' },
    Idoklad: { background: 'white', text: 'black' },
    Omega: { background: 'white', text: 'black' },
    Oberon: { background: '#552b83', text: 'white' },
    Pohoda: { background: 'white', text: 'black' },
    MkSoft: { background: 'white', text: 'black' },
    Ikros: { background: 'white', text: 'black' },
    Mrp: { background: 'white', text: 'black' },
    FakturyOnline: { background: 'white', text: 'black' },
    Fakturoid: { background: 'white', text: 'black' },
    Money: { background: 'white', text: 'black' },
    Profit365: { background: 'white', text: 'black' },
    MoneyS4: { background: 'white', text: 'black' },
    Billdu: { background: '#002af2', text: 'white' },
    Vyfakturuj: { background: 'white', text: 'black' },
    Onix: { background: 'white', text: 'black' },
};

/**
 * Nastavenia fakturacnych systemov.
 *
 * @type {Object}
 */
export const ACCOUNTING_SETTINGS = {
    SuperFaktura: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: true,
        show_social_login_message: true,
        payments_sync: true,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    Idoklad: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: true,
        show_social_login_message: true,
        payments_sync: true,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    Pohoda: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: true,
        delete: true,
        invoicing: true,
        lightbox_message: __('Zadávate prihlasovacie údaje do https://www.mpohoda.sk'),
    },
    Omega: {
        create: true,
        pdf: false,
        regular_from_proforma: false,
        email: false,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: false,
        invoicing: false,
        lightbox_message: null,
    },
    Oberon: {
        create: true,
        pdf: false,
        regular_from_proforma: false,
        email: false,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: false,
        invoicing: false,
        lightbox_message: null,
    },
    MkSoft: {
        create: true,
        pdf: false,
        regular_from_proforma: false,
        email: false,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: false,
        invoicing: false,
        lightbox_message: null,
    },
    Ikros: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: false,
        show_social_login_message: true,
        payments_sync: false,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    Mrp: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: true,
        invoicing: true,
        lightbox_message: __('Zadávate prihlasovacie údaje do https://www.turbofaktura.sk'),
    },
    FakturyOnline: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    Fakturoid: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    Money: {
        create: true,
        pdf: false,
        regular_from_proforma: false,
        email: false,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: false,
        invoicing: false,
        lightbox_message: null,
    },
    Profit365: {
        create: true,
        pdf: true,
        regular_from_proforma: false,
        email: true,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    MoneyS4: {
        create: true,
        pdf: false,
        regular_from_proforma: false,
        email: false,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: false,
        delete: false,
        invoicing: false,
        lightbox_message: null,
    },
    Billdu: {
        create: true,
        pdf: true,
        regular_from_proforma: false,
        email: true,
        show_premium_message: true,
        show_social_login_message: false,
        payments_sync: false,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    Vyfakturuj: {
        create: true,
        pdf: true,
        regular_from_proforma: true,
        email: true,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: true,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
    Onix: {
        create: true,
        pdf: false,
        regular_from_proforma: false,
        email: false,
        show_premium_message: false,
        show_social_login_message: false,
        payments_sync: true,
        delete: true,
        invoicing: true,
        lightbox_message: null,
    },
};

/**
 * Zoznam kurierov.
 *
 * @type {Object}
 */
export const COURIERS = {
    Gls: __('GLS'),
    SlovenskaPosta: __('Slovenská pošta'),
    Zasielkovna: __('Zásielkovňa'),
    Kurier123: __('123Kuriér'),
    Remax: __('Remax'),
    Dpd: __('DPD'),
    Geis: __('GEIS Cargo'),
    Toptrans: __('Toptrans'),
    Fhb: __('FHB Fulfillment'),
    Ppl: __('PPL'),
    Dhl: __('DHL'),
    Sps: __('SPS'),
    ExpressOne: __('EXPRESS ONE'),
    PaletExpress: __('PaletExpress'),
};

/**
 * Farby kurierov.
 *
 * @type {Object}
 */
export const COURIER_COLORS = {
    Gls: { background: 'white', text: 'black' },
    SlovenskaPosta: { background: '#fabb00', text: 'white' },
    Zasielkovna: { background: '#ba1b02', text: 'white' },
    Kurier123: { background: 'white', text: 'black' },
    Remax: { background: 'white', text: 'black' },
    Dpd: { background: 'white', text: 'black' },
    Geis: { background: '#ffe800', text: 'white' },
    Toptrans: { background: 'white', text: 'black' },
    Fhb: { background: 'white', text: 'black' },
    Ppl: { background: 'white', text: 'black' },
    Dhl: { background: '#ffcc00', text: 'white' },
    Sps: { background: '#46515c', text: 'white' },
    ExpressOne: { background: 'white', text: 'black' },
    PaletExpress: { background: 'white', text: 'black' },
};

/**
 * Zoznam feedov.
 *
 * @type {Object}
 */
export const FEEDS = {
    Heureka: __('Heureka'),
    Mall: __('Mall'),
};

/**
 * Zoznam sluzieb.
 *
 * @type {Object}
 */
export const SERVICES = {
    Heureka: __('Heureka košík'),
    Kodas: __('Kodas'),
};

/**
 * Farby sluzieb.
 *
 * @type {Object}
 */
export const SERVICE_COLORS = {
    Heureka: { background: 'white', text: 'black' },
    Kodas: { background: 'white', text: 'black' },
};

/**
 * Zoznam addonov.
 *
 * @type {Object}
 */
export const ADDONS = {
    Kodas: __('Kodas'),
    Ikelp: __('iKelp'),
    Spajame: __('Spajame'),
    Mrp: __('Mrp XML export'),
    Pohoda: __('Pohoda XML export platieb'),
};

/**
 * Farby addonov.
 *
 * @type {Object}
 */
export const ADDON_COLORS = {
    Kodas: { background: 'white', text: 'black' },
    Ikelp: { background: 'white', text: 'black' },
    Spajame: { background: 'white', text: 'black' },
    Mrp: { background: 'white', text: 'black' },
    Pohoda: { background: 'white', text: 'black' },
};

/**
 * Zoznam bank.
 *
 * @type {Object}
 */
export const BANKS = {
    Tatrabanka: __('Tatrabanka'),
    Slsp: __('Slovenská sporiteľňa'),
    Moneta: __('Moneta'),
    Fio: __('Fio'),
    Citfin: __('Citfin'),
    Csp: __('Česká sporiteľňa'),
};

/**
 * Farby bank.
 *
 * @type {Object}
 */
export const BANK_COLORS = {
    Tatrabanka: { background: 'black', text: 'white' },
    Slsp: { background: '#bce5fb', text: 'white' },
    Moneta: { background: 'white', text: 'black' },
    Fio: { background: 'white', text: 'black' },
    Citfin: { background: 'white', text: 'black' },
    Csp: { background: '#bce5fb', text: 'white' },
};

/**
 * Zoznam platobnych bran.
 *
 * @type {Object}
 */
export const GATEWAYS = {
    Stripe: __('Stripe'),
    Besteron: __('Besteron'),
    Gopay: __('Gopay'),
    Mollie: __('Mollie'),
    Webpay: __('GP webpay'),
};

/**
 * Farby platobnych bran.
 *
 * @type {Object}
 */
export const GATEWAYS_COLORS = {
    Stripe: { background: '#635bff', text: 'white' },
    Besteron: { background: '#e21644', text: 'white' },
    Gopay: { background: 'white', text: 'black' },
    Mollie: { background: '#0096ff', text: 'white' },
    Webpay: { background: 'white', text: 'black' },
};

/**
 * Obaly.
 *
 * @type {Object}
 */
export const PACKS = {
    package: __('Balík'),
    palette_euro: __('Europaleta'),
    palette_standard: __('Štandard paleta'),
    palette_half: __('Polopaleta'),
    letter: __('List'),
};

/**
 * Zoznam krajin.
 *
 * @type {Object}
 */
export const COUNTRIES = {
    sk: __('Slovensko'),
    cz: __('Česká republika'),
    hu: __('Maďarsko'),
};

/**
 * Kategorie notifikacii.
 *
 * @type {Object}
 */
export const NOTIFICATIONS_CATEGORIES = {
    order: __('Objednávka'),
    invoice: __('Faktúra'),
    heureka: __('Heureka'),
    user: __('Eshop'),
    bmail: __('Banka'),
};

/**
 * Eventy sablon.
 *
 * @type {Object}
 */
export const TEMPLATES_EVENTS = {
    'create_invoice': {
        name: __('Vystavenie faktúry'),
        text: __('Dobrý deň,\n\nv prílohe vám posielame faktúru č. {invoice.number} k objednávke {order.number}.\n\nS pozdravom,\nEshop.sk'),
        settings: { invoice: true },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'create_proforma': {
        name: __('Vystavenie zálohovej faktúry'),
        text: __('Dobrý deň,\n\nv prílohe vám posielame zálohovú faktúru č. {invoice.number} k objednávke {order.number}.\n\nS pozdravom,\nEshop.sk'),
        settings: { invoice: true },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'paid': {
        name: __('Úhrada faktúry'),
        text: __('Dobrý deň,\n\nďakujeme za úhradu faktúry č. {invoice.number}.\n\nS pozdravom,\nEshop.sk'),
        settings: {},
    },
    'send': {
        name: __('Odoslanie zásielky'),
        text: __('Dobrý deň,\n\nobjednávka č. {order.number} bola odoslaná prepravcovi.\nČíslo zásielky: {package.number}\n\nS pozdravom,\nEshop.sk'),
        settings: { invoice: false },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'change_state': {
        name: __('Zmena stavu objednávky'),
        text: __('Dobrý deň,\n\nobjednávka č. {order.number} bola zrušená.\n\nS pozdravom,\nEshop.sk'),
        settings: { invoice: false, state: null, delivery_type: '' },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'accept': {
        name: __('Prijatie objednávky'),
        text: __('Dobrý deň,\n\nobjednávka č. {order.number} bola prijatá.\n\nS pozdravom,\nEshop.sk'),
        settings: { payment_type: '' },
    },
};

/**
 * Customizacia.
 *
 * @type {Object}
 */
export const CUSTOMIZATION = {
    'objednavky.ezdrav.sk': { key: 'ezdrav', domain: 'Ezdrav.sk' },
    'objednavky.royalpets.sk': { key: 'royalpets', domain: 'RoyalPets.sk' },
};

/**
 * Nastavenia cookie.
 *
 * @type {Object}
 */
export const COOKIE_SETTINGS = process.env.NODE_ENV && process.env.NODE_ENV === 'production'
    ? { path: '/', domain: window.location.host.replace('www.old.', '').replace('old.', '') }
    : {};

/**
 * Minimalna dlzka hesla.
 *
 * @type {number}
 */
export const PASSWORD_MIN_LENGTH = 8;

/**
 * Development url.
 *
 * @type {string}
 */
export const DEVELOPMENT_URL = 'http://eshopio.loc:80';

/**
 * Production url.
 *
 * @type {string}
 */
export const PRODUCTION_URL = 'https://api.goodeshop.sk';
