import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import base64url from 'base64url';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import SyncIcon from '@material-ui/icons/Sync';
import DeleteIcon from '@material-ui/icons/Delete';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import ViewIcon from '@material-ui/icons/Folder';
import InvoicesIcon from '@material-ui/icons/Description';
import StateIcon from '@material-ui/icons/Bookmark';
import PaidIcon from '@material-ui/icons/MonetizationOn';
import CouriersIcon from '@material-ui/icons/LocalShipping';
import AddonsIcon from '@material-ui/icons/Extension';
import OrderPdfIcon from '@material-ui/icons/Archive';
import ExportIcon from '@material-ui/icons/GetApp';
import OrderLightbox from '../lightboxes/OrderLightbox';
import { Screen, Table, Status, TableButton, Checkbox, Select, Input, Button } from '../components';
import { __, formatAmount, formatDate, plural, request, toNumber } from '../functions';
import {
    fetchOrders,
    cleanOrders,
    deleteOrder,
    deleteOrders,
    createInvoice,
    createInvoices,
    changeOrderState,
    changeOrdersState,
    changeOrderInternalComment,
    syncOrder,
    syncOrders,
    paidOrder,
    paidOrders,
    sendOrder,
    sendOrders,
    addonOrders,
    pdfOrder,
    pdfOrders,
    setUser
} from '../actions';
import { BANKS, GATEWAYS, IMAGES } from '../config';
import '../assets/styles/orders.css';

/**
 * Zoznam objednavok.
 */
class OrdersScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Objednávky');

    /**
     * Multiselect uzamknutie.
     *
     * @type {bool}
     */
    multi_locked = false;

    /**
     * Multiselect idcka.
     *
     * @type {Array}
     */
    multi_ids = [];

    /**
     * Default state.
     *
     * @type {{
     *     downloaded_pdf: Array,
     *     loading_sync: boolean,
     *     loading_table_button: ?string,
     *     settings: {},
     *     lightbox: {},
     * }}
     */
    state = {
        downloaded_pdf: [],
        loading_sync: false,
        loading_table_button: null,
        settings: {},
        lightbox: {
            paid: false,
            delete: false,
            multidelete: false,
            state: false,
            send: false,
            multisend: false,
            multistate: false,
            multiaddon: false,
            multiexport: false,
            multipdf: false,
            multiorderpdf: false,
            view: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();

            if (_.has(this.props.match.params, 'id')) {
                // View
                this.view(this.props.match.params.id);
            }
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Komponenta dostane nove props.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.isEmpty(prevState.settings) && !_.isEmpty(this.props.user)) {
            // Nemame nasetovanie settingy
            this.setState({ settings: this.props.user.settings });
        }

        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene nastaveni stlpcov.
     */
    onChangeSettings() {
        // Vycistime zoznam
        this.clean();

        const { orders } = this.props;

        // Nacitame prvu stranku
        this.fetch(1, 0, orders.filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { orders } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, orders.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { orders } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, orders.filtered);
    }

    /**
     * Event po zmene uzamknutia poloziek.
     *
     * @param {bool} locked
     */
    onChangeMultiLocked(locked) {
        this.multi_locked = locked;
    }

    /**
     * Event po zmene oznacenia poloziek.
     *
     * @param {Array} ids
     */
    onChangeMultiIds(ids) {
        this.multi_ids = ids;
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    fetch(page = 1, per_page = 0, filtered = {}, callback = null) {
        const { fetchOrders } = this.props;

        // Nacitame zoznam
        fetchOrders(this, { page, per_page, filtered, callback });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanOrders } = this.props;

        // Vycistime zoznam
        cleanOrders();
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Zmazanie objednavky.
     */
    async delete() {
        const { deleteOrder, orders } = this.props;
        const { settings, lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme objednavku
        await deleteOrder(this, lightbox.delete.id, settings);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, orders.filtered);
    }

    /**
     * Vytvorenie faktury.
     *
     * @param {number} id
     * @param {string} button_id
     */
    createInvoice(id, button_id) {
        const { createInvoice } = this.props;

        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        // Vytvorime fakturu
        createInvoice(this, id);
    }

    /**
     * Synchronizacia objednavky.
     *
     * @param {number} id
     * @param {string} button_id
     */
    syncOrder(id, button_id) {
        const { syncOrder } = this.props;

        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        // Synchronizacia objednavky
        syncOrder(this, id);
    }

    /**
     * Zobrazime lightbox na uhradu.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmPaid(id, button_id) {
        this.showLightbox('paid', { id, button_id });
    }

    /**
     * Uhradenie objednavky.
     */
    paid() {
        const { paidOrder } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.paid.button_id });

        // Zavrieme lightbox
        this.closeLightbox('paid');

        // Uhradime
        paidOrder(this, lightbox.paid.id);
    }

    /**
     * Odoslanie objednavky.
     */
    send() {
        const { sendOrder } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.send.button_id });

        // Zmenime stav
        sendOrder(this, lightbox.send.id, formatDate(lightbox.send.date, 'yyyy-mm-dd'), lightbox.send.position);
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {number} order_id
     * @param {string} button_id
     */
    pdf(id, order_id, button_id) {
        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        request(`/invoices/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({
                loading_table_button: null,
                downloaded_pdf: this.getDownloadedPdf([order_id]),
            });

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Vratime id default exportu pdfka.
     *
     * @return {number}
     */
    getPdfOrderExportId() {
        const { orders } = this.props;

        let export_id = 0;

        _.each(orders.exports, item => {
            if (item.type === 'pdf' && toNumber(item.def) === 1) {
                // Default pdf
                export_id = item.id;
            }
        });

        return export_id;
    }

    /**
     * Stiahnutie order pdf.
     *
     * @param {number} id
     * @param {string} button_id
     */
    pdfOrder(id, button_id) {
        const { pdfOrder } = this.props;

        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        // Zavolame callback
        pdfOrder(this, id, this.getPdfOrderExportId());
    }

    /**
     * Addon order.
     *
     * @param {number} id
     * @param {string} button_id
     */
    addonOrder(id, button_id) {
        const { orders, addonOrders } = this.props;

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            orders.user_addons,
            (result, addon_id) => (_.has(orders.addons, addon_id) ? { ...result, [addon_id]: orders.addons[addon_id] } : result),
            {}
        );

        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        // Zavolame addon
        addonOrders(this, [id], _.keys(addons)[0]);
    }

    /**
     * Zmazanie objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteOrders, orders } = this.props;
        const { settings } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme objednavky
        await deleteOrders(this, ids, settings, orders.filtered);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, orders.filtered);
    }

    /**
     * Stiahnutie pdf objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    multiPdf(ids, clearCallback, loadingCallback) {
        const { orders } = this.props;
        const { settings } = this.state;

        // Zavrieme lightbox
        this.closeLightbox('multipdf');

        // Zobrazime loading
        loadingCallback();

        let params = { merge_multi_pdf: settings.merge_multi_pdf };

        // Nasetujeme filter
        _.forEach(orders.filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        request(`/orders/multiPdf/${ids}`, params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf'));
                return;
            }

            // Nastavime pdfka ako precitane
            this.setState({ downloaded_pdf: this.getDownloadedPdf(ids.split(',')) });

            // Zavolame clear callback
            clearCallback();

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Stiahnutie pdf objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiOrderPdf(ids, clearCallback, loadingCallback) {
        const { orders, pdfOrders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiorderpdf');

        // Zobrazime loading
        loadingCallback();

        // Zavolame callback
        await pdfOrders(this, ids, this.getPdfOrderExportId(), orders.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Vytvorenie faktur k objednavkam cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     */
    async multiInvoices(ids, clearCallback) {
        const { createInvoices, orders } = this.props;

        // Vytvorime faktury
        await createInvoices(this, ids, orders.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Uhradenie objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     */
    async multiPaid(ids, clearCallback) {
        const { paidOrders, orders } = this.props;

        // Uhradime objednavky
        await paidOrders(this, ids, orders.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Odoslanie objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     * @param {string} date
     * @param {string} position
     */
    async multiSend(ids, clearCallback, loadingCallback, date, position) {
        const { sendOrders, orders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multisend');

        // Zobrazime loading
        loadingCallback();

        // Odosleme objednavky
        await sendOrders(this, ids, formatDate(date, 'yyyy-mm-dd'), position, {}, orders.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Zmena stavu objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     * @param {string} state_id
     */
    async multiChangeState(ids, clearCallback, loadingCallback, state_id) {
        const { changeOrdersState, orders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multistate');

        // Zobrazime loading
        loadingCallback();

        // Zmenime stav
        await changeOrdersState(this, ids, state_id, orders.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Synchronizacia objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     */
    async multiSync(ids, clearCallback) {
        const { syncOrders, orders } = this.props;

        // Synchronizujeme
        await syncOrders(this, ids, orders.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Zavolanie addonu cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     * @param {string} addon_id
     */
    async multiAddon(ids, clearCallback, loadingCallback, addon_id) {
        const { addonOrders, orders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiaddon');

        // Zobrazime loading
        loadingCallback();

        // Zavolame addon
        await addonOrders(this, ids, addon_id, orders.filtered);

        // Zavolame clear callback
        clearCallback();
    }

    /**
     * Zavolanie exportu cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     * @param {string} export_id
     */
    async multiExport(ids, clearCallback, loadingCallback, export_id) {
        const { orders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiexport');

        // Zobrazime loading
        loadingCallback();

        let params = {};

        // Nasetujeme filter
        _.forEach(orders.filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        request(`/orders/multiExport/${ids}/${export_id}`, params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa exportovať zoznam objednávok'));
                return;
            }

            // Zavolame clear callback
            clearCallback();

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Event po zmene stavu objednavky.
     *
     * @param {number} order_id
     * @param {string} button_id
     * @param {string} value
     */
    onChangeState(order_id, button_id, value) {
        const { changeOrderState } = this.props;

        this.setState({ loading_table_button: button_id });

        // Zmenime stav
        changeOrderState(this, order_id, value);
    }

    /**
     * Event po zmene stavu objednavok v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiState(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multistate: { ...lightbox.multistate, state_id: value } } });
    }

    /**
     * Event po zmene addonu v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiAddon(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multiaddon: { ...lightbox.multiaddon, addon_id: value } } });
    }

    /**
     * Event po zmene exportu v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiExport(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multiexport: { ...lightbox.multiexport, export_id: value } } });
    }

    /**
     * Event po zmene datumu odoslania objednavky v lightboxe.
     *
     * @param {string} value
     */
    onChangeSendDate(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, send: { ...lightbox.send, date: value } } });
    }

    /**
     * Event po zmene pozicii stitkov odoslania objednavky v lightboxe.
     *
     * @param {string} value
     */
    onChangeSendPosition(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, send: { ...lightbox.send, position: value } } });
    }

    /**
     * Event po zmene datumu odoslania objednavok v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiSendDate(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multisend: { ...lightbox.multisend, date: value } } });
    }

    /**
     * Event po zmene pozicii stitkov odoslania objednavok v lightboxe.
     *
     * @param {string} value
     */
    onChangeMultiSendPosition(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multisend: { ...lightbox.multisend, position: value } } });
    }

    /**
     * Event po kliku na cislo zasielky.
     *
     * @param {string} number
     */
    onClickPackageNumber(number) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(number);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Synchronizujeme.
     */
    sync() {
        this.setState({ loading_sync: true });

        request('/user-eshops/syncOrders').then(response => {
            const { status, data } = response.data;

            this.setState({ loading_sync: false });

            // Vycistime zoznam
            this.clean();

            const { orders } = this.props;
            const count = status === 'success' ? data.items.length : 0;

            // Nacitame prvu stranku
            this.fetch(1, 0, orders.filtered, () => this.showSnackbar('success', `${count} ${plural(count, [
                __('objednávka bola načítaná'),
                __('objednávky boli načítané'),
                __('objednávok bolo načítaných'),
            ])}`));
        });
    }

    /**
     * Pridame stiahnute pdf.
     *
     * @param {Array} ids
     *
     * @return {Array}
     */
    getDownloadedPdf(ids) {
        let { downloaded_pdf } = this.state;

        _.each(ids, id => {
            downloaded_pdf = [ ...downloaded_pdf, toNumber(id) ];
        });

        return downloaded_pdf;
    }

    /**
     * Zobrazime view objednavky.
     *
     * @param {number} id
     */
    view(id) {
        this.showLightbox('view', { id });
    }

    /**
     * Zformatujeme raw data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatRawData(items) {
        const { orders } = this.props;

        return _.map(items, item => {
            let multiselect_fields = [
                !_.isEmpty(item.invoice)
                    || (
                        !_.isEmpty(orders.settings.meta_data_pdf_link)
                        && _.has(item.data.meta_data, orders.settings.meta_data_pdf_link)
                        && !_.isEmpty(item.data.meta_data[orders.settings.meta_data_pdf_link])
                    )
                    ? 'with_invoice'
                    : 'without_invoice',
            ];

            if (item.invoice_status !== 'paid') {
                // Objednavka nie je uhradena
                multiselect_fields = [ ...multiselect_fields, 'not_paid' ];
            }

            return { ...item, multiselect_fields };
        });
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { orders, user } = this.props;
        const { loading_table_button, downloaded_pdf } = this.state;

        // Vytiahneme zoznam idciek typov dodani ku kurierom
        const courier_ids = _.reduce(
            orders.user_couriers,
            (result, { settings }) => ([ ...result, ...[settings.delivery_id.toString()] ]),
            []
        );

        // Vytiahneme zoznam idciek kurierov
        const courier_settings_ids = _.reduce(
            orders.user_couriers,
            (result, { id }) => ([ ...result, ...[id.toString()] ]),
            []
        );

        const banks = { ...BANKS, ...GATEWAYS };
        const has_invoice_pdf = this.hasAccountingAction('pdf');
        const has_invoice_create = this.hasAccountingAction('create');
        const invoicing = this.hasAccountingAction('invoicing');
        const states = _.reduce(orders.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return _.map(items, item => {
            const { id, client_id, data, invoice, invoice_status, invoice_status_pdf, exported } = item;
            let { invoice_id } = item;

            invoice_id = _.has(invoice, 'reference_id') && toNumber(invoice.reference_id) > 0
                ? invoice.reference_id
                : invoice_id;
            const view_text = __('Zobraziť detail');
            const view_button_id = `${id}${view_text}`;
            let view_color = data.id === 0 ? 'secondary' : 'inherit';

            if (_.includes([65, 66, 118, 119, 120, 121, 122, 123, 124, 125, 126], user.user_eshop_id) && !_.isEmpty(data.client.delivery_note)) {
                // TODO:: zapracovat farebnost podla pravidiel
                view_color = 'primary';
            }

            let buttons = [
                <Select
                    key="select"
                    options={states}
                    value={data.state.id}
                    onChange={value => this.onChangeState(id, view_button_id, value)}
                    allow_empty={false}
                    variant="standard"
                    disabled={loading_table_button === view_button_id}
                    className={orders.eshop_name.toString().toLowerCase()}
                />,
                <TableButton
                    id={id}
                    key="view"
                    text={view_text}
                    icon={<ViewIcon color={view_color} />}
                    onClick={() => this.view(id)}
                    loading_button={loading_table_button}
                />,
                <TableButton
                    id={id}
                    key="orderpdf"
                    text={__('PDF objednávky')}
                    icon={<OrderPdfIcon color={toNumber(exported) === 1 ? 'secondary' : 'inherit'} />}
                    onClick={button_id => this.pdfOrder(id, button_id)}
                    loading_button={loading_table_button}
                />,
                <TableButton
                    id={id}
                    key="sync"
                    text={__('Synchronizovať s eshopom')}
                    icon={<SyncIcon />}
                    onClick={button_id => this.syncOrder(id, button_id)}
                    loading_button={loading_table_button}
                />,
            ];

            if (!_.isEmpty(invoice)) {
                buttons = [ ...buttons, <TableButton
                    id={id}
                    key="pdf"
                    text={`${__('Faktúra č.')} ${invoice.number} - ${__('stiahnuť PDF')}`}
                    icon={<PdfIcon color={has_invoice_pdf && invoice_status_pdf === 'newly' && !_.includes(downloaded_pdf, id)
                        ? 'secondary'
                        : 'inherit'} />}
                    onClick={button_id => this.pdf(invoice_id, id, button_id)}
                    loading_button={loading_table_button}
                    disabled={!has_invoice_pdf}
                    />];
            } else if (
                !_.isEmpty(orders.settings.meta_data_pdf_link)
                && _.has(data.meta_data, orders.settings.meta_data_pdf_link)
                && !_.isEmpty(data.meta_data[orders.settings.meta_data_pdf_link])
            ) {
                // Mame meta data field na pdf link
                buttons = [ ...buttons, <TableButton
                    id={id}
                    key="pdflink"
                    text={__('Stiahnuť PDF')}
                    icon={<PdfIcon color="inherit" />}
                    onClick={() => window.open(data.meta_data[orders.settings.meta_data_pdf_link], '_blank')}
                />];
            } else if (has_invoice_create) {
                // Je faktura waiting?
                const invoice_waiting = (_.has(item, 'waiting_invoice') && item.waiting_invoice)
                    || (_.has(data, 'waiting_invoice') && data.waiting_invoice);

                const invoice_error = (_.has(item, 'error_invoice') && item.error_invoice)
                    || (_.has(data, 'error_invoice') && data.error_invoice);

                buttons = [ ...buttons, <TableButton
                    id={id}
                    key="add"
                    text={invoice_error
                        ? invoice_error
                        : (invoice_waiting
                            ? (invoicing
                                    ? __('K objednávke bude v najbližšom čase automaticky vystavená faktúra. Kliknutím vystavíte manuálne.')
                                    : __('Objednávka bude v najbližšom čase automaticky odoslaná do ekonomického systému. Kliknutím odošlete manuálne.'))
                            : (invoicing ? __('Vystaviť faktúru') : __('Odoslať do ekonomického systému'))
                        )}
                    icon={<InvoicesIcon color={invoice_error
                        ? 'error'
                        : (invoice_waiting ? 'action' : 'inherit')} />}
                    onClick={button_id => this.createInvoice(id, button_id)}
                    loading_button={loading_table_button}
                />];
            }

            // Caka zasielka na uhradu?
            const package_payment = item.package.number === 'waiting_payment';
            // Caka zasielka na import
            const package_import = item.package.number === 'waiting_import';
            // Bola zasielka odoslana?
            const package_send = item.package.number !== '' && !package_payment && !package_import;
            // Ma zasielka error?
            const package_error = item.package.error !== '';
            // Je zasielka waiting?
            const package_waiting = (_.has(item, 'waiting_package') && item.waiting_package)
                || (_.has(data, 'waiting_package') && data.waiting_package);
            // Je uhradene?
            const paid = invoice_status === 'paid';

            // Je povolene odosielat?
            let allow_send = toNumber(data.delivery_type.id) > 0 && _.includes(courier_ids, data.delivery_type.id.toString());
            let send_courier = null;

            if (!allow_send) {
                // Nechceme odosielat, pozrieme na default kuriera
                if (
                    toNumber(orders.courier_settings.default_courier) > 0
                    && _.includes(courier_settings_ids, orders.courier_settings.default_courier.toString())
                    && data.delivery_type.allow_default_courier
                ) {
                    // Chceme odosielat
                    allow_send = true;

                    _.forEach(orders.user_couriers, courier => {
                        if (courier.id.toString() === orders.courier_settings.default_courier.toString()) {
                            send_courier = orders.couriers[courier.courier_id];
                        }
                    });
                }
            } else {
                // Vytiahneme konkretneho kuriera
                _.forEach(orders.user_couriers, courier => {
                    if (courier.settings.delivery_id.toString() === data.delivery_type.id.toString()) {
                        send_courier = orders.couriers[courier.courier_id];
                    }
                });
            }

            if (item.package.courier !== '') {
                // Mame uz odoslane cez konkretneho kuriera
                send_courier = item.package.courier;
            }

            buttons = [ ...buttons, ...[
                <TableButton
                    id={id}
                    key="paid"
                    text={paid
                        ? (_.has(item, 'payment') ? `${__('Uhradené')} - ${_.has(banks, item.payment.by) ? banks[item.payment.by] : __('manuálne')}` : __('Uhradené'))
                        : __('Uhradiť')}
                    icon={<PaidIcon color={invoice_status === 'paid' ? 'secondary' : 'inherit'} />}
                    onClick={!paid ? button_id => this.confirmPaid(id, button_id) : () => {}}
                    loading_button={loading_table_button}
                />,
                <TableButton
                    id={id}
                    key="courier-send"
                    text={package_import ? __('Zásielka čaká na spracovanie u prepravcu.') : (package_payment ? __('Zásielka čaká na úhradu u prepravcu. Odkaz na platbu nájdete v zozname štítkov.') : (
                        package_send
                            ? (item.package.number === 'without_number'
                            ? __('Odoslané')
                            : __('Číslo zasielky: {NUMBER}. Kliknutím skopírujete do schránky.').replace('{NUMBER}', item.package.number))
                            : (
                                package_error
                                    ? __('Chyba: {ERROR}. Kliknutím odošlete znovu.').replace('{ERROR}', item.package.error)
                                    : (
                                        package_waiting
                                            ? __('Objednávka bude v najbližšom čase automatický odoslaná prepravcovi. Kliknutím odošlete manuálne.')
                                            : __('Odoslať prepravcovi')
                                    )
                            )
                    ))
                    }
                    icon={(<div className="courier-button"><CouriersIcon color={package_send
                        ? 'secondary'
                        : (package_error
                            ? 'error'
                            : (package_waiting || package_payment || package_import ? 'action' : 'inherit')
                        )}
                    />{allow_send && send_courier !== null ? <img
                        src={IMAGES[`${send_courier.toLowerCase()}-mini.png`]}
                        className="courier-button__icon"
                        alt={send_courier}
                    /> : null}</div>)}
                    onClick={package_payment || package_import ? () => {} : (
                        package_send
                            ? () => this.onClickPackageNumber(item.package.number)
                            : button_id => this.showLightbox('send', { id, button_id, date: new Date(), position: 1 })
                    )
                    }
                    loading_button={loading_table_button}
                    disabled={!allow_send}
                />,
                <TableButton
                    id={id}
                    key="delete"
                    text={__('Zmazať')}
                    icon={<DeleteIcon />}
                    onClick={button_id => this.confirmDelete(id, button_id)}
                    loading_button={loading_table_button}
                />
            ]];

            return [
                <Link to="#" onClick={() => this.view(id)} className="number">
                    <Status color={data.state.color} show_name={false}>{data.state.name}</Status>
                    {data.number}
                </Link>,
                <Link to={`/clients/view/${client_id}`}>{_.truncate(data.client.name, { length: 24 })}</Link>,
                formatAmount(data.amount, data.currency),
                Table.columns_identify,
                buttons,
            ];
        });
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                case 'invoice_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Faktúra'),
                        value: '',
                        options: {
                            'null': __('Bez faktúry'),
                            'not_null': __('S faktúrou'),
                        },
                    }}};

                case 'has_package':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Zásielka'),
                        value: '',
                        options: {
                            0: __('Neodoslané'),
                            1: __('Odoslané'),
                        },
                        strict: false,
                    }}};

                case 'exported':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Exportované'),
                        value: '',
                        options: {
                            0: __('Nie'),
                            1: __('Áno'),
                        },
                        strict: false,
                    }}};

                case 'invoices-status_pdf':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('PDF'),
                        value: '',
                        options: {
                            'newly': __('Nové'),
                            'download': __('Prečítané'),
                        },
                   }}};

                case 'state_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Stav'),
                        value: '',
                        options,
                    }}};

                case 'payment_type_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ úhrady'),
                        value: '',
                        options,
                    }}};

                case 'delivery_type_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Typ dodania'),
                        value: '',
                        options,
                    }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { loading_sync, settings, lightbox } = this.state;
        const { user, orders, match, changeOrderInternalComment } = this.props;

        if (_.isEmpty(orders) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Vytiahneme zoznam aktivnych addonov
        const addons = _.reduce(
            orders.user_addons,
            (result, addon_id) => (_.has(orders.addons, addon_id) ? { ...result, [addon_id]: orders.addons[addon_id] } : result),
            {}
        );

        const synchronized = orders.synchronized === '1970-01-01 00:00:00'
            ? __('Úvodná synchronizácia prebehne v nasledujúcich minútach')
            : formatDate(orders.synchronized, __('Synchronizované s eshopom o hh:ii'));
        const title = (
            <div className="orders__title">
                <Typography variant="h5">{__('Objednávky')}</Typography>
                <Chip label={synchronized} />
                <Chip
                    icon={<div className={`loading-icon ${loading_sync ? 'loading' : ''}`}>
                        <SyncIcon />
                        <CircularProgress color="inherit" size={14} />
                    </div>}
                    label={__('Synchronizovať')}
                    onClick={this.sync.bind(this)}
                    color="secondary"
                    className="orders__title__sync"
                />
                {_.includes(addons, 'Spajame')
                    ? <Chip
                        onClick={() => window.open('https://woo.goodeshop.sk/pohoda-prijem.php', '_blank')}
                        label={__('Príjemky')}
                        style={{ marginLeft: 20 }}
                    />
                    : null}
                {_.includes(addons, 'Spajame')
                    ? <Chip
                        onClick={() => window.open('https://woo.goodeshop.sk/pohoda-vydaj.php', '_blank')}
                        label={__('Výdajky')}
                        style={{ marginLeft: 20 }}
                    />
                    : null}
                {user.id === 1 || user.id === 57
                    ? <Chip
                        onClick={() => this.redirect('/orders/create')}
                        label={__('Nová objednávka')}
                        style={{ marginLeft: 20 }}
                    />
                    : null}
            </div>
        );
        const states = _.reduce(orders.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam exportov
        const exports = _.reduce(orders.exports, (result, { id, name, def }) => toNumber(def) === 0 ? ({ ...result, [id]: name }) : result, {});

        // Vytiahneme zoznam kurierov ktore pouziva user
        const couriers = _.reduce(
            orders.user_couriers,
            (result, item) => (_.has(orders.couriers, item.courier_id) ? [ ...result, orders.couriers[item.courier_id] ] : result),
            []
        );

        // Zobrazujeme zmenu pozicie stitkov?
        const show_change_position = _.includes(couriers, 'Kurier123') || _.includes(couriers, 'Gls');

        return super.render(
            <div className="orders">
                <Table
                    title={title}
                    multiselect={[
                        !_.isEmpty(addons) ? {
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multiaddon', {
                                ids,
                                clearCallback,
                                loadingCallback,
                                addon_id: _.keys(addons)[0],
                            }),
                            icon: <AddonsIcon />,
                            text: __('Doplnok'),
                        } : null,
                        !_.isEmpty(exports) ? {
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multiexport', {
                                ids,
                                clearCallback,
                                loadingCallback,
                                export_id: _.keys(exports)[0],
                            }),
                            icon: <ExportIcon />,
                            text: __('Export'),
                        } : null,
                        {
                            callback: (ids, clearCallback) => this.multiSync(ids, clearCallback),
                            icon: <SyncIcon />,
                            text: __('Synchronizovať'),
                            limit: 50,
                        },
                        {
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multiorderpdf', {
                                ids,
                                clearCallback,
                                loadingCallback,
                            }),
                            icon: <OrderPdfIcon />,
                            text: __('PDF objednávok'),
                            limit: 200,
                        },
                        {
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multistate', {
                                ids,
                                clearCallback,
                                loadingCallback,
                                state_id: _.keys(states)[0],
                            }),
                            icon: <StateIcon />,
                            text: __('Zmeniť stav'),
                        },
                        this.hasAccountingAction('create') ? {
                            callback: (ids, clearCallback) => this.multiInvoices(ids, clearCallback),
                            icon: <InvoicesIcon />,
                            text: __('Vystaviť faktúry'),
                            require: ['without_invoice'],
                        } : null,
                        this.hasAccountingAction('pdf') ? {
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multipdf', {
                                ids,
                                clearCallback,
                                loadingCallback,
                            }),
                            icon: <PdfIcon />,
                            text: __('Stiahnuť PDF'),
                            require: ['with_invoice'],
                            limit: 50,
                        } : null,
                        {
                            callback: (ids, clearCallback) => this.multiPaid(ids, clearCallback),
                            icon: <PaidIcon />,
                            text: __('Uhradiť'),
                            require: ['not_paid'],
                        },
                        {
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multisend', {
                                ids,
                                clearCallback,
                                loadingCallback,
                                date: new Date(),
                                position: 1,
                            }),
                            icon: <CouriersIcon />,
                            text: __('Odoslať prepravcovi'),
                            limit: 50,
                        },
                        {
                            icon: <DeleteIcon />,
                            text: __('Zmazať'),
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                                ids,
                                clearCallback,
                                loadingCallback,
                            }),
                        },
                    ]}
                    multiselect_selected={_.has(match.params, 'selected') ? match.params.selected.split(',') : []}
                    multiselect_selected_refresh_url="/orders"
                    filter={this.formatFilter(orders.filter)}
                    filtered={orders.filtered}
                    onChangeSettings={this.onChangeSettings.bind(this)}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    onChangeLocked={locked => this.onChangeMultiLocked(locked)}
                    onChangeIds={ids => this.onChangeMultiIds(ids)}
                    columns={[
                        __('Číslo'),
                        __('Zákazník'),
                        __('Suma'),
                        { key: 'created', name: __('Vytvorené'), value: order => formatDate(order.date) },
                        { key: 'payment', name: __('Platba'), value: order => !_.isEmpty(order.data.payment_type_name) ? order.data.payment_type_name : order.data.payment_type.name },
                        { key: 'delivery', name: __('Dodanie'), value: order => !_.isEmpty(order.data.delivery_type_name) ? order.data.delivery_type_name : order.data.delivery_type.name },
                        { key: 'package', name: __('Číslo zásielky'), value: order => order.package.number.toString().indexOf('-') === -1 ? order.package.number : '' },
                        { key: 'internal_comment', name: __('Interná poznámka'), value: order => <div title={order.data.internal_comment}>{_.truncate(order.data.internal_comment, { length: 32 })}</div> },
                        '',
                    ]}
                    columns_key="orders"
                    columns_settings={user.settings.columns.orders}
                    columns_max={3}
                    data={this.formatData(orders.items)}
                    raw_data={this.formatRawData(orders.items)}
                    count={orders.total}
                    page={orders.page}
                    per_pages={user.settings.per_pages}
                    per_page={orders.per_page}
                    empty_text={__('Zatiaľ nemáte žiadne objednávky')}
                />
                {!_.isEmpty(lightbox.view) ? this.renderLightbox(
                    'view',
                    __('Objednávka'),
                    <OrderLightbox
                        screen={this}
                        id={lightbox.view.id}
                        callbackInternalComment={(id, comment) => changeOrderInternalComment(id, comment)}
                    />,
                ) : null}
                {this.renderLightbox(
                    'paid',
                    __('Naozaj chcete uhradiť túto objednávku?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    this.paid.bind(this)
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať túto objednávku?'),
                    <div>
                        <Checkbox
                            label={__('Zmazať objednávku aj z eshopu')}
                            value={settings.order_delete_in_eshop}
                            onChange={checked => this.onChangeSetting('order_delete_in_eshop', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené objednávky?'),
                    <div>
                        <Checkbox
                            label={__('Zmazať objednávky aj z eshopu')}
                            value={settings.order_delete_in_eshop}
                            onChange={checked => this.onChangeSetting('order_delete_in_eshop', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                            lightbox.multidelete.ids,
                            lightbox.multidelete.clearCallback,
                            lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'send',
                    __('Odoslanie objednávky'),
                    !_.isEmpty(lightbox.send) ? <div className="orders__send">
                        <Input
                            type="date"
                            label={__('Dátum zvozu')}
                            value={lightbox.send.date}
                            onChange={date => this.onChangeSendDate(date)}
                        />
                        {show_change_position ? <Select
                            label={__('Pozícia štítkov')}
                            value={lightbox.send.position}
                            options={{ 1: '1', 2: '2', 3: '3', 4: '4' }}
                            onChange={value => this.onChangeSendPosition(value)}
                            allow_empty={false}
                        /> : null}
                        {!_.isEmpty(orders.user_couriers) ? <Button
                            onClick={() => {
                                const date = formatDate(lightbox.send.date, 'yyyy-mm-dd');

                                window.location = `/packages/${date}/${lightbox.send.id}/-/-/${lightbox.send.position}`;
                            }}
                            color=""
                        >{__('Rozpísať zásielku')}</Button> : null}
                    </div> : null,
                    __('Odoslať'),
                    __('Zrušiť'),
                    this.send.bind(this)
                )}
                {this.renderLightbox(
                    'multisend',
                    __('Odoslanie objednávok'),
                    !_.isEmpty(lightbox.multisend) ? <div className="orders__send">
                        <Input
                            type="date"
                            label={__('Dátum zvozu')}
                            value={lightbox.multisend.date}
                            onChange={date => this.onChangeMultiSendDate(date)}
                        />
                        {show_change_position ? <Select
                            label={__('Pozícia štítkov')}
                            value={lightbox.multisend.position}
                            options={{ 1: '1', 2: '2', 3: '3', 4: '4' }}
                            onChange={value => this.onChangeMultiSendPosition(value)}
                            allow_empty={false}
                        /> : null}
                        {!_.isEmpty(orders.user_couriers) ? <Button
                            onClick={() => {
                                const date = formatDate(lightbox.multisend.date, 'yyyy-mm-dd');
                                const keys = this.multi_locked && !_.isEmpty(this.multi_ids)
                                    ? this.multi_ids.join(',')
                                    : '-';
                                const filter = !_.isEmpty(orders.filtered)
                                    ? base64url.encode(JSON.stringify(orders.filtered))
                                    : '-';

                                window.location = `/packages/${date}/${lightbox.multisend.ids}/${keys}/${filter}/${lightbox.multisend.position}`;
                            }}
                            color=""
                        >{__('Rozpísať zásielky')}</Button> : null}
                    </div> : null,
                    __('Odoslať'),
                    __('Zrušiť'),
                    !_.isEmpty(lightbox.multisend)
                        ? () => this.multiSend(
                            lightbox.multisend.ids,
                            lightbox.multisend.clearCallback,
                            lightbox.multisend.loadingCallback,
                            lightbox.multisend.date,
                            lightbox.multisend.position
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'multistate',
                    __('Zmena stavu objednávok'),
                    !_.isEmpty(lightbox.multistate) ? <div className="orders__change-state">
                        <Select
                            options={states}
                            value={lightbox.multistate.state_id}
                            onChange={value => this.onChangeMultiState(value)}
                            allow_empty={false}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    !_.isEmpty(lightbox.multistate)
                        ? () => this.multiChangeState(
                        lightbox.multistate.ids,
                        lightbox.multistate.clearCallback,
                        lightbox.multistate.loadingCallback,
                        lightbox.multistate.state_id
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'multiaddon',
                    __('Doplnok'),
                    !_.isEmpty(lightbox.multiaddon) ? <div className="orders__addons">
                        <Select
                            options={addons}
                            value={lightbox.multiaddon.addon_id}
                            onChange={value => this.onChangeMultiAddon(value)}
                            allow_empty={false}
                        />
                    </div> : null,
                    __('Odoslať'),
                    __('Zrušiť'),
                    !_.isEmpty(lightbox.multiaddon)
                        ? () => this.multiAddon(
                        lightbox.multiaddon.ids,
                        lightbox.multiaddon.clearCallback,
                        lightbox.multiaddon.loadingCallback,
                        lightbox.multiaddon.addon_id
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'multiexport',
                    __('Export'),
                    !_.isEmpty(lightbox.multiexport) ? <div className="orders__exports">
                        <Select
                            options={exports}
                            value={lightbox.multiexport.export_id}
                            onChange={value => this.onChangeMultiExport(value)}
                            allow_empty={false}
                        />
                    </div> : null,
                    __('Exportovať'),
                    __('Zrušiť'),
                    !_.isEmpty(lightbox.multiexport)
                        ? () => this.multiExport(
                        lightbox.multiexport.ids,
                        lightbox.multiexport.clearCallback,
                        lightbox.multiexport.loadingCallback,
                        lightbox.multiexport.export_id
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'multipdf',
                    __('Stiahnutie faktúr do pdf'),
                    <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={settings.merge_multi_pdf}
                            onChange={checked => this.onChangeSetting('merge_multi_pdf', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multipdf)
                        ? () => this.multiPdf(
                        lightbox.multipdf.ids,
                        lightbox.multipdf.clearCallback,
                        lightbox.multipdf.loadingCallback
                        )
                        : null,
                )}
                {this.renderLightbox(
                    'multiorderpdf',
                    __('Stiahnutie objednávok do pdf'),
                    <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={settings.merge_multi_order_pdf}
                            onChange={checked => this.onChangeSetting('merge_multi_order_pdf', checked)}
                        />
                    </div>,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multiorderpdf)
                        ? () => this.multiOrderPdf(
                        lightbox.multiorderpdf.ids,
                        lightbox.multiorderpdf.clearCallback,
                        lightbox.multiorderpdf.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, orders }) => ({ user, orders });

export default withCookies(withRouter(connect(stateToProps, {
    fetchOrders,
    cleanOrders,
    deleteOrder,
    deleteOrders,
    createInvoice,
    createInvoices,
    changeOrderState,
    changeOrdersState,
    changeOrderInternalComment,
    syncOrder,
    syncOrders,
    paidOrder,
    paidOrders,
    sendOrder,
    sendOrders,
    addonOrders,
    pdfOrder,
    pdfOrders,
    setUser,
})(OrdersScreen)));
