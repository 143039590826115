import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import NewlyIcon from '@material-ui/icons/Alarm';
import { Screen, NotificationsTable } from '../components';
import { __ } from '../functions';
import {
    fetchNotifications,
    cleanNotifications,
    deleteNotification,
    deleteNotifications,
    readNotification,
    readNotifications,
    setUser,
    fetchUserNotifications,
} from '../actions';
import { NOTIFICATIONS_CATEGORIES } from '../config';
import '../assets/styles/notifications.css';

/**
 * Zoznam notifikacii.
 */
class NotificationsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Upozornenia');

    /**
     * Default state.
     *
     * @type {{
     *     lightbox: {},
     * }}
     */
    state = {
        lightbox: {
            delete: false,
            multidelete: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { notifications } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, notifications.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { notifications } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, notifications.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     */
    fetch(page = 1, per_page = 0, filtered = {}) {
        const { fetchNotifications } = this.props;

        // Nacitame zoznam
        fetchNotifications(this, { page, per_page, filtered });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanNotifications } = this.props;

        // Vycistime zoznam
        cleanNotifications();
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Oznacenie notifikacie ako precitane.
     *
     * @param {number} id
     * @param {number} button_id
     */
    read(id, button_id) {
        const { readNotification, fetchUserNotifications } = this.props;

        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        // Precitame
        readNotification(this, id);

        // Nacitame novy pocet notifikacii
        fetchUserNotifications();
    }

    /**
     * Zmazanie notifikacie.
     */
    async delete() {
        const { deleteNotification, notifications, fetchUserNotifications } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme notifikaciu
        await deleteNotification(this, lightbox.delete.id);

        // Nacitame novy pocet notifikacii
        fetchUserNotifications();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, notifications.filtered);
    }

    /**
     * Zmazanie notifikacii cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteNotifications, notifications, fetchUserNotifications } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme notifikacie
        await deleteNotifications(this, ids, notifications.filtered);

        // Zavolame clear callback
        clearCallback();

        // Nacitame novy pocet notifikacii
        fetchUserNotifications();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, notifications.filtered);
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Oznacenie notifikacii ako precitanych cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     */
    async multiRead(ids, clearCallback) {
        const { readNotifications, fetchUserNotifications } = this.props;

        // Precitame notifikacie
        await readNotifications(this, ids);

        // Zavolame clear callback
        clearCallback();

        // Nacitame novy pocet notifikacii
        fetchUserNotifications();
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                case 'category':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Kategória'),
                        value: '',
                        options: NOTIFICATIONS_CATEGORIES,
                    }}};

                case 'priority':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Priorita'),
                        value: '',
                        options: {
                            low: __('Nízka'),
                            high: __('Vysoká'),
                        },
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, notifications } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(notifications)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <div className="notifications">
                <NotificationsTable
                    title={<Typography variant="h5">{__('Upozornenia')}</Typography>}
                    multiselect={[
                        {
                            icon: <NewlyIcon />,
                            text: __('Označiť ako prečítané'),
                            callback: (ids, clearCallback) => this.multiRead(ids, clearCallback),
                        },
                        {
                            icon: <DeleteIcon />,
                            text: __('Zmazať'),
                            confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                                ids,
                                clearCallback,
                                loadingCallback,
                            }),
                        },
                    ]}
                    filter={this.formatFilter(notifications.filter)}
                    filtered={notifications.filtered}
                    onChangeFilter={filtered => this.onChangeFilter(filtered)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    onRead={(id, button_id) => this.read(id, button_id)}
                    onDelete={(id, button_id) => this.confirmDelete(id, button_id)}
                    user={user}
                    notifications={notifications}
                />
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať toto upozornenie?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať tieto upozornenia?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                        lightbox.multidelete.ids,
                        lightbox.multidelete.clearCallback,
                        lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, notifications }) => ({ user, notifications });

export default withCookies(withRouter(connect(stateToProps, {
    fetchNotifications,
    cleanNotifications,
    deleteNotification,
    deleteNotifications,
    readNotification,
    readNotifications,
    setUser,
    fetchUserNotifications,
})(NotificationsScreen)));