import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Close';
import { getCustomizationKey, __, request, toNumber } from '../functions';
import { setUser } from '../actions';
import DashboardScreen from '../screens/DashboardScreen';
import OrdersScreen from '../screens/OrdersScreen';
import ClientsScreen from '../screens/ClientsScreen';
import InvoicesScreen from '../screens/InvoicesScreen';
import InvoicePaymentsScreen from '../screens/InvoicePaymentsScreen';
import AccountingScreen from '../screens/AccountingScreen';
import EshopScreen from '../screens/EshopScreen';
import LoginScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/RegisterScreen';
import HeurekaScreen from '../screens/HeurekaScreen';
import CouriersScreen from '../screens/CouriersScreen';
import CourierScreen from '../screens/CourierScreen';
import LabelsScreen from '../screens/LabelsScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import AdminScreen from '../screens/AdminScreen';
import StatesScreen from '../screens/StatesScreen';
import OrderStateScreen from '../screens/OrderStateScreen';
import SettingsScreen from '../screens/SettingsScreen';
import FeedsScreen from '../screens/FeedsScreen';
import SubscriptionScreen from '../screens/SubscriptionScreen';
import AddonsScreen from '../screens/AddonsScreen';
import PackagesScreen from '../screens/PackagesScreen';
import HelpScreen from '../screens/HelpScreen';
import StorageScreen from '../screens/StorageScreen';
import BanksScreen from '../screens/BanksScreen';
import GatewaysScreen from '../screens/GatewaysScreen';
import GatewayScreen from '../screens/GatewayScreen';
import ScanScreen from '../screens/ScanScreen';
import OrderCreateScreen from '../screens/OrderCreateScreen';
import EmployeesScreen from '../screens/EmployeesScreen';
import ExportsScreen from '../screens/ExportsScreen';
import ExportScreen from '../screens/ExportScreen';
import TemplatesScreen from '../screens/TemplatesScreen';
import TemplateScreen from '../screens/TemplateScreen';
import ProductsScreen from '../screens/ProductsScreen';
import PublicScreen from '../screens/PublicScreen';
import SyncProductsScreen from '../screens/SyncProductsScreen';
import { COOKIE_SETTINGS } from '../config';

/**
 * Content komponenta.
 */
class Content extends Component {

    /**
     * Precitame update.
     */
    readUpdate() {
        const { setUser, user } = this.props;

        // Schovame update
        setUser({ ...user, updates: [] });

        // Zavolame request
        request('/users/read-update').then(() => {});
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user, cookies } = this.props;

        let opened_menu = true;

        if (!_.isEmpty(user)) {
            // Mame vyplneneho usera, pozrieme v akom stave je menu
            opened_menu = user.settings.menu !== 'mini';
        }

        const cookie_version = toNumber(cookies.get('version', COOKIE_SETTINGS));

        return (
            <div id="content" className={`${!opened_menu ? 'closed' : ''} ${getCustomizationKey()}`}>
                {!_.isEmpty(user) && _.has(user, 'updates') && !_.isEmpty(user.updates) && cookie_version >= 136 ? <div className="updates">
                    <div className="updates__header">
                        <div className="updates__header__title">{`${__('Nová verzia')} 1.0.${user.updates.id}`}</div>
                        <IconButton
                            onClick={() => this.readUpdate()}
                            className="updates__header__button"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                    <div className="updates__content">
                        {_.map(user.updates.data, (item, key) => <div
                            className="updates__content__line"
                            key={key}
                            dangerouslySetInnerHTML={{ __html: `+ ${item}` }}
                        />)}
                    </div>
                </div> : null}
                <Switch>
                    <Route path="/orders/create" component={OrderCreateScreen} />
                    <Route path="/orders/state/:user_eshop_id/:number" component={OrderStateScreen} />
                    <Route path="/orders/view/:id" component={OrdersScreen} />
                    <Route path="/orders/:selected" component={OrdersScreen} />
                    <Route path="/orders" component={OrdersScreen} />
                    <Route path="/clients" component={ClientsScreen} />
                    <Route path="/products" component={ProductsScreen} />
                    <Route path="/invoices" component={InvoicesScreen} />
                    <Route path="/payments" component={InvoicePaymentsScreen} />
                    <Route path="/accounting" component={AccountingScreen} />
                    <Route path="/eshop" component={EshopScreen} />
                    <Route path="/heureka" component={HeurekaScreen} />
                    <Route path="/couriers" component={CouriersScreen} />
                    <Route path="/courier/:type" component={CourierScreen} />
                    <Route path="/labels" component={LabelsScreen} />
                    <Route path="/notifications" component={NotificationsScreen} />
                    <Route path="/states" component={StatesScreen} />
                    <Route path="/settings" component={SettingsScreen} />
                    <Route path="/feeds" component={FeedsScreen} />
                    <Route path="/admin" component={AdminScreen} />
                    <Route path="/login" component={LoginScreen} />
                    <Route path="/register" component={RegisterScreen} />
                    <Route path="/dashboard" component={DashboardScreen} />
                    <Route path="/subscription" component={SubscriptionScreen} />
                    <Route path="/addons" component={AddonsScreen} />
                    <Route path="/packages/:date/:ids/:keys/:filtered/:position" component={PackagesScreen} />
                    <Route path="/help/:section/:article" component={HelpScreen} />
                    <Route path="/help" component={HelpScreen} />
                    <Route path="/storage" component={StorageScreen} />
                    <Route path="/banks" component={BanksScreen} />
                    <Route path="/gateways" component={GatewaysScreen} />
                    <Route path="/gateway/:type" component={GatewayScreen} />
                    <Route path="/scan" component={ScanScreen} />
                    <Route path="/employees" component={EmployeesScreen} />
                    <Route path="/exports" component={ExportsScreen} />
                    <Route path="/export/:id" component={ExportScreen} />
                    <Route path="/export" component={ExportScreen} />
                    <Route path="/templates" component={TemplatesScreen} />
                    <Route path="/template/:id" component={TemplateScreen} />
                    <Route path="/template" component={TemplateScreen} />
                    <Route path="/sync-products" component={SyncProductsScreen} />
                    <Route path="/" component={PublicScreen} />
                </Switch>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps, { setUser })(Content)));
