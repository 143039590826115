import { FETCH_STATES, SAVE_STATES } from './types';
import { __, request } from '../functions';

/**
 * Nacitanie stavov.
 *
 * @param {Screen} screen
 */
export const fetchStates = (screen) => {
    return dispatch => request('/user-states/index').then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_STATES, payload: data });
    });
};

/**
 * Ulozenie stavov.
 *
 * @param {Screen} screen
 * @param {Object} states
 * @param {Object} settings
 */
export const saveStates = (screen, states, settings) => {
    return dispatch => request('/user-states/save', { states, settings }, 'POST').then(response => {
        const { status, data } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa zmenit stavy
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť nastavenia'));
            return;
        }

        screen.showSnackbar('success', __('Nastavenia boli zmenené'));

        dispatch({ type: SAVE_STATES, payload: data });
    });
};