import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import { Button, Logo, Screen } from '../components';
import { __, formatAmount, getCustomization, request, toNumber } from '../functions';
import {
    ACCOUNTING_COLORS,
    ACCOUNTINGS,
    BANK_COLORS,
    BANKS,
    COURIER_COLORS,
    COURIERS,
    ESHOP_COLORS,
    ESHOPS,
    GATEWAYS,
    GATEWAYS_COLORS,
    IMAGES,
    PUBLIC_PRICING,
    STORAGE_COLORS,
    STORAGES
} from '../config';
import '../assets/styles/public.css';

/**
 * Public screen.
 */
class PublicScreen extends Screen {
    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Zoznam funkcii.
     *
     * @type {Array}
     */
    functions = [
        {
            name: __('Manažment'),
            sections: [
                { name: __('Objednávky'), text: __('V GoodEshope máte zoznam svojich objednávok s podrobným detailom. Objednávke viete zmeniť stav, vystaviť faktúru alebo ju odoslať prepravcovi.') },
                { name: __('Produkty a zákaznici'), text: __('V našom systéme máte na jednom mieste zoznam vašich zákaznikov a produktov. U zákaznikoch si viete nastaviť jednoducho internú poznámku pri produktoch zase stav na sklade.') },
                { name: __('Zamestnanci'), text: __('Chcete vytvoriť prístupy svojim zamestnancom zvlásť? U nás je to možné s tým že viete aj sledovať aktivitu zamestnancov.') },
                { name: __('Emaily'), text: __('Potrebujete automaticky odosielať emaily zákaznikom? Jednoducho si nastavíte šablóny emailov a nastavíte ich odoslanie na konkrétne udalosti napr. vystavenie faktúry, odoslanie prepravcovi a pod.') },
                { name: __('Exporty'), text: __('Ak budete potrebovať export objednávok do excelu alebo detail objednávky do PDF, tak u nás si viete nastaviť konkrétne exporty respektíve ktoré dáta z objednávky potrebujete zobraziť v danom exporte.') },
            ],
        },
        {
            name: __('Fakturácia'),
            sections: [
                { name: __('Úvod'), text: __('Potrebujete vo vašom eshope vyriešiť faktúraciu? Ak áno tak u nás si môžete vybrať konkrétny ekonomický systém a vystavovať v ňom faktúry zo svojich objednávok v eshope.') },
                { name: __('Ostrá alebo zálohová?'), text: __('Ktorý typ faktúry potrebujete vystaviť? V našom systéme je to jedno, vystavíme takú akú budete chcieť. Ak potrebujete vystaviť ostrú faktúru po úhrade zálohovej tak aj to viete nastaviť v našom systéme.') },
                { name: __('Nastavenie'), text: __('Vystavíme faktúru z vašej objednávky podla vašich ľubovoľných nastavení. Nemusíte nič podrobne nastavovať vo väčšine prípadov vám postačia naše predvolené nastavenia.') },
                { name: __('Platby'), text: __('V GoodEshope viete konkrétnu objednávku uhradiť keď vám príde platba, náš systém úhradu prenesie na faktúru a uhradí ju aj v ekonomickom systéme. Vieme to aj automaticky keď si nastavíte automatické párovanie platieb z banky.') },
                { name: __('Automatizácia'), text: __('A na koniec vieme všetky úkony s fakturáciou automatizovať, teda vystavenie faktúry, uhradenie faktúry, odoslanie faktúry emailom a pod.') },
            ],
        },
        {
            name: __('Expedícia'),
            sections: [
                { name: __('Úvod'), text: __('Potrebujete z vášho eshopu odoslať zásielky prepravcom? Ak áno tak u nás si môžete vybrať konkrétnych prepravcov a na jedno kliknutie odoslať objednávky a vytvoriť zásielky.') },
                { name: __('Rozpis zásielok'), text: __('V GoodEshope si viete pred odoslaním objednávok prepravcom skontrolovať ich údaje, prípadne ich pozmeniť. Všetky potrebné údaje automaticky náš systém doplní ako je napr. adresa príjemcu, váha a pod.') },
                { name: __('Tlač štítkov a čísla zásielok'), text: __('Po odoslaní objednávok prepravcovi a následnom vytvorení zásielok, vám automaticky stiahneme štítky ktoré si môžete vytlačiť. Tieto štítky archivujeme 7 dní ak by ste ich potrebovali vytlačiť znovu. Náš systém automaticky načíta aj čísla zásielok od prepravcu a uloží ich k objednávkam. Tieto čísla následne viete odoslať emailom svojim zákaznikom.') },
                { name: __('Kontrola skenerom'), text: __('Pred odoslaní objednávok prepravcovi viete dané objednávky skontrolovať. Jednoducho cez pripojený skener k pc. Po kliknutí na objednávku sa vám zobrazí zoznam produktov s počtom kusov a postupným skenovaním sa dané kusy odpočítavajú.') },
                { name: __('Automatizácia'), text: __('A na koniec odosielanie zásielok vieme automatizovať. Odoslanie zásielok viete nastaviť buď na zmenu stavu objednávky alebo na konkrétny čas.') },
            ],
        },
        {
            name: __('Sklad'),
            sections: [
                { name: __('Úvod'), text: __('Potrebujete váš eshop napojiť na skladový systém? Ak áno tak náš GoodEshop to vie, stačí vybrať konkrétny skladový systém.') },
                { name: __('Synchronizácia'), text: __('Náš systém sa automaticky napojí na skladový systém, spracuje skladové zásoby a aktualizuje ich vo vašom eshope.') },
            ],
        },
        {
            name: __('Banka'),
            sections: [
                { name: __('Úvod'), text: __('V GoodEshope vieme automaticky párovať vaše platby z banky formou bankových notifikacií. Náš systém spracuje notifikácie a uhradí konkrétnu objednávku podľa VS faktúry alebo čísla objednávky.') },
                { name: __('Evidencia platieb'), text: __('V našom systéme uvidíte prehľadný zoznam platieb ktoré sme automaticky napárovali na vaše objednávky. Uvidíte kedy a z akej banky platba prišla.') },
                { name: __('Platobné brány'), text: __('Tak isto ako náš systém kontroluje vaše banky rovnako vie kontrolovať aj vaše platobné brány cez ktoré prijímate platby a nové platby napárovať na vaše objednávky.') },
            ],
        },
    ];

    /**
     * Default state.
     *
     * @type {{
     *     scroll: boolean,
     *     pricingType: string,
     *     pricing: number,
     *     func: number,
     *     section: number,
     *     stats: Object,
     * }}
     */
    state = {
        scroll: false,
        pricingType: 'year',
        pricing: 0,
        func: 0,
        section: 0,
        stats: {},
    };

    /**
     * Konstruktor.
     *
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.functions_ref = React.createRef();
        this.integration_ref = React.createRef();
        this.pricing_ref = React.createRef();
        this.contact_ref = React.createRef();
    }

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (getCustomization() !== null) {
            // Ide o customizaciu
            window.location = '/login';
        }

        // Nacitame statistiky
        await request('/stats.php', false, 'GET', {}, false).then(response => {
            this.setState({ stats: response.data });
        }).catch(error => {
            this.setState({ stats: { orders: 0, invoices: 0, packages: 0 } });
        });

        // Pridame listener
        window.addEventListener('scroll', this.handleScroll);

        super.componentDidMount();
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Odstranime listener
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Handlujeme scrolovanie.
     *
     * @param {Object} event
     */
    handleScroll = event => {
        const { scroll } = this.state;

        if (!scroll && window.scrollY > 0) {
            // Zacali sme scrolovat
            this.setState({ scroll: true });
        } else if (scroll && window.scrollY === 0) {
            // Prestali sme scrolovat
            this.setState({ scroll: false });
        }
    }

    /**
     * Scrolujeme navrch.
     */
    scrollToTop() {
        this.scrollTo(0);
    }

    /**
     * Scrolujeme na poziciu.
     *
     * @param {number} top
     */
    scrollTo(top) {
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
    }

    /**
     * Scrolujeme na element.
     *
     * @param {Object} ref
     */
    scrollToElement(ref) {
        this.scrollTo(ref.current.offsetTop);
    }

    /**
     * Event po zmene cennika.
     *
     * @param {number} pricing
     */
    onChangePricing(pricing) {
        this.setState({ pricing });
    }

    /**
     * Vyberieme typ clenstva.
     *
     * @param {string} type
     */
    selectPricingType(type) {
        this.setState({ pricingType: type })
    }

    /**
     * Vyberieme funkciu.
     *
     * @param {number} key
     */
    selectFunc(key) {
        this.setState({ func: key, section: 0 });
    }

    /**
     * Vyberieme sekciu.
     *
     * @param {number} key
     */
    selectSection(key) {
        this.setState({ section: key });
    }

    /**
     * Rendrujeme sluzbu.
     *
     * @param {string} title
     * @param {string} img
     * @param {string} color
     *
     * @return {JSX.Element}
     */
    renderService(title, img, color) {
        return (
            <Paper
                className="public__integrations__panels__panel__logos__logo"
                elevation={1}
                style={{ backgroundColor: color }}
                key={title}
            >
                <Tooltip title={title}>
                    <img
                        className="public__integrations__panels__panel__logos__logo__img"
                        src={IMAGES[img]}
                        alt={title}
                    />
                </Tooltip>
            </Paper>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        if (getCustomization() !== null) {
            // Mame customizaciu
            return null;
        }

        const { scroll, pricingType, pricing, func, section, stats } = this.state;

        if (_.isEmpty(stats)) {
            // Nemame stats, zobrazime loading
            return <div className="public">{this.renderLoading()}</div>;
        }

        return (
            <div className="public">
                <div className="public__header">
                    <div className="public__header__circle1 circle_big" />
                    <div className="public__header__circle2 circle_big" />
                    <div className="public__header__circle3 circle_small" />
                    <div className="public__header__circle4 circle_small" />
                    <Paper className={`public__header__menu ${scroll ? 'menu__mini' : ''}`} elevation={10} square>
                        <div className="public__header__menu__content">
                            <Logo
                                onClick={() => this.scrollToTop()}
                                className="public__header__menu__content__logo"
                            />
                            <div className="public__header__menu__content__buttons">
                                <Button onClick={() => this.scrollToElement(this.functions_ref)}>{__('Funkcie')}</Button>
                                <Button onClick={() => this.scrollToElement(this.integration_ref)}>{__('Integrácie')}</Button>
                                <Button onClick={() => this.scrollToElement(this.pricing_ref)}>{__('Cenník')}</Button>
                                <Button onClick={() => {}}>{__('Blog')}</Button>
                            </div>
                            <div className="public__header__menu__content__trial">
                                <Button onClick={() => this.redirect('/login')}>{__('Prihlásiť sa')}</Button>
                                <Button
                                    onClick={() => this.redirect('/register')}
                                    color="green"
                                >{__('7 dní zdarma')}</Button>
                            </div>
                        </div>
                    </Paper>
                    <div className="public__header__content">
                        <div className="public__header__content__title">
                            <div className="public__header__content__title__mini">{__('Pomáhame eshopom :)')}</div>
                            <div className="public__header__content__title__first">{__('Kompletné externé')}</div>
                            <div className="public__header__content__title__second">{__('riešenie pre eshop.')}</div>
                            <div className="public__header__content__title__text">{__('Spravujte eshop a jeho prepojenia na rôzne služby na jednom mieste.')}</div>
                            <div className="public__header__content__title__buttons">
                                <Button onClick={() => window.open('https://www.youtube.com/watch?v=WqY2RzXFcDk', '_blank')}>
                                    <img src={IMAGES['youtube.png']} alt="youtube" />
                                    <span>{__('Ukážka')}</span>
                                </Button>
                                <Button
                                    onClick={() => this.redirect('/register')}
                                    color="green"
                                >{__('7 dní zdarma')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="public__dashboard" src={IMAGES['public-dashboard.png']} alt="dashboard" />
                <div className="public__dashboard-mobile">
                    <img className="public__dashboard-mobile__photo" src={IMAGES['public-dashboard-mobile.png']} alt="dashboard mobile" />
                    <div className="public__dashboard-mobile__content">
                        <div className="public__dashboard-mobile__content__title">{__('Dostupné odkiaľkoľvek aj na mobiloch.')}</div>
                        <div className="public__dashboard-mobile__content__buttons">
                            <a href="https://play.google.com/store/apps/details?id=com.goodeshop" target="_blank" rel="noopener noreferrer">
                                <img src="https://play.google.com/intl/en_us/badges/static/images/badges/sk_badge_web_generic.png" alt="Teraz na Google Play" />
                            </a>
                            <Tooltip title={__('Pracujeme na tom')}>
                                <a href="#" style={{ cursor: 'default' }}>
                                    <img className="appstore" src={IMAGES['appstore.png']} alt="app store" />
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="public__functions" ref={this.functions_ref}>
                    <div className="public__functions__title">{__('Vyberte si službu')}<br />{__('na správu vášho eshopu.')}</div>
                    <div className="public__functions__panels">
                        <div className="public__functions__panels__panel">
                            <div className="public__functions__panels__panel__title">{__('Pod jednou strechou')}</div>
                            <div className="public__functions__panels__panel__text">{__('Centralizujeme vám všetky možné prepojenia vášho eshopu na jedno miesto pre vás a vašich zamestnancov.')}</div>
                        </div>
                        <div className="public__functions__panels__panel">
                            <div className="public__functions__panels__panel__title">{__('Prehladnosť a dostupnosť')}</div>
                            <div className="public__functions__panels__panel__text">{__('Naša aplikácia je jednoduchá na používanie. U nás máte dostupné všetky potrebné dáta eshopu online 24/7.')}</div>
                        </div>
                        <div className="public__functions__panels__panel">
                            <div className="public__functions__panels__panel__title">{__('Automatizácia')}</div>
                            <div className="public__functions__panels__panel__text">{__('Vieme automatizovať vám a vašim zamestnancom denné úkony s eshopom.')}</div>
                        </div>
                    </div>
                    <div className="public__functions__title second">{__('Naše riešenie')}</div>
                    <div className="public__functions__buttons">
                        {_.map(this.functions, ({ name }, key) => <Button
                            onClick={() => this.selectFunc(key)}
                            className={key === func ? 'active' : ''}
                            key={key}
                        >{name}</Button>)}
                    </div>
                    <div className="public__functions__sections">
                        <div className="public__functions__sections__menu">
                            {_.map(this.functions[func].sections, ({ name }, key) => <Button
                                onClick={() => this.selectSection(key)}
                                className={key === section ? 'active' : ''}
                                key={key}
                            >{`0${key + 1} - ${name}`}</Button>)}
                        </div>
                        <div className="public__functions__sections__content">{this.functions[func].sections[section].text}</div>
                    </div>
                </div>
                <div className="public__clients">
                    <div className="public__clients__title">{__('Stovky spokojných klientov')}</div>
                    <div className="public__clients__text">{__('Pridajte sa k nám :)')}</div>
                    <div className="public__clients__logos">
                        <img src={IMAGES['clients-1.png']} alt="client logo 1" />
                        <img src={IMAGES['clients-3.png']} alt="client logo 3" />
                        <img src={IMAGES['clients-4.png']} alt="client logo 4" />
                        <img src={IMAGES['clients-6.png']} alt="client logo 6" />
                        <img src={IMAGES['clients-7.png']} alt="client logo 7" />
                        <img src={IMAGES['clients-8.png']} alt="client logo 8" />
                    </div>
                </div>
                <div className="public__integrations" ref={this.integration_ref}>
                    <div className="public__integrations__title">{__('Integrácie pre vás')}</div>
                    <div className="public__integrations__text">{__('Potrebujete iné riešenie?')}<br />{__('Kontaktujte nás a za členstvo doplníme :)')}</div>
                    <div className="public__integrations__panels">
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Eshopy')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(ESHOPS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, ESHOP_COLORS[key].background))}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Ekonomika')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(ACCOUNTINGS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, ACCOUNTING_COLORS[key].background))}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Prepravcovia')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(COURIERS, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, COURIER_COLORS[key].background))}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Sklady')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map(STORAGES, (name, key) => _.has(STORAGE_COLORS, key) ? this.renderService(name, `${key.toLowerCase()}.png`, STORAGE_COLORS[key].background) : null)}
                            </div>
                        </div>
                        <div className="public__integrations__panels__panel">
                            <div className="public__integrations__panels__panel__title">{__('Banky')}</div>
                            <div className="public__integrations__panels__panel__logos mini">
                                {_.map({ ...BANKS, ...GATEWAYS }, (name, key) => this.renderService(name, `${key.toLowerCase()}.png`, _.has(BANK_COLORS, key)
                                    ? BANK_COLORS[key].background
                                    : GATEWAYS_COLORS[key].background))}
                            </div>
                        </div>
                    </div>
                </div>
                {toNumber(stats.orders) > 0 ? <div className="public__stats">
                    <div className="public__stats__panels">
                        <div className="public__stats__panels__panel">
                            <span>{formatAmount(stats.orders, '', 0)}</span>
                            <span>{__('objednávok')}</span>
                        </div>
                        <div className="public__stats__panels__panel">
                            <span>{formatAmount(stats.invoices, '', 0)}</span>
                            <span>{__('faktúr')}</span>
                        </div>
                        <div className="public__stats__panels__panel">
                            <span>{formatAmount(stats.packages, '', 0)}</span>
                            <span>{__('zásielok')}</span>
                        </div>
                    </div>
                </div> : null}
                <div className="public__pricing" ref={this.pricing_ref}>
                    <div className="public__pricing__title">{__('Koľko zaplatíte?')}</div>
                    <div className="public__pricing__text">{__('Žiadne skryté poplatky, len fixná suma.')}</div>
                    <div className="public__pricing__buttons shadow">
                        <Button
                            onClick={() => this.selectPricingType('month')}
                            className={pricingType === 'month' ? 'active' : ''}
                        >{__('Mesačne')}</Button>
                        <Button
                            onClick={() => this.selectPricingType('year')}
                            className={pricingType === 'year' ? 'active' : ''}
                        >{__('Ročne')}</Button>
                    </div>
                    <div className="public__pricing__panel shadow">
                        <div className="public__pricing__panel__title">{__('Koľko máte objednávok mesačne?')}</div>
                        <div className="public__pricing__panel__text">{__('Cena obsahuje všetky naše funkcionality.')}</div>
                        <div className="public__pricing__panel__slider">
                            <Slider
                                defaultValue={pricing}
                                step={1}
                                marks={_.reduce(_.keys(PUBLIC_PRICING[pricingType]), (result, value, key) => [ ...result, {
                                    value: key,
                                    label: formatAmount(value, '', 0),
                                } ], [])}
                                min={0}
                                max={_.keys(PUBLIC_PRICING[pricingType]).length - 1}
                                onChange={(event, value) => this.onChangePricing(value)}
                            />
                        </div>
                        <div className="public__pricing__panel__price">
                            <span>{formatAmount(PUBLIC_PRICING[pricingType][_.keys(PUBLIC_PRICING[pricingType])[pricing]], 'EUR')}</span>
                            <span className="public__pricing__panel__price__text">{__('finálna mesačná platba')}</span>
                            <span className="public__pricing__panel__price__discount">{__('Platbou ročne ušetríte 2 mesiace.')}</span>
                        </div>
                        <Button
                            onClick={() => this.redirect('/register')}
                            color="green"
                        >{__('7 dní zdarma')}</Button>
                    </div>
                </div>
                <div className="public__contact" ref={this.contact_ref}>
                    <div className="public__contact__title">
                        <span>{__('Máte otázky, potrebujete poradiť?')}</span><br />
                        <span>{__('Sme pripravení!')}</span>
                    </div>
                    <div className="public__contact__subtitle">
                        <span>{__('Pracovná doba:')} </span><b>Po - Pi (09-16)</b>
                    </div>
                    <div className="public__contact__email">
                        <div className="public__contact__email__icon">
                            <EmailIcon />
                        </div>
                        <div className="public__contact__email__value">info@goodeshop.sk</div>
                    </div>
                </div>
                <div className="public__trial">
                    <div className="public__trial__title">{__('Vyskúšajte nás na 7 dní zadarmo.')}</div>
                    <div className="public__trial__text">
                        <span>{__('Šetríme denne hodiny práce viac ako')}</span> <b>{__('500 eshopom.')}</b>
                    </div>
                    <Button
                        onClick={() => this.redirect('/register')}
                        color="green"
                    >{__('Vyskúšať zadarmo')}</Button>
                    <div className="public__trial__footer">
                        <Button onClick={() => this.redirect('/login')}>{__('Prihlásiť sa')}</Button>
                        <Button onClick={() => this.scrollToElement(this.contact_ref)}>{__('Kontakt')}</Button>
                        <Button onClick={() => window.open('/vop.pdf?v=1', '_blank')}>{__('Obchodné podmienky')}</Button>
                        <Button onClick={() => window.open('/vop.pdf?v=1', '_blank')}>{__('Ochrana osobných údajov')}</Button>
                    </div>
                    <div className="public__trial__footer-text">Goodeshop.sk, IČO: 50009583, Karpatské námestie 7770/10A, 83106 Bratislava</div>
                </div>
            </div>
        );
    }
}

const stateToProps = ({ dashboard, user }) => ({ dashboard, user });

export default withCookies(withRouter(connect(stateToProps)(PublicScreen)));
