import _ from 'lodash';
import { FETCH_PRODUCTS, CLEAN_PRODUCTS, CHANGE_PRODUCT_STOCK, CHANGE_PRODUCT_MALL } from '../actions/types';
import { toNumber } from '../functions';

const PRODUCTS_DEFAULT_STATE = {};

/**
 * Reducer pre produkty.
 */
const ProductsReducer = (state = PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_PRODUCTS:
            // Nacitanie produktov
            return payload;

        case CHANGE_PRODUCT_STOCK:
            // Zmenime stav na sklade
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme produkt
                    item = { ...item, data: { ...item.data, stock: payload.stock } };
                }

                return item;
            }) };

        case CHANGE_PRODUCT_MALL:
            // Pridame/zrusime produkt na mall
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme produkt
                    let settings = item.data.settings;

                    if (_.has(settings, 'mall')) {
                        // Zrusime
                        _.unset(settings, 'mall');
                    } else {
                        // Pridame
                        settings = { ...settings, mall: 1 };
                    }

                    item = { ...item, data: { ...item.data, settings } };
                }

                return item;
            }) };

        case CLEAN_PRODUCTS:
            // Vycistenie produktov
            return PRODUCTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default ProductsReducer;
