import { FETCH_SUBSCRIPTION, CHANGE_SUBSCRIPTION_SETTINGS } from '../actions/types';

const SUBSCRIPTION_DEFAULT_STATE = [];

/**
 * Reducer pre clenstvo.
 */
const SubscriptionReducer = (state = SUBSCRIPTION_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_SUBSCRIPTION:
            // Nacitanie clenstva
            return payload;

        case CHANGE_SUBSCRIPTION_SETTINGS:
            // Zmena fakturacnych udajov
            return { ...state, settings: { ...state.settings, invoicing: payload } };

        default:
            return state;
    }
};

export default SubscriptionReducer;