import { FETCH_SYNC_PRODUCTS, CLEAN_SYNC_PRODUCTS } from '../actions/types';

const SYNC_PRODUCTS_DEFAULT_STATE = {};

/**
 * Reducer pre produkty.
 */
const SyncProductsReducer = (state = SYNC_PRODUCTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_SYNC_PRODUCTS:
            // Nacitanie sync produktov
            return payload;

        case CLEAN_SYNC_PRODUCTS:
            // Vycistenie sync produktov
            return SYNC_PRODUCTS_DEFAULT_STATE;

        default:
            return state;
    }
};

export default SyncProductsReducer;
