import axios from 'axios';
import _ from 'lodash';
import { getUrl } from './getUrl';

/**
 * GET/POST request.
 *
 * @param {string} uri
 * @param {object|boolean} data
 * @param {string} method
 * @param {object} params
 * @param {boolean} logout
 */
export const request = (uri, data = false, method = 'GET', params = {}, logout = true) => {
    // Nasetujeme config pre axios
    const config = {
        method: method === 'FILE' ? 'POST' : method,
        url: `${getUrl()}${uri}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': method === 'FILE' ? 'multipart/form-data' : 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': !_.isUndefined(global.token) ? global.token : '',
        }
    };

    // Zoznam GET parametrov
    params.token = !_.isUndefined(global.token) ? global.token : '';
    params.source = 'frontend';

    switch (method) {
        case 'GET':
            // Pribalime GET parametre
            params = { ...params, ...data };
            break;

        case 'POST':
            // Mame form data ide o klasicky request
            let post_data = {};

            _.each(data, (value, key) => {
                if (_.isObject(value)) {
                    // Hodnota je pole, upravime
                    _.each(value, (sub_value, sub_key) => {
                        if (_.isObject(sub_value)) {
                            // Hodnota je pole, upravime
                            _.each(sub_value, (sub_sub_value, sub_sub_key) => {
                                if (_.isObject(sub_sub_value)) {
                                    _.each(sub_sub_value, (sub_sub_sub_value, sub_sub_sub_key) => {

                                        if (_.isObject(sub_sub_sub_value)) {
                                            _.each(sub_sub_sub_value, (sub_sub_sub_sub_value, sub_sub_sub_sub_key) => {
                                                post_data = { ...post_data, [`${key}[${sub_key}][${sub_sub_key}][${sub_sub_sub_key}][${sub_sub_sub_sub_key}]`]: sub_sub_sub_sub_value };
                                            });
                                        } else {
                                            post_data = { ...post_data, [`${key}[${sub_key}][${sub_sub_key}][${sub_sub_sub_key}]`]: sub_sub_sub_value };
                                        }
                                    });
                                } else {
                                    post_data = { ...post_data, [`${key}[${sub_key}][${sub_sub_key}]`]: sub_sub_value };
                                }
                            });
                        } else {
                            post_data = { ...post_data, [`${key}[${sub_key}]`]: sub_value };
                        }
                    });
                } else {
                    post_data = { ...post_data, [key]: value };
                }
            });

            const formData = new FormData();

            _.each(_.keys(post_data), key => formData.append(key, post_data[key]));

            config.data = formData;
            break;

        case 'FILE':
            const fileData = new FormData();
            fileData.append('file', data);

            config.data = fileData;
            break;

        default:
            break;
    }

    params = _.join(_.map(_.keys(params), key => `${key}=${encodeURIComponent(params[key])}`), '&');

    config.url = `${config.url}?${params}`;

    const result = axios(config);

    return logout ? result.catch(error => window.location = '/logout') : result;
};