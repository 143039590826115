import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Menu from './components/Menu';
import Header from './components/Header';
import Content from './components/Content';
import './assets/styles/app.css';

global.language = 'sk';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Nunito, sans-serif',
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#5c70ff',
            contrastText: '#fff',
        },
        secondary: {
            main: '#459350',
            contrastText: '#fff',
        },
        error: {
            main: '#c24646',
            contrastText: '#fff',
        },
    },
});

const App = ({ store }) => (
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <Router>
                <Header />
                <Menu />
                <Content />
            </Router>
        </MuiThemeProvider>
    </Provider>
);

export default App;