import { FETCH_GATEWAYS } from '../actions/types';

const GATEWAYS_DEFAULT_STATE = [];

/**
 * Reducer pre brany.
 */
const GatewaysReducer = (state = GATEWAYS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_GATEWAYS:
            // Nacitanie bran
            return payload;

        default:
            return state;
    }
};

export default GatewaysReducer;