import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import NoteIcon from '@material-ui/icons/Create';
import { Screen, Table, TableButton, Input } from '../components';
import { __, formatAmount } from '../functions';
import { fetchClients, cleanClients, deleteClient, deleteClients, changeClientNote, setUser } from '../actions';
import '../assets/styles/clients.css';

/**
 * Zoznam klientov.
 */
class ClientsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Zákaznici');

    /**
     * Default state.
     *
     * @type {{
     *     loading_table_button: ?string,
     *     lightbox: {},
     * }}
     */
    state = {
        loading_table_button: null,
        lightbox: {
            note: false,
            delete: false,
            multidelete: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame zoznam
            this.fetch();
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Vycistime zoznam
        this.clean();
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} filtered
     */
    onChangeFilter(filtered) {
        // Vycistime zoznam
        this.clean();

        // Nacitame prvu stranku s pozadovanim filtrom
        this.fetch(1, 0, filtered);
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        const { clients } = this.props;

        // Nacitame pozadovanu stranku
        this.fetch(page, 0, clients.filtered);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        // Vycistime zoznam
        this.clean();

        const { clients } = this.props;

        // Nacitame prvu stranku s pozadovanim strankovanim
        this.fetch(1, per_page, clients.filtered);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     */
    fetch(page = 1, per_page = 0, filtered = {}) {
        const { fetchClients } = this.props;

        // Nacitame zoznam
        fetchClients(this, { page, per_page, filtered });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        const { cleanClients } = this.props;

        // Vycistime zoznam
        cleanClients();
    }

    /**
     * Event po zmene poznamky.
     *
     * @param {string} note
     */
    onChangeNote(note) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, note: { ...lightbox.note, note } } })
    }

    /**
     * Zobrazime lightbox na poznamku.
     *
     * @param {number} id
     * @param {number} button_id
     * @param {string} note
     */
    confirmNote(id, button_id, note) {
        this.showLightbox('note', { id, button_id, note });
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {number} button_id
     */
    confirmDelete(id, button_id) {
        this.showLightbox('delete', { id, button_id });
    }

    /**
     * Ulozenie poznamky.
     */
    note() {
        const { changeClientNote } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.note.button_id });

        // Zmenime poznamku
        changeClientNote(this, lightbox.note.id, lightbox.note.note);
    }

    /**
     * Zmazanie klienta.
     */
    async delete() {
        const { deleteClient, clients } = this.props;
        const { lightbox } = this.state;

        // Nastavime button loading
        this.setState({ loading_table_button: lightbox.delete.button_id });

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteClient(this, lightbox.delete.id);

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, clients.filtered);
    }

    /**
     * Zmazanie klientov cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} clearCallback
     * @param {function} loadingCallback
     */
    async multiDelete(ids, clearCallback, loadingCallback) {
        const { deleteClients, clients } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        loadingCallback();

        // Zmazeme klientov
        await deleteClients(this, ids, clients.filtered);

        // Zavolame clear callback
        clearCallback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetch(1, 0, clients.filtered);
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter: !this.state.filter });
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        const { user } = this.props;
        const { loading_table_button } = this.state;

        return _.map(items, ({ id, name, data, orders, orders_sum, note }) => {
            return [
                <Link to={`/clients/view/${id}`}>{name}</Link>,
                `${data.delivery_address}, ${data.delivery_city} ${data.delivery_zip}`,
                formatAmount(orders_sum, user.settings.currency),
                orders,
                [
                    <TableButton
                        id={id}
                        key="note"
                        text={__('Zmeniť internú poznámku')}
                        icon={<NoteIcon />}
                        onClick={button_id => this.confirmNote(id, button_id, _.toString(note))}
                        loading_button={loading_table_button}
                    />,
                    <TableButton
                        id={id}
                        key="delete"
                        text={__('Zmazať')}
                        icon={<DeleteIcon />}
                        onClick={button_id => this.confirmDelete(id, button_id)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Zformatujeme filter.
     *
     * @param {Object} filter
     *
     * @return {Object}
     */
    formatFilter(filter) {
        return _.reduce(filter, (result, options, key) => {
            switch (key) {
                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { user, clients } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(clients)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <div className="clients">
                <Table
                    title={<Typography variant="h5">{__('Zákaznici')}</Typography>}
                    multiselect={[{
                        icon: <DeleteIcon />,
                        text: __('Zmazať'),
                        confirm: (ids, clearCallback, loadingCallback) => this.showLightbox('multidelete', {
                            ids,
                            clearCallback,
                            loadingCallback,
                        }),
                    }]}
                    filter={this.formatFilter(clients.filter)}
                    filtered={clients.filtered}
                    onChangeFilter={this.onChangeFilter.bind(this)}
                    onChangePage={page => this.onChangePage(page)}
                    onChangePerPage={per_page => this.onChangePerPage(per_page)}
                    columns={[
                        __('Názov / Meno'),
                        __('Adresa'),
                        __('Suma objednávok'),
                        __('Počet objednávok'),
                        '',
                    ]}
                    data={this.formatData(clients.items)}
                    raw_data={clients.items}
                    count={clients.total}
                    page={clients.page}
                    per_pages={user.settings.per_pages}
                    per_page={clients.per_page}
                    empty_text={__('Zatiaľ nemáte žiadnych zákazníkov')}
                />
                {this.renderLightbox(
                    'note',
                    __('Interná poznámka'),
                    !_.isEmpty(lightbox.note) ? <div className="lightbox-change-note">
                        <Input
                            label={__('Interná poznámka')}
                            value={lightbox.note.note}
                            onChange={value => this.onChangeNote(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    this.note.bind(this)
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tohto zákazníka?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    this.delete.bind(this)
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označených zákazníkov?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    !_.isEmpty(lightbox.multidelete)
                        ? () => this.multiDelete(
                            lightbox.multidelete.ids,
                            lightbox.multidelete.clearCallback,
                            lightbox.multidelete.loadingCallback
                        )
                        : null,
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user, clients }) => ({ user, clients });

export default withCookies(withRouter(connect(stateToProps, {
    fetchClients,
    cleanClients,
    deleteClient,
    deleteClients,
    changeClientNote,
    setUser,
})(ClientsScreen)));