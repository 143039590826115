import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import ActivatedIcon from '@material-ui/icons/CheckCircle';
import { Screen, Message } from '../components';
import { __, toNumber } from '../functions';
import { fetchGateways, setUser } from '../actions';
import { IMAGES, GATEWAYS } from '../config';
import '../assets/styles/gateways.css';

/**
 * Brany.
 */
class GatewaysScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Platobné brány');

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchGateways } = this.props;

            await fetchGateways(this);

            const { gateways } = this.props;

            this.setState({ settings: gateways.settings });
        }

        return true;
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { gateways } = this.props;

        if (_.isEmpty(gateways)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        return super.render(
            <Paper className="gateways" elevation={0}>
                <Toolbar className="gateways__header">
                    <div className="gateways__header__left">
                        <Typography className="gateways__header__title" variant="h5">{__('Platobné brány')}</Typography>
                    </div>
                </Toolbar>
                <div className="gateways__content">
                    <Message type="info">{__('Platby obdržané cez platobnú bránu párujeme s faktúrami a objednávkami.')}</Message>
                    <div className="gateways__content__items">
                        {_.map(gateways.gateways, (name, id) => {
                            id = toNumber(id);

                            const activated = _.includes(_.values(gateways.user_gateways), id);

                            return (
                                <Paper className="gateways__content__items__item" elevation={1} key={id}>
                                    <Tooltip title={GATEWAYS[name]}>
                                        <Link to={`/gateway/${name}`}>
                                            <ButtonBase
                                                focusRipple
                                                className="gateways__content__items__item__button"
                                            >
                                                <img src={IMAGES[`${name.toLowerCase()}.png`]} alt={name} />
                                                {activated
                                                    ? <ActivatedIcon
                                                        className="gateways__content__items__item__button__icon"
                                                        color="secondary"
                                                    />
                                                    : null
                                                }
                                            </ButtonBase>
                                        </Link>
                                    </Tooltip>
                                </Paper>
                            );
                        })}
                        <Paper className="gateways__content__items__item" elevation={1}>
                            <Tooltip title={__('Nevidíte tu vašu platobnú bránu? Kontaktujte nás a zvážime jej zapracovanie do systému.')}>
                                <Link to="#">
                                    <ButtonBase
                                        focusRipple
                                        className="gateways__content__items__item__button"
                                    >
                                        <HelpIcon
                                            className="gateways__content__items__item__button__help"
                                            color="primary"
                                        />
                                    </ButtonBase>
                                </Link>
                            </Tooltip>
                        </Paper>
                    </div>
                </div>
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ gateways, user }) => ({ gateways, user });

export default withCookies(withRouter(connect(stateToProps, { fetchGateways, setUser })(GatewaysScreen)));