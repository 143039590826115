import UserReducer from './UserReducer';
import OrdersReducer from './OrdersReducer';
import ClientsReducer from './ClientsReducer';
import InvoicesReducer from './InvoicesReducer';
import DashboardReducer from './DashboardReducer';
import AccountingReducer from './AccountingReducer';
import InvoicePaymentsReducer from './InvoicePaymentsReducer';
import EshopReducer from './EshopReducer';
import HeurekaReducer from './HeurekaReducer';
import CouriersReducer from './CouriersReducer';
import CourierReducer from './CourierReducer';
import LabelsReducer from './LabelsReducer';
import NotificationReducer from './NotificationsReducer';
import AdminReducer from './AdminReducer';
import OrderReducer from './OrderReducer';
import StatesReducer from './StatesReducer';
import SettingsReducer from './SettingsReducer';
import FeedsReducer from './FeedsReducer';
import SubscriptionReducer from './SubscriptionReducer';
import AddonsReducer from './AddonsReducer';
import PackagesReducer from './PackagesReducer';
import StorageReducer from './StorageReducer';
import BanksReducer from './BanksReducer';
import GatewaysReducer from './GatewaysReducer';
import GatewayReducer from './GatewayReducer';
import EmployeesReducer from './EmployeesReducer';
import ExportsReducer from './ExportsReducer';
import ExportReducer from './ExportReducer';
import TemplatesReducer from './TemplatesReducer';
import TemplateReducer from './TemplateReducer';
import ProductsReducer from './ProductsReducer';
import SyncProductsReducer from './SyncProductsReducer';

// Zoznam reducerov
export const reducers = {
    user: UserReducer,
    orders: OrdersReducer,
    clients: ClientsReducer,
    invoices: InvoicesReducer,
    dashboard: DashboardReducer,
    accounting: AccountingReducer,
    invoice_payments: InvoicePaymentsReducer,
    eshop: EshopReducer,
    heureka: HeurekaReducer,
    couriers: CouriersReducer,
    courier: CourierReducer,
    labels: LabelsReducer,
    notifications: NotificationReducer,
    admin: AdminReducer,
    order: OrderReducer,
    states: StatesReducer,
    settings: SettingsReducer,
    feeds: FeedsReducer,
    subscription: SubscriptionReducer,
    addons: AddonsReducer,
    packages: PackagesReducer,
    storage: StorageReducer,
    banks: BanksReducer,
    gateways: GatewaysReducer,
    gateway: GatewayReducer,
    employees: EmployeesReducer,
    exports: ExportsReducer,
    export_data: ExportReducer,
    templates: TemplatesReducer,
    template: TemplateReducer,
    products: ProductsReducer,
    sync_products: SyncProductsReducer,
};
