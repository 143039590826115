import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import PaymentLightbox from '../lightboxes/PaymentLightbox';
import { Screen, Message, Button, Input, Table, TableButton } from '../components';
import { __, formatAmount, formatDate, isEmptyString, isValidZip, request } from '../functions';
import { fetchSubscription, changeSubscriptionSettings, setUser } from '../actions';
import '../assets/styles/subscription.css';

/**
 * Clenstvo.
 */
class SubscriptionScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Členstvo');

    /**
     * Default state.
     *
     * @type {{
     *     loading_settings: boolean,
     *     loading_cancel: boolean,
     *     loading_table_button: ?string,
     *     settings: Object,
     *     lightbox: Object,
     * }}
     */
    state = {
        loading_settings: false,
        loading_cancel: false,
        loading_table_button: null,
        settings: {},
        lightbox: {
            payment: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchSubscription } = this.props;

            await fetchSubscription(this);

            const { subscription } = this.props;

            // Nasetujeme fakturacne udaje
            this.setState({ settings: subscription.settings.invoicing });
        }

        return true;
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Vratime error vo fakturacnych udajoch.
     *
     * @param {Object} settings
     *
     * @return {string}
     */
    getSettingsError(settings) {
        if (isEmptyString(settings.name)) {
            // Nie je vyplneny nazov
            return __('Nie je vyplnený názov');
        } else if (isEmptyString(settings.address)) {
            // Nie je vyplnena adresa
            return __('Nie je vyplnená adresa');
        } else if (isEmptyString(settings.city)) {
            // Nie je vyplnene mesto
            return __('Nie je vyplnené mesto');
        } else if (!isValidZip(settings.zip)) {
            // Neplatne PSC
            return __('Neplatné PSČ eshopu');
        }

        return '';
    }

    /**
     * Zrusenie predplatneho.
     */
    cancelPaid() {
        this.setState({ loading_cancel: true });

        request('/subscriptions/cancelPayment').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Nepodarilo sa zrusit
                this.showSnackbar('error', __('Nepodarilo sa zrušiť členstvo'));
                return;
            }

            window.location = '/subscription';
        });
    }

    /**
     * Uhrada.
     *
     * @param {number} id
     */
    paid(id) {
        request(`/subscriptions/createPayment/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Nepodarilo sa aktivovat
                this.showSnackbar('error', __('Nepodarilo sa aktivovať členstvo'));
                return;
            }

            // Aktivovane presmerujeme
            window.location = data.url;
        });
    }

    /**
     * Zobrazime lightbox uhrady.
     */
    showPaid() {
        const { subscription } = this.props;

        if (this.getSettingsError(subscription.settings.invoicing) !== '') {
            // Fakturacne udaje maju error
            this.showSnackbar('error', __('Najprv musíte uložiť svoje fakturačné údaje'));
            return;
        }

        this.showLightbox('payment');
    }

    /**
     * Ulozenie fakturacnych nastaveni.
     */
    saveSettings() {
        const { settings } = this.state;

        // Pozrieme ci nemaju nastavenia error
        const error = this.getSettingsError(settings);

        if (error !== '') {
            // Maju error, koncime
            this.showSnackbar('error', error);
            return;
        }

        const { changeSubscriptionSettings } = this.props;

        this.setState({ loading_settings: true });

        changeSubscriptionSettings(this, settings);
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {string} button_id
     */
    pdf(id, button_id) {
        // Nastavime button loading
        this.setState({ loading_table_button: button_id });

        request(`/subscriptions/pdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({ loading_table_button: null });

            // Stiahneme subor
            window.location = data.link;
        });
    }

    /**
     * Zformatujeme platby.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatPayments(items) {
        const { loading_table_button } = this.state;

        return _.map(items, ({ id, type, status, amount, orders, created }) => {
            let name = type === 'month' ? __('Mesačné členstvo') : __('Ročné členstvo');
            name = `${name} (${orders} ${__('objednávok')})`;

            return [
                formatDate(created, 'dd.mm.yyyy hh:ii'),
                formatAmount(amount, 'EUR'),
                name,
                [
                    status === 'expecting' ? <Chip className="waiting-payment" label={__('Čaká na platbu')} /> : null,
                    <TableButton
                        id={id}
                        key="pdf"
                        text={status === 'waiting' ? __('V najbližšom čase bude vystavená faktúra') : __('Stiahnuť PDF')}
                        icon={<PdfIcon color={status === 'waiting' ? 'action' : 'inherit'} />}
                        onClick={status === 'waiting' ? () => {} : button_id => this.pdf(id, button_id)}
                        loading_button={loading_table_button}
                    />,
                ],
            ];
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { subscription, user } = this.props;
        const { settings, loading_settings, loading_cancel } = this.state;

        if (_.isEmpty(subscription) || _.isEmpty(settings)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        // Je aktivna pravidelna platba?
        const active_recurring = !_.isEmpty(subscription.recurring);

        return super.render(
            <Paper className="subscription" elevation={0}>
                <Toolbar className="subscription__header">
                    <div className="subscription__header__left">
                        <Typography className="subscription__header__title" variant="h5">{__('Členstvo')}</Typography>
                        {active_recurring ? <Chip
                            label={formatDate(subscription.recurring.pay_date, __('Nasledujúca platba dňa dd.mm.yyyy'))}
                            className="subscription__header__left__date"
                        /> : null}
                    </div>
                    <div className="subscription__header__right">
                        {active_recurring ? <Tooltip title={__('Aktuálne členstvo ostane aktívne. Zrušená bude automatická obnova.')}>
                            <span>
                                <Button
                                    onClick={() => this.cancelPaid()}
                                    loading={loading_cancel}
                                    color="red"
                                >{__('Zrusiť členstvo')}</Button>
                            </span>
                        </Tooltip> : <Tooltip title={__('Aktivovať členstvo')}>
                        <span><Button
                            onClick={() => this.showPaid()}
                            color="orange"
                        >{__('Aktivovať členstvo')}</Button></span>
                        </Tooltip>}
                    </div>
                </Toolbar>
                <div className="subscription__content">
                    <Message type={user.subscription ? 'success' : 'error'}>
                        {user.subscription
                            ? __('Vaše členstvo je aktívne')
                            : __('Vaše členstvo je neaktívne')}
                    </Message>
                    <div className="subscription__content__panel">
                        <div className="subscription__content__panel__item">
                            <div className="subscription__content__panel__item__label">{__('Koniec členstva')}</div>
                            <div className="subscription__content__panel__item__value">
                                {formatDate(user.subscription_end, 'dd.mm.yyyy')}
                            </div>
                        </div>
                        <div className="subscription__content__panel__item">
                            <div className="subscription__content__panel__item__label">{__('Počet volných objednávok')}</div>
                            <div className="subscription__content__panel__item__value">
                                {subscription.orders !== null
                                    ? formatAmount(subscription.orders, '', 0)
                                    : (user.subscription ? __('Neobmedzene') : 0)}
                            </div>
                        </div>
                    </div>
                    {!_.isEmpty(subscription.payments) ? <div className="subscription__content__payments">
                        <Typography className="subscription__content__payments__title" variant="h6">
                            {__('Platby')}
                        </Typography>
                        <Table
                            columns={[
                                __('Dátum'),
                                __('Suma'),
                                __('Popis'),
                                '',
                            ]}
                            data={this.formatPayments(subscription.payments)}
                            count={subscription.payments.length}
                            page={1}
                            per_pages={user.settings.per_pages}
                            per_page={subscription.payments.length}
                            paginate={false}
                        />
                    </div> : null}
                    <div className="subscription__content__settings">
                        <Typography className="subscription__content__settings__title" variant="h6">
                            {__('Fakturačné údaje')}
                        </Typography>
                        <div className="subscription__content__settings__inputs">
                            <Input
                                label={__('Názov')}
                                value={settings.name}
                                onChange={value => this.onChangeSetting('name', value)}
                            />
                            <Input
                                label={__('Adresa')}
                                value={settings.address}
                                onChange={value => this.onChangeSetting('address', value)}
                            />
                            <Input
                                label={__('Mesto')}
                                value={settings.city}
                                onChange={value => this.onChangeSetting('city', value)}
                            />
                            <Input
                                label={__('PŠČ')}
                                value={settings.zip}
                                onChange={value => this.onChangeSetting('zip', value)}
                            />
                        </div>
                        <div className="subscription__content__settings__inputs">
                            <Input
                                label={__('IČO')}
                                value={settings.ico}
                                onChange={value => this.onChangeSetting('ico', value)}
                            />
                            <Input
                                label={__('DIČ')}
                                value={settings.dic}
                                onChange={value => this.onChangeSetting('dic', value)}
                            />
                            <Input
                                label={__('IČ DPH')}
                                value={settings.ic_dph}
                                onChange={value => this.onChangeSetting('ic_dph', value)}
                            />
                        </div>
                    </div>
                    <Button
                        onClick={() => this.saveSettings()}
                        loading={loading_settings}
                        className="subscription__content__button"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderLightbox(
                    'payment',
                    __('Aktivácia členstva'),
                    <PaymentLightbox
                        screen={this}
                        prices={subscription.prices}
                        pay_date={subscription.pay_date}
                        onClick={id => this.paid(id)}
                    />,
                    '',
                    '',
                    () => {},
                )}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ subscription, user }) => ({ subscription, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchSubscription,
    changeSubscriptionSettings,
    setUser,
})(SubscriptionScreen)));
