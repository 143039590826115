import { getCustomization } from './getCustomization';

/**
 * Vratime kluc customizacie.
 *
 * @return {string}
 */
export const getCustomizationKey = () => {
    const data = getCustomization();

    if (data === null) {
        // Nejde o customizaciu
        return '';
    }

    return data.key;
};