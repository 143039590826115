import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import LinkIcon from '@material-ui/icons/Link';
import { Screen, Button, Message, Select } from '../components';
import ServiceLightbox from '../lightboxes/ServiceLightbox';
import { __, request } from '../functions';
import { fetchHeureka, fetchHeurekaData, changeHeurekaSettings, setUser } from '../actions';
import '../assets/styles/heureka.css';

/**
 * Heureka.
 */
class HeurekaScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Heureka košík');

    /**
     * Default state.
     *
     * @type {{
     *     loading_data: boolean,
     *     loading_credentials: boolean,
     *     settings: ?Object,
     *     lightbox: Object,
     * }}
     */
    state = {
        loading_data: false,
        loading_credentials: false,
        settings: null,
        lightbox: {
            credentials: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchHeureka } = this.props;

            await fetchHeureka(this);

            const { heureka } = this.props;

            this.setState({ loading: false, settings: heureka.settings });
        }

        return true;
    }

    /**
     * Nacitame data heureky.
     */
    refreshData() {
        const { fetchHeurekaData } = this.props;

        this.setState({ loading_data: true });

        fetchHeurekaData(this);
    }

    /**
     * Event po kliku na link.
     *
     * @param {string} link
     */
    onClickLink(link) {
        // Nastavime link do clipboardu
        navigator.clipboard.writeText(link);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Zmena credentials.
     *
     * @param {Object} credentials
     */
    changeCredentials(credentials) {
        // Zatvorime lightbox
        this.closeLightbox('credentials');

        this.setState({ loading_credentials: true });

        request('/user-services/changeCredentials', { credentials }, 'POST', { service: 'Heureka' }).then(response => {
            const { status } = response.data;

            this.setState({ loading_credentials: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));

            // Refreshneme data
            this.refreshData();
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeHeurekaSettings } = this.props;
        const { settings } = this.state;

        this.setState({ loading: true });

        changeHeurekaSettings(this, settings);
    }

    /**
     * Event po aktivacii.
     */
    afterActivate() {
        // Zavrieme lightbox
        this.closeLightbox('credentials');

        this.setState({ loading: true });

        // Znova nacitame data
        this.componentDidMount();
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { heureka } = this.props;
        const { loading, loading_data, loading_credentials, settings } = this.state;

        if (_.isEmpty(heureka) || settings === null) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const empty = _.isEmpty(heureka.credentials);
        const states = _.reduce(heureka.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return super.render(
            <Paper className="heureka" elevation={0}>
                <Toolbar className="heureka__header">
                    <div className="heureka__header__left">
                        <Typography className="heureka__header__title" variant="h5">{__('Heureka košík')}</Typography>
                        {!_.isEmpty(heureka.data) ? <Tooltip title={__('Kliknutím skopírujete do schránky.')}>
                            <Chip
                                onClick={() => this.onClickLink(heureka.data.link)}
                                label={heureka.data.link}
                                clickable
                                color="primary"
                                icon={<LinkIcon />}
                            />
                        </Tooltip> : null}
                    </div>
                    {!empty ? <div className="heureka__header__right">
                        <Tooltip title={__('Načíta aktuálne údaje z heureky (stav xml feedu, košíku...)')}>
                            <span><Button
                                onClick={this.refreshData.bind(this)}
                                loading={loading_data}
                            >{__('Načítať údaje')}</Button></span>
                        </Tooltip>
                        <Button
                            onClick={() => this.showLightbox('credentials')}
                            loading={loading_credentials}
                            color="red"
                        >{__('Zmeniť prihlasovacie údaje')}</Button>
                    </div> : null}
                    {empty ? <Button
                        onClick={() => this.showLightbox('credentials')}
                        loading={loading_credentials}
                        color="green"
                    >{__('Aktivovať')}</Button> : null}
                </Toolbar>
                <div className="heureka__content">
                    {!_.isEmpty(heureka.data) && heureka.data.status === 'active'
                        ? <Message type="success">{__('Košík je aktivný.')}</Message>
                        : null
                    }
                    {!_.isEmpty(heureka.data) && heureka.data.status !== 'active'
                        ? <Message type="warning">{__('Košík zatial nie je aktivný. Musíte sa prihlásiť do administrácie heureky a zadať vyššie uvedenú API url. Následne musí košík prejsť schvalovacím procesom.')}</Message>
                        : null
                    }
                    {_.isEmpty(heureka.data)
                        ? <Message type="info">{__('Zadajte prihlasovacie údaje do heureky.')}</Message>
                        : null
                    }
                    {!_.isEmpty(heureka.settings) ? <div className="heureka__content__settings">
                        <Typography className="heureka__content__settings__title" variant="h6">{__('Nastavenie košíka')}</Typography>
                        <div className="heureka__content__settings__selects">
                            <Select
                                label={__('Stav objednávky po úhrade')}
                                options={states}
                                value={settings.state_paid}
                                onChange={value => this.onChangeSetting('state_paid', value)}
                            />
                            <Select
                                label={__('Stav objednávky po storne')}
                                options={states}
                                value={settings.state_cancel}
                                onChange={value => this.onChangeSetting('state_cancel', value)}
                            />
                        </div>
                        <Button
                            onClick={this.changeSettings.bind(this)}
                            loading={loading}
                            className="heureka__content__button"
                        >{__('Uložiť')}</Button>
                    </div> : null}
                </div>
                {this.renderLightbox(
                    'credentials',
                    `${__('Heureka košík')} - ${empty ? __('aktivácia') : __('zmena prihlasovacích údajov')}`,
                    <ServiceLightbox
                        screen={this}
                        services={heureka.services}
                        service_id={heureka.id}
                        item_label={__('Eshop')}
                        item_text={__('V heuréke máte zaevidovaných viacero eshopov, prosím vyberte konkrétny eshop')}
                        item_list_key="eshops"
                        item_id_key="eshop_id"
                        callback={empty ? this.afterActivate.bind(this) : this.changeCredentials.bind(this)}
                        create={empty}
                    />
                )}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ heureka, user }) => ({ heureka, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchHeureka,
    fetchHeurekaData,
    changeHeurekaSettings,
    setUser,
})(HeurekaScreen)));