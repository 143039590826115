import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import { CookiesProvider } from 'react-cookie';
import { reducers } from './reducers';
import App from './App';

const store = createStore(combineReducers(reducers), {}, applyMiddleware(ReduxThunk));

ReactDOM.render(<CookiesProvider><App store={store} /></CookiesProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();