import React, { Component } from 'react';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table } from './Table';
import { __, request, formatDate, toNumber } from '../functions';

/**
 * Zoznam logov.
 */
class LogsTable extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen,
     *     itemType: string,
     *     itemId: number,
     * }}
     */
    static defaultProps = {
        screen: null,
        itemType: '',
        itemId: 0,
    };

    /**
     * Default state.
     *
     * @type {{
     *     data: Object,
     * }}
     */
    state = {
        data: null,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.clean();

        // Nacitame pozadovanu stranku
        this.fetch(page);
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     */
    fetch(page = 1) {
        const { itemType, itemId } = this.props;

        request(`/user-logs/index/${itemType}/${itemId}`, { page, per_page: 10 }).then(response => {
            const { status, data } = response.data;

            if (status === 'success') {
                // Nacitame data
                this.setState({ data });
            }
        });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        this.setState({ data: null });
    }

    /**
     * Vratime log spravu.
     *
     * @param {string} event_type
     * @param {Object} event_data
     *
     * @return {string|null}
     */
    getLog(event_type, event_data) {
        const { data } = this.state;
        const { itemType } = this.props;

        switch (itemType) {
            case 'order':
                // Objednavka
                switch (event_type) {
                    case 'create':
                        // Objednavka vytvorena
                        return __('Objednávka bola vytvorená');

                    case 'create_invoice':
                        // Faktura vytvorena
                        return __('K objednávke bola vystavená faktúra');

                    case 'change_state':
                        // Zmena stavu
                        // Vytiahneme zoznam stavov
                        const states = _.reduce(data.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});
                        const state = _.has(states, event_data.state_id) ? states[event_data.state_id] : event_data.state_id;

                        return __('Zmenený stav objednávky na {STATE}').replace('{STATE}', state);

                    case 'sync':
                        // Synchronizacia
                        return __('Objednávka bola synchronizovaná s eshopom');

                    case 'paid':
                        // Uhradena
                        return __('Objednávka bola uhradená');

                    case 'send':
                        // Odoslane
                        return __('Objednávka bola odoslaná');

                    default:
                        return null;
                }

            case 'product':
                // Produkt
                switch (event_type) {
                    case 'stock':
                        // Zmeneny stav na sklade
                        return event_data.order_number !== ''
                            ? __('Objednávka č. {NUMBER} - {DIFF} ks na {VALUE}')
                                .replace('{NUMBER}', event_data.order_number)
                                .replace('{DIFF}', Math.abs(toNumber(event_data.diff)).toString())
                                .replace('{VALUE}', event_data.value)
                            : __('Zmena stavu na sklade o {DIFF} na {VALUE}')
                                .replace('{DIFF}', event_data.diff)
                                .replace('{VALUE}', event_data.value);

                    default:
                        return null;
                }

            default:
                return null;
        }
    }

    /**
     * Zformatujeme data.
     *
     * @param {Array} items
     *
     * @return {Array}
     */
    formatData(items) {
        return _.map(items, ({ user, event_type, data, created }) => {
            return [
                formatDate(created, 'dd.mm.yyyy hh:ii'),
                !_.isEmpty(user) ? user : __('Systém'),
                this.getLog(event_type, data),
            ];
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { data } = this.state;

        if (_.isEmpty(data)) {
            return <CircularProgress color="primary" size={40} />;
        }

        const { screen } = this.props;

        return <Table
            title={null}
            onChangePage={page => this.onChangePage(page)}
            onChangePerPage={per_page => this.onChangePerPage(per_page)}
            columns={[
                __('Čas'),
                __('Užívateľ'),
                __('Udalosť'),
            ]}
            data={this.formatData(data.items)}
            raw_data={data.items}
            count={data.total}
            page={data.page}
            per_pages={screen.props.user.settings.per_pages}
            per_page={10}
            empty_text={__('Zatiaľ nie sú žiadne udalosti')}
            allowPerPage={false}
        />;
    }
}

export { LogsTable };
