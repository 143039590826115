import _ from 'lodash';
import { FETCH_CLIENTS, CLEAN_CLIENTS, DELETE_CLIENT, DELETE_CLIENTS, CHANGE_CLIENT_NOTE } from './types';
import { request, __ } from '../functions';

/**
 * Nacitanie klientov.
 *
 * @param {Screen} screen
 * @param {object} options
 */
export const fetchClients = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/clients/index', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Vypneme loading
        screen.setState({ loading: false });

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_CLIENTS, payload: data });
    });
};

/**
 * Nastavime klientovi poznamku.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {string} note
 */
export const changeClientNote = (screen, id, note) => {
    return dispatch => request(`/clients/note/${id}`, { note }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Klienta sa nepodarilo zmenit
            screen.showSnackbar('error', __('Nepodarilo sa nastaviť internú poznámku'));
            return;
        }

        screen.showSnackbar('success', __('Interná poznámka bola nastavená'));

        dispatch({ type: CHANGE_CLIENT_NOTE, payload: { id, note } });
    });
};

/**
 * Zmazanie klienta.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteClient = (screen, id) => {
    return dispatch => request(`/clients/delete/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Klienta sa nepodarilo zmazat
            screen.showSnackbar('error', __('Zákazník nebol zmazaný pretože má evidované objednávky'));
            return;
        }

        screen.showSnackbar('success', __('Zákazník bol zmazaný'));

        dispatch({ type: DELETE_CLIENT });
    });
};

/**
 * Zmazanie klientov cez multiselect.
 *
 * @param {Screen} screen
 * @param {string} ids
 * @param {Object} filtered
 */
export const deleteClients = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/clients/multiDelete/${ids}`, params).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Klientov sa nepodarilo zmazat
            screen.showSnackbar('error', __('Zákazníci neboli zmazaní pretože majú evidované objednávky'));
            return;
        }

        screen.showSnackbar('success', __('Zákazníci boli zmazaní'));

        dispatch({ type: DELETE_CLIENTS });
    });
};

/**
 * Vycistenie klientov.
 */
export const cleanClients = () => ({ type: CLEAN_CLIENTS });