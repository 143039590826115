import { FETCH_COURIER, CHANGE_COURIER_SETTINGS } from '../actions/types';

const COURIER_DEFAULT_STATE = [];

/**
 * Reducer pre kuriera.
 */
const CourierReducer = (state = COURIER_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_COURIER:
            // Nacitanie kuriera
            return payload;

        case CHANGE_COURIER_SETTINGS:
            // Zmena nastaveni kuriera
            return { ...state, settings: payload };

        default:
            return state;
    }
};

export default CourierReducer;