import { CLEAN_ORDER, FETCH_CREATE_ORDER } from './types';
import { __, request } from '../functions';

/**
 * Vycistenie objednavky.
 */
export const cleanOrder = () => ({ type: CLEAN_ORDER });

/**
 * Vytvorenie objednavky.
 *
 * @param {Screen} screen
 */
export const fetchCreateOrder = (screen) => {
    return dispatch => request('/orders/create').then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_CREATE_ORDER, payload: data });
    });
};

/**
 * Pridame objednavku.
 *
 * @param {Screen} screen
 * @param {Object} data
 */
export const addOrder = (screen, data) => {
    return dispatch => request('/orders/add', { data }, 'POST').then(response => {
        const { status, data } = response.data;

        screen.setState({ loading: false });

        if (status === 'error') {
            // Nepodarilo sa ulozit objednavku
            screen.showSnackbar('error', __('Nepodarilo sa uložiť objednávku'));
            return;
        }

        window.location = `/orders/view/${data.id}`;
    });
};