import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Folder';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import { Screen, TableButton } from '../components';
import { __, formatAmount, formatDate, toNumber } from '../functions';
import { fetchAdmin, fetchAdminProcess, fetchAdminError, setUser } from '../actions';
import { COOKIE_SETTINGS } from '../config';
import '../assets/styles/admin.css';

/**
 * Admin.
 */
class AdminScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Admin');

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            const { fetchAdmin } = this.props;

            fetchAdmin(this);
        }

        return true;
    }

    /**
     * Zobrazime process.
     *
     * @param {string} name
     * @param {string} date
     */
    showProcess(name, date) {
        const { fetchAdminProcess } = this.props;

        fetchAdminProcess(this, name, date);
    }

    /**
     * Zobrazime error.
     *
     * @param {string} name
     * @param {string} date
     */
    showError(name, date) {
        const { fetchAdminError } = this.props;

        fetchAdminError(this, name, date);
    }

    /**
     * Prihlasenie ako.
     *
     * @param {string} token
     * @param {number} user_eshop_id
     */
    loginAs(token, user_eshop_id) {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set('token', token, COOKIE_SETTINGS);
        cookies.set('user_eshop_id', toNumber(user_eshop_id), COOKIE_SETTINGS);

        window.location = '/dashboard';
    }

    /**
     * Rendrujeme process.
     *
     * @returns {JSX.Element}
     */
    renderProcess() {
        const { admin } = this.props;

        return super.render(
            <Paper className="admin" elevation={0}>
                <Toolbar className="admin__header">
                    <div className="admin__header__left">
                        <Typography className="admin__header__title" variant="h5">Admin</Typography>
                    </div>
                    <div className="admin__header__right">
                        <IconButton className="admin__header__right__button">
                            <BackIcon onClick={() => window.location = '/admin'} />
                        </IconButton>
                    </div>
                </Toolbar>
                <div className="table">
                    <div className="table__title">{admin.date} - {admin.name}</div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Čas</TableCell>
                                <TableCell>Trvanie</TableCell>
                                <TableCell>Počet položiek</TableCell>
                                <TableCell>{null}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(admin.data, (item, key) => {
                                return (
                                    <TableRow key={key}>
                                        <TableCell>{formatDate(item.date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                        <TableCell>{item.duration}</TableCell>
                                        <TableCell>{item.items}</TableCell>
                                        <TableCell>{null}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }

    /**
     * Rendrujeme error.
     *
     * @returns {JSX.Element}
     */
    renderError() {
        const { admin } = this.props;

        return super.render(
            <Paper className="admin" elevation={0}>
                <Toolbar className="admin__header">
                    <div className="admin__header__left">
                        <Typography className="admin__header__title" variant="h5">Admin</Typography>
                    </div>
                    <div className="admin__header__right">
                        <IconButton className="admin__header__right__button">
                            <BackIcon onClick={() => window.location = '/admin'} />
                        </IconButton>
                    </div>
                </Toolbar>
                <div className="table">
                    <div className="table__title">{admin.date} - {admin.name}</div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Čas</TableCell>
                                <TableCell>Ip</TableCell>
                                <TableCell>Url</TableCell>
                                <TableCell>Chyba</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>{null}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(admin.data, (item, key) => {
                                return (
                                    <TableRow key={key}>
                                        <TableCell>{formatDate(item.date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                        <TableCell>{item.ip}</TableCell>
                                        <TableCell>{item.url}</TableCell>
                                        <TableCell>{item.message}</TableCell>
                                        <TableCell>{JSON.stringify(item.data)}</TableCell>
                                        <TableCell>{null}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { admin } = this.props;

        if (_.isEmpty(admin)) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        if (_.has(admin, 'type')) {
            // Renderujeme konkrenty typ
            switch (admin.type) {
                case 'process':
                default:
                    // Proces
                    return this.renderProcess();

                case 'error':
                    // Error
                    return this.renderError();
            }
        }

        return super.render(
            <Paper className="admin" elevation={0}>
                <Toolbar className="admin__header">
                    <div className="admin__header__left">
                        <Typography className="admin__header__title" variant="h5">Admin</Typography>
                    </div>
                </Toolbar>
                <div className="admin__stats">
                    <div className="admin__stats__panel">
                        <div className="admin__stats__panel__title">Užívatelia</div>
                        <div className="admin__stats__panel__value">
                            {admin.users.length} <span>+{admin.stats.users_daily}</span>
                        </div>
                    </div>
                    <div className="admin__stats__panel">
                        <div className="admin__stats__panel__title">Eshopy</div>
                        <div className="admin__stats__panel__value">
                            {admin.stats.eshops} <span>+{admin.stats.eshops_daily}</span>
                        </div>
                    </div>
                    <div className="admin__stats__panel">
                        <div className="admin__stats__panel__title">Platby</div>
                        <div className="admin__stats__panel__value">
                            {formatAmount(admin.stats.payments, 'EUR')} <span>+{formatAmount(admin.stats.payments_daily, 'EUR')}</span>
                        </div>
                    </div>
                    <div className="admin__stats__panel">
                        <div className="admin__stats__panel__title">Error log</div>
                        <div className={`admin__stats__panel__value ${admin.stats.error_log ? 'red' : ''}`}>
                            {admin.stats.error_log ? 'áno' : 'nie'}
                        </div>
                    </div>
                    <div className="admin__stats__panel">
                        <div className="admin__stats__panel__title">Debug log</div>
                        <div className={`admin__stats__panel__value ${admin.stats.debug_log ? 'red' : ''}`}>
                            {admin.stats.debug_log ? 'áno' : 'nie'}
                        </div>
                    </div>
                    <div className="admin__stats__panel">
                        <div className="admin__stats__panel__title">Dlho v procese</div>
                        <div className="admin__stats__panel__value">{admin.stats.processing}</div>
                    </div>
                </div>
                <div className="table">
                    <div className="table__title">{__('Errory')}</div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Názov</TableCell>
                                <TableCell>Počet</TableCell>
                                <TableCell>Posledné volanie</TableCell>
                                <TableCell>Posledná chyba</TableCell>
                                <TableCell>{null}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(admin.errors, (items, name) => {
                                return (
                                    <TableRow key={name}>
                                        <TableCell>{name}</TableCell>
                                        <TableCell>{items.length}</TableCell>
                                        <TableCell>{formatDate(items[0].date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                        <TableCell>{items[0].message}</TableCell>
                                        <TableCell>
                                            <TableButton
                                                key="detail"
                                                text={__('Detail')}
                                                icon={<ViewIcon />}
                                                onClick={() => this.showError(name, admin.date)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div className="table">
                    <div className="table__title">{__('Users')}</div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>{null}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(admin.users, ({ id, user_eshop_id, email, token }) => {
                                return (
                                    <TableRow key={id}>
                                        <TableCell>{id}</TableCell>
                                        <TableCell>{email}</TableCell>
                                        <TableCell>
                                            <TableButton
                                                key="detail"
                                                text={__('Detail')}
                                                icon={<ViewIcon />}
                                                onClick={() => this.loginAs(token, user_eshop_id)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div className="table">
                    <div className="table__title">{__('Procesy')}</div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Proces</TableCell>
                                <TableCell>Počet</TableCell>
                                <TableCell>Posledné volanie</TableCell>
                                <TableCell>Min. trvanie</TableCell>
                                <TableCell>Max. trvanie</TableCell>
                                <TableCell>Priemerné trvanie</TableCell>
                                <TableCell>{null}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(admin.processes, (items, name) => {
                                // Vytiahneme zoznam trvani
                                const durations = _.reduce(items, (result, item) => ([ ...result, item.duration ]), []);

                                return (
                                    <TableRow key={name}>
                                        <TableCell>{name}</TableCell>
                                        <TableCell>{items.length}</TableCell>
                                        <TableCell>{formatDate(items[0].date, 'dd.mm.yyyy hh:ii:ss')}</TableCell>
                                        <TableCell>{formatAmount(Math.min(...durations))}</TableCell>
                                        <TableCell>{formatAmount(Math.max(...durations))}</TableCell>
                                        <TableCell>{formatAmount(_.sum(durations) / durations.length)}</TableCell>
                                        <TableCell>
                                            <TableButton
                                                key="detail"
                                                text={__('Detail')}
                                                icon={<ViewIcon />}
                                                onClick={() => this.showProcess(name, admin.date)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }
}

const stateToProps = ({ admin, user }) => ({ admin, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchAdmin,
    fetchAdminProcess,
    fetchAdminError,
    setUser,
})(AdminScreen)));
