import React, { Component } from 'react';
import _ from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';
import { Message, Lightbox } from '../components';
import { __, getCustomization, toNumber } from '../functions';
import { COOKIE_SETTINGS, ACCOUNTING_SETTINGS } from '../config';

/**
 * Screen komponenta.
 */
class Screen extends Component {
    /**
     * Title.
     *
     * @type {string}
     */
    title = '';

    /**
     * Povolujeme eventy?
     *
     * @type {boolean}
     */
    allowEvents = true;

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = true;

    /**
     * Redirect po auth required.
     *
     * @type {boolean}
     */
    authRequiredRedirect = true;

    /**
     * Eshop required.
     *
     * @type {boolean}
     */
    eshopRequired = true;

    /**
     * Checkujem verziu?
     *
     * @type {boolean}
     */
    checkingVersion = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (!this.allowEvents) {
            return true;
        }

        window.scrollTo(0, 0);

        // Nasetujeme title
        this.setTitle(this.title);

        const { cookies } = this.props;

        if (this.getActualUrl() === '/logout') {
            // Chceme odhlasit
            // Zmazeme token
            cookies.remove('token', COOKIE_SETTINGS);

            window.location = '/login';
            return false;
        }

        // Pozrieme sa ci mame ulozeny token
        const token = cookies.get('token', COOKIE_SETTINGS);

        global.token = !_.isUndefined(token) ? token : '';

        if (this.authRequired && _.isEmpty(token)) {
            // Vyzaduje si auth a nemame token, presmerujeme na login
            this.redirect('/login');
            return false;
        } else if (!this.authRequired && this.authRequiredRedirect && !_.isEmpty(token)) {
            // Nevyzaduje si auth a mame token
            this.redirect('/dashboard');
            return false;
        }

        // Pozrieme sa ci mame ulozene user_eshop_id
        const user_eshop_id = toNumber(cookies.get('user_eshop_id', COOKIE_SETTINGS));

        global.user_eshop_id = user_eshop_id;

        if (
            this.authRequired
            && this.eshopRequired
            && !user_eshop_id
        ) {
            // Nie je zadane user eshop id a vyzaduje sa
            this.redirect('/dashboard');
            return false;
        }

        return true;
    }

    /**
     * Komponenta dostane nove props.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Skontrolujeme novu verziu
        this.checkVersion();
    }

    /**
     * Nasetujeme title.
     *
     * @param {string} title
     */
    setTitle(title) {
        const customization = getCustomization();
        const domain_name = customization !== null ? customization.domain : __('GoodEshop.sk');

        // Nasetujeme title
        document.title = !_.isEmpty(title) ? `${title} - ${domain_name}` : domain_name;
    }

    /**
     * Vratime aktualnu url adresu.
     *
     * @returns {string}
     */
    getActualUrl() {
        return this.props.location.pathname;
    }

    /**
     * Presmerujeme na path.
     *
     * @param {string} path
     */
    redirect(path) {
        this.props.history.push(path);
    }

    /**
     * Zobrazime snackbar.
     *
     * @param {string} type
     * @param {string} message
     */
    showSnackbar(type, message) {
        this.setState({
            snackbar: { ...this.state.snackbar, ...{ type, message } },
            // Ak ma screen aktivny table button tak aj ten schovame
            loading_table_button: null,
        });
    }

    /**
     * Event na schovanie snackbaru.
     */
    onCloseSnackbar() {
        this.setState({ snackbar: { ...this.state.snackbar, type: null } });
    }

    /**
     * Zobrazime lightbox.
     *
     * @param {string} type
     * @param {object,null} data
     */
    showLightbox(type, data = null) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: data !== null ? data : true } });
    }

    /**
     * Zatvorime lightbox.
     *
     * @param {string} type
     */
    closeLightbox(type) {
        this.onCloseLightbox(type);
    }

    /**
     * Event po zatvoreny lightboxu.
     *
     * @param {string} type
     */
    onCloseLightbox(type) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: false } });
    }

    /**
     * Ma fakturacny system dostupnu funkciu?
     *
     * @param {string} type
     * @param {number|null} accounting_id
     * @param {boolean|null} default_return
     *
     * @return {boolean}
     */
    hasAccountingAction(type, accounting_id = null, default_return = true) {
        if (_.has(this.props, 'user')) {
            const { user } = this.props;

            if (accounting_id === null) {
                accounting_id = user.accounting_id;
            }

            accounting_id = toNumber(accounting_id);

            if (accounting_id === 0) {
                // Nemame fakturacny system
                return false;
            }

            // Zformatujeme zoznam fakturacnych systemom
            const accountings = _.reduce(user.accountings, (result, accounting) => {
                return { ...result, [toNumber(accounting.id)]: accounting.name };
            }, {});

            if (
                _.has(accountings, accounting_id)
                && _.has(ACCOUNTING_SETTINGS, accountings[accounting_id])
            ) {
                // Mame fakturacny system
                const settings = ACCOUNTING_SETTINGS[accountings[accounting_id]];

                if (_.has(settings, type)) {
                    // Vratime nastavenie
                    return settings[type];
                }
            }
        }

        return default_return;
    }

    /**
     * Skontrolujeme verziu.
     */
    checkVersion() {
        if (
            this.allowEvents
            && _.has(this.props, 'user')
            && _.has(this.props, 'cookies')
            && !this.checkingVersion
        ) {
            const { user, cookies } = this.props;

            if (_.has(user, 'version')) {
                // Skontrolujeme verziu
                this.checkingVersion = true;

                const version = toNumber(user.version);
                const cookie_version = toNumber(cookies.get('version', COOKIE_SETTINGS));

                if (version > cookie_version) {
                    // Je nova verzia
                    // Nasetujeme verziu
                    cookies.set('version', version, COOKIE_SETTINGS);

                    if (caches) {
                        // Service worker cache should be cleared with caches.delete()
                        caches.keys().then(function(names) {
                            for (let name of names) caches.delete(name);
                        });
                    }

                    // delete browser cache and hard reload
                    window.location.reload(true);
                }
            }
        }
    }

    /**
     * Rendrovanie snackbaru.
     *
     * @returns {JSX.Element}
     */
    renderSnackbar() {
        if (!_.has(this.state, 'snackbar')) {
            return null;
        }

        const { type, message } = this.state.snackbar;

        return (
            <Portal>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={!_.isEmpty(type)}
                    autoHideDuration={6000}
                    onClose={this.onCloseSnackbar.bind(this)}
                    style={{ zIndex: 5000000 }}
                >
                    <Message type={type}>{message}</Message>
                </Snackbar>
            </Portal>
        );
    }

    /**
     * Rendrujeme lightbox.
     *
     * @param {string} type
     * @param {string} title
     * @param {JSX.Element} content
     * @param {string} agree_text
     * @param {string} close_text
     * @param {function|null} onAgree
     * @param {boolean} disable_close
     *
     * @return {JSX.Element}
     */
    renderLightbox(
        type,
        title,
        content,
        agree_text = '',
        close_text = '',
        onAgree = null,
        disable_close = false
    ) {
        return <Lightbox
            title={title}
            content={content}
            open={_.isBoolean(this.state.lightbox[type]) ? this.state.lightbox[type] : true}
            agree_text={agree_text}
            close_text={close_text}
            onAgree={onAgree}
            onExit={this.onCloseLightbox.bind(this, type)}
            disable_close={disable_close}
        />;
    }

    /**
     * Rendrovanie loadingu.
     *
     * @param {number} size
     *
     * @return {JSX.Element}
     */
    renderLoading(size = 40) {
        return <CircularProgress id="content-loading" color="primary" size={size} />;
    }

    /**
     * Rendrovanie.
     *
     * @param {JSX.Element} data
     *
     * @return {JSX.Element}
     */
    render(data) {
        if (!_.isEmpty(this.props.user)) {
            // Su nacitane data
            return data;
        }

        return (
            <div id="loading">
                <CircularProgress color="primary" size={50} />
            </div>
        );
    }
}

export { Screen };
