import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonCore from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Screen, Input, Button, Logo } from '../components';
import { __, getCustomization, request, toNumber } from '../functions';
import { IMAGES, COOKIE_SETTINGS } from '../config';
import '../assets/styles/login.css';

/**
 * Prihlasenie.
 */
class LoginScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Prihlásenie');

    /**
     * Default state.
     *
     * @type {{
     *     email: string,
     *     password: string,
     *     loading: boolean,
     *     social_links: Object|null,
     * }}
     */
    state = {
        email: '',
        password: '',
        loading: false,
        social_links: null,
    };

    /**
     * Auth required.
     *
     * @type {boolean}
     */
    authRequired = false;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame linky na soc siete
            request('/users/social-links').then(response => {
                const { data } = response.data;

                this.setState({ social_links: data });
            });
        }

        return true;
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    /**
     * Prihlasenie.
     *
     * @return boolean
     */
    login() {
        const { email, password } = this.state;

        if (_.isEmpty(email)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Nevyplnili ste email'));
            return false;
        } else if (_.isEmpty(password)) {
            // Nie je vyplnene heslo
            this.showSnackbar('error', __('Nevyplnili ste heslo'));
            return false;
        }

        this.setState({ loading: true });

        request('/users/login', { email, password }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Nesprávne prihlasovacie údaje'));
                return;
            }

            // Zavolame callback
            this.afterLogin(data.token, data.user_eshop_id);
        });
    }

    /**
     * Callback po prihlaseni.
     *
     * @param {string} token
     * @param {number} user_eshop_id
     */
    afterLogin(token, user_eshop_id) {
        const { cookies } = this.props;

        // Ulozime token do cookie
        cookies.set('token', token, COOKIE_SETTINGS);
        cookies.set('user_eshop_id', toNumber(user_eshop_id), COOKIE_SETTINGS);

        window.location = '/dashboard';
    }

    /**
     * Prihlasenie cez soc. siete.
     *
     * @param {string} link
     */
    socialLogin(link) {
        window.location = link;
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { email, password, loading, social_links } = this.state;

        if (social_links === null) {
            // Nie su nacitane linky na soc siete
            return (
                <Paper className="login" elevation={0}>
                    {this.renderLoading()}
                </Paper>
            );
        }

        // Mame customizaciu?
        const has_customization = getCustomization() !== null;

        return (
            <Paper className="login" elevation={0}>
                <Logo onClick={() => { window.location = '/'; }} />
                <Typography className="login__title" variant="h4">{__('Prihlásenie')}</Typography>
                <Typography className="login__subtitle">{__('Pre pokračovanie sa prosím prihláste')}</Typography>
                {!has_customization ? <div className="login__social">
                    <ButtonCore
                        onClick={!_.isEmpty(social_links)
                            ? () => this.socialLogin(social_links.facebook_login)
                            : () => {}}
                        className="login__social__button facebook"
                        variant="outlined"
                        color="inherit"
                    >
                        <FacebookIcon />
                        <Typography variant="body2">{__('Facebook')}</Typography>
                    </ButtonCore>
                    <ButtonCore
                        onClick={!_.isEmpty(social_links)
                            ? () => this.socialLogin(social_links.google_login)
                            : () => {}}
                        className="login__social__button google"
                        variant="outlined"
                        color="inherit"
                    >
                        <img src={IMAGES['google.svg']} alt="google logo" />
                        <Typography variant="body2">{__('Google')}</Typography>
                    </ButtonCore>
                </div> : null}
                {!has_customization ? <Typography className="login__or">{__('Alebo sa prihláste cez email a heslo')}</Typography> : null}
                <Input
                    type="email"
                    label={__('Email')}
                    value={email}
                    onChange={value => this.onChangeValue('email', value)}
                />
                <Input
                    type="password"
                    label={__('Heslo')}
                    value={password}
                    onChange={value => this.onChangeValue('password', value)}
                />
                <Button
                    onClick={this.login.bind(this)}
                    className="login__button"
                    loading={loading}
                >{__('Prihlásiť')}</Button>
                {!has_customization ? <Link to="/register">
                    <Button
                        color="transparent"
                        className="login__button"
                    >{__('Ešte nemáte účet? Registrujte sa.')}</Button>
                </Link> : null}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(LoginScreen)));
