import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import GatewayLightbox from '../lightboxes/GatewayLightbox';
import { Screen, Button, Message, Select, Input } from '../components';
import { __, request } from '../functions';
import { fetchGateway, changeGatewaySettings, setUser } from '../actions';
import { GATEWAYS } from '../config';
import '../assets/styles/gateway.css';

/**
 * Brana.
 */
class GatewayScreen extends Screen {
    /**
     * Default state.
     *
     * @type {{
     *     name: string,
     *     loading_credentials: boolean,
     *     loading_delete: boolean,
     *     settings: Object|null,
     *     lightbox: Object,
     * }}
     */
    state = {
        name: '',
        loading_credentials: false,
        loading_delete: false,
        settings: null,
        lightbox: {
            credentials: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        if (super.componentDidMount()) {
            // Nacitame data
            this.fetch().then();
        }

        return true;
    }

    /**
     * Nacitame data.
     */
    async fetch() {
        const { fetchGateway, match } = this.props;

        // Vytiahneme nazov brany
        const name = _.has(GATEWAYS, match.params.type) ? GATEWAYS[match.params.type] : '';

        // Nastavime title
        this.setTitle(name);

        await fetchGateway(this);

        const { gateway } = this.props;

        this.setState({ loading: false, name, settings: gateway.settings });
    }

    /**
     * Event po zmene settingu.
     *
     * @param {string} type
     * @param {string|Object} value
     */
    onChangeSetting(type, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [type]: value } });
    }

    /**
     * Zmena credentials.
     *
     * @param {Object} credentials
     */
    changeCredentials(credentials) {
        // Zatvorime lightbox
        this.closeLightbox('credentials');

        this.setState({ loading_credentials: true });

        const { match } = this.props;

        request(
            '/user-gateways/changeCredentials',
            { credentials },
            'POST',
            { gateway: match.params.type }
        ).then(response => {
            const { status } = response.data;

            this.setState({ loading_credentials: false });

            if (status === 'error') {
                // Nepodarilo sa zmenit credentials
                this.showSnackbar('error', __('Nepodarilo sa zmeniť prihlasovacie údaje'));
                return;
            }

            this.showSnackbar('success', __('Prihlasovacie údaje boli zmenené'));
        });
    }

    /**
     * Zmazanie brany.
     */
    delete() {
        this.setState({ loading_delete: true });

        const { match } = this.props;

        request('/user-gateways/delete', { gateway: match.params.type }).then(response => {
            const { status } = response.data;

            this.setState({ loading_delete: false });

            if (status === 'error') {
                // Nepodarilo sa zmazat
                this.showSnackbar('error', __('Nepodarilo sa zmazať platobnú bránu'));
                return;
            }

            // Zmazane, presmerujeme
            this.redirect('/gateways');
        });
    }

    /**
     * Zmena nastaveni.
     */
    changeSettings() {
        const { changeGatewaySettings } = this.props;
        const { settings } = this.state;

        this.setState({ loading: true });

        changeGatewaySettings(this, settings);
    }

    /**
     * Event po aktivacii.
     */
    afterActivate() {
        // Zavrieme lightbox
        this.closeLightbox('credentials');

        this.setState({ loading: true });

        // Znova nacitame data
        this.componentDidMount();
    }

    /**
     * Vratime item lightbox data podla brany.
     *
     * @return {Object}
     */
    getItemLightboxData() {
        const { match } = this.props;

        switch (match.params.type) {
            default:
                return {
                    label: '',
                    text: '',
                    list_key: '',
                    id_key: '',
                    allow_empty: false,
                };
        }
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { gateway } = this.props;
        const { name, loading, loading_credentials, loading_delete, settings } = this.state;

        if (_.isEmpty(gateway) || settings === null) {
            // Data nie su nacitane
            return super.render(this.renderLoading());
        }

        const empty = _.isEmpty(gateway.credentials);
        const item_lightbox_data = this.getItemLightboxData();

        return super.render(
            <Paper className="gateway" elevation={0}>
                <Toolbar className="gateway__header">
                    <div className="gateway__header__left">
                        <Typography className="gateway__header__title" variant="h5">{name}</Typography>
                    </div>
                    {!empty ? <div className="gateway__header__right">
                        <Button
                            onClick={() => this.showLightbox('credentials')}
                            loading={loading_credentials}
                            color="primary"
                        >{__('Zmeniť prihlasovacie údaje')}</Button>
                        <Button
                            onClick={() => this.delete()}
                            loading={loading_delete}
                            color="red"
                        >{__('Zmazať')}</Button>
                    </div> : null}
                    {empty ? <Button
                        onClick={() => this.showLightbox('credentials')}
                        loading={loading_credentials}
                        color="green"
                    >{__('Aktivovať')}</Button> : null}
                </Toolbar>
                <div className="gateway__content">
                    {empty
                        ? <Message type="info">{__('Najprv musíte zadať prihlasovacie údaje')}</Message>
                        : <Message type="success">{__('Platobná brána je aktívna')}</Message>
                    }
                    <div className="gateway__content__title">
                        <Typography
                            className="gateway__content__title__text"
                            variant="h6"
                        >{__('Nastavenie')}</Typography>
                    </div>
                    <div className="gateway__content__settings">
                        <div className="gateway__content__settings__selects">
                            <Select
                                label={__('Referencia objednávky')}
                                options={{ 'number': __('Číslo objednávky'), 'id': __('ID objednávky') }}
                                value={settings.reference_id}
                                onChange={value => this.onChangeSetting('reference_id', value)}
                                allow_empty={false}
                            />
                            {name === 'Stripe' ? <Input
                                label={__('Názov vlastného polia pre referenciu objednávky')}
                                value={settings.meta_data_reference}
                                onChange={value => this.onChangeSetting('meta_data_reference', value)}
                            /> : null}
                        </div>
                    </div>
                    {!_.isEmpty(settings) ? <Button
                        onClick={this.changeSettings.bind(this)}
                        loading={loading}
                        className="gateway__content__button"
                        disabled={empty}
                    >{__('Uložiť')}</Button> : null}
                </div>
                {this.renderLightbox(
                    'credentials',
                    `${name} - ${empty ? __('aktivácia') : __('zmena prihlasovacích údajov')}`,
                    <GatewayLightbox
                        screen={this}
                        gateways={gateway.gateways}
                        gateway_id={gateway.id}
                        callback={empty ? this.afterActivate.bind(this) : this.changeCredentials.bind(this)}
                        create={empty}
                        item_label={item_lightbox_data.label}
                        item_text={item_lightbox_data.text}
                        item_list_key={item_lightbox_data.list_key}
                        item_id_key={item_lightbox_data.id_key}
                        item_allow_empty={item_lightbox_data.allow_empty}
                    />
                )}
                {this.renderSnackbar()}
            </Paper>
        );
    }
}

const stateToProps = ({ gateway, user }) => ({ gateway, user });

export default withCookies(withRouter(connect(stateToProps, {
    fetchGateway,
    changeGatewaySettings,
    setUser,
})(GatewayScreen)));