import { FETCH_GATEWAY, CHANGE_GATEWAY_SETTINGS } from '../actions/types';

const GATEWAY_DEFAULT_STATE = [];

/**
 * Reducer pre brany.
 */
const GatewayReducer = (state = GATEWAY_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_GATEWAY:
            // Nacitanie brany
            return payload;

        case CHANGE_GATEWAY_SETTINGS:
            // Zmena nastaveni brany
            return { ...state, settings: payload };

        default:
            return state;
    }
};

export default GatewayReducer;