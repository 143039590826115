import React, { Component } from 'react';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import MainTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FilterIcon from '@material-ui/icons/FilterList';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button, Checkbox, Filter, Message } from '../components';
import { __, toNumber, plural, request, formatDate } from '../functions';
import '../assets/styles/list.css';

/**
 * Table komponenta.
 */
class Table extends Component {
    /**
     * Default props.
     *
     * @type {{
     *  title: string,
     *  class_name: string,
     *  multiselect: Array,
     *  multiselect_selected: Array,
     *  multiselect_selected_force: Array,
     *  multiselect_selected_refresh_url: string,
     *  filter: Object,
     *  filtered: Object,
     *  columns: Array,
     *  columns_key: string,
     *  columns_settings: Array,
     *  columns_max: number|null,
     *  data: Array,
     *  raw_data: Array,
     *  count: number,
     *  page: number,
     *  per_pages: Array,
     *  per_page: number,
     *  onChangeSettings: function(),
     *  onChangeFilter: function(),
     *  onChangePage: function(),
     *  onChangePerPage: function(),
     *  onChangeLocked: function(),
     *  onChangeIds: function(),
     *  empty_text: string,
     *  message: Object,
     *  paginate: boolean,
     *  allowPerPage: boolean,
     *  right_data: string
     * }}
     */
    static defaultProps = {
        title: '',
        class_name: '',
        multiselect: [],
        multiselect_selected: [],
        multiselect_selected_force: [],
        multiselect_selected_refresh_url: '',
        filter: {},
        filtered: {},
        columns: [],
        columns_key: '',
        columns_settings: [],
        columns_max: null,
        data: [],
        raw_data: [],
        count: 0,
        page: 0,
        per_pages: [],
        per_page: 0,
        empty_text: __('Zatiaľ nemáte žiadne položky'),
        message: {},
        paginate: true,
        allowPerPage: true,
        onCheckbox: null,
        right_data: null,
        onChangeSettings: () => {},
        onChangeFilter: () => {},
        onChangePage: () => {},
        onChangePerPage: () => {},
        onChangeLocked: () => {},
        onChangeIds: () => {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     columns: Array,
     *     loading_action: null|number,
     *     loading_settings: boolean,
     *     filter_opened: boolean,
     *     settings_opened: boolean,
     *     multiselect_ids: Array,
     *     all_multiselect_ids: boolean,
     *     locked: boolean,
     *     settings: Array,
     * }}
     */
    state = {
        columns: [],
        loading_action: null,
        loading_settings: false,
        filter_opened: false,
        settings_opened: false,
        multiselect_ids: [],
        all_multiselect_ids: false,
        locked: false,
        settings: [],
    };

    /**
     * Identifikator nastavenia sltpcov.
     *
     * @type {string}
     */
    static columns_identify = '==columns==';

    /**
     * Drzi shift?
     *
     * @type {boolean}
     */
    holdShift = false;

    /**
     * Posledny kliknuty checkbox.
     *
     * @type {null|number}
     */
    lastCheckbox = null;

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        // Nastavime listener
        window.addEventListener('keydown', this.keyDown);
        window.addEventListener('keyup', this.keyUp);

        const { multiselect_selected, columns, columns_settings } = this.props;

        if (!_.isEmpty(multiselect_selected)) {
            // Mame zadane oznacene, tym padom je aj zamknute
            const { onChangeLocked, onChangeIds } = this.props;
            const multiselect_ids = _.map(multiselect_selected, key => toNumber(key));

            onChangeLocked(true);
            onChangeIds(multiselect_ids);

            this.setState({ locked: true, multiselect_ids });
        }

        let settings = [];

        // Naformatujeme stlpce
        const formatted_columns = _.reduce(columns, (result, column) => {
            if (_.isObject(column)) {
                // Je objekt, pozrieme na nastavenia
                const checked = _.includes(columns_settings, column.key);

                settings = [ ...settings, { ...column, checked } ];

                if (!checked) {
                    // Nezobrazujeme
                    return result;
                }
            }

            // Ked nie je object vzdy zobrazujeme
            return [ ...result, column ];
        }, []);

        // Nasetujeme stlpce
        this.setState({ columns: formatted_columns, settings });
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Zmazeme listener
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('keyup', this.keyUp);
    }

    /**
     * Stlacenie klavesi.
     *
     * @param {Object} event
     */
    keyDown = ({ key }) => {
        if (key === 'Shift') {
            this.holdShift = true;
        }
    }

    /**
     * Pustenie klavesi.
     *
     * @param {Object} event
     */
    keyUp = ({ key }) => {
        if (key === 'Shift') {
            this.holdShift = false;
        }
    }

    /**
     * Zapneme/vypneme filter.
     */
    filter() {
        this.setState({ filter_opened: !this.state.filter_opened, settings_opened: false });
    }

    /**
     * Zapneme/vypneme nastavenia stlpcov.
     */
    settings() {
        this.setState({ settings_opened: !this.state.settings_opened, filter_opened: false });
    }

    /**
     * Event po zmene filtra.
     *
     * @param {Object} data
     */
    onChangeFilter(data) {
        // Schovame filter
        this.filter();

        const { onChangeFilter } = this.props;

        // Zavolame callback
        onChangeFilter(data);
    }

    /**
     * Event po zmene strany.
     *
     * @param {number} page
     */
    onChangePage(page) {
        const { onChangePage } = this.props;

        // Zavolame callback
        onChangePage(page);
    }

    /**
     * Event po zmene strankovania.
     *
     * @param {number} per_page
     */
    onChangePerPage(per_page) {
        const { onChangePerPage } = this.props;

        // Zavolame callback
        onChangePerPage(per_page);
    }

    /**
     * Event po zmene checkboxu.
     *
     * @param {number} index
     * @param {boolean} checked
     */
    onChecked(index, checked) {
        let { multiselect_ids } = this.state;
        const { onChangeIds, onCheckbox } = this.props;

        let selected = [index];

        if (this.holdShift && this.lastCheckbox !== null) {
            // Drzi shift a pred tym sme klikli na checkbox
            const min = index < this.lastCheckbox ? index : this.lastCheckbox;
            const max = index > this.lastCheckbox ? index : this.lastCheckbox;

            for (let i = min + 1; i < max; i++) {
                // Oznacime
                selected = [ ...selected, i ];
            }
        }

        if (checked) {
            // Zaskrtnute, pridame
            multiselect_ids = [ ...multiselect_ids, ...selected ];
        } else {
            // Odskrtnute, odstranime
            if (selected.length > 1) {
                // Odskrtavame aj posledne oznaceny
                selected = [ ...selected, this.lastCheckbox ];
            }

            multiselect_ids = _.remove(multiselect_ids, i => !_.includes(selected, i));
        }

        // Nastavime referenciu na posledny checkbox
        this.lastCheckbox = index;

        onChangeIds(multiselect_ids);

        let state = { multiselect_ids };

        if (!checked) {
            // Odskrtli sme checkbox, zrusime pripadne vsetky checkboxy
            state = { ...state, all_multiselect_ids: false };
        }

        this.setState(state);

        if (onCheckbox !== null) {
            // Mame custom event
            onCheckbox(index, checked);
        }
    }

    /**
     * Event po zmene vsetkych checkboxov.
     *
     * @param {boolean} checked
     */
    onCheckedAll(checked) {
        const { all_multiselect_ids } = this.state;
        const { data, onChangeIds } = this.props;

        onChangeIds([]);

        this.setState({
            // Ak zaskrtneme vsetky checkboxy, pridame idcka celej strany, inak zrusime vsetky
            multiselect_ids: !all_multiselect_ids ? _.map(_.keys(data), id => toNumber(id)) : [],
            all_multiselect_ids: !all_multiselect_ids,
        });
    }

    /**
     * Zmazeme filtered field.
     *
     * @param {string} key
     */
    deleteFiltered(key) {
        const { filtered, onChangeFilter } = this.props;

        // Odstranime field
        _.unset(filtered, key);

        // Zavolame callback
        onChangeFilter(filtered);
    }

    /**
     * Zavolanie multiselect akcie.
     *
     * @param {number} index
     */
    multiSelect(index) {
        if (index === 0) {
            // Lock button
            const { locked } = this.state;
            const { onChangeLocked, multiselect_selected, multiselect_selected_refresh_url } = this.props;

            if (locked) {
                // Je zamknute, zrusime aj oznacene polozky
                if (!_.isEmpty(multiselect_selected)) {
                    // Su zadane select z url, musime refreshnut naprazdno
                    window.location = multiselect_selected_refresh_url;
                    return;
                }

                this.setState({
                    locked: false,
                    multiselect_ids: [],
                    all_multiselect_ids: false,
                });

                onChangeLocked(false);
            } else {
                this.setState({ locked: true });

                onChangeLocked(true);
            }

            return;
        }

        const { multiselect, raw_data, count } = this.props;
        let { multiselect_ids, all_multiselect_ids } = this.state;

        multiselect_ids = _.map(multiselect_ids, multiselect_id => raw_data[multiselect_id].id);

        // Odpocitame index kvoli lock buttonu
        const { callback, confirm } = multiselect[index - 1];

        // Idcka all nastavime iba ak je nastavene vsetko ale vysledkov je viac ako na aktualnej stranke
        const ids = all_multiselect_ids && count > multiselect_ids.length ? 'all' : multiselect_ids.join(',');

        if (typeof confirm !== 'undefined') {
            // Mame nastaveny confirm, zobrazime
            confirm(ids, this.clearCallback.bind(this), this.loadingCallback.bind(this, index));
            return;
        }

        // Zapneme loading
        this.loadingCallback(index);

        // Zavolame callback
        callback(ids, this.clearCallback.bind(this));
    }

    /**
     * Callback na loading.
     *
     * @param {number} index
     */
    loadingCallback(index) {
        this.setState({ loading_action: index });
    }

    /**
     * Callback na vycistenie multiselectu.
     */
    clearCallback() {
        const { locked } = this.state;

        if (locked) {
            // Zoznam je zamknuty
            this.setState({ loading_action: null });
            return;
        }

        this.setState({
            loading_action: null,
            multiselect_ids: [],
            all_multiselect_ids: false,
        });
    }

    /**
     * Event po zmene nastaveni stlpca
     *
     * @param {string} type
     * @param {bool} value
     */
    onChangeSetting(type, value) {
        let { settings } = this.state;

        settings = _.map(settings, setting => {
            if (setting.key === type) {
                // Menime toto nastavenie
                setting = { ...setting, checked: value };
            }

            return setting;
        });

        this.setState({ settings });
    }

    /**
     * Ulozenie nastaveni.
     */
    saveSettings() {
        const { columns_key, onChangeSettings } = this.props;
        const { settings } = this.state;

        this.setState({ loading_settings: true });

        // Vytiahneme zoznam zaskrtnutych stlpcov
        const checked = _.reduce(settings, (result, setting) => {
            if (setting.checked) {
                result = [ ...result, setting.key ];
            }

            return result;
        }, []);

        request('/user-eshops/saveColumns', { key: columns_key, settings: checked }, 'POST').then(response => {
            this.setState({ loading_settings: false, settings_opened: false });

            onChangeSettings();
        });
    }

    /**
     * Vratime title.
     *
     * @return {JSX.Element}
     */
    renderTitle() {
        let { multiselect } = this.props;
        const { title, count, filter } = this.props;
        const { multiselect_ids, all_multiselect_ids, loading_action, locked } = this.state;

        if (title === null) {
            // Nechceme zobrazovat title
            return null;
        }

        let left_data = title;
        let right_data = !_.isEmpty(filter) ? this.renderFiltered() : this.props.right_data;
        let active = false;

        if (!_.isEmpty(multiselect_ids) || all_multiselect_ids) {
            // Mame aktivny multiselect
            active = true;
            const total = all_multiselect_ids ? count : multiselect_ids.length;

            left_data = <Typography variant="h6">
                {`${total} ${plural(total, [
                    __('označená položka'),
                    __('označené položky'),
                    __('označených položiek'),
                ])}`}
            </Typography>;

            const { raw_data } = this.props;

            // Pridame akciu na uzamknutie
            multiselect = [ {
                icon: !locked ? <LockIcon /> : <UnlockIcon />,
                text: !locked
                    ? __('Uzamknutie označených položiek. Položky ostanú označené po vykonaní akcie.')
                    : __('Odomknutie označených položiek'),
            }, ...multiselect ];

            right_data = _.map(multiselect, (item, index) => {
                if (item === null) {
                    return null;
                }

                let { icon, text, require, limit } = item;

                if (!_.isEmpty(require) && !all_multiselect_ids) {
                    // Mame zadane povinne fieldy
                    // Skontrolujeme ci ich oznacene polozky obsahuju
                    let valid = true;

                    _.map(raw_data, (item, key) => {
                        if (_.includes(multiselect_ids, key)) {
                            // Tento riadok je oznaceny
                            const multiselect_fields = _.has(item, 'multiselect_fields') ? item.multiselect_fields : [];

                            // Prejdeme zoznam require fieldov
                            _.each(require, field => {
                                if (!_.includes(multiselect_fields, field)) {
                                    // Polozka neobsahuje required field, akcia je nevalidna
                                    valid = false;
                                }
                            });
                        }
                    });

                    if (!valid) {
                        // Akcia nie je validna
                        return null;
                    }
                }

                limit = toNumber(limit);

                if (
                    limit > 0
                    && (
                        multiselect_ids.length > limit
                        || (all_multiselect_ids && total > limit)
                    )
                ) {
                    // Je zadany limit a je oznacenych viacej poloziek ako limit
                    return null;
                }

                if (loading_action === index) {
                    // Akcia sa nacitava
                    icon = <CircularProgress color="inherit" size={20} />;
                }

                if (limit > 0) {
                    // Mame limit zobrazime ho v nazve
                    text = `${text} (max ${limit} ${__('položiek')})`;
                }

                return <Tooltip title={text} key={index}>
                    <IconButton onClick={this.multiSelect.bind(this, index)}>{icon}</IconButton>
                </Tooltip>;
            });
        }

        return (
            <Toolbar className={`list__header ${active ? 'multiselect' : ''}`}>
                {left_data}
                <div>{right_data}</div>
            </Toolbar>
        );
    }

    /**
     * Rendrovanie nastaveni.
     */
    renderSettings() {
        const { columns_max } = this.props;
        const { settings, loading_settings } = this.state;

        // Spocitame pocet aktivnych stlpcov
        const checked = _.reduce(settings, (result, setting) => result + (setting.checked ? 1 : 0), 0);

        return (
            <div className="list__settings">
                <div className="list__settings__values">
                    {_.map(settings, (setting, key) => {
                        return <Checkbox
                            label={setting.name}
                            value={setting.checked}
                            onChange={checked => this.onChangeSetting(setting.key, checked)}
                            key={key}
                            disabled={!setting.checked && checked >= columns_max}
                        />
                    })}
                </div>
                <div className="list__settings__buttons">
                    <Button
                        onClick={() => this.saveSettings()}
                        loading={loading_settings}
                    >{__('Uložiť')}</Button>
                </div>
            </div>
        );
    }

    /**
     * Rendrujeme filtrovane fieldy.
     *
     * @return {JSX.Element}
     */
    renderFiltered() {
        const { filter, filtered, filter_opened } = this.props;
        const { settings, settings_opened } = this.state;

        return (
            <div className="list__filtered">
                {_.map(filtered, (value, key) => {
                    let label = null;

                    key = key.replace(' >=', '-from').replace(' <=', '-to');

                    switch (filter[key].type) {
                        case 'select':
                            label = `${filter[key].name}: ${filter[key].options[value]}`;
                            break;

                        case 'input':
                            label = `${filter[key].name}: "${value}"`;
                            break;

                        case 'date':
                            label = `${filter[key].name}: "${formatDate(value, 'dd.mm.yyyy')}"`;
                            break;

                        default:
                            label = null;
                            break;
                    }

                    return <Chip
                        className="list__filtered__value"
                        label={label}
                        onDelete={() => this.deleteFiltered(key)}
                        color="primary"
                    />;
                })}
                <Tooltip title={__('Filter')}>
                    <IconButton onClick={this.filter.bind(this)}>
                        <FilterIcon color={filter_opened || !_.isEmpty(filtered) ? 'primary' : 'inherit'} />
                    </IconButton>
                </Tooltip>
                {!_.isEmpty(settings) ? <Tooltip title={__('Nastavenia stĺpcov')}>
                    <IconButton onClick={this.settings.bind(this)}>
                        <SettingsIcon color={settings_opened ? 'primary' : 'inherit'} />
                    </IconButton>
                </Tooltip> : null}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const {
            multiselect,
            class_name,
            filter,
            filtered,
            data,
            raw_data,
            count,
            page,
            per_pages,
            per_page,
            empty_text,
            message,
            onCheckbox,
            paginate,
            allowPerPage,
            multiselect_selected_force
        } = this.props;
        const { filter_opened, multiselect_ids, all_multiselect_ids, columns, settings_opened } = this.state;

        if (_.isEmpty(columns)) {
            // Nie su vygenerovane stlpce
            return null;
        }

        const colspan = columns.length + (!_.isEmpty(multiselect) || onCheckbox !== null ? 1 : 0);

        return (
            <Paper className={`list ${class_name}`} elevation={0}>
                {this.renderTitle()}
                {!_.isEmpty(filter) && filter_opened ? <Filter
                    data={filter}
                    filtered={filtered}
                    onChange={this.onChangeFilter.bind(this)}
                /> : null}
                {settings_opened ? this.renderSettings() : null}
                {!_.isEmpty(message) ? <Message type={message.type}>{message.text}</Message> : null}
                <TableContainer>
                    <MainTable size="small">
                        <TableHead>
                            <TableRow>
                                {!_.isEmpty(multiselect) || onCheckbox !== null ? <TableCell padding="checkbox">
                                    <Checkbox
                                        value={all_multiselect_ids}
                                        onChange={checked => this.onCheckedAll(checked)}
                                        disabled={data.length === 0 || onCheckbox !== null}
                                    />
                                </TableCell> : null}
                                {_.map(columns, (column, key) => <TableCell key={key}>{_.isObject(column) ? column.name : column}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(data, (row, index) => {
                                // Je riadok oznaceni?
                                const selected = (!_.isEmpty(multiselect) || onCheckbox !== null)
                                    && (_.includes(multiselect_ids, index) || _.includes(multiselect_selected_force, index) || all_multiselect_ids);

                                return (
                                    <TableRow className={selected ? 'selected' : ''} key={index}>
                                        {!_.isEmpty(multiselect) || onCheckbox !== null ? <TableCell padding="checkbox">
                                            <Checkbox
                                                value={selected}
                                                onChange={checked => this.onChecked(index, checked)}
                                            />
                                        </TableCell> : null}
                                        {_.map(row, (cell, cell_index) => {
                                            if (cell === Table.columns_identify) {
                                                // Doplnime settings stlpce
                                                return _.reduce(columns, (result, column, column_index) => {
                                                    if (_.isObject(column)) {
                                                        // Setting stlpec
                                                        result = [
                                                            ...result,
                                                            <TableCell key={`${cell_index}${column_index}`}>
                                                                {column.value(raw_data[index])}
                                                            </TableCell>
                                                        ];
                                                    }

                                                    return result;
                                                }, []);
                                            }

                                            return <TableCell key={cell_index}>{cell}</TableCell>;
                                        })}
                                    </TableRow>
                                );
                            })}
                            {count === 0 ? <TableRow>
                                <TableCell
                                    colSpan={colspan}
                                    className="list__empty"
                                >{_.isEmpty(filtered) ? empty_text : __('Zadanému filtru nezodpovedajú žiadne položky')}</TableCell>
                            </TableRow> : null}
                        </TableBody>
                        {paginate ? <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={allowPerPage ? per_pages : []}
                                    count={count}
                                    rowsPerPage={per_page}
                                    page={page - 1}
                                    backIconButtonText={__('Predošlá stránka')}
                                    nextIconButtonText={__('Ďalšia stránka')}
                                    labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                    labelRowsPerPage={__('Počet záznamov na stránku')}
                                    onChangePage={(event, page) => this.onChangePage(page + 1)}
                                    onChangeRowsPerPage={event => this.onChangePerPage(parseInt(event.target.value))}
                                    colSpan={colspan}
                                />
                            </TableRow>
                        </TableFooter> : null}
                    </MainTable>
                </TableContainer>
            </Paper>
        );
    }
}

export { Table };
