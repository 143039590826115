import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Create';
import { Message, LogsTable, Input, Button } from '../components';
import { __, formatAmount, formatDate, request } from '../functions';
import { BANKS, GATEWAYS } from '../config';
import '../assets/styles/order-lightbox.css';

/**
 * Order lightbox.
 */
class OrderLightbox extends Component
{
    /**
     * Default props.
     *
     * @type {{
     *     screen: Screen,
     *     id: number,
     *     callbackInternalComment: function,
     * }}
     */
    static defaultProps = {
        screen: null,
        id: 0,
        callbackInternalComment: () => {},
    };

    /**
     * Default state.
     *
     * @type {{
     *     data: Object,
     *     panel: number,
     *     internal_comment: Object,
     * }}
     */
    state = {
        data: {},
        panel: 0,
        internal_comment: {
            loading: false,
            edit: false,
            value: '',
        },
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { id, screen } = this.props;

        // Nacitame data objednavky
        request(`/orders/view/${id}`, false, 'GET', {}, false).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Objednavka neexistuje
                screen.showSnackbar('error', __('Objednávka neexistuje'));
                screen.closeLightbox('view');
                return;
            }

            // Nasetujeme data
            this.setState({ data });
        }).catch(error => {
            screen.showSnackbar('error', __('Objednávka neexistuje'));
            screen.closeLightbox('view');
        });
    }

    /**
     * Zmena panelu.
     *
     * @param {number} panel
     */
    changePanel(panel) {
        this.setState({ panel });
    }

    /**
     * Editacia internej poznamky.
     *
     * @param {string} comment
     */
    editInternalComment(comment) {
        this.setState({ internal_comment: { edit: true, value: comment } });
    }

    /**
     * Event po zmene internej poznamky.
     *
     * @param {string} value
     */
    onChangeInternalComment(value) {
        const { internal_comment } = this.state;

        this.setState({ internal_comment: { ...internal_comment, value } });
    }

    /**
     * Ulozime internu poznamku.
     */
    saveInternalComment() {
        const { id, screen, callbackInternalComment } = this.props;
        const { internal_comment, data } = this.state;

        this.setState({ internal_comment: { ...internal_comment, loading: true } });

        request(`/orders/save-internal-comment/${id}`, { comment: internal_comment.value }, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                // Neplatne udaje
                this.setState({ internal_comment: { ...internal_comment, loading: false } });
                screen.showSnackbar('error', __('Nepodarilo sa uložiť internú poznámku'));
                return;
            }

            screen.showSnackbar('success', __('Interná poznámka bola uložená'));

            this.setState({
                internal_comment: { ...internal_comment, loading: false, edit: false },
                data: { ...data, data: { ...data.data, internal_comment: internal_comment.value } },
            });

            // Zavolame callback
            callbackInternalComment(id, internal_comment.value);
        });
    }

    /**
     * Rendrujeme adresu.
     *
     * @param {string} name
     * @param {string} address
     * @param {string} city
     * @param {string} zip
     * @param {string} country
     *
     * @return {JSX.Element|string}
     */
    renderAddress(name, address, city, zip, country) {
        if (_.isEmpty(address)) {
            // Nie je vyplnena adresa
            return '-';
        }

        const { data } = this.state;
        const full_address = `${address}, ${city} ${zip}, ${data.countries[country.toLowerCase()]}`;

        return <a
            href={`https://www.google.com/maps/search/?api=1&query=${full_address}`}
            target="_blank"
            rel="noopener noreferrer"
        >{name !== '' ? <div>{name}</div> : ''}{full_address}</a>;
    }

    /**
     * Rendrujeme hodnotu.
     *
     * @param {string} value
     *
     * @return {string}
     */
    renderValue(value) {
        return !_.isEmpty(value) ? value : '-';
    }

    /**
     * Rendrovanie obsahu.
     *
     * @param {number} panel
     *
     * @return {JSX.Element|null}
     */
    renderContent(panel) {
        const { data, internal_comment } = this.state;
        const { meta_data, payment_type, delivery_type, amount, state, number, currency, client, products } = data.data;

        switch (panel) {
            case 0:
                // Objednavka
                const banks = { ...BANKS, ...GATEWAYS };

                const package_send = data.package.number !== ''
                    && data.package.number !== 'waiting_import'
                    && data.package.number !== 'waiting_payment';

                return (
                    <Paper className="order-lightbox__content__order">
                        <div className="order-lightbox__content__order__panel">
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Číslo')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{this.renderValue(number)}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Stav')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{state.name}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Suma')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{formatAmount(amount, currency)}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Vytvorené')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{formatDate(data.created, 'dd.mm.yyyy hh:ii')}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Platba')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{payment_type.name}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Dodanie')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{delivery_type.name}</div>
                            </div>
                        </div>
                        <div className="order-lightbox__content__order__panel">
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Faktúra')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{!_.isEmpty(data.invoice) ? data.invoice.data.number : '-'}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Uhradené')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">
                                    {!_.isEmpty(data.payment) ? `${formatDate(data.payment.created, 'dd.mm.yyyy hh:ii')} (${_.has(banks, data.payment.data.by)
                                        ? banks[data.payment.data.by]
                                        : __('Manuálne')})` : '-'}
                                </div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Číslo zásielky')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{package_send ? data.package.number : '-'}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Poznámka')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">{this.renderValue(client.delivery_note)}</div>
                            </div>
                            <div className="order-lightbox__content__order__panel__item">
                                <div className="order-lightbox__content__order__panel__item__label">{__('Interná poznámka')}</div>
                                <div className="order-lightbox__content__order__panel__item__value">
                                    {!internal_comment.edit
                                        ? this.renderValue(data.data.internal_comment)
                                        : <Input
                                            value={internal_comment.value}
                                            onChange={value => this.onChangeInternalComment(value)}
                                            maxLength={512}
                                        />}
                                    {!internal_comment.edit
                                        ? <EditIcon onClick={() => this.editInternalComment(data.data.internal_comment)} />
                                        : <Button
                                            onClick={() => this.saveInternalComment()}
                                            color="primary"
                                            loading={internal_comment.loading}
                                        >{__('Uložiť')}</Button>}
                                </div>
                            </div>
                        </div>
                    </Paper>
                );

            case 1:
                // Zakaznik
                return (
                    <Paper className="order-lightbox__content__client">
                        <div className="order-lightbox__content__client__panel">
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('Meno')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderValue(client.name)}</div>
                            </div>
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('Fakturačná adresa')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderAddress(
                                    client.contact_name,
                                    client.address,
                                    client.city,
                                    client.zip,
                                    client.country
                                )}</div>
                            </div>
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('Dodacia adresa')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderAddress(
                                    client.delivery_contact_name,
                                    client.delivery_address,
                                    client.delivery_city,
                                    client.delivery_zip,
                                    client.delivery_country
                                )}</div>
                            </div>
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('Email')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">
                                    {!_.isEmpty(client.email) ? <a href={`mailto:${client.email}`}>{client.email}</a> : '-'}
                                </div>
                            </div>
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('Tel. číslo')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderValue(client.phone)}</div>
                            </div>
                        </div>
                        <div className="order-lightbox__content__client__panel">
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('IČO')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderValue(client.ico)}</div>
                            </div>
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('DIČ')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderValue(client.dic)}</div>
                            </div>
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('IČ DPH')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderValue(client.ic_dph)}</div>
                            </div>
                            <div className="order-lightbox__content__client__panel__item">
                                <div className="order-lightbox__content__client__panel__item__label">{__('Interná poznámka')}</div>
                                <div className="order-lightbox__content__client__panel__item__value">{this.renderValue(data._matchingData.Clients.note)}</div>
                            </div>
                        </div>
                    </Paper>
                );

            case 2:
                // Produkty
                return (
                    <Paper className="order-lightbox__content__products">
                        {!data.import_products ? _.map(products, (product, key) => {
                            let product_data = {};

                            if (_.has(data.products, product.id)) {
                                product_data = {
                                    img: data.products[product.id].img_url,
                                    name: `${data.products[product.id].name}${!_.isEmpty(product.variant) ? ` - ${product.variant}` : ''}`,
                                };
                            } else {
                                product_data = {
                                    img: '',
                                    name: product.name,
                                };
                            }

                            return (
                                <div className="order-lightbox__content__products__product" key={key}>
                                    <div className="order-lightbox__content__products__product__img">
                                        {!_.isEmpty(product_data.img) ? <img
                                            src={product_data.img}
                                            alt={product_data.name}
                                        /> : <HelpIcon />}
                                    </div>
                                    <div className="order-lightbox__content__products__product__info">
                                        <div className="order-lightbox__content__products__product__info__name">
                                            {product_data.name}
                                        </div>
                                        <div className="order-lightbox__content__products__product__info__text">
                                            <div>{__('Počet')}: {product.quantity}{`${!_.isEmpty(product.unit) ? ` ${product.unit}` : ''}`}</div>
                                            <div>{__('Jednotková cena')}: {formatAmount(product.total_price, currency)}</div>
                                            <div>{__('Celková cena')}: {formatAmount(product.total_price * product.quantity, currency)}</div>
                                            <div>{__('EAN')}: {this.renderValue(product.ean)}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) : <Message type="warning">{__('Prebieha import produktov z eshopu.')}</Message>}
                    </Paper>
                );

            case 3:
                // Udalosti
                const { id, screen } = this.props;

                return (
                    <Paper className="order-lightbox__content__logs">
                        <LogsTable itemType="order" itemId={id} screen={screen} />
                    </Paper>
                );

            case 4:
                // Ostatne
                return (
                    <Paper className="order-lightbox__content__other">
                        <div className="order-lightbox__content__other__panel">
                            {_.map(meta_data, (value, key) => <div className="order-lightbox__content__other__panel__item" key={key}>
                                <div className="order-lightbox__content__other__panel__item__label">{`${key} (meta dáta)`}</div>
                                <div className="order-lightbox__content__other__panel__item__value">{this.renderValue(value)}</div>
                            </div>)}
                        </div>
                        <div className="order-lightbox__content__other__panel" />
                    </Paper>
                );

            default:
                return null;
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { data, panel } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su vyplnene
            return <div className="order-lightbox"><CircularProgress
                className="order-lightbox__loading"
                color="primary"
                size={40}
            /></div>;
        }

        return (
            <div className="order-lightbox">
                <Paper>
                    <Tabs
                        value={panel}
                        indicatorColor="primary"
                        onChange={(event, value) => this.changePanel(value)}
                        className="order-lightbox__tabs"
                    >
                        <Tab label={__('Objednávka')} />
                        <Tab label={__('Zákaznik')} />
                        <Tab label={__('Produkty')} />
                        <Tab label={__('Udalosti')} />
                        <Tab label={__('Ostatné')} />
                    </Tabs>
                </Paper>
                <div className="order-lightbox__content">{this.renderContent(panel)}</div>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(withRouter(connect(stateToProps)(OrderLightbox)));
